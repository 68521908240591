import { defineSchema, SchemaInstance } from "../type-system/schema";

export const baseNewFulfillmentGroupSchema = defineSchema({
  fields: {
    groupId: {
      dataType: "Text",
      documentation: "Redo internal _id for the fulfillment group",
    },
  },
});

export const examplebaseNewFulfillmentGroupInstance: SchemaInstance<
  typeof baseNewFulfillmentGroupSchema
> = { groupId: "6891f5cbb1zfec0007611061" };

export function createbaseNewFulfillmentGroupInstance(
  groupId: string,
): SchemaInstance<typeof baseNewFulfillmentGroupSchema> {
  return { groupId };
}
