import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getCheckoutExperiences = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.array(
    z.object({
      _id: zExt.objectId(),
      name: z.string(),
      active: z.boolean(),
      rateTables: z.array(z.object({ _id: zExt.objectId(), name: z.string() })),
    }),
  ),
};

export type GetCheckoutExperiencesOutput = z.infer<
  typeof getCheckoutExperiences.output
>;
