import { z } from "zod";

export const updateConversionSettings = {
  input: z.object({
    teamId: z.string(),
    checkoutOptimizationEnabled: z.boolean(),
    checkoutOptimizationBilling: z
      .object({
        enabled: z.boolean(),
        startDate: z.date().optional(),
        pricePerOrderInCents: z.number(),
      })
      .optional(),
  }),
  output: z.null(),
};
