export enum REDO_OUTBOUND_CARRIER_IDS {
  FEDEX_SMARTPOST = "ca_f996de1247154d2b84c4e51f3df913f6",
  FEDEX = "ca_19dd1198bb3d462ea25612b34f04d6a7",
  EASYPOST_USPS = "ca_fa25281c95a445939738a4725bd9c2b5",
  B2B_UPS = "ca_4011dee962d44d3db1d05d9aea5549a3",
  B2C_UPS = "ca_1ae50b34cac648a2a12f8978ce65b049",
  SUREPOST_UPS = "ca_4c6ef77ad1d34c54ba56e5c73f6b8367",
  USPS = "ca_58a3433030904f4f86c1eb33a6a1aa47",
  ALTERNATIVE_USPS = "ca_84debb7ff3ad4c899d573daa993296e9",
  HATCH_USPS = "ca_9ce15d8eca4e440b94c287aab10f55a3",
  AMAZON = "ca_beb146dd1f7944f3b9fb6d564a615f96",
}

export const DEFAULT_CARRIER_ACCOUNTS = [
  REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST,
  REDO_OUTBOUND_CARRIER_IDS.FEDEX,
  REDO_OUTBOUND_CARRIER_IDS.EASYPOST_USPS,
  REDO_OUTBOUND_CARRIER_IDS.B2B_UPS,
  REDO_OUTBOUND_CARRIER_IDS.B2C_UPS,
  REDO_OUTBOUND_CARRIER_IDS.SUREPOST_UPS,
  REDO_OUTBOUND_CARRIER_IDS.USPS,
] as readonly REDO_OUTBOUND_CARRIER_IDS[];

export function removeFedexCarrierAccount(
  carrierAccounts: REDO_OUTBOUND_CARRIER_IDS[],
) {
  return carrierAccounts.filter(
    (carrier) =>
      carrier !== REDO_OUTBOUND_CARRIER_IDS.FEDEX &&
      carrier !== REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST,
  );
}
