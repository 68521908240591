import { useInput } from "@redotech/react-util/form";
import {
  composeSeeDetailsUrl,
  DEFAULT_TEXT,
  EWWidgetTextProps,
  ExtendedWarrantyBlurbType,
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyDefaultSelection,
  ExtendedWarrantyPdpExperience,
  ExtendedWarrantyPdpNoCoverageType,
  ExtendedWarrantyPdpRenderMethod,
  ExtendedWarrantyRadioOptionType,
  ExtendedWarrantyTilesSize,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { Team } from "@redotech/redo-model/team";
import {
  InsertionPosition,
  insertionPositionToDisplayText,
} from "@redotech/redo-model/widget";
import { ExtendedWarrantyCartToggleContent } from "@redotech/redo-shopify-extension/extended-warranty/cart-toggle/content";
import { ExtendedWarrantyModal } from "@redotech/redo-shopify-extension/extended-warranty/modal";
import { ExtendedWarrantyOptionsExperience } from "@redotech/redo-shopify-extension/extended-warranty/pdp-options/experience";
import { EwSettingsContext } from "@redotech/redo-shopify-extension/extended-warranty/provider/ew-settings-provider";
import { Card } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormImageUpload } from "@redotech/redo-web/image-upload";
import { LabelPosition } from "@redotech/redo-web/labeled-input";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormSwitch, SwitchSize } from "@redotech/redo-web/switch";
import { Header, Text } from "@redotech/redo-web/text";
import {
  FormTextInput,
  InputLines,
  InputTheme,
} from "@redotech/redo-web/text-input";
import { groupInput, Input, input, InputProvider } from "@redotech/ui/form";
import { optionalEqual, urlEqual } from "@redotech/util/equal";
import { assertNever } from "@redotech/util/type";
import classNames from "classnames";
import {
  Component,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ClientUploader } from "../../../client/uploader";
import { TeamContext } from "../../team";
import { ExtendedWarrantySettingsValue } from "./extended-warranties";
import * as extendedWarrantiesCss from "./extended-warranties.module.css";

const extendedWarrantyPdpExperienceToTitle: Record<
  ExtendedWarrantyPdpExperience,
  string
> = {
  [ExtendedWarrantyPdpExperience.TILES_MODAL]: "Tiles modal",
  [ExtendedWarrantyPdpExperience.UPSELL_MODAL]: "Upsell modal",
  [ExtendedWarrantyPdpExperience.RADIO_MODAL]: "Radio modal",
};

const extendedWarrantyDefaultSelectionToTitle: Record<
  ExtendedWarrantyDefaultSelection,
  string
> = {
  [ExtendedWarrantyDefaultSelection.NO_COVERAGE]: "No coverage",
  [ExtendedWarrantyDefaultSelection.CHEAPEST]: "Cheapest",
};

const extendedWarrantyPdpRenderMethodToTitle: Record<
  ExtendedWarrantyPdpRenderMethod,
  string
> = {
  [ExtendedWarrantyPdpRenderMethod.APP_BLOCK]: "App block",
  [ExtendedWarrantyPdpRenderMethod.SCRIPT_TAG]: "Script tag",
};

const extendedWarrantyCartToggleExperienceToTitle: Record<
  ExtendedWarrantyCartToggleExperience,
  string
> = {
  [ExtendedWarrantyCartToggleExperience.TOGGLE]: "Toggle",
  [ExtendedWarrantyCartToggleExperience.MODAL]: "Modal",
  [ExtendedWarrantyCartToggleExperience.REMOVE_ONLY]: "Remove only",
  [ExtendedWarrantyCartToggleExperience.DISABLED]: "Disabled",
};

const extendedWarrantyPdpNoCoverageTypeToTitle: Record<
  ExtendedWarrantyPdpNoCoverageType,
  string
> = {
  [ExtendedWarrantyPdpNoCoverageType.DETAILED]: "Detailed",
  [ExtendedWarrantyPdpNoCoverageType.BASIC]: "Basic",
};

const extendedWarrantyBlurbTypeToTitle: Record<
  ExtendedWarrantyBlurbType,
  string
> = {
  [ExtendedWarrantyBlurbType.REGULAR]: "Regular",
  [ExtendedWarrantyBlurbType.COMPACT]: "Compact",
};

const extendedWarrantyTilesSizeToTitle: Record<
  ExtendedWarrantyTilesSize,
  string
> = {
  [ExtendedWarrantyTilesSize.REGULAR]: "Regular",
  [ExtendedWarrantyTilesSize.COMPACT]: "Compact",
};

const extendedWarrantyRadioOptionTypeToTitle: Record<
  ExtendedWarrantyRadioOptionType,
  string
> = {
  [ExtendedWarrantyRadioOptionType.REGULAR]: "Regular",
  [ExtendedWarrantyRadioOptionType.EXPANDED]: "Expanded",
};

const PreviewContainer = memo(function PreviewContainer({
  children,
  previewSettings = [],
}: {
  children: ReactNode;
  previewSettings?: { input: Input<boolean>; label: string }[];
}) {
  class PreviewErrorBoundary extends Component<
    { children: ReactNode },
    { error: Error | null }
  > {
    constructor(props: { children: ReactNode }) {
      super(props);
      this.state = { error: null };
    }

    override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      this.setState({ error });
    }

    override render() {
      if (this.state.error) {
        return (
          <Flex flexDirection="column" gap="xs">
            <Text>Rendering the preview failed</Text>
            <Text>{this.state.error.message}</Text>
          </Flex>
        );
      }
      return this.props.children;
    }
  }

  // TODO: Add to a shadow root so the styles don't bleed into the parent
  return (
    <Flex flexDirection="column" gap="md" w="half">
      <Text>Preview</Text>
      <Flex
        align="center"
        className={extendedWarrantiesCss.previewContainer}
        height="full"
        justify="center"
        p="md"
        radius="md"
        w="full"
      >
        <PreviewErrorBoundary>{children}</PreviewErrorBoundary>
      </Flex>
      {previewSettings.length > 0 && (
        <Flex flexDirection="column" gap="md">
          {previewSettings.map((setting, index) => (
            <FormSwitch
              input={setting.input}
              key={index}
              label={setting.label}
              position={LabelPosition.LEFT}
              size={SwitchSize.EXTRA_SMALL}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
});

const generateExampleOfferings = (
  collectionSettings?: ExtendedWarrantySettingsValue["collectionSettings"][0],
  coverageDisabledLabel?: string,
) => {
  if (!collectionSettings) {
    return [
      {
        title: "1 year",
        id: "1-year",
        collectionHandle: "",
        price: { currency: "USD", amount: 10 },
      },
      {
        title: "2 years",
        id: "2-years",
        collectionHandle: "",
        price: { currency: "USD", amount: 20 },
      },
      {
        title: "No coverage",
        id: NO_COVERAGE_TIME_ID,
        collectionHandle: "",
        price: { currency: "USD", amount: 0 },
      },
    ];
  }

  const offerings = collectionSettings.offerings.map((offering) => ({
    title: offering.timeTitle,
    id: offering.timeId,
    collectionHandle: collectionSettings.collectionHandle,
    price: { currency: "USD", amount: Number(offering.minPrice) },
  }));

  offerings.push({
    title: coverageDisabledLabel || DEFAULT_TEXT.pdpCoverageDisabledLabel,
    id: NO_COVERAGE_TIME_ID,
    collectionHandle: collectionSettings.collectionHandle,
    price: { currency: "USD", amount: 0 },
  });

  return offerings;
};

const experienceDesignValueToWidgetTextProps = (
  settings: ExperienceDesignValue,
): EWWidgetTextProps => {
  return {
    modalTitle: settings.modalTitle || DEFAULT_TEXT.modalTitle,
    modalDescription:
      settings.modalDescription || DEFAULT_TEXT.modalDescription,
    modalCoverageHeader:
      settings.modalCoverageHeader || DEFAULT_TEXT.modalCoverageHeader,
    modalPlanHeader: settings.modalPlanHeader || DEFAULT_TEXT.modalPlanHeader,
    modalBulletPoints:
      settings.modalBulletPoints !== ""
        ? settings.modalBulletPoints.split("\n")
        : DEFAULT_TEXT.modalBulletPoints,
    pdpTitle: settings.pdpTitle || DEFAULT_TEXT.pdpTitle,
    pdpLabel: settings.pdpLabel || DEFAULT_TEXT.pdpLabel,
    pdpCoverageDisabledLabel:
      settings.pdpCoverageDisabledLabel ||
      DEFAULT_TEXT.pdpCoverageDisabledLabel,
    pdpDescription: settings.pdpDescription || DEFAULT_TEXT.pdpDescription,
    pdpAddToCartDescription:
      settings.pdpAddToCartDescription || DEFAULT_TEXT.pdpAddToCartDescription,
    pdpAddedToCartTitle:
      settings.pdpAddedToCartTitle || DEFAULT_TEXT.pdpAddedToCartTitle,
    pdpAddedToCartDescription:
      settings.pdpAddedToCartDescription ||
      DEFAULT_TEXT.pdpAddedToCartDescription,
    pdpCompactBlurbTitle:
      settings.pdpCompactBlurbTitle || DEFAULT_TEXT.pdpCompactBlurbTitle,
    pdpRadioOptionTitle:
      settings.pdpRadioOptionTitle || DEFAULT_TEXT.pdpRadioOptionTitle,
    pdpNoCoveragePrice:
      settings.pdpNoCoveragePrice || DEFAULT_TEXT.pdpNoCoveragePrice,
    cartToggleModalNotAddedDescription:
      settings.cartToggleModalNotAddedDescription ||
      DEFAULT_TEXT.cartToggleModalNotAddedDescription,
    cartToggleModalAddedDescription:
      settings.cartToggleModalAddedDescription ||
      DEFAULT_TEXT.cartToggleModalAddedDescription,
    cartToggleAddedDescription:
      settings.cartToggleAddedDescription ||
      DEFAULT_TEXT.cartToggleAddedDescription,
    cartToggleNotAddedDescription:
      settings.cartToggleNotAddedDescription ||
      DEFAULT_TEXT.cartToggleNotAddedDescription,
  };
};

const ExtendedWarrantyOptionsPreview = memo(
  function ExtendedWarrantyOptionsPreview({
    settings,
    itemSelected,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    itemSelected: boolean;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
      settings.pdpCoverageDisabledLabel,
    );

    const [selectedOffering, setSelectedOffering] = useState<
      Offering | undefined
    >(populatedOfferings[0]);

    useEffect(() => {
      if (itemSelected) {
        setSelectedOffering(populatedOfferings[0]);
      } else {
        setSelectedOffering(
          populatedOfferings.find(
            (offering) => offering.id === NO_COVERAGE_TIME_ID,
          ),
        );
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemSelected]);

    return (
      <EwSettingsContext.Provider
        value={{
          offeringConfig: { collectionSettings: [] },
          collectionSettings: [],
          getWidgetConfig: () =>
            ({ ...extendedWarrantySettings, ...settings }) as any,
        }}
      >
        <Flex
          className={extendedWarrantiesCss.pdpPreviewContainer}
          flexDirection="column"
          gap="xs"
          px="xl"
          py="lg"
        >
          <ExtendedWarrantyOptionsExperience
            cheapestOffering={populatedOfferings[0]}
            customCss={extendedWarrantySettings.widgetConfig.customPdpCss}
            experience={settings.pdpExperience}
            offerings={populatedOfferings}
            onTileClick={setSelectedOffering}
            openModal={() => {}}
            selectedOffering={selectedOffering}
            text={experienceDesignValueToWidgetTextProps(settings)}
          />
        </Flex>
      </EwSettingsContext.Provider>
    );
  },
);

const ExtendedWarrantyModalPreview = memo(
  function ExtendedWarrantyModalPreview({
    settings,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const team = useContext(TeamContext);
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
    );

    return (
      <ExtendedWarrantyModal
        customCss={extendedWarrantySettings.widgetConfig.customModalCss}
        isPreview
        logo={settings.logo?.toString()}
        offerings={populatedOfferings}
        onClose={() => {}}
        onTileClick={() => {}}
        open
        seeDetailsUrl={
          settings.modalSeeDetailsUrl
            ? settings.modalSeeDetailsUrl
            : team?.storeUrl
              ? composeSeeDetailsUrl(team?.storeUrl)
              : undefined
        }
        selectedOffering={undefined}
        sideImage={settings.sideImage?.toString()}
        text={experienceDesignValueToWidgetTextProps(settings)}
      />
    );
  },
);

const ExtendedWarrantyCartTogglePreview = memo(
  function ExtendedWarrantyCartTogglePreview({
    settings,
    itemSelected,
    extendedWarrantySettings,
  }: {
    settings: ExperienceDesignValue;
    itemSelected: boolean;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
  }) {
    const populatedOfferings = generateExampleOfferings(
      extendedWarrantySettings.collectionSettings[0],
    );

    const [selectedOffering, setSelectedOffering] = useState<
      Offering | undefined
    >(populatedOfferings[0]);

    useEffect(() => {
      if (itemSelected) {
        setSelectedOffering(populatedOfferings[0]);
      } else {
        setSelectedOffering(undefined);
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemSelected, settings.cartToggleExperience]);

    return (
      <Flex
        className={extendedWarrantiesCss.togglePreviewContainer}
        flexDirection="column"
        gap="xs"
        px="xl"
        py="lg"
      >
        <ExtendedWarrantyCartToggleContent
          addWarranty={(variantId, selectedOffering) => {
            setSelectedOffering(selectedOffering);
          }}
          cartToggleExperience={settings.cartToggleExperience}
          customCss={extendedWarrantySettings.widgetConfig.customToggleCss}
          isPreview
          offerings={populatedOfferings}
          removeWarranty={() => {
            if (
              settings.cartToggleExperience !==
              ExtendedWarrantyCartToggleExperience.REMOVE_ONLY
            ) {
              setSelectedOffering(undefined);
            }
          }}
          selection={selectedOffering}
          text={experienceDesignValueToWidgetTextProps(settings)}
          variantId="123"
        />
      </Flex>
    );
  },
);

export const previewForm = groupInput({
  itemSelectedPdp: input<boolean>(),
  itemSelectedCartToggle: input<boolean>(),
});

export type PreviewForm = InputProvider.Form<typeof previewForm>;

export const experienceDesignForm = groupInput(
  {
    logo: input<URL | undefined>({ equal: optionalEqual(urlEqual) }),
    sideImage: input<URL | undefined>({ equal: optionalEqual(urlEqual) }),
    // TODO: Add HTML validation so that we can't save invalid HTML
    modalTitle: input<string>(),
    modalCoverageHeader: input<string>(),
    modalDescription: input<string>(),
    modalPlanHeader: input<string>(),
    modalBulletPoints: input<string>(),
    modalSeeDetailsUrl: input<string>(),
    pdpExperience: input<ExtendedWarrantyPdpExperience>(),
    pdpDefaultSelection: input<ExtendedWarrantyDefaultSelection>(),
    pdpRenderMethod: input<ExtendedWarrantyPdpRenderMethod>(),
    pdpInsertPosition: input<InsertionPosition>(),
    pdpSelector: input<string>(),
    pdpTitle: input<string>(),
    pdpLabel: input<string>(),
    pdpCoverageDisabledLabel: input<string>(),
    pdpDescription: input<string>(),
    pdpAddToCartDescription: input<string>(),
    pdpAddedToCartTitle: input<string>(),
    pdpAddedToCartDescription: input<string>(),
    pdpBlurbType: input<ExtendedWarrantyBlurbType>(),
    pdpCompactBlurbTitle: input<string>(),
    pdpTilesSize: input<ExtendedWarrantyTilesSize>(),
    pdpRadioOptionType: input<ExtendedWarrantyRadioOptionType>(),
    pdpRadioOptionTitle: input<string>(),
    pdpNoCoverageType: input<ExtendedWarrantyPdpNoCoverageType>(),
    pdpNoCoveragePrice: input<string>(),
    pdpTilesBackdropEnabled: input<boolean>(),
    cartToggleExperience: input<ExtendedWarrantyCartToggleExperience>(),
    cartToggleModalNotAddedDescription: input<string>(),
    cartToggleModalAddedDescription: input<string>(),
    cartToggleAddedDescription: input<string>(),
    cartToggleNotAddedDescription: input<string>(),
  },
  {
    validator: (value) => {
      if (
        value.pdpRenderMethod === ExtendedWarrantyPdpRenderMethod.SCRIPT_TAG &&
        !value.pdpSelector
      ) {
        return ["PDP selector is required"];
      }
      return [];
    },
  },
);

export type ExperienceDesignForm = InputProvider.Form<
  typeof experienceDesignForm
>;

export type ExperienceDesignValue = InputProvider.Value<
  typeof experienceDesignForm
>;

export const experienceDesignDefault = {
  logo: undefined,
  sideImage: undefined,
  modalTitle: "",
  modalCoverageHeader: "",
  modalDescription: "",
  modalPlanHeader: "",
  modalBulletPoints: "",
  modalSeeDetailsUrl: "",
  pdpExperience: ExtendedWarrantyPdpExperience.TILES_MODAL,
  pdpDefaultSelection: ExtendedWarrantyDefaultSelection.NO_COVERAGE,
  pdpRenderMethod: ExtendedWarrantyPdpRenderMethod.APP_BLOCK,
  pdpInsertPosition: InsertionPosition.AFTER,
  pdpNoCoverageType: ExtendedWarrantyPdpNoCoverageType.BASIC,
  pdpBlurbType: ExtendedWarrantyBlurbType.REGULAR,
  pdpRadioOptionType: ExtendedWarrantyRadioOptionType.REGULAR,
  pdpNoCoveragePrice: "",
  pdpTilesBackdropEnabled: false,
  pdpSelector: "",
  pdpTitle: "",
  pdpLabel: "",
  pdpCoverageDisabledLabel: "",
  pdpDescription: "",
  pdpAddToCartDescription: "",
  pdpAddedToCartTitle: "",
  pdpAddedToCartDescription: "",
  pdpCompactBlurbTitle: "",
  pdpRadioOptionTitle: "",
  pdpTilesSize: ExtendedWarrantyTilesSize.REGULAR,
  cartToggleExperience: ExtendedWarrantyCartToggleExperience.TOGGLE,
  cartToggleModalNotAddedDescription: "",
  cartToggleModalAddedDescription: "",
  cartToggleAddedDescription: "",
  cartToggleNotAddedDescription: "",
};

export function experienceDesignTeamToFormValue(
  team?: Team,
): ExperienceDesignValue {
  return {
    logo: team?.settings.extendedWarranties?.logo
      ? new URL(team?.settings.extendedWarranties?.logo)
      : undefined,
    sideImage: team?.settings.extendedWarranties?.sideImage
      ? new URL(team?.settings.extendedWarranties?.sideImage)
      : undefined,
    modalTitle: team?.settings.extendedWarranties?.modalTitle || "",
    modalCoverageHeader:
      team?.settings.extendedWarranties?.modalCoverageHeader || "",
    modalDescription: team?.settings.extendedWarranties?.modalDescription || "",
    modalBulletPoints:
      team?.settings.extendedWarranties?.modalBulletPoints?.join("\n") || "",
    modalPlanHeader: team?.settings.extendedWarranties?.modalPlanHeader || "",
    modalSeeDetailsUrl:
      team?.settings.extendedWarranties?.modalSeeDetailsUrl || "",
    pdpExperience:
      team?.settings.extendedWarranties?.pdpExperience ||
      ExtendedWarrantyPdpExperience.TILES_MODAL,
    pdpDefaultSelection:
      team?.settings.extendedWarranties?.defaultSelection ||
      ExtendedWarrantyDefaultSelection.NO_COVERAGE,
    pdpRenderMethod:
      team?.settings.extendedWarranties?.pdpRenderMethod ||
      ExtendedWarrantyPdpRenderMethod.APP_BLOCK,
    pdpInsertPosition:
      team?.settings.extendedWarranties?.pdpInsertPosition ||
      InsertionPosition.AFTER,
    pdpRadioOptionType:
      team?.settings.extendedWarranties?.pdpRadioOptionType ||
      ExtendedWarrantyRadioOptionType.REGULAR,
    pdpNoCoverageType:
      team?.settings.extendedWarranties?.pdpNoCoverageType ||
      ExtendedWarrantyPdpNoCoverageType.BASIC,
    pdpBlurbType:
      team?.settings.extendedWarranties?.pdpBlurbType ||
      ExtendedWarrantyBlurbType.REGULAR,
    pdpTilesSize:
      team?.settings.extendedWarranties?.pdpTilesSize ||
      ExtendedWarrantyTilesSize.REGULAR,
    pdpNoCoveragePrice:
      team?.settings.extendedWarranties?.pdpNoCoveragePrice || "",
    pdpTilesBackdropEnabled:
      team?.settings.extendedWarranties?.pdpTilesBackdropEnabled || false,
    pdpSelector: team?.settings.extendedWarranties?.pdpSelector || "",
    pdpTitle: team?.settings.extendedWarranties?.pdpTitle || "",
    pdpLabel: team?.settings.extendedWarranties?.pdpLabel || "",
    pdpCoverageDisabledLabel:
      team?.settings.extendedWarranties?.pdpCoverageDisabledLabel || "",
    pdpDescription: team?.settings.extendedWarranties?.pdpDescription || "",
    pdpAddToCartDescription:
      team?.settings.extendedWarranties?.pdpAddToCartDescription || "",
    pdpAddedToCartTitle:
      team?.settings.extendedWarranties?.pdpAddedToCartTitle || "",
    pdpAddedToCartDescription:
      team?.settings.extendedWarranties?.pdpAddedToCartDescription || "",
    pdpCompactBlurbTitle:
      team?.settings.extendedWarranties?.pdpCompactBlurbTitle || "",
    pdpRadioOptionTitle:
      team?.settings.extendedWarranties?.pdpRadioOptionTitle || "",
    cartToggleExperience:
      team?.settings.extendedWarranties?.cartToggleExperience ||
      ExtendedWarrantyCartToggleExperience.TOGGLE,
    cartToggleModalNotAddedDescription:
      team?.settings.extendedWarranties?.cartToggleModalNotAddedDescription ||
      "",
    cartToggleModalAddedDescription:
      team?.settings.extendedWarranties?.cartToggleModalAddedDescription || "",
    cartToggleAddedDescription:
      team?.settings.extendedWarranties?.cartToggleAddedDescription || "",
    cartToggleNotAddedDescription:
      team?.settings.extendedWarranties?.cartToggleNotAddedDescription || "",
  };
}

export const ExperienceDesignCard = memo(function ExperienceDesignCard({
  input,
  extendedWarrantySettings,
}: {
  input: ExperienceDesignForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
}) {
  return (
    <Card title="Experience Design">
      <ExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
      />
    </Card>
  );
});

const getPreviewValue = (
  base: ExperienceDesignValue | undefined,
  inputValue: ExperienceDesignValue,
): ExperienceDesignValue => {
  if (!base) {
    return inputValue;
  }

  const filteredInput = Object.fromEntries(
    Object.entries(inputValue).filter(
      ([_, value]) => value !== "" && value !== undefined,
    ),
  );

  return { ...base, ...filteredInput };
};

export const ExperienceDesign = memo(function ExperienceDesign({
  input,
  extendedWarrantySettings,
  value,
}: {
  input: ExperienceDesignForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
  value?: ExperienceDesignValue;
}) {
  const previewInput = useInput(previewForm, {
    itemSelectedPdp: true,
    itemSelectedCartToggle: true,
  });

  const previewValue = useMemo(() => {
    return getPreviewValue(value, input.value);
  }, [value, input.value]);

  return (
    <Flex flexDirection="column" gap="4xl">
      <ModalExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
        previewValue={previewValue}
      />
      <Divider />
      <PDPExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
        previewInput={previewInput}
        previewValue={previewValue}
      />
      <Divider />
      <CartToggleExperienceDesign
        extendedWarrantySettings={extendedWarrantySettings}
        input={input}
        previewInput={previewInput}
        previewValue={previewValue}
      />
    </Flex>
  );
});

export const ModalExperienceDesign = memo(function ModalExperienceDesign({
  input,
  extendedWarrantySettings,
  previewValue,
}: {
  input: ExperienceDesignForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
  previewValue: ExperienceDesignValue;
}) {
  const {
    logo,
    sideImage,
    modalTitle,
    modalCoverageHeader,
    modalDescription,
    modalBulletPoints,
    modalSeeDetailsUrl,
  } = input.inputs;

  const team = useContext(TeamContext);

  return (
    <Flex flexDirection="column" gap="none">
      <Flex flexDirection="column" gap="none">
        <Header as="h3">Modal</Header>
        <Text fontSize="sm" fontStyle="italic">
          Each element can render HTML. Use the preview to see how it will look.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Flex height="fit-content" w="half">
          <div
            className={classNames(gridCss.grid, extendedWarrantiesCss.fullGrid)}
          >
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <ClientUploader>
                <FormImageUpload input={logo} label="Logo" />
              </ClientUploader>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <ClientUploader>
                <FormImageUpload input={sideImage} label="Left side image" />
              </ClientUploader>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormTextInput
                input={modalTitle}
                label="Modal title"
                placeholder={DEFAULT_TEXT.modalTitle}
              />
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormTextInput
                input={modalCoverageHeader}
                label="Modal coverage header"
                placeholder={DEFAULT_TEXT.modalCoverageHeader}
              />
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                description="If left blank, the link will direct to Redo's Warranty Policy page."
                input={modalSeeDetailsUrl}
                label="Modal see details url"
                placeholder={
                  team?.storeUrl
                    ? composeSeeDetailsUrl(team?.storeUrl)
                    : "Redo's Warranty Policy page"
                }
              />
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                input={modalDescription}
                label="Modal description"
                lines={InputLines.MULTI}
                placeholder={DEFAULT_TEXT.modalDescription}
                theme={InputTheme.FORM}
              />
            </div>
            <div className={gridCss.span12}>
              <FormTextInput
                description="Bullet points will be displayed in a list, one per line."
                input={modalBulletPoints}
                label="Modal bullet points"
                lines={InputLines.MULTI}
                placeholder={DEFAULT_TEXT.modalBulletPoints.join("\n")}
                theme={InputTheme.FORM}
              />
            </div>
          </div>
        </Flex>
        <PreviewContainer>
          <ExtendedWarrantyModalPreview
            extendedWarrantySettings={extendedWarrantySettings}
            settings={previewValue}
          />
        </PreviewContainer>
      </Flex>
    </Flex>
  );
});

const PDPRegularBlurb = memo(function PDPRegularBlurb({
  input,
}: {
  input: ExperienceDesignForm;
}) {
  const { pdpTitle, pdpDescription } = input.inputs;

  return (
    <>
      <div className={gridCss.span12}>
        <FormTextInput
          input={pdpTitle}
          label="Block title"
          placeholder={DEFAULT_TEXT.pdpTitle}
        />
      </div>
      <div className={gridCss.span12}>
        <FormTextInput
          input={pdpDescription}
          label="Block description"
          lines={InputLines.MULTI}
          placeholder={DEFAULT_TEXT.pdpDescription}
          theme={InputTheme.FORM}
        />
      </div>
    </>
  );
});

const PDPCompactBlurb = memo(function PDPCompactBlurb({
  input,
}: {
  input: ExperienceDesignForm;
}) {
  const { pdpCompactBlurbTitle } = input.inputs;

  return (
    <div className={gridCss.span12}>
      <FormTextInput
        input={pdpCompactBlurbTitle}
        label="Compact title"
        placeholder={DEFAULT_TEXT.pdpCompactBlurbTitle}
      />
    </div>
  );
});

const PDPTilesExperienceDesign = memo(function PDPTilesExperienceDesign({
  input,
}: {
  input: ExperienceDesignForm;
}) {
  const {
    pdpCoverageDisabledLabel,
    pdpLabel,
    pdpNoCoverageType,
    pdpNoCoveragePrice,
    pdpTilesBackdropEnabled,
    pdpBlurbType,
    pdpTilesSize,
  } = input.inputs;

  let blurb = null;
  switch (pdpBlurbType.value) {
    case ExtendedWarrantyBlurbType.REGULAR:
      blurb = <PDPRegularBlurb input={input} />;
      break;
    case ExtendedWarrantyBlurbType.COMPACT:
      blurb = <PDPCompactBlurb input={input} />;
      break;
    default:
      assertNever(pdpBlurbType.value);
  }

  return (
    <>
      <div className={gridCss.span12}>
        <FormSelectDropdown
          input={pdpBlurbType}
          label="Blurb type"
          options={Object.values(ExtendedWarrantyBlurbType)}
        >
          {(option) => extendedWarrantyBlurbTypeToTitle[option]}
        </FormSelectDropdown>
      </div>
      {blurb}
      <div
        className={
          pdpNoCoverageType.value === ExtendedWarrantyPdpNoCoverageType.DETAILED
            ? classNames(gridCss.span12, gridCss.span6M)
            : gridCss.span12
        }
      >
        <FormSelectDropdown
          input={pdpNoCoverageType}
          label="No coverage type"
          options={Object.values(ExtendedWarrantyPdpNoCoverageType)}
        >
          {(option) => extendedWarrantyPdpNoCoverageTypeToTitle[option]}
        </FormSelectDropdown>
      </div>
      {pdpNoCoverageType.value ===
        ExtendedWarrantyPdpNoCoverageType.DETAILED && (
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormTextInput
            input={pdpNoCoveragePrice}
            label="No coverage price"
            placeholder={DEFAULT_TEXT.pdpNoCoveragePrice}
          />
        </div>
      )}
      <div className={gridCss.span12}>
        <FormSelectDropdown
          input={pdpTilesSize}
          label="Tiles size"
          options={Object.values(ExtendedWarrantyTilesSize)}
        >
          {(option) => extendedWarrantyTilesSizeToTitle[option]}
        </FormSelectDropdown>
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpCoverageDisabledLabel}
          label="Coverage disabled label"
          placeholder={DEFAULT_TEXT.pdpCoverageDisabledLabel}
        />
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpLabel}
          label="Block label"
          placeholder={DEFAULT_TEXT.pdpLabel}
        />
      </div>
      <div className={gridCss.span12}>
        <FormSwitch input={pdpTilesBackdropEnabled} label="Enable backdrop" />
      </div>
    </>
  );
});

const PDPRadioExperienceDesign = memo(function PDPRadioExperienceDesign({
  input,
}: {
  input: ExperienceDesignForm;
}) {
  const {
    pdpCompactBlurbTitle,
    pdpRadioOptionTitle,
    pdpCoverageDisabledLabel,
    pdpRadioOptionType,
  } = input.inputs;

  return (
    <>
      <div className={gridCss.span12}>
        <FormSelectDropdown
          input={pdpRadioOptionType}
          label="Radio option type"
          options={Object.values(ExtendedWarrantyRadioOptionType)}
        >
          {(option) => extendedWarrantyRadioOptionTypeToTitle[option]}
        </FormSelectDropdown>
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpCompactBlurbTitle}
          label="Block title"
          placeholder={DEFAULT_TEXT.pdpCompactBlurbTitle}
        />
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpCoverageDisabledLabel}
          label="Coverage disabled label"
          placeholder={DEFAULT_TEXT.pdpCoverageDisabledLabel}
        />
      </div>
      <div className={gridCss.span12}>
        <FormTextInput
          input={pdpRadioOptionTitle}
          label="Option title"
          placeholder={DEFAULT_TEXT.pdpRadioOptionTitle}
        />
      </div>
    </>
  );
});

const PDPUpsellExperienceDesign = memo(function PDPUpsellExperienceDesign({
  input,
}: {
  input: ExperienceDesignForm;
}) {
  const {
    pdpAddedToCartTitle,
    pdpTitle,
    pdpAddedToCartDescription,
    pdpAddToCartDescription,
  } = input.inputs;

  return (
    <>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpAddedToCartTitle}
          label="Added to cart title"
          placeholder={DEFAULT_TEXT.pdpAddedToCartTitle}
        />
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpTitle}
          label="Add to cart title"
          placeholder={DEFAULT_TEXT.pdpTitle}
        />
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpAddedToCartDescription}
          label="Added to cart description"
          lines={InputLines.MULTI}
          placeholder={DEFAULT_TEXT.pdpAddedToCartDescription}
          theme={InputTheme.FORM}
        />
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <FormTextInput
          input={pdpAddToCartDescription}
          label="Add to cart description"
          lines={InputLines.MULTI}
          placeholder={DEFAULT_TEXT.pdpAddToCartDescription}
          theme={InputTheme.FORM}
        />
      </div>
    </>
  );
});

export const PDPExperienceDesign = memo(function PDPExperienceDesign({
  input,
  previewInput,
  extendedWarrantySettings,
  previewValue,
}: {
  input: ExperienceDesignForm;
  previewInput: PreviewForm;
  extendedWarrantySettings: ExtendedWarrantySettingsValue;
  previewValue: ExperienceDesignValue;
}) {
  const {
    pdpExperience,
    pdpDefaultSelection,
    pdpRenderMethod,
    pdpSelector,
    pdpInsertPosition,
  } = input.inputs;

  return (
    <Flex flexDirection="column" gap="none">
      <Flex flexDirection="column" gap="none">
        <Header as="h3">App Block</Header>
        <Text fontSize="sm" fontStyle="italic">
          Each element can render HTML. Use the preview to see how it will look.
        </Text>
      </Flex>
      <Flex justify="space-between">
        <Flex height="fit-content" w="half">
          <div
            className={classNames(gridCss.grid, extendedWarrantiesCss.fullGrid)}
          >
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormSelectDropdown
                input={pdpRenderMethod}
                label="Render method"
                options={Object.values(ExtendedWarrantyPdpRenderMethod)}
              >
                {(option) => extendedWarrantyPdpRenderMethodToTitle[option]}
              </FormSelectDropdown>
            </div>
            <div className={classNames(gridCss.span12, gridCss.span6M)}>
              <FormSelectDropdown
                input={pdpDefaultSelection}
                label="Default selection"
                options={Object.values(ExtendedWarrantyDefaultSelection)}
              >
                {(option) => extendedWarrantyDefaultSelectionToTitle[option]}
              </FormSelectDropdown>
            </div>
            {pdpRenderMethod.value ===
            ExtendedWarrantyPdpRenderMethod.SCRIPT_TAG ? (
              <>
                <div className={gridCss.span12}>
                  <FormTextInput
                    input={pdpSelector}
                    label="PDP selector for the extended warranty toggle"
                  />
                </div>
                <div className={gridCss.span12}>
                  <FormSelectDropdown
                    input={pdpInsertPosition}
                    label="Insert position"
                    options={Object.values(InsertionPosition)}
                  >
                    {(option) => insertionPositionToDisplayText[option]}
                  </FormSelectDropdown>
                </div>
              </>
            ) : null}
            <div className={gridCss.span12}>
              <FormSelectDropdown
                input={pdpExperience}
                label="Block experience"
                options={Object.values(ExtendedWarrantyPdpExperience)}
              >
                {(option) => extendedWarrantyPdpExperienceToTitle[option]}
              </FormSelectDropdown>
            </div>
            {pdpExperience.value ===
            ExtendedWarrantyPdpExperience.RADIO_MODAL ? (
              <PDPRadioExperienceDesign input={input} />
            ) : pdpExperience.value ===
              ExtendedWarrantyPdpExperience.TILES_MODAL ? (
              <PDPTilesExperienceDesign input={input} />
            ) : pdpExperience.value ===
              ExtendedWarrantyPdpExperience.UPSELL_MODAL ? (
              <PDPUpsellExperienceDesign input={input} />
            ) : null}
          </div>
        </Flex>
        <PreviewContainer
          previewSettings={
            pdpExperience.value === ExtendedWarrantyPdpExperience.UPSELL_MODAL
              ? [
                  {
                    input: previewInput.inputs.itemSelectedPdp,
                    label: "Item selected",
                  },
                ]
              : []
          }
        >
          <ExtendedWarrantyOptionsPreview
            extendedWarrantySettings={extendedWarrantySettings}
            itemSelected={previewInput.value.itemSelectedPdp}
            settings={previewValue}
          />
        </PreviewContainer>
      </Flex>
    </Flex>
  );
});

export const CartToggleExperienceDesign = memo(
  function CartToggleExperienceDesign({
    input,
    previewInput,
    extendedWarrantySettings,
    previewValue,
  }: {
    input: ExperienceDesignForm;
    previewInput: PreviewForm;
    extendedWarrantySettings: ExtendedWarrantySettingsValue;
    previewValue: ExperienceDesignValue;
  }) {
    const {
      cartToggleExperience,
      cartToggleModalNotAddedDescription,
      cartToggleModalAddedDescription,
      cartToggleNotAddedDescription,
      cartToggleAddedDescription,
    } = input.inputs;

    return (
      <Flex flexDirection="column" gap="none">
        <Flex flexDirection="column" gap="none">
          <Header as="h3">Cart Toggle</Header>
          <Text fontSize="sm" fontStyle="italic">
            Each element can render HTML. Use the preview to see how it will
            look.
          </Text>
        </Flex>
        <Flex justify="space-between">
          <Flex height="fit-content" w="half">
            <div
              className={classNames(
                gridCss.grid,
                extendedWarrantiesCss.fullGrid,
              )}
            >
              <div className={gridCss.span12}>
                <FormSelectDropdown
                  input={cartToggleExperience}
                  label="Block experience"
                  options={Object.values(ExtendedWarrantyCartToggleExperience)}
                >
                  {(option) =>
                    extendedWarrantyCartToggleExperienceToTitle[option]
                  }
                </FormSelectDropdown>
              </div>
              {cartToggleExperience.value ===
              ExtendedWarrantyCartToggleExperience.MODAL ? (
                <>
                  <div className={classNames(gridCss.span12, gridCss.span6M)}>
                    <FormTextInput
                      input={cartToggleModalAddedDescription}
                      label="Added description"
                      placeholder={DEFAULT_TEXT.cartToggleModalAddedDescription}
                    />
                  </div>
                  <div className={classNames(gridCss.span12, gridCss.span6M)}>
                    <FormTextInput
                      input={cartToggleModalNotAddedDescription}
                      label="Not added description"
                      placeholder={
                        DEFAULT_TEXT.cartToggleModalNotAddedDescription
                      }
                    />
                  </div>
                </>
              ) : cartToggleExperience.value ===
                ExtendedWarrantyCartToggleExperience.TOGGLE ? (
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={cartToggleNotAddedDescription}
                    label="Not added description"
                    placeholder={DEFAULT_TEXT.cartToggleNotAddedDescription}
                  />
                </div>
              ) : null}
              {cartToggleExperience.value ===
                ExtendedWarrantyCartToggleExperience.REMOVE_ONLY ||
              cartToggleExperience.value ===
                ExtendedWarrantyCartToggleExperience.TOGGLE ? (
                <div className={classNames(gridCss.span12, gridCss.span6M)}>
                  <FormTextInput
                    input={cartToggleAddedDescription}
                    label="Added description"
                    placeholder={DEFAULT_TEXT.cartToggleAddedDescription}
                  />
                </div>
              ) : null}
            </div>
          </Flex>
          <PreviewContainer
            previewSettings={
              cartToggleExperience.value ===
              ExtendedWarrantyCartToggleExperience.MODAL
                ? [
                    {
                      input: previewInput.inputs.itemSelectedCartToggle,
                      label: "Item selected",
                    },
                  ]
                : []
            }
          >
            {cartToggleExperience.value !==
            ExtendedWarrantyCartToggleExperience.DISABLED ? (
              <ExtendedWarrantyCartTogglePreview
                extendedWarrantySettings={extendedWarrantySettings}
                itemSelected={previewInput.value.itemSelectedCartToggle}
                settings={previewValue}
              />
            ) : (
              <Flex>
                <Text>Disabled</Text>
              </Flex>
            )}
          </PreviewContainer>
        </Flex>
      </Flex>
    );
  },
);
