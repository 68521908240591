import { getSignupFormSchema as getSignupForm } from "@redotech/redo-model/marketing/schemas/get-signup-form-schema";
import { submitSignupFormStepSchema as submitSignupFormStep } from "@redotech/redo-model/marketing/schemas/submit-signup-form-step-schema";
import { subscribeToEmailMarketingSchema as subscribeToEmailMarketing } from "@redotech/redo-model/marketing/schemas/subscribe-to-email-marketing-schema";
import { logSignupFormAnalyticsSchema as logSignupFormAnalytics } from "@redotech/redo-model/marketing/schemas/view-signup-form-schema";
import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { addProductsToCustomerProductList } from "./schema/add-products-to-customer-product-list";
import { getCheckoutOptimizationConfig } from "./schema/checkout-optimization/get-checkout-optimization-config";
import { getCheckoutOptimizationCustomer } from "./schema/checkout-optimization/get-checkout-optimization-customer";
import { createOneTimePasscode } from "./schema/create-one-time-passcode";
import { createReview } from "./schema/create-review";
import { createReviewDiscount } from "./schema/create-review-discount";
import { getFormsByGoal } from "./schema/forms/get-forms-by-goal";
import { submitBackInStockForm } from "./schema/forms/submit-back-in-stock-form";
import { getBrandKit } from "./schema/get-brand-kit";
import { getConciergeTreatment } from "./schema/get-concierge-treatment";
import { getCustomerAuthFromCustomerWidgetAuth } from "./schema/get-customer-auth-from-customer-widget-auth";
import { getExpandedWarrantyTreatment } from "./schema/get-expanded-warranty-treatment";
import { getOrderByNumber } from "./schema/get-order-by-number";
import { getProductById } from "./schema/get-product-by-id";
import { getProductQuestions } from "./schema/get-product-questions";
import { getShopifyExtensionTeamInfo } from "./schema/get-shopify-extension-team-info";
import { getEmbeddedForms } from "./schema/marketing-embedded-form/get-embedded-forms";
import { getActiveReviewForm } from "./schema/review-form/get-active-review-form";
import { getProductReviewsRating } from "./schema/reviews/get-product-reviews-rating";
import { getReviewsSummaryByProductId } from "./schema/reviews/get-reviews-summary-by-product-id";
import { getSortedProductReviews } from "./schema/reviews/get-sorted-product-reviews";
import { conciergePresent } from "./schema/user-session/concierge-present";
import { verifyOneTimePasscode } from "./schema/verify-one-time-passcode";

export const rpcDefinition = {
  addProductsToCustomerProductList,
  getCustomerAuthFromCustomerWidgetAuth,
  getShopifyExtensionTeamInfo,
  createOneTimePasscode,
  verifyOneTimePasscode,
  getReviewsSummaryByProductId,
  getSignupForm,
  logSignupFormAnalytics,
  submitSignupFormStep,
  getConciergeTreatment,
  getExpandedWarrantyTreatment,
  getProductQuestions,
  createReviewDiscount,
  getProductReviewsRating,
  getActiveReviewForm,
  createReview,
  getProductById,
  getOrderByNumber,
  getBrandKit,
  getSortedProductReviews,
  subscribeToEmailMarketing,
  conciergePresent,
  getEmbeddedForms,
  getFormsByGoal,
  submitBackInStockForm,
  getCheckoutOptimizationConfig,
  getCheckoutOptimizationCustomer,
};

export type RedoShopifyRpcDefinition = InferRpcDefinition<typeof rpcDefinition>;
