import type { ClientOptions, RpcClient } from "@redotech/rpc/client";
import { createRpcClient } from "@redotech/rpc/client";
import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { rpcDefinition } from "./definition";

export function createRedoShopifyRpcClient(
  options: ClientOptions<typeof rpcDefinition>,
): RpcClient<InferRpcDefinition<typeof rpcDefinition>> {
  return createRpcClient(rpcDefinition, options);
}

export type RedoShopifyRpcClient = ReturnType<
  typeof createRedoShopifyRpcClient
>;
