import { z } from "zod";

export const FulfillmentCutoffDayOfWeekParser = z.object({
  activeFulfillmentDay: z.boolean(),
  cutoffTime: z.union([z.string(), z.null()]),
});
export type FulfillmentCutoffDayOfWeek = z.infer<
  typeof FulfillmentCutoffDayOfWeekParser
>;

export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export const FulfillmentCutoffScheduleParser = z.object({
  timezone: z.string(),
  [daysOfWeek[0]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[1]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[2]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[3]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[4]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[5]]: FulfillmentCutoffDayOfWeekParser,
  [daysOfWeek[6]]: FulfillmentCutoffDayOfWeekParser,
});
export type FulfillmentCutoffSchedule = z.infer<
  typeof FulfillmentCutoffScheduleParser
>;

export const DefaultFulfillmentCutoffSchedule: FulfillmentCutoffSchedule = {
  timezone: "America/New_York",
  sunday: { activeFulfillmentDay: false, cutoffTime: null },
  monday: { activeFulfillmentDay: true, cutoffTime: "14:00" },
  tuesday: { activeFulfillmentDay: true, cutoffTime: "14:00" },
  wednesday: { activeFulfillmentDay: true, cutoffTime: "14:00" },
  thursday: { activeFulfillmentDay: true, cutoffTime: "14:00" },
  friday: { activeFulfillmentDay: true, cutoffTime: "14:00" },
  saturday: { activeFulfillmentDay: false, cutoffTime: null },
};
