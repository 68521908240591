import { useEffect, useState } from "react";

const SCREEN_SIZE_THRESHOLD = 600;

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < SCREEN_SIZE_THRESHOLD,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < SCREEN_SIZE_THRESHOLD);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
