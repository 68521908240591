import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { SchemaType } from "./advanced-flow/schemas/schemas";
import { categories } from "./advanced-flow/triggers";
import { buttonHierarchies, fontWeights } from "./brand-kit";
import {
  Alignment,
  ButtonLinkType,
  CartLayoutType,
  EmailBlockType,
  EmailHeaderType,
  FontFamily,
  FontWeight,
  ProductSelectionType,
  ScratcherSize,
  Section,
  ShoppableProductsSize,
  Size,
  SocialPlatform,
  VerticalAlignment,
} from "./email-builder";

export interface EmailTemplateRow extends EmailTemplate {
  _id: any;
}
export const paddingSchema = z.object({
  top: z.number().min(0),
  right: z.number().min(0),
  bottom: z.number().min(0),
  left: z.number().min(0),
});

const baseSectionSchema = z.object({
  blockId: zExt.objectId().optional(),
  schemaFieldName: z.string().optional(),
  sectionPadding: paddingSchema,
  sectionColor: z.string(),
});

export const discountSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.DISCOUNT),
  discountId: zExt.objectId().optional(),
  alignment: z.nativeEnum(Alignment),
  fontFamily: z.nativeEnum(FontFamily),
  fontWeight: z.nativeEnum(FontWeight),
  fontSize: z.number(),
  textColor: z.string(),
  blockBackgroundColor: z.string(),
});

export const columnSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.COLUMN),
  columns: z.array(z.lazy(() => sectionWithoutColumnSchema.nullable())),
  columnCount: z.number(),
  gap: z.number(),
  stackOnMobile: z.boolean(),
  alignment: z.nativeEnum(VerticalAlignment),
});

export const menuItemSchema = z.object({ id: z.string(), label: z.string() });

export const menuSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.MENU),
  menuItems: z.array(menuItemSchema),
  linkColor: z.string(),
  alignment: z.nativeEnum(Alignment),
  fontFamily: z.nativeEnum(FontFamily),
  fontSize: z.number(),
  textColor: z.string(),
  stackOnMobile: z.boolean(),
});

export const socialItemSchema = z.object({
  id: z.string(),
  platform: z.nativeEnum(SocialPlatform),
  url: z.string(),
});

export const socialsSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SOCIALS),
  socialLinks: z.array(socialItemSchema),
  iconColor: z.nativeEnum(Section.SocialIconColors),
  iconPadding: z.number(),
  alignment: z.nativeEnum(Alignment),
});

export const headerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.HEADER),
  headerType: z.nativeEnum(EmailHeaderType),
  layout: z.nativeEnum(Alignment),
  imageUrl: z.string(),
  text: z.string(),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  logoHeight: z.number(),
  imageHeight: z.number(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
});

export const footerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.FOOTER),
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  padding: paddingSchema,
  textColor: z.string(),
  alignment: z.nativeEnum(Alignment),
  fontSize: z.number().optional(),
  fontFamily: z.nativeEnum(FontFamily).optional(),
});

export const textSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TEXT),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  linkColor: z.string(),
  text: z.string(),
});

export const buttonSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.BUTTON),
  width: z.number(),
  height: z.number(),
  size: z.nativeEnum(Size),
  alignment: z.nativeEnum(Alignment),
  cornerRadius: z.number(),
  buttonText: z.string(),
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  padding: paddingSchema,
  buttonLink: z.string().optional(),
  fillColor: z.string(),
  strokeColor: z.string(),
  textColor: z.string(),
  strokeWeight: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  fontSize: z.number(),
  as: z.enum(["button", "a"]).optional(),
  buttonType: z.enum(["button", "submit", "reset"]).optional(),
  fullWidth: z.boolean().optional(),
  linkType: z.nativeEnum(ButtonLinkType).optional(),
});

export const inlineButtonSchema = buttonSchema.omit({
  sectionPadding: true,
  type: true,
  sectionColor: true,
});

const reviewRequestSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.REVIEW_REQUEST),
  sectionHeader: z.string(),
  imageCornerRadius: z.number(),
  imageSize: z.nativeEnum(Size),
  buttonHeight: z.number(),
  buttonWidth: z.number(),
  buttonColor: z.string(),
  buttonTextColor: z.string(),
  buttonSize: z.nativeEnum(Size),
  buttonCornerRadius: z.number(),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
  imageAspectRatio: z.number(),
});

export const lineSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.LINE),
  color: z.string(),
  padding: paddingSchema,
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
});

export const spacerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SPACER),
  height: z.number(),
});

export const imageSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.IMAGE),
  imageUrl: z.string(),
  padding: paddingSchema,
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  showCaption: z.boolean(),
  caption: z.string().optional(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
  aspectRatio: z.number().optional(),
});

const trackingInfoSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TRACKING_INFO),
  textColor: z.string(),
  buttonVisible: z.boolean(),
  buttonHeight: z.number(),
  buttonWidth: z.number(),
  buttonColor: z.string(),
  buttonTextColor: z.string(),
  buttonSize: z.nativeEnum(Size),
  buttonCornerRadius: z.number(),
  statusIndicatorColor: z.string(),
  statusIndicatorInnerColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
});

const trackableSummarySchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TRACKABLE_SUMMARY),
  showOrderInformation: z.boolean(),
  showCostSummary: z.boolean(),
  showPaymentInformation: z.boolean(),
  showCustomerInformation: z.boolean(),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
  imageSize: z.nativeEnum(Size),
  imageCornerRadius: z.number(),
  imageAspectRatio: z.number(),
});

const manuallySelectedProductSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
});

const interactiveCartSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.INTERACTIVE_CART),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
  imageCornerRadius: z.number(),
  checkoutButton: inlineButtonSchema,
  lineItemButtons: inlineButtonSchema,
  numberOfProducts: z.number(),
  imageSize: z.nativeEnum(Size),
  productSelectionType: z.nativeEnum(ProductSelectionType),
  showPrice: z.boolean().optional(),
  showTitle: z.boolean().optional(),
  showImage: z.boolean().optional(),
  showButton: z.boolean().optional(),
  showQuantity: z.boolean().optional(),
  layoutType: z.nativeEnum(CartLayoutType).optional(),
  alignment: z.nativeEnum(Alignment),
  columns: z.number(),
  stackOnMobile: z.boolean(),
  recommendedProductFilterId: zExt.objectId().optional(),
  manuallySelectedProducts: z.array(manuallySelectedProductSchema),
  imageAspectRatio: z.number(),
});

const brandKitEmailButtonSchema = z.object({
  hierarchy: z.enum(buttonHierarchies),
  override: z.object({
    enabled: z.boolean(),
    backgroundColor: z.string().optional(),
    textColor: z.string(),
    border: z.object({
      cornerRadiusPx: z.number(),
      stroke: z.object({ color: z.string(), weightPx: z.number() }),
    }),
    paddingPx: z.number(),

    font: z.object({
      fontFamily: z.nativeEnum(FontFamily),
      fontSizePx: z.number(),
      fontWeight: z.enum(fontWeights),
    }),
  }),
  fullWidth: z.boolean(),
  text: z.string(),
});

const shoppableProductsSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SHOPPABLE_PRODUCTS),
  productSelectionType: z.nativeEnum(ProductSelectionType),
  manuallySelectedProducts: z.array(manuallySelectedProductSchema),
  productFilterId: zExt.objectId().optional(),
  numberOfDynamicProducts: z.number(),
  cardCornerRadiusPx: z.number(),
  size: z.nativeEnum(ShoppableProductsSize),
  show: z.object({
    image: z.boolean(),
    title: z.boolean(),
    price: z.boolean(),
    description: z.boolean(),
    button: z.boolean(),
  }),
  fontFamily: z.nativeEnum(FontFamily),
  textColor: z.string(),
  alignment: z.nativeEnum(Alignment),
  productButtons: brandKitEmailButtonSchema,
  checkoutButton: brandKitEmailButtonSchema,
  imageAspectRatio: z.number(),
});

const scratchToRevealSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SCRATCH_TO_REVEAL),
  discountId: zExt.objectId().optional(),
  alignment: z.nativeEnum(Alignment),
  cornerRadiusPx: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  fontWeight: z.nativeEnum(FontWeight),
  fontSizePx: z.number(),
  textColor: z.string(),
  secretBackgroundColor: z.string(),
  scratchOffColor: z.string(),
  scratcherSize: z.nativeEnum(ScratcherSize),
  scratchOffAreaPadding: paddingSchema,
  fallbackText: z.string(),
});

const sectionWithoutColumnSchema = z.discriminatedUnion("type", [
  discountSchema,
  menuSchema,
  socialsSchema,
  headerSchema,
  footerSchema,
  textSchema,
  buttonSchema,
  reviewRequestSchema,
  lineSchema,
  spacerSchema,
  imageSchema,
  trackingInfoSchema,
  trackableSummarySchema,
  interactiveCartSchema,
  shoppableProductsSchema,
  scratchToRevealSchema,
]);

const sectionSchema = z.discriminatedUnion("type", [
  ...sectionWithoutColumnSchema.options,
  columnSchema,
]);

export const emailTemplateSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  subject: z.string(),
  templateType: z.enum(["transactional", "marketing", "default"]),
  category: z.enum(categories),
  schemaType: z.nativeEnum(SchemaType),
  emailPreview: z.string().optional().nullable(),
  emailBackgroundColor: z.string(),
  contentBackgroundColor: z.string(),
  address: z.object({
    businessAddress: z.string().optional().nullable(),
    legalAddress: z.string(),
    cityStateZip: z.string(),
    country: z.string(),
  }),
  sections: z.array(sectionSchema),
  linkColor: z.string().optional().nullable(),
  // optional because we do have some emailTemplates as subdocuments
  // on campaigns that don't have the team field
  team: zExt.objectId().optional(),
  createdAt: z.date().nullish(),
  updatedAt: z.date().nullish(),
});

export type EmailTemplate = z.infer<typeof emailTemplateSchema>;

/**
 * Templates with these schema types will not show up in the global "email templates" or "sms templates" pages in the merchant dashboard.
 *
 * This array is not expected to live very long since we will be significantly refactoring the way we display templates in the near future.
 */
export const excludedFromListView: SchemaType[] = [
  SchemaType.MARKETING_CAMPAIGN,
];
