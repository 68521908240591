// extracted by mini-css-extract-plugin
export var chip = "_1o-9";
export var chipLabel = "_1o-a";
export var close = "_1o-7";
export var closeIcon = "_1o-8";
export var error = "_1o-6";
export var infoTooltip = "_1o-2";
export var innerContainer = "_1o-1";
export var input = "_1o-b";
export var medium = "_1o-5";
export var outerContainer = "_1o-0";
export var small = "_1o-4";
export var xSmall = "_1o-3";