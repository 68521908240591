import { object, z } from "zod";
import {
  IReview,
  ReviewDbParser,
  ReviewWithRatingField,
} from "./review-db-parser";
import { ReviewFieldSchemaType, ReviewRating } from "./review-field-schema";

export const reviewBodySchema = ReviewDbParser.extend({
  _id: z.string(),
  teamId: z.string(),
  rating: z.optional(z.nativeEnum(ReviewRating)),
});

export type ReviewBody = z.infer<typeof reviewBodySchema>;

export function reviewToReviewBodySchema(
  review: IReview,
): z.infer<typeof reviewBodySchema> {
  const reviewRating = review.reviewFields.find(
    (field) => field.fieldType === ReviewFieldSchemaType.REVIEW_RATING,
  );
  return { ...review, rating: reviewRating?.rating };
}

// This is the review body schema sent to the PDP widget, it makes unsafe
// assumptions about the meaning of the review fields since this is built
// before we have data tags to assign real meaning to the review fields.
export const reviewBodySchemaWithAdditionalInferredInfo = object({
  customerName: z.string(),
  createdAt: z.date(),
  verifiedPurchase: z.boolean(),
  shortAnswer: z.string().optional(),
  paragraph: z.string().optional(),
  rating: z.nativeEnum(ReviewRating),
  images: z.array(z.string()).optional(),
});

export type ReviewBodyWithAdditionalInferredInfo = z.infer<
  typeof reviewBodySchemaWithAdditionalInferredInfo
>;

export function reviewWithRatingFieldToReviewBodySchemaWithAdditionalInferredInfo(
  reviewWithRatingField: ReviewWithRatingField,
): z.infer<typeof reviewBodySchemaWithAdditionalInferredInfo> {
  const review = reviewWithRatingField[0];
  const ratingField = reviewWithRatingField[1];

  const customerName = review.customer?.firstName
    ? `${review.customer.firstName}${review.customer.lastName ? ` ${review.customer.lastName.slice(0, 1)}.` : ""}`
    : "Anonymous";

  return {
    verifiedPurchase: review.verifiedPurchase,
    rating: ratingField,
    customerName,
    createdAt: review.createdAt,
    shortAnswer: review.reviewFields.find(
      (field) => field.fieldType === ReviewFieldSchemaType.REVIEW_SHORT_ANSWER,
    )?.text,
    paragraph: review.reviewFields.find(
      (field) => field.fieldType === ReviewFieldSchemaType.REVIEW_PARAGRAPH,
    )?.text,
    images: review.reviewFields
      .filter(
        (field) =>
          field.fieldType === ReviewFieldSchemaType.REVIEW_MEDIA_UPLOAD,
      )
      ?.map((field) => field.text),
  };
}
