// extracted by mini-css-extract-plugin
export var box = "_29-1";
export var checked = "_29-5";
export var descriptionSpacer = "_29-9";
export var disabled = "_29-4";
export var icon = "_29-6";
export var input = "_29-0";
export var labelSpacer = "_29-8";
export var medium = "_29-3";
export var small = "_29-2";
export var text = "_29-7";