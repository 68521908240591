import * as classNames from "classnames";
import { LabelHTMLAttributes, memo, ReactNode } from "react";
import { Flex } from "../../flex";
import { DescriptionText, LabelText } from "../common/descriptors";
import * as redoTextInputCss from "./base-redo-input.module.css";
import {
  RedoInputSize,
  RedoInputState,
  sizeStyles,
  sizeToDescriptorTextProps,
  sizeToPx,
} from "./base-redo-text-input";

export interface BaseRedoInputContainerProps {
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: string;
  className?: string;
  children: ReactNode | ReactNode[];
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  fullWidth?: boolean;
  dynamicHeight?: boolean;
}

export const BaseRedoInputContainer = memo(function BaseRedoInputContainer({
  size = "sm",
  state = "default",
  label,
  description,
  className,
  labelProps,
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
  children,
  fullWidth,
  dynamicHeight,
}: BaseRedoInputContainerProps) {
  const descriptorFontSize = sizeToDescriptorTextProps[size];

  const error = state === "error";
  const disabled = state === "disabled";
  const readonly = state === "readonly";

  return (
    <Flex dir="column" gap="sm" minW="none" w={fullWidth ? "full" : undefined}>
      <LabelText label={label} size={descriptorFontSize} />
      <Flex
        align="center"
        as="label"
        bgColor="primary"
        className={classNames(
          disabled && redoTextInputCss.disabled,
          redoTextInputCss.inputWrapper,
          error && redoTextInputCss.error,
          !dynamicHeight && sizeStyles[size],
          readonly && redoTextInputCss.readonly,
          className,
        )}
        labelProps={labelProps}
        px={readonly ? undefined : sizeToPx[size]}
        style={{
          backgroundColor: readonly ? "transparent" : undefined,
          ...dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
        }}
        textColor="primary"
      >
        {children}
      </Flex>
      <DescriptionText
        description={description}
        error={error}
        size={descriptorFontSize}
      />
    </Flex>
  );
});
