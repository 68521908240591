import { ShippingRateTableArraySchema } from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import { DeliveryProfileSchema } from "@redotech/redo-model/integration/shopify/delivery-profile";
import { z } from "zod";

export interface GetRateTablesFromDeliveryProfileDef {
  input: z.ZodObject<{
    deliveryProfile: typeof DeliveryProfileSchema;
    hasPackageProtection: z.ZodBoolean;
    hasReturnCoverage: z.ZodBoolean;
    hasCarrierServices: z.ZodBoolean;
  }>;
  output: typeof ShippingRateTableArraySchema;
}

export const getRateTablesFromDeliveryProfile: GetRateTablesFromDeliveryProfileDef =
  {
    input: z.object({
      deliveryProfile: DeliveryProfileSchema,
      hasPackageProtection: z.boolean(),
      hasReturnCoverage: z.boolean(),
      hasCarrierServices: z.boolean(),
    }),
    output: ShippingRateTableArraySchema,
  };
