import { z } from "zod";
import { ScheduleSchema } from "./upsert-balance-schedule";

export const getBalanceScheduleInfo = {
  input: z.object({ teamId: z.string() }),
  output: z.object({
    schedule: ScheduleSchema.nullish(),
    lastRunDate: z.date().nullish(),
    nextRunDates: z.array(z.date()),
  }),
};
