import {
  CustomerPortalVersion,
  doesCustomerPortalSupportBulkReturns,
  NonZeroValueExchangeStrategy,
} from "@redotech/redo-model/team";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";

export const returnsGeneralForm = groupInput({
  enabled: input<boolean>(),
  customerPortalVersion: input<CustomerPortalVersion>(),
  refundAllReturnedItems: input<boolean>(),
  settlementEnabled: input<boolean>(),
  settlementRefund: input<string>(),
  allowMultiOrderReturns: input<boolean>(),
  multiOrderReturnLimit: input<string>(),
  disableRejectedItems: input<boolean>(),
  bulkReturnsEnabled: input<boolean>(),
  chargeMerchantForDamagedOrWrongProductReturnLabels: input<boolean>(),
  enableCustomerPortal: input<boolean>(),
  enableShopAppReturns: input<boolean>(),
  buyYourOwnLabel: input<boolean>(),
  newLogin: input<boolean>(),
  nonZeroValueExchangeStrategy: input<NonZeroValueExchangeStrategy>(),
});

export type ReturnsGeneralForm = InputProvider.Form<typeof returnsGeneralForm>;

export type ReturnsGeneralValue = InputProvider.Value<
  typeof returnsGeneralForm
>;

export const returnsGeneralDefault: ReturnsGeneralValue = {
  enabled: true,
  customerPortalVersion: CustomerPortalVersion.V3_0,
  refundAllReturnedItems: false,
  settlementEnabled: false,
  settlementRefund: "0",
  allowMultiOrderReturns: false,
  multiOrderReturnLimit: "0",
  disableRejectedItems: false,
  bulkReturnsEnabled: false,
  chargeMerchantForDamagedOrWrongProductReturnLabels: true,
  enableCustomerPortal: true,
  enableShopAppReturns: false,
  buyYourOwnLabel: false,
  newLogin: false,
  nonZeroValueExchangeStrategy: NonZeroValueExchangeStrategy.NONE,
};

const customerPortalVersions: readonly CustomerPortalVersion[] = [
  CustomerPortalVersion.V3_0,
  CustomerPortalVersion.V3_5,
  CustomerPortalVersion.V4_0,
];

const nonZeroValueExchangeStrategies: readonly NonZeroValueExchangeStrategy[] =
  [
    NonZeroValueExchangeStrategy.NONE,
    NonZeroValueExchangeStrategy.APPLY_DISCOUNT,
    NonZeroValueExchangeStrategy.MARK_AS_PAID,
  ];

export const ReturnsGeneralCard = memo(function ReturnsGeneralCard({
  input,
}: {
  input: ReturnsGeneralForm;
}) {
  const {
    enabled,
    refundAllReturnedItems,
    settlementEnabled,
    settlementRefund,
    customerPortalVersion,
    allowMultiOrderReturns,
    multiOrderReturnLimit,
    disableRejectedItems,
    bulkReturnsEnabled,
    chargeMerchantForDamagedOrWrongProductReturnLabels,
    enableCustomerPortal,
    enableShopAppReturns,
    buyYourOwnLabel,
    newLogin,
    nonZeroValueExchangeStrategy,
  } = input.inputs;

  const minMaxError =
    parseInt(settlementRefund.value) < 0 ||
    parseInt(settlementRefund.value) > 100;
  return (
    <Card title="Returns">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6L)}>
          <FormSwitch input={enabled} label="Enabled">
            Enable Returns product
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6L)}>
          <FormSelectDropdown
            input={customerPortalVersion}
            label="Return portal version"
            options={customerPortalVersions}
          >
            {(option) => option}
          </FormSelectDropdown>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6L)}>
          <FormSwitch
            input={allowMultiOrderReturns}
            label="Allow multi order returns"
          >
            A single return can be created from multiple orders
          </FormSwitch>
        </div>
        {allowMultiOrderReturns.value && (
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormTextInput
              description=""
              input={multiOrderReturnLimit}
              label="Multi Order Returns Day Range"
              min={0}
              type="number"
            />
          </div>
        )}
        <div className={classNames(gridCss.span12, gridCss.span6L)}>
          <FormSwitch
            input={refundAllReturnedItems}
            label="Always create refund"
          >
            Create a Shopify refund for all returned items, even store credit
            and exchanges
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6L)}>
          <FormSwitch input={settlementEnabled} label="Allow Settlement" />
        </div>
        {settlementEnabled.value && (
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormTextInput
              description={
                minMaxError && (
                  <span style={{ color: "red" }}>
                    Value must be between 0 and 100
                  </span>
                )
              }
              errorOverride={minMaxError}
              input={settlementRefund}
              label="Settlement Refund"
              max={100}
              min={0}
              suffix="%"
              type="number"
            />
            The percentage of the refund that the customer will receive if they
            choose to keep the item
          </div>
        )}
        {customerPortalVersion.value === CustomerPortalVersion.V3_5 && (
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormSwitch
              input={disableRejectedItems}
              label="Pre-disable rejected items"
            >
              Show rejection message in tooltip instead of opening the return
              flow modal
            </FormSwitch>
          </div>
        )}
        {doesCustomerPortalSupportBulkReturns[customerPortalVersion.value] && (
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <FormSwitch
              input={bulkReturnsEnabled}
              label="Enable bulk returns"
            />
          </div>
        )}
        <div className={classNames(gridCss.span6L)}>
          <FormSwitch
            input={chargeMerchantForDamagedOrWrongProductReturnLabels}
            label="Charge Merchants for damaged or wrong product return labels"
          />
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormSwitch
            input={enableCustomerPortal}
            label="Enable customer portal"
          >
            Allow customers to return through the customer portal
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormSwitch
            input={enableShopAppReturns}
            label="Enable Shop App returns"
          >
            Allow customers to return through the Shop App
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormSwitch input={buyYourOwnLabel} label="Enable Buy Your Own Label">
            Changes text during international green returns to prompt the
            shopper to buy their own label.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormSwitch input={newLogin} label="Enable new secure login">
            Switch the merchants customer portal login to the new UI/UX and to
            use OTP only (with zipcode and gift returns still available).
            WARNING, the new login will not work with old customCss it must be
            re-written.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormSelectDropdown
            input={nonZeroValueExchangeStrategy}
            label="even exchange new order value"
            options={nonZeroValueExchangeStrategies}
          >
            {(option) => {
              switch (option) {
                case NonZeroValueExchangeStrategy.NONE:
                  return "Zero";
                case NonZeroValueExchangeStrategy.APPLY_DISCOUNT:
                  return "Full value discounted to zero";
                case NonZeroValueExchangeStrategy.MARK_AS_PAID:
                  return "Full value marked as paid";
                default:
                  return option;
              }
            }}
          </FormSelectDropdown>
        </div>
      </section>
    </Card>
  );
});
