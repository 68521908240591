import { z } from "zod";

export const addProductsToCustomerProductList = {
  input: z.object({
    productsToAdd: z.array(
      z.object({ productHandle: z.string(), variantId: z.string().optional() }),
    ),
    teamId: z.string(),
    email: z.string(),
  }),
  output: z.null(),
};
