// extracted by mini-css-extract-plugin
export var box = "_1u-0";
export var check = "_1u-1";
export var checkboxButton = "_1u-6";
export var checkboxGroup = "_1u-5";
export var disabled = "_1u-7";
export var ghost = "_1u-9";
export var input = "_1u-2";
export var label = "_1u-4";
export var normal = "_1u-8";
export var uncheck = "_1u-3";