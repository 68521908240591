import * as classNames from "classnames";
import { memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { TextColorValue, TextSizeValue } from "../../theme/typography";
import * as redoRadioCss from "./redo-radio.module.css";

export const redoRadioButtonSize = ["xs", "sm", "md"] as const;
export type RedoRadioButtonSize = (typeof redoRadioButtonSize)[number];

export interface RedioRadioButtonProps {
  label?: string | React.ReactNode;
  description?: string;
  bordered?: boolean;
  selected: boolean;
  onSelect(value: boolean): void;
  disabled?: boolean;
  size?: RedoRadioButtonSize;
  dataTestId?: string;
}

export const RedoRadioButton = memo(function RedoRadioButton({
  label,
  description,
  bordered,
  selected,
  onSelect,
  disabled,
  size = "xs",
  dataTestId,
}: RedioRadioButtonProps) {
  const labelTextColor: TextColorValue =
    (disabled && "disabled") || (selected && "primary") || "secondary";
  const descriptionTextColor: TextColorValue =
    (disabled && "disabled") || "secondary";

  const showDescriptors = !!(label || description);

  return (
    <Flex
      align="center"
      as="label"
      className={classNames(
        redoRadioCss.radioButton,
        disabled && redoRadioCss.disabled,
        bordered && redoRadioCss.border,
        selected && bordered && redoRadioCss.selected,
      )}
    >
      <Flex gap="none" pt={showDescriptors ? "xxs" : "none"}>
        <input
          checked={selected}
          className={classNames(redoRadioCss.radioInput)}
          data-testid={dataTestId}
          onChange={(event) => {
            onSelect(event.target.checked);
          }}
          type="radio"
        />
        <Flex
          align="center"
          as="span"
          className={classNames(
            redoRadioCss.circle,
            selected && redoRadioCss.selected,
            sizeToClass[size],
            disabled && redoRadioCss.disabled,
          )}
          justify="center"
        >
          <span className={redoRadioCss.circleInner} />
        </Flex>
      </Flex>
      {showDescriptors && (
        <Flex dir="column" gap="none">
          {typeof label === "string" ? (
            <Text
              className={redoRadioCss.text}
              fontSize={sizeToLabelTextSize[size]}
              fontWeight={selected ? "medium" : "regular"}
              textColor={labelTextColor}
            >
              {label}
            </Text>
          ) : (
            label
          )}
          {description && (
            <Text
              className={redoRadioCss.text}
              fontSize={sizeToDescriptionTextSize[size]}
              textColor={descriptionTextColor}
            >
              {description}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
});

const sizeToClass: Record<RedoRadioButtonSize, string> = {
  ["xs"]: redoRadioCss.xs,
  ["sm"]: redoRadioCss.sm,
  ["md"]: redoRadioCss.md,
};

export const sizeToLabelTextSize: Record<RedoRadioButtonSize, TextSizeValue> = {
  ["xs"]: "xs",
  ["sm"]: "sm",
  ["md"]: "md",
};

export const sizeToDescriptionTextSize: Record<
  RedoRadioButtonSize,
  TextSizeValue
> = { ["xs"]: "xxs", ["sm"]: "xs", ["md"]: "sm" };
