import { z } from "zod";

export const getShopifyExtensionTeamInfo = {
  input: z.null(),
  output: z.object({
    teamId: z.string(),
    storeUrl: z.string(),
    widgetId: z.string(),
    shouldLoginUsingMultipass: z.boolean(),
  }),
};
