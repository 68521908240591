import { isEnumValue } from "@redotech/util/enum";
import { assertNever } from "@redotech/util/type";
import {
  AmazonServices,
  Carriers,
  FedexServices,
  Service,
  UpsServices,
  UspsServices,
} from "../fulfillments/fulfillment-carriers-and-services";
import {
  OutboundRateTable,
  RateTableType,
  RateTableValueMode,
} from "./rate-table";

export enum RateTableCarrier {
  USPS = "USPS",
  FEDEX = "FedEx",
  UPS = "UPS",
  UPS_SUREPOST = "UPSSurePost",
  Amazon = "Amazon",
}

export function rateTableCarrierLabel(carrier: RateTableCarrier): string {
  switch (carrier) {
    case RateTableCarrier.USPS:
      return "USPS";
    case RateTableCarrier.FEDEX:
      return "FedEx";
    case RateTableCarrier.UPS:
      return "UPS";
    case RateTableCarrier.UPS_SUREPOST:
      return "UPS SurePost";
    case RateTableCarrier.Amazon:
      return "Amazon";
  }
}

export enum USPSRateTableServiceLevel {
  GroundAdvantage = "Ground Advantage",
  Priority = "Priority",
}

export enum FedexRateTableServiceLevel {
  SmartPost = "Smart Post",
  OneRateExpressSaver = "One Rate Express Saver",
  OneRateTwoDay = "One Rate Two Day",
  OneRateTwoDayAM = "One Rate Two Day AM",
  OneRateStandard = "One Rate Standard Overnight",
  OneRatePriorityOvernight = "One Rate Priority Overnight",
  OneRateFirstOvernight = "One Rate First Overnight",
}

const nonSurePostUpsServices = Object.values(UpsServices).filter(
  (service) =>
    service !== UpsServices.SurePostUnder1Lb &&
    service !== UpsServices.SurePostOver1Lb,
);

export type UpsRateTableServiceLevel =
  | (typeof nonSurePostUpsServices)[number]
  | "Default";

export type UpsSurePostRateTableServiceLevel =
  | UpsServices.SurePostUnder1Lb
  | UpsServices.SurePostOver1Lb
  | "Default";

export type AmazonRateTableServiceLevel = AmazonServices | "Default";

export type RateTableServiceLevel =
  | USPSRateTableServiceLevel
  | FedexRateTableServiceLevel
  | UpsRateTableServiceLevel
  | AmazonRateTableServiceLevel
  | UpsSurePostRateTableServiceLevel;

type RateTableServiceLevels = {
  [RateTableCarrier.USPS]: USPSRateTableServiceLevel;
  [RateTableCarrier.FEDEX]: FedexRateTableServiceLevel;
  [RateTableCarrier.UPS]: UpsRateTableServiceLevel;
  [RateTableCarrier.Amazon]: AmazonRateTableServiceLevel;
  [RateTableCarrier.UPS_SUREPOST]: UpsSurePostRateTableServiceLevel;
};

export const RateTableServiceLevelsMapping: Record<
  RateTableCarrier,
  RateTableServiceLevels[RateTableCarrier][]
> = {
  [RateTableCarrier.USPS]: [
    "Default",
    ...Object.values(USPSRateTableServiceLevel),
  ],
  [RateTableCarrier.FEDEX]: [
    "Default",
    ...Object.values(FedexRateTableServiceLevel),
  ],
  [RateTableCarrier.UPS]: ["Default", ...Object.values(nonSurePostUpsServices)],
  [RateTableCarrier.UPS_SUREPOST]: [
    "Default",
    UpsServices.SurePostUnder1Lb,
    UpsServices.SurePostOver1Lb,
  ],
  [RateTableCarrier.Amazon]: ["Default", ...Object.values(AmazonServices)],
};

export type RateTableKeys = {
  carrier: RateTableCarrier;
  serviceLevel: RateTableServiceLevel;
};

// Determines what rate table keys to use given the carrier and service level from easypost
export function getRateTableKeysFromRate(
  carrier: Carriers,
  serviceLevel: Service,
): RateTableKeys {
  switch (carrier) {
    case Carriers.FEDEX_SMART_POST:
    case Carriers.FEDEX:
      switch (serviceLevel) {
        case FedexServices.SMART_POST:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.SmartPost,
          };
        case FedexServices.FEDEX_EXPRESS_SAVER:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRateExpressSaver,
          };
        case FedexServices.FEDEX_2_DAY:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRateTwoDay,
          };
        case FedexServices.FEDEX_2_DAY_AM:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRateTwoDayAM,
          };
        case FedexServices.PRIORITY_OVERNIGHT:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRatePriorityOvernight,
          };
        case FedexServices.STANDARD_OVERNIGHT:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRateStandard,
          };
        case FedexServices.FIRST_OVERNIGHT:
          return {
            carrier: RateTableCarrier.FEDEX,
            serviceLevel: FedexRateTableServiceLevel.OneRateFirstOvernight,
          };
        default:
          return { carrier: RateTableCarrier.FEDEX, serviceLevel: "Default" };
      }
    case Carriers.USPS_RETURNS:
    case Carriers.USPS:
    case Carriers.USPS_REDO:
      switch (serviceLevel) {
        case UspsServices.GroundAdvantage:
          return {
            carrier: RateTableCarrier.USPS,
            serviceLevel: USPSRateTableServiceLevel.GroundAdvantage,
          };
        case UspsServices.Priority:
          return {
            carrier: RateTableCarrier.USPS,
            serviceLevel: USPSRateTableServiceLevel.Priority,
          };
        default:
          return { carrier: RateTableCarrier.USPS, serviceLevel: "Default" };
      }
    case Carriers.UPS:
      if (isEnumValue(serviceLevel, UpsServices)) {
        return { carrier: RateTableCarrier.UPS, serviceLevel };
      }
      return { carrier: RateTableCarrier.UPS, serviceLevel: "Default" };
    case Carriers.UPS_SUREPOST:
      switch (serviceLevel) {
        case UpsServices.SurePostUnder1Lb:
          return {
            carrier: RateTableCarrier.UPS_SUREPOST,
            serviceLevel: UpsServices.SurePostUnder1Lb,
          };
        case UpsServices.SurePostOver1Lb:
          return {
            carrier: RateTableCarrier.UPS_SUREPOST,
            serviceLevel: UpsServices.SurePostOver1Lb,
          };
        default:
          return {
            carrier: RateTableCarrier.UPS_SUREPOST,
            serviceLevel: "Default",
          };
      }
    case Carriers.AMAZON:
      if (isEnumValue(serviceLevel, AmazonServices)) {
        return { carrier: RateTableCarrier.Amazon, serviceLevel };
      }
      return { carrier: RateTableCarrier.Amazon, serviceLevel: "Default" };
    default:
      assertNever(carrier);
  }
}

export function findMatchingOutboundRateTable(
  rateTableKeys: RateTableKeys,
  rateTables: OutboundRateTable[],
  valueMode: RateTableValueMode,
  rateTableType: RateTableType,
): OutboundRateTable | null {
  return (
    rateTables.find(
      (table: OutboundRateTable) =>
        table.carrier === rateTableKeys.carrier &&
        table.serviceLevel === rateTableKeys.serviceLevel &&
        table.valueMode === valueMode &&
        table.rateTableType === rateTableType,
    ) ?? null
  );
}
