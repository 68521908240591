import { useHandler } from "@redotech/react-util/hook";
import {
  EWWidgetTextProps,
  ExtendedWarrantyCartToggleExperience as ExtendedWarrantyCartToggleExperienceEnum,
  ExtendedWarrantyDataTarget,
  getCheapestOffering,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import ShieldZapIconOutline from "@redotech/redo-web/arbiter-icon/shield-zap.svg";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import * as extendedWarrantyCss from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import htmlReactParser from "html-react-parser";
import { memo } from "react";
import { ToggleAddButton } from "../toggle-add-button";

export const ExtendedWarrantyCartToggleExperience = memo(
  function CartToggleExperience({
    cartToggleExperience,
    selection,
    variantId,
    offerings,
    removeWarranty,
    addWarranty,
    text,
  }: {
    variantId: string;
    selection?: Offering;
    offerings: Offering[];
    cartToggleExperience?: ExtendedWarrantyCartToggleExperienceEnum;
    text: EWWidgetTextProps;
    removeWarranty: (variantId: string) => void;
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
  }) {
    if (selection) {
      return (
        <ExtendedWarrantyCartToggleAdded
          removeWarranty={removeWarranty}
          text={text}
          title={selection.title}
          variantId={variantId}
        />
      );
    } else if (
      cartToggleExperience !==
      ExtendedWarrantyCartToggleExperienceEnum.REMOVE_ONLY
    ) {
      return (
        <ExtendedWarrantyCartToggleNotAdded
          addWarranty={addWarranty}
          offerings={offerings}
          text={text}
          variantId={variantId}
        />
      );
    } else {
      return null;
    }
  },
);

const ExtendedWarrantyCartToggleAdded = memo(
  function ExtendedWarrantyCartToggleAdded({
    title,
    variantId,
    removeWarranty,
    text,
  }: {
    title: string;
    variantId: string;
    removeWarranty: (variantId: string) => void;
    text: EWWidgetTextProps;
  }) {
    const { cartToggleAddedDescription } = text;

    return (
      <Flex
        className={extendedWarrantyCss.container}
        data-target={ExtendedWarrantyDataTarget.CT_ADDED_CONTAINER}
        justify="space-between"
        wrap="nowrap"
      >
        <ShieldTickIcon
          className={extendedWarrantyCss.shieldIcon}
          data-target={ExtendedWarrantyDataTarget.CT_ADDED_SHIELD}
        />
        <Flex
          data-target={ExtendedWarrantyDataTarget.CT_ADDED_CONTENT}
          flexDirection="column"
          gap="none"
        >
          <Text
            className={extendedWarrantyCss.plan}
            fontSize="sm"
            fontWeight="medium"
          >
            {htmlReactParser(
              cartToggleAddedDescription.replace("%plan_title%", title),
            )}
          </Text>
          <a
            className={extendedWarrantyCss.remove}
            data-target={ExtendedWarrantyDataTarget.CT_ADDED_REMOVE}
            onClick={() => removeWarranty(variantId)}
          >
            Remove
          </a>
        </Flex>
      </Flex>
    );
  },
);

const ExtendedWarrantyCartToggleNotAdded = memo(
  function ExtendedWarrantyCartToggleNotAdded({
    variantId,
    offerings,
    addWarranty,
    text,
  }: {
    variantId: string;
    offerings: Offering[];
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
    text: EWWidgetTextProps;
  }) {
    const selectedOffering = getCheapestOffering(offerings);

    const handleAddClick = useHandler(() => {
      if (!selectedOffering) {
        return;
      }
      addWarranty(variantId, selectedOffering);
    });

    if (!selectedOffering) {
      return null;
    }

    const { cartToggleNotAddedDescription } = text;

    return (
      <Flex
        className={extendedWarrantyCss.container}
        data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_CONTAINER}
        justify="space-between"
        wrap="nowrap"
      >
        <ShieldZapIconOutline
          className={extendedWarrantyCss.shieldIcon}
          data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_SHIELD}
        />
        <Flex
          data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_CONTENT}
          flexDirection="column"
          gap="xs"
        >
          <Text
            className={extendedWarrantyCss.plan}
            fontSize="sm"
            fontWeight="medium"
          >
            {htmlReactParser(
              cartToggleNotAddedDescription
                .replace("%plan_title%", selectedOffering.title)
                .replace(
                  "%price%",
                  CURRENCY_FORMAT(selectedOffering.price.currency).format(
                    selectedOffering.price.amount,
                  ),
                ),
            )}
          </Text>
          <ToggleAddButton
            data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_ADD}
            onAddClick={handleAddClick}
          />
        </Flex>
      </Flex>
    );
  },
);
