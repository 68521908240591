// binarySearch returns the element in the array that is closest to the target value.
export function binarySearch<T>(
  items: T[],
  target: number,
  getValue: (item: T) => number,
): T {
  let start = 0;
  let end = items.length - 1;
  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const value = getValue(items[mid]);
    if (value === target) {
      start = mid;
      break;
    } else if (value < target) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }
  if (start >= items.length) {
    start = items.length - 1;
  }
  return items[start];
}

export function partition<T>(
  items: T[],
  condition: (item: T) => boolean,
): [T[], T[]] {
  const truePartition: T[] = [];
  const falsePartition: T[] = [];

  items.forEach((item) => {
    if (condition(item)) {
      truePartition.push(item);
    } else {
      falsePartition.push(item);
    }
  });
  return [falsePartition, truePartition];
}

export function unique<T>(items: T[], uniqueKey?: (item: T) => string): T[] {
  if (uniqueKey) {
    const map = new Map<string, T>();
    items.forEach((item) => {
      map.set(uniqueKey(item), item);
    });
    return Array.from(map.values());
  } else {
    return Array.from(new Set(items));
  }
}

export function uniqueMerge<T>(
  items: T[],
  merge: (itemA: T, itemB: T) => T,
  uniqueKey: (item: T) => string,
): T[] {
  const map = new Map<string, T>();
  items.forEach((item) => {
    const key = uniqueKey(item);
    const existing = map.get(key);
    if (existing) {
      map.set(key, merge(existing, item));
    } else {
      map.set(key, item);
    }
  });
  return Array.from(map.values());
}

// Array equivalent of string.join
export function interleave<T>(
  items: T[],
  separator: (item: T, index: number) => T,
): T[] {
  return items.reduce((acc, item, index) => {
    if (index === 0) {
      return [item];
    }
    return [...acc, separator(item, index), item];
  }, [] as T[]);
}

/**
 * Filter on javascript's definition of truthy. Use with care as it filters out 0 and empty strings.
 */
export function filterTruthy<T>(items: (T | null | undefined | false)[]): T[] {
  return items.filter(Boolean) as T[];
}

export function swap<T>(items: T[], i: number, j: number): T[] {
  const newItems = items.slice();
  const temp = newItems[i];
  newItems[i] = newItems[j];
  newItems[j] = temp;
  return newItems;
}

export function insert<T>(items: T[], index: number, item: T): T[] {
  const copy = [...items];
  copy.splice(index, 0, item);
  return copy;
}

export function replace<T>(items: T[], index: number, item: T): T[] {
  const copy = [...items];
  copy.splice(index, 1, item);
  return copy;
}

export function remove<T>(items: T[], index: number): T[] {
  const copy = [...items];
  copy.splice(index, 1);
  return copy;
}

export function chunk<T>(items: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < items.length; i += size) {
    chunks.push(items.slice(i, i + size));
  }
  return chunks;
}

export function repeat<T>(arr: T[], count: number): T[] {
  return Array(count).fill(arr).flat();
}
