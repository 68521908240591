// extracted by mini-css-extract-plugin
export var container = "_u-0";
export var error = "_u-9";
export var label = "_u-5";
export var light = "_u-6";
export var md = "_u-3";
export var sm = "_u-2";
export var thin = "_u-8";
export var thinBold = "_u-7";
export var vertical = "_u-4";
export var xs = "_u-1";