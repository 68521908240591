import { useRequiredContext } from "@redotech/react-util/context";
import {
  EWWidgetTextProps,
  ExtendedWarrantyBlurbType,
  ExtendedWarrantyRadioOptionType,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import { RedoRadioButton } from "@redotech/redo-web/arbiter-components/radio/redo-radio-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import htmlReactParser from "html-react-parser";
import { memo, useMemo } from "react";
import { EwSettingsContext } from "../provider/ew-settings-provider";
import { ExtendedWarrantyBlurb } from "./blurb";
import * as RadioModalCss from "./radio-modal.module.css";

export const ExtendedWarrantyRadioModal = memo(
  function ExtendedWarrantyRadioModal({
    customCss,
    offerings,
    selectedOffering,
    onTileClick,
    onInfoClick,
    text,
    foundSelector,
  }: {
    // TODO: Abstract custom css
    customCss?: string;
    offerings: Offering[];
    selectedOffering?: Offering;
    onTileClick: (offering: Offering) => void;
    onInfoClick: () => void;
    text: EWWidgetTextProps;
    foundSelector?: string;
  }) {
    const { getWidgetConfig } = useRequiredContext(EwSettingsContext);

    const widgetConfig = getWidgetConfig();

    if (!selectedOffering || !widgetConfig) {
      return null;
    }

    return (
      <>
        {customCss && <style>{customCss}</style>}
        {foundSelector && <meta found-selector={foundSelector} />}
        <Flex
          className={RadioModalCss.container}
          flexDirection="column"
          gap="md"
        >
          <ExtendedWarrantyBlurb
            onInfoClick={onInfoClick}
            showIcon={
              widgetConfig.pdpRadioOptionType ===
              ExtendedWarrantyRadioOptionType.EXPANDED
            }
            text={text}
            type={ExtendedWarrantyBlurbType.COMPACT}
          />
          <Flex flexDirection="column" gap="md">
            {offerings.map((offering) => (
              <OptionCheckbox
                key={offering.id}
                offering={offering}
                onTileClick={onTileClick}
                selectedOffering={selectedOffering}
                text={text}
                type={
                  widgetConfig.pdpRadioOptionType ??
                  ExtendedWarrantyRadioOptionType.REGULAR
                }
              />
            ))}
          </Flex>
        </Flex>
      </>
    );
  },
);

const OptionCheckbox = ({
  offering,
  selectedOffering,
  onTileClick,
  text,
  type,
}: {
  offering: Offering;
  selectedOffering: Offering;
  onTileClick: (offering: Offering) => void;
  text: EWWidgetTextProps;
  type: ExtendedWarrantyRadioOptionType;
}) => {
  switch (type) {
    case ExtendedWarrantyRadioOptionType.REGULAR:
      return (
        <RegularOptionCheckbox
          offering={offering}
          onTileClick={onTileClick}
          selectedOffering={selectedOffering}
          text={text}
        />
      );
    case ExtendedWarrantyRadioOptionType.EXPANDED:
      return (
        <ExpandedOptionCheckbox
          offering={offering}
          onTileClick={onTileClick}
          selectedOffering={selectedOffering}
          text={text}
        />
      );
    default:
      assertNever(type);
  }
};

const RegularOptionCheckbox = ({
  offering,
  selectedOffering,
  onTileClick,
  text,
}: {
  offering: Offering;
  selectedOffering: Offering;
  onTileClick: (offering: Offering) => void;
  text: EWWidgetTextProps;
}) => {
  const { pdpRadioOptionTitle, pdpCoverageDisabledLabel } = text;

  const isSelected = selectedOffering.id === offering.id;
  const fontWeight = isSelected ? "medium" : "regular";

  const titleComponents: React.ReactNode[] = useMemo(() => {
    const optionTitle = pdpRadioOptionTitle.replace(
      "%plan_title%",
      offering.title,
    );
    return optionTitle.split("%price%").reduce((acc, part, index, array) => {
      if (index === array.length - 1) return [...acc, part];
      return [
        ...acc,
        htmlReactParser(part),
        <span className={RadioModalCss.price} key={index}>
          {CURRENCY_FORMAT(offering.price.currency).format(
            offering.price.amount,
          )}
        </span>,
      ];
    }, [] as React.ReactNode[]);
  }, [offering, pdpRadioOptionTitle]);

  return (
    <RedoRadioButton
      key={offering.id}
      label={
        offering.id === NO_COVERAGE_TIME_ID ? (
          <Text
            className={RadioModalCss.label}
            fontSize="sm"
            fontWeight={fontWeight}
          >
            {htmlReactParser(pdpCoverageDisabledLabel)}
          </Text>
        ) : (
          <Text
            className={RadioModalCss.label}
            fontSize="sm"
            fontWeight={fontWeight}
          >
            {titleComponents}
          </Text>
        )
      }
      onSelect={() => onTileClick(offering)}
      selected={isSelected}
      size="sm"
    />
  );
};

const ExpandedOptionCheckbox = ({
  offering,
  selectedOffering,
  onTileClick,
  text,
}: {
  offering: Offering;
  selectedOffering: Offering;
  onTileClick: (offering: Offering) => void;
  text: EWWidgetTextProps;
}) => {
  const { pdpRadioOptionTitle, pdpCoverageDisabledLabel } = text;

  const isSelected = selectedOffering.id === offering.id;
  const fontWeight = isSelected ? "semibold" : "medium";

  const titleComponents: React.ReactNode[] = useMemo(() => {
    const isNoCoverage = offering.id === NO_COVERAGE_TIME_ID;
    const defaultTitle = isNoCoverage
      ? pdpCoverageDisabledLabel
      : pdpRadioOptionTitle;
    const optionTitle = defaultTitle.replace("%plan_title%", offering.title);
    return optionTitle.split("%price%").reduce((acc, part, index, array) => {
      if (index === array.length - 1) return [...acc, part];
      return [
        ...acc,
        htmlReactParser(part),
        <span className={RadioModalCss.priceExpanded} key={index}>
          {isNoCoverage
            ? "Free"
            : CURRENCY_FORMAT(offering.price.currency).format(
                offering.price.amount,
              )}
        </span>,
      ];
    }, [] as React.ReactNode[]);
  }, [offering, pdpRadioOptionTitle, pdpCoverageDisabledLabel]);

  return (
    <Flex
      borderColor={isSelected ? "brand-solid_alt" : "secondary"}
      borderStyle="solid"
      borderWidth={isSelected ? "2px" : "1px"}
      className={RadioModalCss.expandedOptionCheckbox}
      cursor="pointer"
      onClick={() => onTileClick(offering)}
      p="xl"
      radius="xl"
    >
      <RedoRadioButton
        key={offering.id}
        label={
          <Text
            className={RadioModalCss.labelExpanded}
            fontSize="sm"
            fontWeight={fontWeight}
          >
            {titleComponents}
          </Text>
        }
        onSelect={() => onTileClick(offering)}
        selected={isSelected}
        size="sm"
      />
    </Flex>
  );
};
