import { z } from "zod";

export const sendTestEmail = {
  input: z.object({
    teamId: z.string(),
    emailTemplateId: z.string(),
    recipients: z.array(z.string()),
  }),
  output: z.object({}),
};
