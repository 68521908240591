import { useRequiredContext } from "@redotech/react-util/context";
import {
  CollectionSettings,
  ExtendedWarrantyShopMetafieldStyles,
  SHOP_METAFIELD_KEY,
  SHOP_METAFIELD_NAMESPACE,
  SHOP_METAFIELD_STYLES_KEY,
} from "@redotech/redo-model/extended-warranty";

import { useLoad } from "@redotech/react-util/load";
import { OfferingConfig } from "@redotech/redo-model/extended-warranty";
import { createContext, memo, ReactNode, useMemo } from "react";
import { ExtensionShopifyClientContext } from "../../shopify";
import { useExpandedWarrantyTreatment } from "../hooks/use-expanded-warranty-treatment";

export const EwSettingsContext = createContext<
  | {
      offeringConfig: OfferingConfig;
      collectionSettings: CollectionSettings[];
      getWidgetConfig: () => ExtendedWarrantyShopMetafieldStyles;
    }
  | undefined
>({
  offeringConfig: {} as OfferingConfig,
  collectionSettings: [] as CollectionSettings[],
  getWidgetConfig: () => ({}) as ExtendedWarrantyShopMetafieldStyles,
});

export const EwSettingsProvider = memo(function EwSettingsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const shopifyClient = useRequiredContext(ExtensionShopifyClientContext);

  const expandedWarrantyTreatmentLoad = useExpandedWarrantyTreatment({
    currentUrl: new URL(window.location.href),
  });

  const offeringsConfigLoad = useLoad(async () => {
    const offeringConfigMetafield = await shopifyClient.getShopMetafield({
      key: SHOP_METAFIELD_KEY,
      namespace: SHOP_METAFIELD_NAMESPACE,
    });
    const offeringConfigValue = offeringConfigMetafield?.metafield?.value;
    if (!offeringConfigValue) {
      return undefined;
    }
    return JSON.parse(offeringConfigValue) as OfferingConfig;
  }, [shopifyClient]);

  const widgetConfigsLoad = useLoad(async () => {
    const widgetConfigsMetafield = await shopifyClient.getShopMetafield({
      key: SHOP_METAFIELD_STYLES_KEY,
      namespace: SHOP_METAFIELD_NAMESPACE,
    });
    const widgetConfigsValue = widgetConfigsMetafield?.metafield?.value;
    if (!widgetConfigsValue) {
      return undefined;
    }
    const baseWidgetConfig = JSON.parse(
      widgetConfigsValue,
    ) as ExtendedWarrantyShopMetafieldStyles;
    if (expandedWarrantyTreatmentLoad.value) {
      return {
        ...baseWidgetConfig,
        ...expandedWarrantyTreatmentLoad.value.ewWidgetConfig,
        sideImage:
          expandedWarrantyTreatmentLoad.value.ewWidgetConfig.sideImage ??
          baseWidgetConfig?.sideImage,
        logo:
          expandedWarrantyTreatmentLoad.value.ewWidgetConfig.logo ??
          baseWidgetConfig?.logo,
      };
    }
    return baseWidgetConfig;
  }, [shopifyClient, expandedWarrantyTreatmentLoad.value]);

  const offeringConfigCollectionSettings = useMemo(() => {
    return offeringsConfigLoad.value?.collectionSettings;
  }, [offeringsConfigLoad.value]);

  // Eventually this will be keyed to a widget type
  const getWidgetConfig = () => {
    return (
      widgetConfigsLoad.value ?? ({} as ExtendedWarrantyShopMetafieldStyles)
    );
  };

  if (
    offeringsConfigLoad.value === undefined ||
    widgetConfigsLoad.value === undefined ||
    offeringConfigCollectionSettings === undefined ||
    (expandedWarrantyTreatmentLoad.value &&
      !expandedWarrantyTreatmentLoad.value.settings.expandedWarrantiesEnabled)
  ) {
    return null;
  }

  return (
    <EwSettingsContext.Provider
      value={{
        offeringConfig: offeringsConfigLoad.value,
        collectionSettings: offeringConfigCollectionSettings,
        getWidgetConfig,
      }}
    >
      {children}
    </EwSettingsContext.Provider>
  );
});
