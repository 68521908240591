import { camelToSnakeCase } from "@redotech/util/string";
import { Schema, SchemaInstance } from "../type-system/schema";
import {
  baseMarketingBackInStockSchema,
  baseMarketingBrowseAbandomentSchema,
  baseMarketingCartAbandomentSchema,
  baseMarketingCheckoutAbandonmentSchema,
  baseMarketingEmailSchema,
  emailMarketingSignupSchema,
  exampleBaseMarketingBackInStockSchemaInstance,
  exampleBaseMarketingBrowseAbandomentSchemaInstance,
  exampleBaseMarketingCartAbandomentSchemaInstance,
  exampleBaseMarketingCheckoutAbandonmentSchemaInstance,
  exampleBaseMarketingEmailSchemaInstance,
  exampleEmailMarketingSignupSchema,
  exampleMarketingWarrantyRegistrationSchemaInstance,
  exampleSegmentMembershipChangeSchemaInstance,
  exampleSmsMarketingConfirmedSchema,
  exampleSmsMarketingSignupSchema,
  customerGroupMembershipChangeSchema as marketingSegmentMembershipChangeSchema,
  marketingWarrantyRegistrationSchema,
  smsMarketingConfirmedSchema,
  smsMarketingSignupSchema,
} from "./marketing/marketing";
import {
  baseNewFulfillmentGroupSchema,
  examplebaseNewFulfillmentGroupInstance,
} from "./oms";
import {
  baseReviewsSchema,
  exampleBaseReviewsSchemaInstance,
} from "./reviews/reviews";
import {
  exampleOrderTrackingSchemaInstance,
  orderTrackingSchema,
} from "./tracking/order-tracking";
import {
  exampleReturnTrackingSchemaInstance,
  returnTrackingSchema,
} from "./tracking/return-tracking";

export enum SchemaType {
  ORDER_TRACKING = "order_tracking",
  RETURN_TRACKING = "return_tracking",
  EMAIL_MARKETING_SIGNUP = "email_marketing_signup",
  SMS_MARKETING_SIGNUP = "sms_marketing_signup",
  SMS_MARKETING_CONFIRMED = "sms_marketing_confirmed",
  MARKETING_CAMPAIGN = "marketing_campaign",
  REVIEWS = "reviews",
  MARKETING_EMAIL = "marketing_email",
  MARKETING_CART_ABANDONMENT = "marketing_cart_abandonment",
  MARKETING_BROWSE_ABANDONMENT = "marketing_browse_abandonment",
  MARKETING_CHECKOUT_ABANDONMENT = "marketing_checkout_abandonment",
  MARKETING_BACK_IN_STOCK = "marketing_back_in_stock",
  MARKETING_SEGMENT_MEMBERSHIP_CHANGE = "marketing_segment_membership_change",
  MARKETING_WARRANTY_REGISTRATION = "marketing_warranty_registration",
  OMS_NEW_FULFILLMENT_GROUP = "oms_new_fulfillment_group",
}

export const schemas: Record<SchemaType, Schema> = {
  [SchemaType.ORDER_TRACKING]: orderTrackingSchema,
  [SchemaType.RETURN_TRACKING]: returnTrackingSchema,
  [SchemaType.EMAIL_MARKETING_SIGNUP]: emailMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_SIGNUP]: smsMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_CONFIRMED]: smsMarketingConfirmedSchema,
  [SchemaType.MARKETING_CAMPAIGN]: baseMarketingEmailSchema,
  [SchemaType.MARKETING_SEGMENT_MEMBERSHIP_CHANGE]:
    marketingSegmentMembershipChangeSchema,
  [SchemaType.REVIEWS]: baseReviewsSchema,
  [SchemaType.MARKETING_EMAIL]: baseMarketingEmailSchema,
  [SchemaType.MARKETING_CART_ABANDONMENT]: baseMarketingCartAbandomentSchema,
  [SchemaType.MARKETING_BROWSE_ABANDONMENT]:
    baseMarketingBrowseAbandomentSchema,
  [SchemaType.MARKETING_CHECKOUT_ABANDONMENT]:
    baseMarketingCheckoutAbandonmentSchema,
  [SchemaType.OMS_NEW_FULFILLMENT_GROUP]: baseNewFulfillmentGroupSchema,
  [SchemaType.MARKETING_BACK_IN_STOCK]: baseMarketingBackInStockSchema,
  [SchemaType.MARKETING_WARRANTY_REGISTRATION]:
    marketingWarrantyRegistrationSchema,
};

export const schemaInstanceExamples: Record<
  SchemaType,
  SchemaInstance<Schema>
> = {
  [SchemaType.ORDER_TRACKING]: exampleOrderTrackingSchemaInstance,
  [SchemaType.RETURN_TRACKING]: exampleReturnTrackingSchemaInstance,
  [SchemaType.EMAIL_MARKETING_SIGNUP]: exampleEmailMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_SIGNUP]: exampleSmsMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_CONFIRMED]: exampleSmsMarketingConfirmedSchema,
  [SchemaType.MARKETING_CAMPAIGN]: exampleBaseMarketingEmailSchemaInstance, // intentionally the same as email
  [SchemaType.MARKETING_SEGMENT_MEMBERSHIP_CHANGE]:
    exampleSegmentMembershipChangeSchemaInstance,
  [SchemaType.REVIEWS]: exampleBaseReviewsSchemaInstance,
  [SchemaType.MARKETING_EMAIL]: exampleBaseMarketingEmailSchemaInstance,
  [SchemaType.MARKETING_CART_ABANDONMENT]:
    exampleBaseMarketingCartAbandomentSchemaInstance,
  [SchemaType.MARKETING_BROWSE_ABANDONMENT]:
    exampleBaseMarketingBrowseAbandomentSchemaInstance,
  [SchemaType.MARKETING_CHECKOUT_ABANDONMENT]:
    exampleBaseMarketingCheckoutAbandonmentSchemaInstance,
  [SchemaType.OMS_NEW_FULFILLMENT_GROUP]:
    examplebaseNewFulfillmentGroupInstance,
  [SchemaType.MARKETING_BACK_IN_STOCK]:
    exampleBaseMarketingBackInStockSchemaInstance,
  [SchemaType.MARKETING_WARRANTY_REGISTRATION]:
    exampleMarketingWarrantyRegistrationSchemaInstance,
};

interface DynamicVariableBase {
  key: string;
}

export type DynamicVariableText = DynamicVariableBase & {
  type: "text";
  default?: string;
};

export type DynamicVariableUrl = DynamicVariableBase & {
  type: "url";
  text: string;
};

export type DynamicVariable = DynamicVariableText | DynamicVariableUrl;

export const applyDynamicVariableSyntax = (
  dynamicVariable: Omit<DynamicVariableText, "type">,
) => {
  return [
    `{{ `,
    camelToSnakeCase(dynamicVariable.key),
    `${dynamicVariable.default ? ` | \`${dynamicVariable.default}\`` : ""}`,
    ` }}`,
  ].join("");
};
