import { useHandler } from "@redotech/react-util/hook";
import {
  composeSeeDetailsUrl,
  EWWidgetTextProps,
  ExtendedWarrantyDataTarget,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import ShieldZapIconOutline from "@redotech/redo-web/arbiter-icon/shield-zap.svg";
import * as extendedWarrantyCss from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import htmlReactParser from "html-react-parser";
import { memo, useState } from "react";
import { LearnMoreLink } from "../learn-more-link";
import { ExtendedWarrantyModal } from "../modal";
import { ToggleAddButton } from "../toggle-add-button";

export const ExtendedWarrantyCartToggleModalExperience = memo(
  function ModalExperience({
    variantId,
    selection,
    offerings,
    sideImage,
    logo,
    removeWarranty,
    addWarranty,
    customModalCss,
    text,
    isPreview,
    seeDetailsUrl,
    foundSelector,
  }: {
    variantId: string;
    selection?: Offering;
    offerings: Offering[];
    sideImage?: string;
    logo?: string;
    seeDetailsUrl?: string;
    text: EWWidgetTextProps;
    removeWarranty: (variantId: string) => void;
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
    customModalCss?: string;
    isPreview?: boolean;
    foundSelector?: string;
  }) {
    const selectedOffering = selection
      ? offerings.find((o) => o.id === selection.id)
      : undefined;

    const [showModal, setShowModal] = useState(false);

    const handleLearnMoreClick = useHandler(() => {
      setShowModal(true);
    });

    const handleAddClick = useHandler(() => {
      setShowModal(true);
    });

    const handleModalClose = useHandler(() => {
      setShowModal(false);
    });

    const handleModalTileClick = useHandler((offering: Offering) => {
      if (offering.id === NO_COVERAGE_TIME_ID) {
        if (selection) {
          removeWarranty(variantId);
        }
      } else if (!selection || selection.id !== offering.id) {
        addWarranty(variantId, offering);
      }
      setShowModal(false);
    });

    const {
      cartToggleModalNotAddedDescription,
      cartToggleModalAddedDescription,
    } = text;

    return (
      <>
        <Flex
          className={extendedWarrantyCss.container}
          data-target={ExtendedWarrantyDataTarget.CT_MODAL_CONTAINER}
          justify="space-between"
          wrap="nowrap"
        >
          {selection ? (
            <ShieldTickIcon
              className={extendedWarrantyCss.shieldIcon}
              data-target={ExtendedWarrantyDataTarget.CT_ADDED_SHIELD}
            />
          ) : (
            <ShieldZapIconOutline
              className={extendedWarrantyCss.shieldIcon}
              data-target={ExtendedWarrantyDataTarget.CT_NOT_ADDED_SHIELD}
            />
          )}
          <Flex
            data-target={ExtendedWarrantyDataTarget.CT_MODAL_TEXT}
            flexDirection="column"
            gap="none"
          >
            <Text
              className={extendedWarrantyCss.plan}
              fontSize="sm"
              fontWeight="medium"
            >
              {!selection
                ? htmlReactParser(cartToggleModalNotAddedDescription)
                : htmlReactParser(
                    cartToggleModalAddedDescription.replace(
                      "%plan_title%",
                      selection.title,
                    ),
                  )}
            </Text>
            <LearnMoreLink onClick={handleLearnMoreClick} />
          </Flex>
          <Flex
            align="center"
            data-target={ExtendedWarrantyDataTarget.CT_MODAL_BUTTON}
            height="auto"
          >
            <ToggleAddButton
              onAddClick={handleAddClick}
              selectedOffering={selectedOffering}
            />
          </Flex>
        </Flex>
        {showModal && !isPreview && (
          <ExtendedWarrantyModal
            customCss={customModalCss}
            foundSelector={foundSelector}
            logo={logo}
            offerings={offerings}
            onClose={handleModalClose}
            onTileClick={handleModalTileClick}
            open={showModal}
            seeDetailsUrl={
              seeDetailsUrl ?? composeSeeDetailsUrl(window.location.host)
            }
            selectedOffering={selectedOffering}
            sideImage={sideImage}
            text={text}
          />
        )}
      </>
    );
  },
);
