import {
  ExtendedWarrantyDataTarget,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const ToggleAddButton = memo(function ToggleAddButton({
  selectedOffering,
  onAddClick,
  dataTarget,
}: {
  selectedOffering?: Offering;
  onAddClick: () => void;
  dataTarget?: string;
}) {
  return (
    <button
      className={warrantyStyles.addButton}
      data-target={dataTarget}
      onClick={onAddClick}
      type="button"
    >
      <Flex
        align="center"
        data-target={ExtendedWarrantyDataTarget.ADD_BUTTON_CONTAINER}
        gap="xs"
        justify="center"
        px="md"
        py="xs"
      >
        <Text
          fontSize="xs"
          fontWeight="medium"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {!!selectedOffering && selectedOffering?.id !== NO_COVERAGE_TIME_ID
            ? "MODIFY"
            : "ADD"}
        </Text>
      </Flex>
    </button>
  );
});
