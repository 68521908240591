import { useHover } from "@redotech/react-util/hover";
import * as classNames from "classnames";
import {
  JSXElementConstructor,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import CheckIcon from "../../arbiter-icon/check.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import {
  TextColorValue,
  TextSizeValue,
  TextWeightValue,
} from "../../theme/typography";
import { OverflowTooltip } from "../../tooltip/overflow-tooltip";
import { RedoBadge, RedoBadgeProps, RedoBadgeSize } from "../badge/redo-badge";
import { RedoCheckbox } from "../checkbox/redo-checkbox";
import { RedoCommandMenuItem } from "../command-menu/redo-command-menu";
import { RedoCommandShortcut } from "../command-menu/redo-command-shortcut";
import { RedoListItemCommandMenu } from "./redo-list-item-command-menu";
import { LeadingItem, RedoListItemLeadingItem } from "./redo-list-item-lead";
import * as redoListItemCss from "./redo-list-item.module.css";

export const redoListItemSelectionVariant = [
  "checkmark",
  "checkbox",
  "none",
] as const;
export type RedoListItemSelectionVariant =
  (typeof redoListItemSelectionVariant)[number];

export const redoListItemSize = ["sm", "md", "lg"] as const;
export type RedoListItemSize = (typeof redoListItemSize)[number];

interface BaseListItemProps {
  focused?: boolean;
  selected?: boolean;
  indeterminate?: boolean;
  menu?: RedoCommandMenuItem[] | { onClick: () => void };
  hotkey?: string[] | string;
  selectionVariant?: RedoListItemSelectionVariant;
  disabled?: boolean;
  size?: RedoListItemSize;
  card?: boolean;
  useWrapper?: boolean;
  infoBadge?: Omit<RedoBadgeProps, "size">;
  TrailingIcon?: JSXElementConstructor<any>;
  onItemHovered?(hovered: boolean): void;
  onItemSelected?(selected: boolean): void;
}

export interface TextRedoListItemProps extends BaseListItemProps {
  leadingItem?: LeadingItem;
  text: string;
  supportingText?: string;
  placeholder?: boolean;
}

export const TextRedoListItem = memo(function TextRedoListItem({
  leadingItem,
  text,
  supportingText,
  size = "sm",
  disabled = false,
  selected = false,
  useWrapper = true,
  placeholder = false,
  ...rest
}: TextRedoListItemProps) {
  const textColor: TextColorValue =
    ((disabled || placeholder) && "disabled") || "primary";
  const textWeight: TextWeightValue =
    ((selected || size === "sm") && !placeholder && "medium") || "regular";

  const supportingTextColor: TextColorValue =
    ((disabled || placeholder) && "disabled") || "tertiary";

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const contents = (
    <>
      {leadingItem && (
        <RedoListItemLeadingItem leadingItem={leadingItem} size={size} />
      )}
      <Flex dir="column" gap="none" justify="center" overflow="hidden">
        <OverflowTooltip
          overflowRef={ref}
          tooltipProps={{ title: text }}
          wrapperStyle={{ overflow: "hidden" }}
        >
          <Text
            className={redoListItemCss.text}
            fontSize={itemSizeToTextSize[size]}
            fontWeight={textWeight}
            overflow="hidden"
            ref={setRef}
            textColor={textColor}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {text.trim() || <br />}
          </Text>
        </OverflowTooltip>
        {supportingText && (
          <Text
            fontSize={itemSizeToSupportingTextSize[size]}
            fontWeight="regular"
            textColor={supportingTextColor}
          >
            {supportingText}
          </Text>
        )}
      </Flex>
    </>
  );

  if (!useWrapper) {
    return contents;
  }

  return (
    <RedoListItemContainer
      disabled={disabled}
      selected={selected}
      size={size}
      {...rest}
    >
      {contents}
    </RedoListItemContainer>
  );
});

export interface BadgeRedoListItemProps extends BaseListItemProps {
  badge: Omit<RedoBadgeProps, "size">;
}

export const BadgeRedoListItem = memo(function BadgeRedoListItem({
  badge,
  size = "sm",
  useWrapper = true,
  ...rest
}: BadgeRedoListItemProps) {
  if (!useWrapper) {
    return <RedoBadge {...badge} size={listItemSizeToBadgeSize[size]} />;
  }

  return (
    <RedoListItemContainer size={size} {...rest}>
      <RedoBadge {...badge} size={listItemSizeToBadgeSize[size]} />
    </RedoListItemContainer>
  );
});

const listItemSizeToBadgeSize: Record<RedoListItemSize, RedoBadgeSize> = {
  sm: "xs",
  md: "sm",
  lg: "md",
};

export const RedoListItemContainer = memo(function RedoListItemContainer({
  focused = false,
  selected = false,
  indeterminate = false,
  menu,
  hotkey,
  selectionVariant = "checkmark",
  disabled = false,
  size = "sm",
  card,
  onItemHovered,
  onItemSelected,
  infoBadge,
  TrailingIcon,
  children,
}: BaseListItemProps & { children: ReactNode }) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isHovered = useHover(anchor);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    onItemHovered?.(isHovered);
  }, [isHovered, onItemHovered]);

  const itemInFocus = isHovered || focused || menuOpen;

  const showMenu =
    itemInFocus && menu && (!Array.isArray(menu) || menu.length > 0);

  return (
    <Flex
      align="center"
      className={classNames(
        redoListItemCss.container,
        card && redoListItemCss.card,
        disabled && redoListItemCss.disabled,
        focused && redoListItemCss.focused,
        selected && redoListItemCss.selected,
      )}
      grow={1}
      justify="space-between"
      onClick={(e) => {
        if (!onItemSelected) {
          return;
        }
        if (disabled) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        if (indeterminate) {
          onItemSelected(false);
        } else {
          onItemSelected(!selected);
        }
      }}
      overflow="hidden"
      ref={setAnchor}
      {...itemSizeToContainerPadding[size]}
    >
      <Flex align="center" overflow="hidden">
        {selectionVariant === "checkbox" && (
          <RedoCheckbox
            disabled={disabled}
            setValue={() => {
              if (indeterminate) {
                onItemSelected?.(false);
              } else {
                onItemSelected?.(!selected);
              }
            }}
            size="sm"
            value={indeterminate ? "indeterminate" : selected}
          />
        )}
        {children}
      </Flex>
      <Flex>
        {showMenu && (
          <RedoListItemCommandMenu
            disabled={disabled}
            menu={menu}
            open={menuOpen}
            setOpen={setMenuOpen}
          />
        )}
        {hotkey && !showMenu && (
          <RedoCommandShortcut hotkey={hotkey} size={size} />
        )}
        {infoBadge && (
          <RedoBadge {...infoBadge} size={listItemSizeToBadgeSize[size]} />
        )}
        {TrailingIcon && (
          <TrailingIcon
            className={classNames(
              redoListItemCss.icon,
              itemSizeToSizeClass[size],
            )}
          />
        )}
        {selectionVariant === "checkmark" && selected && !showMenu && (
          <CheckIcon
            className={classNames(
              redoListItemCss.checkmark,
              itemSizeToSizeClass[size],
            )}
          />
        )}
      </Flex>
    </Flex>
  );
});

export const itemSizeToSizeClass: Record<RedoListItemSize, string> = {
  sm: redoListItemCss.sm,
  md: redoListItemCss.md,
  lg: redoListItemCss.lg,
};

export const itemSizeToTextSize: Record<RedoListItemSize, TextSizeValue> = {
  sm: "xs",
  md: "md",
  lg: "md",
};

export const itemSizeToSupportingTextSize: Record<
  RedoListItemSize,
  TextSizeValue
> = { sm: "xs", md: "sm", lg: "sm" };

const itemSizeToContainerPadding: Record<
  RedoListItemSize,
  { px: SpacingValue; py: SpacingValue }
> = {
  sm: { px: "xs", py: "xs" },
  md: { px: "md", py: "xs" },
  lg: { px: "md", py: "md" },
};
