import { OmitUnion } from "@redotech/util/type";
import { AdvancedFlowTokenType as AdvancedFlowConditionTokenType } from "./data-types/base-types";
import { GenericDataType, GenericPrefixes } from "./data-types/generic-types";
import { DataType, DataTypeName } from "./data-types/index";

/**
 * Infers the true type of a schema's field by recursively unwrapping generic prefixes.
 */
type Value<T> = T extends `${GenericPrefixes["Array"]}${infer U}`
  ? Value<U>[]
  : T extends `${GenericPrefixes["Optional"]}${infer V}`
    ? Value<V> | undefined
    : T extends DataTypeName
      ? DataType[T]
      : never;

/**
 * The generic type for datatypes that can be passed as input to an email flow.
 *
 * @example
 * const customerEventSchema = defineSchema({
 *  fields: {
 *    email: {
 *    dataType: "Email",
 *    documentation: "The email address of the customer who triggered the event",
 *  },
 * });
 */
export type Schema = {
  fields: { [key: string]: OmitUnion<SchemaField, "key"> };
};

export type SchemaField = {
  key: string;
  documentation: string;
  prettyValues?: Record<string, string>;
} & (
  | { dataType: Exclude<GenericDataType, "Enum" | "Token">; values?: string[] }
  | { dataType: "Enum"; values: string[] }
  | { dataType: "Token"; tokenType: AdvancedFlowConditionTokenType }
);
/**
 * A helper function to define a schema that provides increased type safety.
 * Without using it, the type of the schema is inferred broadly,
 * thus making it difficult to hardcode example schemas.
 */
export function defineSchema<T extends Schema>(schema: T) {
  return schema;
}

/**
 * An instance of a Schema.
 *
 * @example
 * const customerEvent<SchemaInstance<typeof customerEventSchema>> = {
 *  fields: {
 *    email: "john@fake.com"
 *  }
 * };
 */
export type SchemaInstance<T extends Schema> = {
  [K in keyof T["fields"]]: Value<T["fields"][K]["dataType"]>;
};
