import { DeliveryProfileSchema } from "@redotech/redo-model/integration/shopify/delivery-profile";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export enum GetDeliveryProfileResult {
  SUCCESS = "SUCCESS",
  ERROR__GRAPHQL_ACCESS_DENIED = "ERROR__GRAPHQL_ACCESS_DENIED",
  NOT_FOUND = "NOT_FOUND",
}

export const getDeliveryProfile = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.union([
    z.object({
      deliveryProfile: DeliveryProfileSchema,
      result: z.literal(GetDeliveryProfileResult.SUCCESS),
    }),
    z.object({
      result: z.union([
        z.literal(GetDeliveryProfileResult.ERROR__GRAPHQL_ACCESS_DENIED),
        z.literal(GetDeliveryProfileResult.NOT_FOUND),
      ]),
    }),
  ]),
};

export type GetDeliveryProfileOutput = z.infer<
  typeof getDeliveryProfile.output
>;
