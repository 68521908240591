import { toast } from "@redotech/redo-web/alert";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import UploadCloudIcon from "@redotech/redo-web/arbiter-icon/upload-cloud-02.svg";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import classNames from "classnames";
import { memo, useEffect, useRef, useState } from "react";
import { RedoAdminClient } from "../../../client";
import { uploadZendeskMacros } from "../../../client/team";

export const ImportZendeskMacrosCard = memo(function ImportZendeskMacrosCard({
  client,
  teamId,
}: {
  client: RedoAdminClient;
  teamId: string | undefined;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const abortController = useRef<AbortController>(new AbortController());

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const onSubmit = async () => {
    if (!file || !teamId) return;

    try {
      setLoading(true);
      await uploadZendeskMacros(client, {
        teamId,
        file,
        signal: abortController.current.signal,
      });
      toast("Zendesk macros uploaded successfully", { variant: "success" });
    } catch (error) {
      toast("Failed to upload Zendesk macros", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => abortController.current.abort();
  }, []);

  return (
    <Card title="Zendesk macro import">
      <section className={classNames(gridCss.grid, gridCss.span12)}>
        <div className={gridCss.span6L}>
          Upload a CSV of Zendesk macros. You can upload one file at a time
        </div>
        <div className={gridCss.span3L}>
          <label htmlFor="zendesk-macro-upload" id="zendesk-macro-upload-label">
            <RedoButton
              disabled={loading}
              hierarchy="primary"
              IconLeading={UploadCloudIcon}
              onClick={() => {
                document.getElementById("zendesk-macro-upload")?.click();
              }}
              size="md"
              text="Upload"
              theme="normal"
            />
          </label>
          {file && <div className={gridCss.span3L}>{file.name}</div>}
          <input
            accept=".csv"
            id="zendesk-macro-upload"
            onChange={handleFileChange}
            style={{ display: "none" }}
            type="file"
          />
        </div>
        <div className={gridCss.span3L}>
          <RedoButton
            disabled={!file || loading}
            hierarchy="primary"
            IconLeading={UploadCloudIcon}
            onClick={onSubmit}
            pending={loading}
            size="md"
            text="Submit"
            theme="normal"
          />
        </div>
      </section>
    </Card>
  );
});
