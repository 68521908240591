import { PatchCode } from "@redotech/admin-sdk/rpc/schema/patch-admin-settings/patch-codes";
import { Patch } from "@redotech/admin-sdk/rpc/schema/patch-admin-settings/patch-schema-index";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { ActionPortalContext, Actions } from "@redotech/redo-web/page";
import { assertNever, DeepReadonly } from "@redotech/util/type";
import { createContext, memo, useContext, useState } from "react";
import { createPortal } from "react-dom";
import { RedoAdminRpcClientContext } from "../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../team";

/** Useful for e.g. making a popup modal before saving under certain conditions. */
type PreCommitValidation = () => Promise<boolean>;

export type PatchAdminSettingsClient = {
  upsertPatch(
    code: PatchCode,
    patch: Patch | null,
    preCommitValidation?: PreCommitValidation,
  ): void;
  commit(): Promise<void>;
  resetInputs(): void;
  triggerReloadDeps: symbol;
  triggerResetInputs: symbol;
  patches: DeepReadonly<Map<PatchCode, Patch>>;
};

export const PatchAdminSettingsClientContext = createContext<
  PatchAdminSettingsClient | undefined
>(undefined);

/**
 * Allows disparate components to submit a group of patches defined in different subtrees / places at once, all in one network request upon clicking one save button.
 *
 * Also allows components to reset their inputs when the user clicks cancel or saves.
 */
export const PatchAdminSettingsClientProvider = memo(
  function PatchAdminSettingsClientProvider({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const [patches, setPatches] = useState<Map<PatchCode, Patch>>(new Map());
    const [preCommitValidations, setPreCommitValidations] = useState<
      Map<PatchCode, PreCommitValidation>
    >(new Map());
    const [triggerResetInputs, setTriggerResetInputs] = useState(Symbol());
    const [triggerReloadDeps, setTriggerReloadDeps] = useState(Symbol());
    const redoAdminRpcClient = useRequiredContext(RedoAdminRpcClientContext);
    return (
      <PatchAdminSettingsClientContext.Provider
        value={{
          upsertPatch: (code, patch, preCommitValidation) => {
            setPatches((prev) => {
              const newPatches = new Map(prev);
              if (patch) {
                newPatches.set(code, patch);
              } else {
                newPatches.delete(code);
              }
              return newPatches;
            });

            setPreCommitValidations((prev) => {
              const newPreCommitValidations = new Map(prev);
              if (preCommitValidation) {
                newPreCommitValidations.set(code, preCommitValidation);
              } else {
                newPreCommitValidations.delete(code);
              }
              return newPreCommitValidations;
            });
          },
          commit: async () => {
            /** If any patch fails to validate, cancel saving all the patches. */
            for (const preCommitValidation of preCommitValidations.values()) {
              if (!preCommitValidation) {
                continue;
              }
              const isValid = await preCommitValidation();
              if (!isValid) {
                return;
              }
            }

            for (const patch of patches.values()) {
              switch (patch.code) {
                case PatchCode.UPDATE_WIDGET_CONFIGS:
                  await redoAdminRpcClient.patchWidgetConfigs(patch);
                  break;
                case PatchCode.PATCH_STOREFRONT_TREATMENT:
                  await redoAdminRpcClient.patchStorefrontTreatment(patch);
                  break;
                default:
                  assertNever(patch);
              }
            }

            setPatches(new Map());
            setPreCommitValidations(new Map());
            setTriggerReloadDeps(Symbol());
          },
          resetInputs: () => {
            setTriggerResetInputs(Symbol());
          },
          triggerResetInputs,
          triggerReloadDeps,
          patches,
        }}
      >
        {children}
      </PatchAdminSettingsClientContext.Provider>
    );
  },
);

export const PatchBasedSaveCancelButtons = memo(
  function PatchBasedSaveCancelButtons() {
    const patchAdminSettingsClient = useRequiredContext(
      PatchAdminSettingsClientContext,
    );
    const actionsPortal = useContext(ActionPortalContext);
    const team = useRequiredContext(TeamContext);

    const [saveLoad, doSave] = useTriggerLoad((signal) =>
      alertOnFailure("Saving failed, please check your inputs")(async () => {
        if (!team) {
          throw new Error("Team not found");
        }
        await patchAdminSettingsClient.commit();
        return true as const;
      }),
    );

    const hasUnsavedPatches = patchAdminSettingsClient.patches.size > 0;
    return (
      <>
        {actionsPortal &&
          createPortal(
            <Actions show={hasUnsavedPatches}>
              <Flex dir="row" gap="xl">
                <Button
                  disabled={!hasUnsavedPatches}
                  onClick={() => doSave()}
                  pending={saveLoad.pending}
                  theme={ButtonTheme.PRIMARY}
                >
                  Save
                </Button>
                <Button
                  disabled={!hasUnsavedPatches}
                  onClick={() => patchAdminSettingsClient.resetInputs()}
                  theme={ButtonTheme.OUTLINED}
                >
                  Cancel
                </Button>
              </Flex>
            </Actions>,
            actionsPortal,
          )}
      </>
    );
  },
);
