import {
  AmazonServices,
  UpsServices,
} from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import {
  RateTableType,
  RateTableValueMode,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  FedexRateTableServiceLevel,
  RateTableCarrier,
  USPSRateTableServiceLevel,
} from "@redotech/redo-model/rate-table/rate-table-mapping";
import { z } from "zod";

const baseDeleteRateTable = z.object({
  teamId: z.string(),
  carrier: z.nativeEnum(RateTableCarrier),
  valueMode: z.nativeEnum(RateTableValueMode),
  rateTableType: z.nativeEnum(RateTableType),
  serviceLevel: z.union([
    z.union([z.nativeEnum(USPSRateTableServiceLevel), z.literal("Default")]),
    z.union([z.nativeEnum(FedexRateTableServiceLevel), z.literal("Default")]),
    z.union([z.nativeEnum(UpsServices), z.literal("Default")]),
    z.union([z.nativeEnum(AmazonServices), z.literal("Default")]),
  ]),
});

export const deleteRateTable = {
  input: baseDeleteRateTable,
  output: z.object({ success: z.boolean() }),
};
