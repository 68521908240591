import { ReviewRating } from "@redotech/redo-model/review/review-field-schema";
import { array, nativeEnum, number, object, string } from "zod";

export const getReviewsSummaryByProductId = {
  input: string(),
  output: object({
    rating: number().optional(),
    reviewCountByRating: array(
      object({ rating: nativeEnum(ReviewRating), count: number() }),
    ),
  }),
};
