import * as classNames from "classnames";
import { memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import { DisabledTooltip } from "../../tooltip/disabled-tooltip";
import * as toggleCss from "./redo-toggle.module.css";

export const redoToggleSize = ["xs", "sm", "md"] as const;
export type RedoToggleSize = (typeof redoToggleSize)[number];

export const redoToggleStyle = ["desktop", "mobile"] as const;
export type RedoToggleStyle = (typeof redoToggleStyle)[number];

export interface RedoToggleProps {
  value: boolean;
  setValue(value: boolean): void;
  style?: RedoToggleStyle;
  size?: RedoToggleSize;
  label?: string;
  description?: string;
  disabled?: string | boolean;
}

export const RedoToggle = memo(function RedoToggle({
  value,
  setValue,
  size = "xs",
  style = "desktop",
  label,
  description,
  disabled,
}: RedoToggleProps) {
  return (
    <DisabledTooltip disabledMessage={disabled}>
      {style === "desktop" ? (
        <DesktopToggle
          description={description}
          disabled={disabled}
          label={label}
          setValue={setValue}
          size={size}
          value={value}
        />
      ) : (
        <MobileToggle
          disabled={disabled}
          label={label}
          setValue={setValue}
          size={size}
          value={value}
        />
      )}
    </DisabledTooltip>
  );
});

export const ToggleSwitch = memo(function ToggleSwitch({
  value,
  setValue,
  disabled,
  size,
}: {
  value: boolean;
  disabled: string | boolean | undefined;
  setValue: (value: boolean) => void;
  size: RedoToggleSize;
}) {
  return (
    <>
      <input
        checked={value}
        className={classNames(toggleCss.toggleInput)}
        disabled={!!disabled}
        onChange={(e) => setValue(e.target.checked)}
        type="checkbox"
      />
      <div
        className={classNames(toggleCss.toggleSwitch, toggleSizeToClass[size])}
      />
    </>
  );
});

const DesktopToggle = memo(function DesktopToggle({
  value,
  setValue,
  size = "xs",
  label,
  description,
  disabled,
}: Omit<RedoToggleProps, "style">) {
  const showDescriptors = !!(label || description);

  const labelTextColor =
    (disabled && "disabled") || (value && "primary") || "secondary";
  const descriptionTextColor = (disabled && "disabled") || "secondary";

  const onlyLabel = !description && !!label;

  return (
    <Flex align={onlyLabel ? "center" : "stretch"} as="label">
      <Flex pt={showDescriptors ? "xxs" : "none"}>
        <ToggleSwitch
          disabled={disabled}
          setValue={setValue}
          size={size}
          value={value}
        />
      </Flex>
      {showDescriptors && (
        <Flex dir="column" gap="none">
          {label && (
            <Text
              className={toggleCss.text}
              fontSize={toggleSizeToLabelSize[size]}
              fontWeight={value ? "medium" : "regular"}
              textColor={labelTextColor}
            >
              {label}
            </Text>
          )}
          {description && (
            <Text
              className={toggleCss.text}
              fontSize={toggleSizeToDescriptionSize[size]}
              textColor={descriptionTextColor}
            >
              {description}
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  );
});

const MobileToggle = memo(function MobileToggle({
  value,
  setValue,
  size = "xs",
  label,
  disabled,
}: Omit<RedoToggleProps, "style" | "description">) {
  const labelTextColor =
    (disabled && "disabled") || (value && "primary") || "secondary";

  return (
    <Flex as="label" grow={1} justify="space-between">
      <ToggleSwitch
        disabled={disabled}
        setValue={setValue}
        size={size}
        value={value}
      />
      {label && (
        <Text
          className={toggleCss.text}
          fontSize={toggleSizeToLabelSize[size]}
          fontWeight={value ? "medium" : "regular"}
          textColor={labelTextColor}
        >
          {label}
        </Text>
      )}
    </Flex>
  );
});

const toggleSizeToClass: Record<RedoToggleSize, string> = {
  xs: toggleCss.xs,
  sm: toggleCss.sm,
  md: toggleCss.md,
};
const toggleSizeToLabelSize: Record<RedoToggleSize, TextSizeValue> = {
  xs: "xs",
  sm: "sm",
  md: "md",
};

const toggleSizeToDescriptionSize: Record<RedoToggleSize, TextSizeValue> = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
};
