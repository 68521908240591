import {
  Rate,
  ShippingRateTable,
  ShippingRateTableSchema,
} from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Switch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { useState } from "react";
export function EditRate({
  rateTable,
  close,
  save,
}: {
  rateTable: ShippingRateTable;
  close: () => void;
  save: (newTable: ShippingRateTable) => string | undefined;
}) {
  const [copyOfRateTable, setCopyOfRateTable] =
    useState<ShippingRateTable>(rateTable);

  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <RedoModal
      onModalCloseRequested={function (): void {
        close();
      }}
      primaryButton={{
        text: "Save & Exit",
        onClick: function (): void {
          const error = save(copyOfRateTable);
          if (error) {
            setError(error);
            return;
          }
          close();
        },
        disabled: false,
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: function (): void {
          close();
        },
        disabled: false,
      }}
      subtitle="Configure your shipping zone details here."
      title="Shipping zone"
    >
      <Flex dir="column" gap="lg">
        <RedoTextInput
          label="Name"
          setValue={function (value: string): void {
            setCopyOfRateTable({ ...copyOfRateTable, name: value });
          }}
          value={copyOfRateTable.name}
        />
        <RedoTextInput
          label="Subtext"
          setValue={function (value: string): void {
            setCopyOfRateTable({ ...copyOfRateTable, description: value });
          }}
          value={copyOfRateTable.description}
        />

        <RedoTextInput
          label="Shipping code"
          setValue={function (value: string): void {
            throw new Error("Function not implemented.");
          }}
          state="readonly"
          value={copyOfRateTable.code}
        />

        <LabeledInput label="The merchant has included the coverage cost in their pricing set in Shopify.">
          <Switch
            onChange={function (value: boolean): void {
              const rates: Rate[] = copyOfRateTable.rates;
              for (const rate of rates) {
                rate.merchantPaidCoverageTypes = { return: value };
              }

              setCopyOfRateTable(
                ShippingRateTableSchema.parse({
                  ...copyOfRateTable,
                  rates: rates,
                }),
              );
            }}
            value={copyOfRateTable.rates.some(
              (rate) => rate.merchantPaidCoverageTypes?.return === true,
            )}
          />
        </LabeledInput>
        {error && (
          <Text fontSize="md" fontWeight="regular" textColor="error">
            {error}
          </Text>
        )}
      </Flex>
    </RedoModal>
  );
}
