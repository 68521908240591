import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { REDO_OUTBOUND_CARRIER_IDS } from "@redotech/redo-model/rate-table/redo-carriers";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../app/auth";
import { RedoAdminRpcClientContext } from "../../app/redo-admin-rpc-client-provider";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { AddBalanceCreditCard } from "./outbound-labels/add-balance-credit";
import {
  BalanceSettings,
  balanceSettingsDefaultValue,
  balanceSettingsForm,
} from "./outbound-labels/balance-settings";
import {
  BlockCarrierAccountsCard,
  carrierAccountDefault,
  carrierAccountForm,
} from "./outbound-labels/block-carrier-accounts";
import {
  CheckoutOutboundRatesCard,
  checkoutOutboundRatesDefault,
  checkoutOutboundRatesForm,
} from "./outbound-labels/checkout-outbound-rates";
import { OutboundLabelsGeneral } from "./outbound-labels/general";
import { LookupRateDetailsCard } from "./outbound-labels/lookup-rate-details";
import { OutboundLabelRateCardCard } from "./outbound-labels/rate-tables/rate-table-card";

export const OutboundLabelsPage = memo(function OutboundLabelsPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const settingsLoad = useLoad<OutboundLabelValue | undefined>(async () => {
    if (!team) {
      return;
    }

    const checkoutOutboundRates =
      await rpcClient.getCheckoutOutboundRatesSettings({ teamId });

    const blockedCarrierAccounts =
      team.settings.outboundLabels?.blockedCarrierAccounts ?? [];
    const carrierAccounts = {
      fedex: blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.FEDEX),
      fedexSmartpost: blockedCarrierAccounts.includes(
        REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST,
      ),
      upsGround:
        blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.B2B_UPS) &&
        blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.B2C_UPS),
      upsGroundSaver: blockedCarrierAccounts.includes(
        REDO_OUTBOUND_CARRIER_IDS.SUREPOST_UPS,
      ),
      uspsShip: blockedCarrierAccounts.includes(REDO_OUTBOUND_CARRIER_IDS.USPS),
      easypostUSPS: blockedCarrierAccounts.includes(
        REDO_OUTBOUND_CARRIER_IDS.EASYPOST_USPS,
      ),
    };
    return {
      ...outboundLabelDefault,
      newOmsEnabled: team.settings.outboundLabels?.newOmsEnabled ?? false,
      automationDelaySeconds: (
        team.settings.outboundLabels?.automationDelaySeconds ?? 0
      ).toFixed(0),
      binNamespace: team.settings.outboundLabels?.binNamespace ?? "",
      binKey: team.settings.outboundLabels?.binKey ?? "",
      blockedCarrierAccounts: carrierAccounts,
      checkoutOutboundRates: {
        checkoutOutboundRatesEnabled: checkoutOutboundRates.enabled,
        demoCheckoutOutboundRatesEnabled: checkoutOutboundRates.demoEnabled,
        hideNonRedoRatesWhenRedoPresent:
          checkoutOutboundRates.hideNonRedoRatesWhenRedoPresent,
      },
      balanceSettings: {
        minimumBalance:
          team?.settings?.outboundLabels?.billing?.autoReload?.floor ?? 0,
      },
    };
  }, [team]);

  return (
    <OutboundLabelsForm
      initialValue={settingsLoad.value}
      key={+!team}
      onSave={handleSave}
      teamId={teamId}
    />
  );
});

export const outboundLabelForm = groupInput({
  newOmsEnabled: input<boolean>(),
  automationDelaySeconds: input<string>(),
  binNamespace: input<string>(),
  binKey: input<string>(),
  blockedCarrierAccounts: carrierAccountForm,
  checkoutOutboundRates: checkoutOutboundRatesForm,
  balanceSettings: balanceSettingsForm,
});

export type OutboundLabelForm = InputProvider.Form<typeof outboundLabelForm>;

export type OutboundLabelValue = InputProvider.Value<typeof outboundLabelForm>;

export const outboundLabelDefault: OutboundLabelValue = {
  newOmsEnabled: false,
  automationDelaySeconds: "0",
  binNamespace: "",
  binKey: "",
  blockedCarrierAccounts: carrierAccountDefault,
  checkoutOutboundRates: checkoutOutboundRatesDefault,
  balanceSettings: balanceSettingsDefaultValue,
};

const omsEngineers = [
  "joe@getredo.com",
  "pj@getredo.com",
  "brian@getredo.com",
  "jaden.sterner@getredo.com",
  "tyson.peterson@getredo.com",
  "tyler.trommlitz@getredo.com",
  "samuel@getredo.com",
  "coleman@getredo.com",
  "bcarper@getredo.com",
  "paul@getredo.com",
];

const usersWhoCanEditRateTables = [
  ...omsEngineers,
  "suz@getredo.com",
  "aaron@getredo.com",
  "jackie@getredo.com",
  "ridge@getredo.com",
];

const usersWhoCanSeeRateTables = [
  ...usersWhoCanEditRateTables,
  "patrick@getredo.com",
  "jordan@getredo.com",
  "hayden@getredo.com",
  "spencer@getredo.com",
  "logan@getredo.com",
  "eric@getredo.com",
];

const usersWhoCanChangeBalance = [
  ...omsEngineers,
  "suz@getredo.com",
  "aaron@getredo.com",
  "hayden@getredo.com",
  "jackson@getredo.com",
  "kash@getredo.com",
  "josh.thayne@getredo.com",
];

const OutboundLabelsForm = memo(function OutboundLabelsForm({
  onSave,
  teamId,
  initialValue,
}: {
  teamId: string;
  initialValue: OutboundLabelValue | undefined;
  onSave?: (value: OutboundLabelValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input = useInput(
    outboundLabelForm,
    initialValue || outboundLabelDefault,
  );
  const auth = useRequiredContext(AuthContext);
  const team = useRequiredContext(TeamContext);
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const carrierServicesLoad = useLoad(
    async (signal) => {
      return await rpcClient.hasCarrierServices({ teamId }, { signal });
    },
    [teamId],
  );

  const userCanUseBalance = useMemo(() => {
    return usersWhoCanChangeBalance.includes(auth.email);
  }, [auth]);

  const userCanEditRateTables = useMemo(() => {
    return usersWhoCanEditRateTables.includes(auth.email);
  }, [auth]);

  const userCanSeeRateTables = useMemo(() => {
    return usersWhoCanSeeRateTables.includes(auth.email);
  }, [auth]);

  useEffect(() => {
    if (initialValue) {
      input.setValue(initialValue);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId, signal });
    const value = input.value;

    const blockedCarrierAccounts: string[] = [];

    if (value.blockedCarrierAccounts.fedex) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.FEDEX);
    }

    if (value.blockedCarrierAccounts.fedexSmartpost) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.FEDEX_SMARTPOST);
    }

    if (value.blockedCarrierAccounts.upsGround) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.B2B_UPS);
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.B2C_UPS);
    }

    if (value.blockedCarrierAccounts.upsGroundSaver) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.SUREPOST_UPS);
    }

    if (value.blockedCarrierAccounts.uspsShip) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.USPS);
    }
    if (value.blockedCarrierAccounts.easypostUSPS) {
      blockedCarrierAccounts.push(REDO_OUTBOUND_CARRIER_IDS.EASYPOST_USPS);
    }

    team.settings.outboundLabels = {
      ...team.settings.outboundLabels,
      newOmsEnabled: value?.newOmsEnabled,
      automationDelaySeconds: Math.floor(
        parseFloat(value.automationDelaySeconds) || 0,
      ),
      binNamespace: value.binNamespace,
      binKey: value.binKey,
      blockedCarrierAccounts: blockedCarrierAccounts,
      billing: {
        ...team.settings.outboundLabels?.billing,
        autoReload: {
          amount:
            team?.settings?.outboundLabels?.billing?.autoReload?.amount ?? 0,
          threshold:
            team?.settings?.outboundLabels?.billing?.autoReload?.threshold ?? 0,
          floor: value.balanceSettings.minimumBalance,
        },
      },
    };

    await updateTeam(client, { teamId, team, signal });

    await rpcClient.updateCheckoutOutboundRatesSettings({
      teamId,
      enabled: value.checkoutOutboundRates.checkoutOutboundRatesEnabled,
      demoEnabled: value.checkoutOutboundRates.demoCheckoutOutboundRatesEnabled,
      hideNonRedoRatesWhenRedoPresent:
        value.checkoutOutboundRates.hideNonRedoRatesWhenRedoPresent,
    });

    onSave && onSave(value);
  });

  return (
    <div className={teamCss.container}>
      <PageForm
        initial={initialValue || outboundLabelDefault}
        input={input}
        save={save}
      >
        <div className={gridCss.grid}>
          <div className={gridCss.span12}>
            {userCanSeeRateTables && (
              <div className={gridCss.span12}>
                <OutboundLabelRateCardCard readonly={!userCanEditRateTables} />
              </div>
            )}
          </div>
        </div>
        <br />
        <div className={gridCss.grid}>
          <div className={gridCss.span4}>
            <OutboundLabelsGeneral input={input} />
            {userCanUseBalance && (
              <>
                <br />
                <AddBalanceCreditCard team={team} />
              </>
            )}
            <br />
            <BalanceSettings
              balanceSettingsInput={input.inputs.balanceSettings}
              team={team}
            />
            {carrierServicesLoad.value && (
              <>
                <br />
                <CheckoutOutboundRatesCard
                  hasCarrierServices={
                    !!carrierServicesLoad.value?.hasCarrierServices
                  }
                  input={input.inputs.checkoutOutboundRates}
                  teamId={teamId}
                />
              </>
            )}
          </div>
          <div className={gridCss.span8}>
            {userCanSeeRateTables && (
              <>
                <LookupRateDetailsCard />
                <br />
              </>
            )}
            <BlockCarrierAccountsCard input={input} />
          </div>
        </div>
      </PageForm>
    </div>
  );
});
