// extracted by mini-css-extract-plugin
export var border = "_20-5";
export var button = "_20-g";
export var center = "_20-7";
export var centered = "_20-3";
export var closeButton = "_20-9";
export var disabledWrapper = "_20-h";
export var error = "_20-k";
export var fill = "_20-d";
export var footer = "_20-c";
export var footerAction = "_20-e";
export var footerButtons = "_20-f";
export var header = "_20-4";
export var icon = "_20-j";
export var info = "_20-n";
export var inline = "_20-6";
export var large = "_20-2";
export var main = "_20-a";
export var modal = "_20-0";
export var neutral = "_20-o";
export var none = "_20-p";
export var scrollable = "_20-b";
export var small = "_20-1";
export var success = "_20-m";
export var tight = "_20-i";
export var titleIcon = "_20-8";
export var warn = "_20-l";