import { assertNever } from "@redotech/util/type";
import {
  BorderStrokeStyleDefinition,
  FontFamily,
  FontWeight,
  HexCode,
  defaultBrandKit,
  defaultTertiaryButton,
} from "./brand-kit";
import {
  ButtonElement,
  ImageElement,
  InputElement,
  MarketingForgeElementType,
  RichTextElement,
} from "./forge/marketing-signup-forge-types";
import { SignupForm } from "./signup-form-schema";

export const directions = [
  "top",
  "right",
  "bottom",
  "left",
  "no",
  "background",
] as const;
export type Direction = (typeof directions)[number];

export enum SignUpFormGoal {
  MARKETING_SUBSCRIPTION = "marketing-subscription",
  BACK_IN_STOCK = "back-in-stock",
}

export const prettySignUpFormGoal: Record<SignUpFormGoal, string> = {
  [SignUpFormGoal.MARKETING_SUBSCRIPTION]: "Marketing subscription",
  [SignUpFormGoal.BACK_IN_STOCK]: "Back in stock",
};

export enum LayoutType {
  POPUP = "popup",
  FULLSCREEN = "fullscreen",
  EMBEDDED = "embedded",
}

export const inputTypes = ["email", "phone", "birthday"] as const;
export type InputType = (typeof inputTypes)[number];

export const captureGoalTypes = ["phone", "email", "both"] as const;
export type CaptureGoalType = (typeof captureGoalTypes)[number];

export const deviceOptions = ["desktop", "mobile", "desktop & mobile"] as const;
export type DeviceOption = (typeof deviceOptions)[number];

export const signupFormSiteTypes = [
  "tracking-page",
  "marketing",
  "customer-account",
] as const;
export type SignupFormSiteType = (typeof signupFormSiteTypes)[number];

export const discountTypes = ["percent", "fixed"] as const;
export type DiscountType = (typeof discountTypes)[number];

export const audienceTypes = ["all visitors", "based on campaign"] as const;
export type AudienceType = (typeof audienceTypes)[number];

export const targetingTypes = [
  "all visitors",
  "exclude existing contacts",
  "only existing contacts",
] as const;
export type TargetingType = (typeof targetingTypes)[number];

export enum ButtonAction {
  SUBMIT_AND_ADVANCE = "submit-and-advance",
  CLOSE = "close",
}

export const teaserVisibility = ["before-displaying", "after-closing"] as const;
export type TeaserVisibility = (typeof teaserVisibility)[number];

export const alignmentHorizontalValues = ["left", "center", "right"] as const;
export type AlignmentHorizontal = (typeof alignmentHorizontalValues)[number];

export const alignmentVerticalValues = ["top", "center", "bottom"] as const;
export type AlignmentVertical = (typeof alignmentVerticalValues)[number];

export interface Alignment2D {
  vertical: AlignmentVertical;
  horizontal: AlignmentHorizontal;
}

export enum URLVisibilityType {
  INCLUSIVE = "inclusive",
  EXCLUSIVE = "exclusive",
}

export const fontWeightValues: Record<FontWeight, number> = {
  regular: 400,
  semibold: 600,
  bold: 700,
};

export interface FormStep {
  elements: FormElement[];
}

export interface Teaser {
  text: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  positionHorizontal: AlignmentHorizontal;
  positionVertical: AlignmentVertical;
  backgroundColor: HexCode;
  textColor: HexCode;
  cornerRadiusPx: number;
  borderStroke: BorderStrokeStyleDefinition;
  iconVisible: boolean;
  visibility: TeaserVisibility;
}

export type FormElement =
  | RichTextElement
  | ButtonElement
  | InputElement
  | ImageElement;

const surveyStep: FormStep = {
  elements: [
    {
      id: 1,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-start"><b>Get 10% off</b></p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 32,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
    {
      id: 2,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-start">Join our mailing list today and get a discount on your next order. Interested?</p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "regular",
      fontSizePx: 16,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
    {
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "primary",
      text: "Yes, sign me up",
      action: ButtonAction.SUBMIT_AND_ADVANCE,
    },
    {
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "secondary",
      text: "No thanks",
      action: ButtonAction.CLOSE,
    },
  ],
};

export const marketingCaptureStep = (
  captureGoal: CaptureGoalType,
  isEmbedded: boolean = false,
): FormStep => {
  const step: FormStep = {
    elements: [
      {
        id: 1,
        type: MarketingForgeElementType.RICH_TEXT,
        html: isEmbedded
          ? '<p class="ql-align-center">Sign up for our VIP newsletter</p>'
          : '<p class="ql-align-start"><b>Get 10% off your first order</b></p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: isEmbedded ? "regular" : "bold",
        fontSizePx: 32,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        id: 2,
        type: MarketingForgeElementType.RICH_TEXT,
        html: isEmbedded
          ? '<p class="ql-align-center">You\'ll be the first to know about exclusive products and offers.</p>'
          : '<p class="ql-align-start">Sign up for special offers and updates</p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: "regular",
        fontSizePx: 16,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
    ],
  };

  if (captureGoal === "email" || captureGoal === "both") {
    step.elements.push({
      type: MarketingForgeElementType.INPUT,
      inputType: "email",
      placeholderText: "Enter your email address",
    });
  }

  if (captureGoal === "phone" || captureGoal === "both") {
    step.elements.push({
      type: MarketingForgeElementType.INPUT,
      inputType: "phone",
      placeholderText: "Enter your phone number",
    });
  }

  step.elements.push({
    type: MarketingForgeElementType.BUTTON,
    hierarchy: "primary",
    text: isEmbedded ? "Submit" : "Sign up",
    action: ButtonAction.SUBMIT_AND_ADVANCE,
  });

  return step;
};

const successStep = (isEmbedded: boolean): FormStep => {
  const elements: FormElement[] = [
    {
      id: 1,
      type: MarketingForgeElementType.RICH_TEXT,
      html: '<p class="ql-align-center">Thank you for subscribing!</p>',
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 32,
      textColor: "#000000",
      linkColor: "#0000ff",
    },
  ];

  if (isEmbedded) {
    elements.push({
      type: MarketingForgeElementType.BUTTON,
      hierarchy: "primary",
      text: "Close",
      action: ButtonAction.CLOSE,
    });
  }

  return { elements };
};

export const backInStockCaptureStep = (
  captureGoal: CaptureGoalType,
): FormStep => {
  const step: FormStep = {
    elements: [
      {
        id: 1,
        type: MarketingForgeElementType.RICH_TEXT,
        html: '<p class="ql-align-center">Get notified</p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: "bold",
        fontSizePx: 32,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
      {
        id: 2,
        type: MarketingForgeElementType.RICH_TEXT,
        html: '<p class="ql-align-center">We will let you know if this item is restocked.</p>',
        fontFamily: FontFamily.ARIAL,
        fontWeight: "regular",
        fontSizePx: 16,
        textColor: "#000000",
        linkColor: "#0000ff",
      },
    ],
  };

  if (captureGoal === "email" || captureGoal === "both") {
    step.elements.push({
      type: MarketingForgeElementType.INPUT,
      inputType: "email",
      placeholderText: "Enter your email address",
    });
  }

  step.elements.push({
    type: MarketingForgeElementType.BUTTON,
    hierarchy: "primary",
    text: "Submit",
    action: ButtonAction.SUBMIT_AND_ADVANCE,
  });

  return step;
};

export const getExampleSteps = (
  goal: SignUpFormGoal,
  captureGoal: CaptureGoalType,
  includeSurvey: boolean,
  type: LayoutType,
) => {
  const steps: FormStep[] = [];

  switch (goal) {
    case SignUpFormGoal.BACK_IN_STOCK: {
      steps.push(backInStockCaptureStep(captureGoal));
      steps.push(successStep(type === LayoutType.EMBEDDED));
      return steps;
    }
    case SignUpFormGoal.MARKETING_SUBSCRIPTION: {
      if (includeSurvey && type !== LayoutType.EMBEDDED) {
        steps.push(surveyStep);
      }

      const isEmbedded = type === LayoutType.EMBEDDED;
      steps.push(marketingCaptureStep(captureGoal, isEmbedded));
      steps.push(successStep(isEmbedded));
      return steps;
    }
    default:
      assertNever(goal);
  }
};

export const getExampleForm = ({
  name,
  goal,
  captureGoal,
  includeSurvey,
  type,
  includeTeaser,
}: {
  name: string;
  goal: SignUpFormGoal;
  captureGoal: CaptureGoalType;
  includeSurvey: boolean;
  type: LayoutType;
  includeTeaser: boolean;
}): SignupForm => {
  return {
    name,
    goal,
    theme: {
      overrides: {
        buttons: {
          enabled: false,
          primary: {
            ...defaultBrandKit?.buttons.primary,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
          secondary: {
            ...defaultBrandKit?.buttons.secondary,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
          tertiary: {
            ...defaultTertiaryButton,
            font: {
              fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
              fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
              fontFamily: defaultBrandKit?.font.fontFamily,
            },
          },
        },
        inputs: {
          ...defaultBrandKit?.inputs,
          enabled: false,
          font: {
            fontSizePx: defaultBrandKit?.font.hierarchy.body.fontSizePx,
            fontWeight: defaultBrandKit?.font.hierarchy.body.fontWeight,
            fontFamily: defaultBrandKit?.font.fontFamily,
          },
        },
      },
      closeButton: {
        color: "#000000",
        background: { enabled: false, color: "#ffffff" },
      },
      border: { cornerRadiusPx: 0, stroke: { color: "#000000", weightPx: 0 } },
      overlay: { color: "#000000", opacityPercent: 75 },
    },
    layout: {
      type,
      backgroundColor: "#ffffff",
      image: {
        style:
          type === LayoutType.EMBEDDED || goal === SignUpFormGoal.BACK_IN_STOCK
            ? "no"
            : "left",
        url: "",
        hideOnMobile: false,
      },
    },
    discount: { enabled: true, discountId: undefined },
    behavior: {
      appearsOn: "desktop & mobile",
      appearsOnSite: "marketing",
      subscriberTags: [`${name.toLowerCase().split(" ").join("_")}_redo_popup`],
      display: {
        timeOnPage: { enabled: true, seconds: 10 },
        exitIntent: { enabled: false }, // TODO: support this
      },
      audience: "all visitors",
      campaignUtms: [],
      targeting: "all visitors",
      embeddedCode: getEmbeddedCode(),
      urlVisibility: { type: URLVisibilityType.INCLUSIVE, urls: [] },
    },
    teaser: {
      enabled: includeTeaser,
      text: "Get 10% off",
      fontFamily: FontFamily.ARIAL,
      fontWeight: "bold",
      fontSizePx: 16,
      positionHorizontal: "left",
      positionVertical: "bottom",
      backgroundColor: "#000000",
      textColor: "#ffffff",
      cornerRadiusPx: 5,
      borderStroke: { color: "#000000", weightPx: 0 },
      iconVisible: false,
      visibility: "before-displaying",
    },
    pdpButton:
      goal === SignUpFormGoal.BACK_IN_STOCK
        ? {
            hierarchy: "primary",
            text: "Notify me when available",
            action: ButtonAction.SUBMIT_AND_ADVANCE,
            type: MarketingForgeElementType.BUTTON,
          }
        : undefined,
    steps: getExampleSteps(goal, captureGoal, includeSurvey, type),
  };
};

export const getEmbeddedCode = () => {
  const randomFiveLetterString = Math.random().toString(36).substring(2, 7);
  return `<div id="redo-marketing-embedded-form-${randomFiveLetterString}"></div>`;
};
