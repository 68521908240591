import { Status } from "@redotech/http/semantics";
import { ZodError } from "zod";
export class RpcError<Code extends string> extends Error {
  constructor(
    readonly status: Status,

    override readonly message: string,
    readonly code?: Code,
  ) {
    super(message);
  }

  static from(error: unknown): RpcError<string> {
    if (error instanceof RpcError) {
      return error;
    }

    const anyError = error as any;

    /**
     * implicitly, AxiosErrors will have error.response.status, error.response.statusText, and error.response.data.message
     * (didn't want to add axios as a dependency for this library)
     **/
    return new RpcError(
      new Status(
        anyError?.response?.status ?? 500,
        anyError?.response?.statusText ?? "Unknown Error",
      ),
      anyError?.response?.data?.message ??
        anyError?.response?.data?.error ??
        anyError?.message ??
        anyError?.toString() ??
        "(No error message provided)",
    );
  }
}

export class ClientError<Code extends string> extends Error {
  constructor(
    readonly statusCode: number,

    override readonly message: string,
    public readonly code: Code | undefined,
  ) {
    super(message);
  }
}

export function serializeError(error: unknown): string {
  if (error instanceof ZodError) {
    return JSON.stringify(error.format());
  } else if (error instanceof RpcError) {
    return JSON.stringify({ error: error.message, code: error.code });
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return JSON.stringify(error, null, 2);
  }
}
