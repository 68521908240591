// extracted by mini-css-extract-plugin
export var checkbox = "_1m-0";
export var circle = "_1m-3";
export var horizontalContainer = "_1m-7";
export var sm = "_1m-5";
export var switch0 = "_1m-2";
export var switchDisabled = "_1m-8";
export var switchEnabled = "_1m-1";
export var textSwitchContainer = "_1m-9";
export var textSwitchOption = "_1m-a";
export var textSwitchOptionSelected = "_1m-b";
export var verticalContainer = "_1m-6";
export var xs = "_1m-4";