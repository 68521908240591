const suffix =
  "your items for free. If you're not completely satisfied, we've got you covered.";

const fallback = {
  primaryMessage: "Free returns & exchanges",
  secondaryMessage: "Return or exchange",
};
export const returnMessages = {
  "111": {
    primaryMessage: "Free returns & exchanges",
    secondaryMessage: `Return or exchange ${suffix}`,
  },
  "110": {
    primaryMessage: "Free returns for exchanges & refunds",
    secondaryMessage: `Return or exchange ${suffix}`,
  },
  "101": {
    primaryMessage: "Free returns for exchanges & store credit",
    secondaryMessage: `Return or exchange ${suffix}`,
  },
  "011": {
    primaryMessage: "Free returns for refunds & store credit",
    secondaryMessage: `Return ${suffix}`,
  },
  "000": fallback,
  "001": fallback,
  "100": fallback,
  "010": fallback,
};

export const defaultHeaderText = "Checkout with confidence";
export const defaultSubheaderText =
  "Shop with confidence, knowing your purchases are protected every step of the way.";

export const bulletTwoTitleText = "Package protection";
export const bulletTwoSubtitleText =
  "Rest assured, if your package is lost, stolen, or damaged, we've got you covered.";

export const bulletThreeTitleText = "Easy return portal";
export const bulletThreeSubtitleText =
  "Skip all the back and forth, and submit your return in a few clicks.";
