import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { Team } from "@redotech/redo-model/team";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoMultiselectDropdown } from "@redotech/redo-web/arbiter-components/list/redo-multiselect-dropdown";
import { RedoToggle } from "@redotech/redo-web/arbiter-components/toggle/redo-toggle";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { titleCase } from "@redotech/web-util/strings";
import { memo, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";

const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;
type DayOfWeek = (typeof daysOfWeek)[number];

const options: RedoListItem<DayOfWeek>[] = daysOfWeek.map((day) => ({
  id: day,
  type: "text",
  text: titleCase(day),
  value: day,
}));

export const balanceSettingsForm = groupInput({
  minimumBalance: input<number>(),
});

export type BalanceSettingsForm = InputProvider.Form<
  typeof balanceSettingsForm
>;

export type BalanceSettingsValue = InputProvider.Value<
  typeof balanceSettingsForm
>;

export const balanceSettingsDefaultValue: BalanceSettingsValue = {
  minimumBalance: 0,
};

const defaultScheduleDays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

export const BalanceSettings = memo(function BalanceSettings({
  team,
  balanceSettingsInput,
}: {
  team: Team | undefined;
  balanceSettingsInput: BalanceSettingsForm;
}) {
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);
  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [reload, setReload] = useState<symbol>(Symbol());

  const { minimumBalance } = balanceSettingsInput.inputs;

  const [schedule, setSchedule] = useState(defaultScheduleDays);
  const [cronExpression, setCronExpression] = useState<string | null>(null);
  const [useAdvancedSchedule, setUseAdvancedSchedule] =
    useState<boolean>(false);

  const [scheduleLoad, updateSchedule] = useTriggerLoad(async (signal) => {
    if (!team) {
      return;
    }

    await rpcClient.upsertBalanceSchedule(
      { teamId: team._id, schedule: { days: schedule }, cronExpression },
      { signal },
    );
    setSchedule(defaultScheduleDays);
    setCronExpression(null);
    setReload(Symbol());
  });

  const scheduleInfoLoad = useLoad(
    async (signal) => {
      if (!team) {
        return;
      }

      return await rpcClient.getBalanceScheduleInfo(
        { teamId: team._id },
        { signal },
      );
    },
    [rpcClient, team, reload],
  );

  const scheduledDays: DayOfWeek[] = useMemo(() => {
    const daySelection = Object.entries(schedule) as [DayOfWeek, boolean][];
    return daySelection.filter(([, value]) => value).map(([day]) => day);
  }, [schedule]);

  const selectedOptions = useMemo<RedoListItem<DayOfWeek>[]>(() => {
    return scheduledDays.map((day) => ({
      id: day,
      type: "text",
      text: titleCase(day),
      value: day,
    }));
  }, [scheduledDays]);

  return (
    <Card
      title={
        <Flex justify="space-between">
          <Text>Balance settings</Text>
          <RedoToggle
            description="(engineers only)"
            label="Advanced schedule settings"
            setValue={setUseAdvancedSchedule}
            value={useAdvancedSchedule}
          />
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <Flex>
          <Text>Minimum balance</Text>
          <RedoIncrementDecrement
            placeholder="0"
            setValue={(value) => minimumBalance.setValue(value ?? 0)}
            size="small"
            value={minimumBalance.value ?? 0}
          />
        </Flex>

        {!useAdvancedSchedule ? (
          <Flex flexDirection="column" py="2xl" w="full">
            <RedoButton
              hierarchy="secondary"
              onClick={() => setDropdownOpen(!dropdownOpen)}
              ref={setDropdownButtonRef}
              text="Choose days"
            />
            <RedoMultiselectDropdown<DayOfWeek>
              dropdownAnchor={dropdownButtonRef}
              dropdownOpen={dropdownOpen}
              items={options}
              selectedItems={selectedOptions}
              setDropdownOpen={setDropdownOpen}
              setSelectedItems={(options: RedoListItem<DayOfWeek>[]) => {
                setSchedule({
                  sunday: options.some((option) => option.value === "sunday"),
                  monday: options.some((option) => option.value === "monday"),
                  tuesday: options.some((option) => option.value === "tuesday"),
                  wednesday: options.some(
                    (option) => option.value === "wednesday",
                  ),
                  thursday: options.some(
                    (option) => option.value === "thursday",
                  ),
                  friday: options.some((option) => option.value === "friday"),
                  saturday: options.some(
                    (option) => option.value === "saturday",
                  ),
                });
              }}
              size="xs"
            />

            <Flex flexDirection="row" justify="space-between">
              <RedoButton
                disabled={!Object.values(schedule).some(Boolean)}
                hierarchy="primary"
                onClick={() => {
                  updateSchedule();
                }}
                pending={scheduleLoad.pending}
                size="sm"
                text="Update schedule"
                theme="normal"
              />
            </Flex>
          </Flex>
        ) : (
          <Flex flexDirection="column" py="2xl" w="full">
            <RedoTextInput
              description="Plz be careful"
              label="Cron expression"
              setValue={setCronExpression}
              value={cronExpression ?? ""}
            />

            <Flex flexDirection="row" justify="space-between">
              <RedoButton
                disabled={!cronExpression}
                hierarchy="primary"
                onClick={() => {
                  updateSchedule();
                }}
                pending={scheduleLoad.pending}
                size="sm"
                text="Update schedule"
                theme="normal"
              />
            </Flex>
          </Flex>
        )}
        <Flex flexDirection="column" gap="xs">
          <Text>Next run date-times:</Text>
          {scheduleInfoLoad.value?.nextRunDates?.map(
            (date: Date, index: number) =>
              date && (
                <Text fontSize="sm" key={index}>
                  {getDateTimeString(date)}
                </Text>
              ),
          )}
        </Flex>
        <Flex flexDirection="column" gap="xs">
          <Text>Last run date-time:</Text>
          {scheduleInfoLoad.value?.lastRunDate && (
            <Text fontSize="sm">
              {getDateTimeString(scheduleInfoLoad.value?.lastRunDate)}
            </Text>
          )}
        </Flex>
      </Flex>
    </Card>
  );
});
