// extracted by mini-css-extract-plugin
export var border = "_2f-b";
export var circle = "_2f-4";
export var circleInner = "_2f-5";
export var disabled = "_2f-1";
export var focused = "_2f-a";
export var md = "_2f-8";
export var radioButton = "_2f-0";
export var radioInput = "_2f-2";
export var selected = "_2f-9";
export var sm = "_2f-7";
export var text = "_2f-3";
export var xs = "_2f-6";