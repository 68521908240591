import { useHandler } from "@redotech/react-util/hook";
import { getMaskedOutput, InputMask } from "@redotech/react-util/input-mask";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  memo,
  useEffect,
  useState,
} from "react";
import { TextMeasureBox } from "../../common/text-measure-box";
import { SpacingValue } from "../../theme/box";
import { textClasses, TextProps, TextSizeValue } from "../../theme/typography";
import * as redoTextInputCss from "./base-redo-input.module.css";

export const redoInputState = [
  "default",
  "disabled",
  "error",
  "readonly",
  "link",
] as const;
export type RedoInputState = (typeof redoInputState)[number];

export const redoInputSize = ["sm", "md", "lg"] as const;
export type RedoInputSize = (typeof redoInputSize)[number];

export interface BaseRedoInputProps {
  value: string;
  setValue(value: string): void;
  placeholder?: string;
  size?: RedoInputSize;
  state?: RedoInputState;
  inputTypeHint?: HTMLInputTypeAttribute;
  required?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  htmlPattern?: string;
  constrainToInputLength?: boolean;
  name?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  mask?: InputMask;
  htmlId?: string;
  autoCompleteDisabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
  step?: number;
}

export const BaseRedoInput = memo(
  forwardRef(function BaseRedoInput(
    {
      size = "sm",
      value,
      placeholder,
      setValue,
      inputTypeHint,
      state = "default",
      required,
      maxLength,
      min,
      max,
      name,
      constrainToInputLength,
      htmlPattern,
      onFocus,
      onBlur,
      mask,
      htmlId,
      autoCompleteDisabled,
      onKeyDown,
      onClick,
      autoFocus,
      step,
    }: BaseRedoInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    // Needed for readonly styles
    const [textWidth, setTextWidth] = useState<number>(0);
    const [lastMask, setLastMask] = useState<InputMask | undefined>(mask);

    const disabled = state === "disabled";
    const readonly = state === "readonly";

    const handleInputContentChange = useHandler(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const realValue = getMaskedOutput({
          mask,
          newInput: event.target.value,
        });
        setValue(realValue);
      },
    );

    useEffect(() => {
      if (mask !== lastMask) {
        setLastMask(mask);
        setValue(getMaskedOutput({ mask, newInput: value }));
      }
    }, [mask, lastMask, setValue, value]);

    return (
      <>
        <input
          autoComplete={autoCompleteDisabled ? "off" : undefined}
          autoFocus={autoFocus}
          className={classNames(
            textClasses(sizeToInputTextProps[size]),
            redoTextInputCss.input,
          )}
          disabled={disabled || readonly}
          id={htmlId}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name}
          onBlur={onBlur}
          onChange={handleInputContentChange}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          pattern={htmlPattern}
          placeholder={placeholder}
          readOnly={state === "link"}
          ref={ref}
          required={required}
          step={step}
          style={{
            ...(readonly || constrainToInputLength
              ? { width: textWidth + "px" }
              : {}),
            ...(state === "link" ? { cursor: "pointer" } : {}),
          }}
          type={inputTypeHint}
          value={value}
        />
        <TextMeasureBox
          text={value || placeholder || ""}
          {...sizeToInputTextProps[size]}
          setTextWidth={setTextWidth}
          textWidth={textWidth}
        />
      </>
    );
  }),
);

export const sizeToInputTextProps: Record<RedoInputSize, TextProps> = {
  ["sm"]: { fontSize: "xs" },
  ["md"]: { fontSize: "md" },
  ["lg"]: { fontSize: "md" },
};

export const sizeStyles: Record<RedoInputSize, string> = {
  ["sm"]: redoTextInputCss.small,
  ["md"]: redoTextInputCss.medium,
  ["lg"]: redoTextInputCss.large,
};

export const sizeToDescriptorTextProps: Record<RedoInputSize, TextSizeValue> = {
  ["sm"]: "xs",
  ["md"]: "sm",
  ["lg"]: "sm",
};

export const sizeToPx: Record<RedoInputSize, SpacingValue> = {
  ["sm"]: "md",
  ["md"]: "lg",
  ["lg"]: "xl",
};
