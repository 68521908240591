import { Jsonified } from "@redotech/json/json";
import { z } from "zod";
import { BrandKit } from "./brand-kit";
import { CartProductType } from "./cart-product-types";
import { ConciergeProductFormSettings } from "./concierge-product-form/concierge-product-form";
import { Coverage, PaymentModel } from "./coverage";
import type {
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyPdpRenderMethod,
} from "./extended-warranty";
import { SlideInPanelAnchorLocation } from "./slide-in-panel/slide-in-panel-anchor-location";
import { TreatmentDoc } from "./split";
import type {
  Address,
  Automation,
  Icons,
  InfoModalVersion,
  Portal,
  Settings,
} from "./team";
import {
  AttachmentStrategy,
  CheckoutUICustomizationSettings,
  DynamicReturnsPricingItemCount,
  HideRedoConfig,
  InternationalReturnPricing,
  PricingRuleSet,
  StorefrontConfig,
  ToggleFields,
} from "./team";

export enum InsertionPosition {
  AFTER = "after",
  BEFORE = "before",
  PREPEND = "prepend",
  APPEND = "append",
}

export const insertionPositionToDisplayText = {
  [InsertionPosition.AFTER]: "After",
  [InsertionPosition.BEFORE]: "Before",
  [InsertionPosition.PREPEND]: "Prepend",
  [InsertionPosition.APPEND]: "Append",
};

export enum WidgetElementType {
  COVERAGE_CHECKOUT_BUTTON = "coverage-checkout-button",
  NON_COVERAGE_CHECKOUT_BUTTON = "non-coverage-checkout-button",
  BOTH_CHECKOUT_BUTTONS = "both-checkout-buttons",
  INFO_CARD = "info-card",
}

export enum ButtonSettingPerProduct {
  BOTH = "both",
  RETURN = "return",
  PACKAGE_PROTECTION = "packageProtection",
}

export const buttonSettingPerProductToDisplayText: Record<
  ButtonSettingPerProduct,
  string
> = {
  [ButtonSettingPerProduct.BOTH]: "Returns & package protection",
  [ButtonSettingPerProduct.RETURN]: "Returns only",
  [ButtonSettingPerProduct.PACKAGE_PROTECTION]: "Package protection only",
};

export interface TitleSubtitle {
  titleText?: string;
  subtitleText?: string;
}
export interface CoverageText {
  returns?: TitleSubtitle;
  packageProtection?: TitleSubtitle;
  both?: TitleSubtitle;
}

export interface NonCoverageButtonText {
  returns?: string;
  packageProtection?: string;
  both?: string;
}

export interface CoverageButtonStyles {
  color?: string;
  fontColor?: string;
  subtitleFontColor?: string;
  fontSize?: number;
  subtitleFontSize?: number;
  nonCoverageFontColor?: string;
  nonCoverageFontSize?: number;
  includeNonCoverageUnderline?: boolean;
}

export interface InfoCardStyles {
  color?: string;
  titleFontSize?: number;
  subtitleFontSize?: number;
  titleFontColor?: string;
  subtitleFontColor?: string;
}

interface BaseWidgetConfig {
  elementType: WidgetElementType;
  insertionPosition?: InsertionPosition;
  placementSelector: string;
  shadowRootSelector?: string;
  placeInsideAndOutsideShadowRoot?: boolean;
  dataTarget?: string;
}

export interface IndividualCheckoutButtons extends BaseWidgetConfig {
  elementType:
    | WidgetElementType.COVERAGE_CHECKOUT_BUTTON
    | WidgetElementType.NON_COVERAGE_CHECKOUT_BUTTON;
  content: string; // HTML, with template variables
}

export interface BothCheckoutButtons extends BaseWidgetConfig {
  elementType: WidgetElementType.BOTH_CHECKOUT_BUTTONS;
  coverageButtonText?: CoverageText;
  nonCoverageButtonText?: NonCoverageButtonText;
  buttonSettingPerProduct?: ButtonSettingPerProduct;
  coverageButtonStyles?: CoverageButtonStyles;
  includeNonCoverageUnderline?: boolean;
}

export enum ImageElementType {
  NONE = "none",
  CUSTOM_IMAGE = "custom-image",
  SHIELD = "shield",
  GIFT = "gift",
  LOCK = "lock",
}

export enum PriceLocation {
  NONE = "none",
  TITLE = "title",
  RIGHT = "right",
}

export const priceLocationToDisplayText = {
  [PriceLocation.NONE]: "None",
  [PriceLocation.TITLE]: "Title",
  [PriceLocation.RIGHT]: "Right",
};

export interface InfoCard extends BaseWidgetConfig {
  elementType: WidgetElementType.INFO_CARD;
  cardText?: CoverageText;
  showInfoIcon: boolean;
  badgeWord?: string;
  infoCardStyles?: InfoCardStyles;
  imageElement?: ImageElementType;
  priceLocation?: PriceLocation;
  showBackground?: boolean;
}

export function getCardStyleLabel(showBackground: boolean) {
  return showBackground ? "Card with background" : "Inline card";
}

export type WidgetConfig =
  | InfoCard
  | BothCheckoutButtons
  | IndividualCheckoutButtons;

export type TermsAndConditions = {
  blockCheckoutIfNotAccepted: boolean;
  termsCheckboxSelector?: string;
  blockedMessageText?: string;
};

export type ClickStandardCheckoutButton = {
  enabled: boolean;
  checkoutButtonSelector: string;
};

export type InfoModal = {
  cartIconEnabled: boolean;
  checkoutIconEnabled?: boolean | null;
  logo?: string;
  sideImage?: string;
  version?: InfoModalVersion;
  headerText?: string;
  subheaderText?: string;
  line1?: TitleSubtitle;
  line2?: TitleSubtitle;
  line3?: TitleSubtitle;
  customCss?: string;
};
export interface WidgetAutocheck {
  widgetSlug?: string;
  noDebounceButtonRendering?: boolean;
  exchangeBannerPositionBottom?: boolean;
  exchangeBannerInsertBeforeBody?: boolean;
  icon?: Icons;
  iconColor?: string;
  iconBackgroundColor?: string;
  displayIcon: boolean;
  storefrontAccessToken: string;
  multistorefront?: StorefrontConfig[];
  html?: string | null;
  checkboxText?: string | null;
  logo?: string | null;
  toggleTextOptions?: {
    returnToggle?: ToggleFields;
    packageProtectionToggle?: ToggleFields;
    bothProductToggle?: ToggleFields;
  };
  groupNearbyToggleElementsTogether?: boolean;
  checkboxTextAfterRedo?: string | null;
  checkoutSelector?: string;
  exchangeBannerColor?: string;
  exchangeBannerText?: string;
  exchangeBannerFont?: string;
  exchangeBannerFontColor?: string;
  exchangeBannerShowDoneButton: boolean;
  disableRedoForCustomer: boolean;
  coverageExcludeCollections: string[];
  coverageExcludeDiscountCodes: string[];
  coverageExcludeProperties: string[];
  excludeCoverageIfAnyInvalidProduct?: boolean;
  redoAutoCheck: boolean;
  selector: string;
  paidModel: PaymentModel;
  textColor: string;
  coverage: Coverage;
  coverageEnabled: boolean;
  enabled: boolean;
  finalSaleTags: string[];
  hideCheckboxBranding: boolean;
  hideCheckbox: boolean;
  hideCheckboxPrice: boolean;
  countries: string[];
  countriesPackageProtection: string[];
  countriesFinalSaleReturns: string[];
  submitButtonSelector?: string;
  modalTextAdjustments: {
    text1: string;
    title1: string;
    text2: string;
    title2: string;
    text3: string;
    title3: string;
  };
  infoModal?: InfoModal;
  modalLogo?: string;
  shopSiteUseCartDiscounts?: boolean;
  excludedItemProperties?: { name: string; value: string }[];
  cartToggle: {
    enabled: boolean;
    useShadowRootInCart?: boolean;
    cartShadowRootSelector?: string;
    cartToggleSelector?: string;
    cartToggleShadowRootSelector?: string;
    cartClickListenerSelector?: string;
    toggleOnColor: string;
    toggleOffColor: string;
    toggleCircleColor: string;
    cartTogglePlacement?: "before" | "after" | "prepend" | "append";
    cartReplaceItems?: boolean;
  };
  widgetConfigs?: WidgetConfig[];
  hideRedoProduct?: HideRedoConfig;
  checkoutExtensionEnabled: boolean;
  checkboxFontSize?: number;
  toggleFontSize?: number;
  infoIconLink?: string;
  attachmentStrategy: AttachmentStrategy;
  toggleSubtextEnabled: boolean;
  packageProtectionAutoCheck: boolean;
  protectionExcludeTags?: string[];
  usingCartAndCheckoutToggle?: boolean;
  forceDoubleCheckoutToggle?: boolean;
  setCheckoutToggleBasedOnOptInCartAttribute?: boolean;
  packageProtection: {
    coverageEnabled: boolean;
    packageProtectionPlusEnabled: boolean;
    percentage: number;
    splitProducts: boolean;
    pricingRuleSets?: PricingRuleSet[];
    minPrice: number;
    maxPrice: number;
  };
  predictedCountry?: string;
  customPdpCss?: string;
  customToggleCss?: string;
  treatments?: Jsonified<TreatmentDoc>[] | undefined;
  forceIPAddressLocation?: boolean;
  discountCodePartialMatch?: boolean;
  excludePickup: boolean;
  showPDPDeliveryEstimate?: boolean;
  dynamicReturnsPricing?: DynamicReturnsPricingItemCount;
  preProtectedOrdersEnabled?: boolean;
  checkoutUICustomizations?: CheckoutUICustomizationSettings;
  internationalReturnsPricing?: InternationalReturnPricing;
  automation?: Automation;
  currencyExchangeRate?: number;
  offerDiscountOnNextOrder?: boolean;
  offerDiscountOnNextOrderPercentage?: string;
  finalSaleReturns: {
    coverageEnabled: boolean;
    coverageValidTags: string[];
    coverageValidCollections: string[];
    percentage: number;
    pricingRuleSets?: PricingRuleSet[];
    maxPrice: number;
  };
  customerAccounts: {
    enabled: boolean;
    saveForLaterPanel: {
      enabled: boolean;
      numViewedProductsUntilPanelOpen: number | undefined;
      title: string | undefined;
      message: string | undefined;
      anchorLocation: SlideInPanelAnchorLocation | undefined;
    };
  };
  showIcons?: boolean;
  customerWidgetEnabled: boolean;
  reviews: { enabled: boolean };
  pricePerOrder?: string;
  brandKit: BrandKit;
  extendedWarranties?: {
    enabled: boolean;
    cartToggleExperience: ExtendedWarrantyCartToggleExperience;
    pdpRenderMethod: ExtendedWarrantyPdpRenderMethod;
  };
  concierge: {
    enabled: boolean;
    placeConciergeProductForm: boolean;
    conciergeProductFormSettings?: ConciergeProductFormSettings | undefined;
  };
  marketing: { enabled: boolean; subscribeFromSaveForLater: boolean };
  termsAndConditions?: TermsAndConditions;
  clickStandardCheckoutButton?: ClickStandardCheckoutButton;
  addProductOnOptInClick?: boolean;
  conversion?: { treatmentId?: string | undefined };
}

export const defaultTermsAndConditionsBlockedMessage =
  "Please agree to the terms and conditions before checking out";

export const sellingPlanGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  sellingPlans: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      billingPolicy: z.object({
        interval: z.string().optional(),
        intervalCount: z.number().optional(),
        intervalDays: z.number().optional(),
      }),
    }),
  ),
});
export type SellingPlanGroup = z.infer<typeof sellingPlanGroupSchema>;

export interface feeProduct {
  productId: number;
  variants: { variant_id: string }[];
  handle: string;
  type: Exclude<CartProductType, CartProductType.ExtendedWarranty>;
  sellingPlanGroups: SellingPlanGroup[];
}

export interface WidgetFees {
  price: string;
  handle: string;
  enabled: boolean;
  productId: number;
  variantId: number;
  mainType: Exclude<CartProductType, CartProductType.ExtendedWarranty>;
  splitProducts: boolean;
  returnProduct?: feeProduct;
  packageProduct?: feeProduct;
  bothProduct?: feeProduct;
}

export interface GetWidgetResponse {
  widget_slug: string;
  name: string;
  storeUrl: string;
  theme: Portal;
  settings: Settings;
  service_active: boolean;
  credit_value: number | null;
  storefrontAccessToken: string | null;
  headlessAccessToken: string | undefined;
  hasStorefrontAccess: boolean;
  customerEmail: string;
  automation: Automation;
  address?: Address;
  customInStoreAddresses?: Address[];
}

export const defaultInfoCardWidget: InfoCard = {
  elementType: WidgetElementType.INFO_CARD,
  cardText: undefined,
  showInfoIcon: true,
  placementSelector: "",
};

export const defaultBothCheckoutButtonsWidget: BothCheckoutButtons = {
  elementType: WidgetElementType.BOTH_CHECKOUT_BUTTONS,
  placementSelector: "",
  coverageButtonText: undefined,
  nonCoverageButtonText: undefined,
  coverageButtonStyles: undefined,
};

export const defaultWidgets: WidgetConfig[] = [
  defaultInfoCardWidget,
  defaultBothCheckoutButtonsWidget,
];
