import { baseSplitZodSchema } from "@redotech/redo-model/split";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updateSplit = {
  input: z.object({
    splitId: zExt.objectId(),
    name: z.string().optional(),
    treatments: z.array(zExt.objectId()).optional(),
  }),
  output: z.object({ split: baseSplitZodSchema }),
};
