import { sinkValue, Tuple } from "@redotech/util/type";
import { FontWeight } from "../brand-kit";
import { FontFamily } from "../email-builder";

export enum ReviewForgeElementType {
  RICH_TEXT = "rich-text",
  SHORT_ANSWER = "short-answer",
  PARAGRAPH = "paragraph",
  LOGO = "logo",
  PRODUCT_DETAILS = "product-details",
  STARS = "stars",
  MEDIA_UPLOAD = "media-upload",
  SINGLE_SELECT = "single-select",
  DISCOUNT = "discount",
  // UPSELL = "upsell",
  // START_ANOTHER_REVIEW = "start-another-review",
}

export const StaticElementType = [
  ReviewForgeElementType.RICH_TEXT,
  ReviewForgeElementType.LOGO,
  ReviewForgeElementType.PRODUCT_DETAILS,
] as const;
export type StaticElementType = (typeof StaticElementType)[number];

export const SubmittableElementType = [
  ReviewForgeElementType.SHORT_ANSWER,
  ReviewForgeElementType.PARAGRAPH,
  ReviewForgeElementType.STARS,
  ReviewForgeElementType.MEDIA_UPLOAD,
  ReviewForgeElementType.SINGLE_SELECT,
] as const;
export type SubmittableElementType = (typeof SubmittableElementType)[number];

export const ThankYouUpsellElementType = [
  ...StaticElementType,
  ReviewForgeElementType.DISCOUNT,
  // ReviewForgeElementType.UPSELL,
  // ReviewForgeElementType.START_ANOTHER_REVIEW,
] as const;
export type ThankYouUpsellElementType =
  (typeof ThankYouUpsellElementType)[number];

export type ReviewForgeElement<T extends ReviewForgeElementType> = { type: T };

export type ReviewForgeSubmittableElement<T extends SubmittableElementType> =
  ReviewForgeElement<T> & { tag: ReviewForgeSemanticTag<T>; required: boolean };

export function elementIsSubmittableElement(
  element: ReviewForgeElement<any>,
): element is ReviewForgeSubmittableElement<any> {
  return SubmittableElementType.includes(element.type);
}

export interface RichTextElement
  extends ReviewForgeElement<ReviewForgeElementType.RICH_TEXT> {
  id: number;
  html: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  textColor: string;
  linkColor: string;
}
export interface ProductDetailsElement
  extends ReviewForgeElement<ReviewForgeElementType.PRODUCT_DETAILS> {
  imageWidthPercentage: number;
}

export interface LogoElement
  extends ReviewForgeElement<ReviewForgeElementType.LOGO> {
  widthPercentage: number;
}

export type ThankYouPageElement<T extends ThankYouUpsellElementType> =
  ReviewForgeElement<T>;

export interface DiscountElement
  extends ReviewForgeElement<ReviewForgeElementType.DISCOUNT> {}

export interface ShortAnswerElement
  extends ReviewForgeSubmittableElement<ReviewForgeElementType.SHORT_ANSWER> {
  title: string;
  placeholderText: string;
}

export interface StarsElement
  extends ReviewForgeSubmittableElement<ReviewForgeElementType.STARS> {
  title: string;
}

export interface MediaUploadElement
  extends ReviewForgeSubmittableElement<ReviewForgeElementType.MEDIA_UPLOAD> {
  widthPercentage: number;
}

export interface SingleSelectElement<N extends number>
  extends ReviewForgeSubmittableElement<ReviewForgeElementType.SINGLE_SELECT> {
  tag: BaseSingleSelectSemanticTag<N>;
  options: Tuple<string, N>;
}

export interface ParagraphElement
  extends ReviewForgeSubmittableElement<ReviewForgeElementType.PARAGRAPH> {
  title: string;
  placeholderText: string;
}

export type SubmittableElementData<T extends SubmittableElementType> =
  ReviewForgeElement<T>;

export interface ShortAnswerData
  extends SubmittableElementData<ReviewForgeElementType.SHORT_ANSWER> {
  answer: string;
}

export interface StarsData
  extends SubmittableElementData<ReviewForgeElementType.STARS> {
  rating: number;
}

export interface MediaUploadData
  extends ReviewForgeElement<ReviewForgeElementType.MEDIA_UPLOAD> {
  url: string;
}

export interface SingleSelectData
  extends SubmittableElementData<ReviewForgeElementType.SINGLE_SELECT> {
  selectedOption: string;
}

export interface ParagraphData
  extends SubmittableElementData<ReviewForgeElementType.PARAGRAPH> {
  value: string;
}

export interface ReviewForgeSemanticTag<T extends ReviewForgeElementType> {
  tagId: string;
  name: string;
  type: T;
}

interface BaseSingleSelectSemanticTag<N extends number>
  extends ReviewForgeSemanticTag<ReviewForgeElementType.SINGLE_SELECT> {
  metadata: { options: Tuple<string, N> };
}

interface BaseStarsSemanticTag
  extends ReviewForgeSemanticTag<ReviewForgeElementType.STARS> {}
interface BaseShortAnswerSemanticTag
  extends ReviewForgeSemanticTag<ReviewForgeElementType.SHORT_ANSWER> {}
interface BaseParagraphSemanticTag
  extends ReviewForgeSemanticTag<ReviewForgeElementType.PARAGRAPH> {}
interface BaseMediaUploadSemanticTag
  extends ReviewForgeSemanticTag<ReviewForgeElementType.MEDIA_UPLOAD> {}

type ReviewSemanticTag =
  | BaseSingleSelectSemanticTag<number>
  | BaseStarsSemanticTag
  | BaseShortAnswerSemanticTag
  | BaseParagraphSemanticTag
  | BaseMediaUploadSemanticTag;

const temp: Partial<ReviewSemanticTag> = {};
sinkValue(temp);

export enum SystemSemanticTagId {
  REVIEW_RATING = "review-rating",
  REVIEW_TITLE = "review-title",
  REVIEW_DESCRIPTION = "review-description",
  REVIEW_MEDIA = "review-media",
  REVIEW_RECOMMENDED = "review-recommended",
}

export const ReviewRatingSemanticTag: BaseStarsSemanticTag = {
  tagId: SystemSemanticTagId.REVIEW_RATING,
  name: "Review Rating",
  type: ReviewForgeElementType.STARS,
};

export const ReviewTitleSemanticTag: BaseShortAnswerSemanticTag = {
  tagId: SystemSemanticTagId.REVIEW_TITLE,
  name: "Review Title",
  type: ReviewForgeElementType.SHORT_ANSWER,
};

export const ReviewDescriptionSemanticTag: BaseParagraphSemanticTag = {
  tagId: SystemSemanticTagId.REVIEW_DESCRIPTION,
  name: "Review Description",
  type: ReviewForgeElementType.PARAGRAPH,
};

export const ReviewMediaSemanticTag: BaseMediaUploadSemanticTag = {
  tagId: SystemSemanticTagId.REVIEW_MEDIA,
  name: "Review Media",
  type: ReviewForgeElementType.MEDIA_UPLOAD,
};

export const ReviewRecommendedSemanticTag: BaseSingleSelectSemanticTag<2> = {
  tagId: SystemSemanticTagId.REVIEW_RECOMMENDED,
  name: "Review Recommended",
  type: ReviewForgeElementType.SINGLE_SELECT,
  metadata: { options: ["Yes", "No"] },
};

export const SystemReviewSemanticTags = [
  ReviewRatingSemanticTag,
  ReviewTitleSemanticTag,
  ReviewDescriptionSemanticTag,
  ReviewMediaSemanticTag,
  ReviewRecommendedSemanticTag,
] as const;
