import { ButtonHierarchy, FontWeight } from "../brand-kit";
import { FontFamily } from "../email-builder";
import { ButtonAction, InputType } from "../signup-form";

export enum MarketingForgeElementType {
  RICH_TEXT = "rich-text",
  BUTTON = "button",
  INPUT = "input",
  IMAGE = "image",
}

export type BaseElement<T extends MarketingForgeElementType> = { type: T };

export interface RichTextElement
  extends BaseElement<MarketingForgeElementType.RICH_TEXT> {
  id: number;
  html: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  textColor: string;
  linkColor: string;
}

export interface ButtonElement
  extends BaseElement<MarketingForgeElementType.BUTTON> {
  hierarchy: ButtonHierarchy;
  text: string;
  action: ButtonAction;
}

export interface InputElement
  extends BaseElement<MarketingForgeElementType.INPUT> {
  inputType: InputType;
  placeholderText: string;
}

export interface ImageElement
  extends BaseElement<MarketingForgeElementType.IMAGE> {
  url: string;
}
