import { Currency } from "@redotech/money/currencies";
import { CheckoutExperience } from "../integration/redo-in-shipping/ris";
import {
  FixedRate,
  FixedShippingRateTable,
  RateTableType,
} from "../integration/redo-in-shipping/shipping-rate-table";

export enum UnableToStartCheckoutAbTestReason {
  NO_ACTIVE_CHECKOUT_EXISTS = "NO_ACTIVE_CHECKOUT_EXISTS",
  ALREADY_RUNNING_CHECKOUT_AB_TEST = "ALREADY_RUNNING_CHECKOUT_AB_TEST",
}

export enum CheckoutAbTestMetric {
  CHECKOUT_CONVERSION_RATE = "checkoutConversionRate",
  AVERAGE_SHIPPING_REVENUE = "averageShippingRevenue",
  ORDERS_PLACED = "ordersPlaced",
  AVERAGE_ORDER_VALUE = "averageOrderValue",
}

export enum UnableToStartHalfRedoCheckoutAbTestReason {
  ACTIVE_CHECKOUT_EXISTS = "ACTIVE_CHECKOUT_EXISTS",
  ALREADY_RUNNING_CHECKOUT_AB_TEST = "ALREADY_RUNNING_CHECKOUT_AB_TEST",
  CHECKOUT_OUTBOUND_RATES_NOT_ENABLED = "CHECKOUT_OUTBOUND_RATES_NOT_ENABLED",
  NO_CHECKOUT_EXPERIENCES_CREATED = "NO_CHECKOUT_EXPERIENCES_CREATED",
}

// This is a fixed rate that is used to indicate to the Delivery Customization
// function that we are running a half-redo checkout ab test. Seeing this shipping
// method means that the rates endpoint has selected the side of the A/B test that
// shows the pre-existing shipping methods (non-Redo) to the customer.
const FIXED_RATE_SPECIAL_AB_TEST_INDICATOR: FixedRate = {
  enabled: true,
  conditions: [],
  currency: Currency.USD,
  price: "100",
};

export const FIXED_TROJAN_HORSE_RATE_TABLE: FixedShippingRateTable = {
  _id: "67be72ec8c6215b5636861d5",
  type: RateTableType.FIXED,
  rates: [FIXED_RATE_SPECIAL_AB_TEST_INDICATOR],
  description: "Fixed Redo shipping method",
  // This always needs to align with INDICATOR_CODE in redo/shopify-app/delivery-customization/src/run.rs
  code: "redo-ab-test-indicator",
  name: "redo-ab-test-indicator",
  originLocations: [],
  destinationLocations: [],
};

export const SPECIAL_EXISTING_SHIPPING_METHODS_CHECKOUT_EXPERIENCE: CheckoutExperience =
  {
    _id: "67be6dbae6312824cb37c78f",
    name: "Pre-existing shipping methods experience",
    rateTables: [FIXED_TROJAN_HORSE_RATE_TABLE],
  };

export function isSpecialExistingShippingMethodsCheckoutExperience(
  checkoutExperience: CheckoutExperience,
): boolean {
  return (
    checkoutExperience._id ===
    SPECIAL_EXISTING_SHIPPING_METHODS_CHECKOUT_EXPERIENCE._id
  );
}
