import { useRequiredContext } from "@redotech/react-util/context";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { ExpandedWarrantyTreatment } from "@redotech/redo-model/split";
import {
  EXPANDED_WARRANTY_TREATMENT_ID_QUERY_PARAM,
  EXPANDED_WARRANTY_TREATMENT_KEY,
} from "@redotech/redo-web/utils/shared-conf";
import { useContext, useEffect } from "react";
import { RedoShopifyRpcClientContext } from "../../client/redo-shopify-rpc-client";
import { ExtensionShopifyClientContext } from "../../shopify";
import { getExpandedWarrantyTreatment } from "../../utils/treatment-client";

// TODO: This is identical to useConciergeTreatment. We should refactor both to use a shared hook.
export function useExpandedWarrantyTreatment({
  currentUrl,
}: {
  currentUrl: URL;
}): LoadState<ExpandedWarrantyTreatment | undefined> {
  const extensionShopifyClient = useContext(ExtensionShopifyClientContext);
  const rpcClient = useRequiredContext(RedoShopifyRpcClientContext);

  const expandedWarrantyTreatmentLoad = useLoad(async (signal) => {
    const urlParams = new URLSearchParams(currentUrl.search);
    const urlExpandedWarrantyTreatmentId = urlParams.get(
      EXPANDED_WARRANTY_TREATMENT_ID_QUERY_PARAM,
    );

    const expandedWarrantyTreatment = await getExpandedWarrantyTreatment(
      rpcClient,
      {
        desiredTreatmentId: urlExpandedWarrantyTreatmentId
          ? urlExpandedWarrantyTreatmentId
          : undefined,
      },
      { signal },
    );
    return expandedWarrantyTreatment;
  }, []);

  const cartLoad = useLoad(async () => {
    if (!extensionShopifyClient) {
      return;
    }

    return extensionShopifyClient.cartGet();
  }, [extensionShopifyClient]);

  useEffect(() => {
    const syncExpandedWarrantyTreatmentCartAttribute = async () => {
      if (!cartLoad.value || !extensionShopifyClient) {
        return;
      }

      const cart = cartLoad.value;
      if (expandedWarrantyTreatmentLoad.value) {
        const expandedWarrantyTreatmentId =
          expandedWarrantyTreatmentLoad.value._id;
        if (
          !cart.attributes ||
          cart.attributes[EXPANDED_WARRANTY_TREATMENT_KEY] !==
            expandedWarrantyTreatmentId
        ) {
          await extensionShopifyClient.updateCartAttribute({
            attributes: [
              {
                key: EXPANDED_WARRANTY_TREATMENT_KEY,
                value: expandedWarrantyTreatmentId,
              },
            ],
          });
        }
      }
    };

    void syncExpandedWarrantyTreatmentCartAttribute();
  }, [
    expandedWarrantyTreatmentLoad.value,
    cartLoad.value,
    extensionShopifyClient,
  ]);

  return expandedWarrantyTreatmentLoad;
}
