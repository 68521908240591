import { ValidatedConciergeTreatment } from "@redotech/redo-model/ab-tests/concierge";
import { ExpandedWarrantyTreatment } from "@redotech/redo-model/split";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import {
  CONCIERGE_TREATMENT_KEY,
  EXPANDED_WARRANTY_TREATMENT_KEY,
} from "@redotech/redo-web/utils/shared-conf";
import type { RedoShopifyRpcClient } from "@redotech/shopify-sdk/rpc/client";

export async function getConciergeTreatment(
  rpcClient: RedoShopifyRpcClient,
  { desiredTreatmentId }: { desiredTreatmentId?: string },
): Promise<ValidatedConciergeTreatment | undefined> {
  const cachedTreatment = getCachedConciergeTreatment();
  if (
    cachedTreatment &&
    (!desiredTreatmentId || cachedTreatment.id === desiredTreatmentId)
  ) {
    return cachedTreatment;
  }

  try {
    const result = await rpcClient.getConciergeTreatment({
      desiredTreatmentId,
    });
    if (result.conciergeTreatment) {
      setCachedConciergeTreatment(result.conciergeTreatment);
    }

    return result.conciergeTreatment;
  } catch (error) {
    console.error("Error fetching concierge treatment:", error);
    return undefined;
  }
}

function getCachedConciergeTreatment(): ValidatedConciergeTreatment | null {
  return getLocalStorageWithExpiry(CONCIERGE_TREATMENT_KEY);
}

function setCachedConciergeTreatment(treatment: ValidatedConciergeTreatment) {
  setLocalStorageWithExpiry(CONCIERGE_TREATMENT_KEY, treatment, 24);
}

export async function getExpandedWarrantyTreatment(
  rpcClient: RedoShopifyRpcClient,
  { desiredTreatmentId }: { desiredTreatmentId?: string },
  { signal }: { signal?: AbortSignal },
): Promise<ExpandedWarrantyTreatment | undefined> {
  const cachedTreatment = getCachedExpandedWarrantyTreatment();

  if (
    cachedTreatment &&
    (!desiredTreatmentId || cachedTreatment._id === desiredTreatmentId)
  ) {
    return cachedTreatment;
  }

  try {
    const result = await rpcClient.getExpandedWarrantyTreatment(
      { desiredTreatmentId },
      { signal },
    );
    if (result.expandedWarrantyTreatment) {
      // Check to see if the cached treatment exists now after the RPC call
      const cachedTreatment = getCachedExpandedWarrantyTreatment();
      if (
        cachedTreatment &&
        (cachedTreatment._id === desiredTreatmentId || !desiredTreatmentId)
      ) {
        return cachedTreatment;
      }
      setCachedExpandedWarrantyTreatment(result.expandedWarrantyTreatment);
    }

    return result.expandedWarrantyTreatment;
  } catch (error) {
    console.error("Error fetching expanded warranty treatment:", error);
    return undefined;
  }
}

function getCachedExpandedWarrantyTreatment(): ExpandedWarrantyTreatment | null {
  return getLocalStorageWithExpiry(EXPANDED_WARRANTY_TREATMENT_KEY);
}

function setCachedExpandedWarrantyTreatment(
  treatment: ExpandedWarrantyTreatment,
) {
  setLocalStorageWithExpiry(EXPANDED_WARRANTY_TREATMENT_KEY, treatment, 24);
}
