import {
  ExtendedWarrantyDataTarget,
  ExtendedWarrantyPdpNoCoverageType,
  ExtendedWarrantyTilesSize,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import htmlReactParser from "html-react-parser";
import { memo } from "react";

export const OfferingTiles = memo(function OfferingTiles({
  offerings,
  selectedOffering,
  onTileClick,
  excludeNoCoverage = false,
  tilesSize,
  view,
}: {
  offerings: Offering[];
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  excludeNoCoverage?: boolean;
  tilesSize: ExtendedWarrantyTilesSize;
  view: {
    noCoverageLabel: string;
    pdpLabel: string;
    pdpNoCoverageType: ExtendedWarrantyPdpNoCoverageType;
    pdpNoCoveragePrice: string;
  };
}) {
  const offeringsToRender = excludeNoCoverage
    ? offerings.filter((offering) => offering.id !== NO_COVERAGE_TIME_ID)
    : offerings;

  let offeringsContainerClassName = warrantyStyles.regular;
  switch (tilesSize) {
    case ExtendedWarrantyTilesSize.REGULAR:
      offeringsContainerClassName = warrantyStyles.regular;
      break;
    case ExtendedWarrantyTilesSize.COMPACT:
      offeringsContainerClassName = warrantyStyles.compact;
  }

  if (!view) return null;

  return (
    // TODO: Rebuild components to use arbiter
    <div
      className={classNames(
        warrantyStyles.offeringsContainer,
        offeringsContainerClassName,
      )}
      data-target={ExtendedWarrantyDataTarget.OFFERING_TILES_CONTAINER}
    >
      {offeringsToRender.map((offering) => {
        switch (tilesSize) {
          case ExtendedWarrantyTilesSize.REGULAR:
            return (
              <RegularOfferingTile
                key={offering.id}
                offering={offering}
                onTileClick={onTileClick}
                selectedOffering={selectedOffering}
                view={view}
              />
            );
          case ExtendedWarrantyTilesSize.COMPACT:
            return (
              <CompactOfferingTile
                key={offering.id}
                offering={offering}
                onTileClick={onTileClick}
                selectedOffering={selectedOffering}
                view={view}
              />
            );
          default:
            assertNever(tilesSize);
        }
      })}
    </div>
  );
});

const RegularOfferingTile = memo(function RegularOfferingTile({
  offering,
  selectedOffering,
  onTileClick,
  view,
}: {
  offering: Offering;
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  view: {
    noCoverageLabel: string;
    pdpLabel: string;
    pdpNoCoverageType: ExtendedWarrantyPdpNoCoverageType;
    pdpNoCoveragePrice: string;
  };
}) {
  const { noCoverageLabel, pdpLabel, pdpNoCoverageType, pdpNoCoveragePrice } =
    view;

  const isActive = offering.title === selectedOffering?.title;

  return (
    <div
      className={classNames(
        warrantyStyles.offeringOption,
        isActive
          ? warrantyStyles.selectedOption
          : warrantyStyles.unselectedOption,
        warrantyStyles.regular,
      )}
      data-state={isActive ? "active" : "inactive"}
      data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_CONTAINER}
      key={offering.title}
      onClick={() => onTileClick(offering)}
    >
      <div>
        <div
          className={warrantyStyles.offeringTitle}
          data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_TITLE}
        >
          {offering.id === NO_COVERAGE_TIME_ID
            ? noCoverageLabel
            : offering.title}
        </div>
        {(!!offering.price?.amount ||
          pdpNoCoverageType === ExtendedWarrantyPdpNoCoverageType.DETAILED) && (
          <div
            className={warrantyStyles.offeringSubtitle}
            data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_SUBTITLE}
          >
            {htmlReactParser(pdpLabel)}
          </div>
        )}
      </div>
      <div
        className={warrantyStyles.offeringPrice}
        data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_PRICE}
      >
        {offering.price?.amount
          ? CURRENCY_FORMAT(offering.price.currency).format(
              offering.price.amount,
            )
          : pdpNoCoverageType === ExtendedWarrantyPdpNoCoverageType.DETAILED &&
            htmlReactParser(pdpNoCoveragePrice)}
      </div>
    </div>
  );
});

const CompactOfferingTile = memo(function CompactOfferingTile({
  offering,
  selectedOffering,
  onTileClick,
  view,
}: {
  offering: Offering;
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  view: { noCoverageLabel: string };
}) {
  const { noCoverageLabel } = view;

  const isActive = offering.title === selectedOffering?.title;

  return (
    <div
      className={classNames(
        warrantyStyles.offeringOption,
        isActive
          ? warrantyStyles.selectedOption
          : warrantyStyles.unselectedOption,
        warrantyStyles.compact,
      )}
      data-state={isActive ? "active" : "inactive"}
      data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_CONTAINER}
      key={offering.title}
      onClick={() => onTileClick(offering)}
    >
      <div
        className={warrantyStyles.offeringTitle}
        data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_TITLE}
      >
        {offering.id === NO_COVERAGE_TIME_ID
          ? noCoverageLabel
          : `${offering.title} Protection`}
      </div>
      <div
        className={warrantyStyles.offeringSubtitle}
        data-target={ExtendedWarrantyDataTarget.OFFERING_TILE_SUBTITLE}
      >
        {!!offering.price?.amount &&
          CURRENCY_FORMAT(offering.price.currency).format(
            offering.price.amount,
          )}
      </div>
    </div>
  );
});
