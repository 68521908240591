import { Input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, ReactNode } from "react";
import {
  LabeledInput,
  LabelOrientation,
  LabelPosition,
  LabelSize,
} from "./labeled-input";
import * as switchCss from "./switch.module.css";
import { Text } from "./text";
import { Tooltip } from "./tooltip/tooltip";

export interface SwitchProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  disabledTooltip?: string;
  position?: LabelPosition;
  size?: SwitchSize;
}

export enum SwitchSize {
  EXTRA_SMALL = "EXTRA_SMALL",
  SMALL = "SMALL",
}

/**
 * @deprecated use RedoToggle instead
 */
export function Switch({
  value,
  onChange,
  disabled = false,
  disabledTooltip,
  position,
  size = SwitchSize.SMALL,
}: SwitchProps) {
  return (
    <label
      className={
        position === LabelPosition.ABOVE
          ? switchCss.verticalContainer
          : switchCss.horizontalContainer
      }
    >
      <input
        checked={value}
        className={switchCss.checkbox}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        type="checkbox"
      />
      <Tooltip title={disabledTooltip}>
        <div
          className={classNames(
            switchCss.switch0,
            switchSizeToClass[size],
            disabled ? switchCss.switchDisabled : switchCss.switchEnabled,
          )}
        >
          <div
            className={classNames(switchCss.circle, switchSizeToClass[size])}
          />
        </div>
      </Tooltip>
    </label>
  );
}

/**
 * @deprecated use RedoToggle instead
 */
export const TextSwitch = memo(function TextSwitch({
  value,
  onChange,
  disabled = false,
  labels,
  className,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  labels: [string, string];
  className?: string;
}) {
  return (
    <div className={classNames(switchCss.textSwitchContainer, className)}>
      <div
        className={classNames(
          switchCss.textSwitchOption,
          value ? switchCss.textSwitchOptionSelected : null,
        )}
        onClick={() => onChange(true)}
      >
        <Text fontSize="sm" fontWeight="medium" textColor="primary">
          {labels[0]}
        </Text>
      </div>
      <div
        className={classNames(
          switchCss.textSwitchOption,
          !value ? switchCss.textSwitchOptionSelected : null,
        )}
        onClick={() => onChange(false)}
      >
        <Text fontSize="sm" fontWeight="medium" textColor="primary">
          {labels[1]}
        </Text>
      </div>
    </div>
  );
});

/**
 * @deprecated use RedoToggle instead
 */
export function FormSwitch({
  input,
  label,
  children,
  position = LabelPosition.ABOVE,
  orientation = LabelOrientation.HORIZONTAL,
  disabled = false,
  disabledTooltip,
  size,
  onChange,
}: {
  input: Input<boolean>;
  label: ReactNode;
  children?: ReactNode | ReactNode[];
  position?: LabelPosition;
  orientation?: LabelOrientation;
  disabled?: boolean;
  disabledTooltip?: string;
  onChange?: (value: boolean) => void; //Called after setting input
} & Omit<SwitchProps, "value" | "onChange">) {
  return (
    <LabeledInput
      description={children}
      errors={input.errors}
      label={label}
      orientation={orientation}
      position={position}
      size={size ? switchSizeToLabelSize[size] : undefined}
    >
      <Switch
        disabled={disabled}
        disabledTooltip={disabledTooltip}
        onChange={(value) => {
          input.setValue(value);
          onChange?.(value);
        }}
        position={position}
        size={size}
        value={input.value}
      />
    </LabeledInput>
  );
}

const switchSizeToClass: Record<SwitchSize, string> = {
  [SwitchSize.EXTRA_SMALL]: switchCss.xs,
  [SwitchSize.SMALL]: switchCss.sm,
};

const switchSizeToLabelSize: Record<SwitchSize, LabelSize> = {
  [SwitchSize.EXTRA_SMALL]: LabelSize.EXTRA_SMALL,
  [SwitchSize.SMALL]: LabelSize.SMALL,
};
