import { useHtmlEventListener } from "@redotech/react-util/event";
import { useQueue } from "@redotech/react-util/queue";
import * as classNames from "classnames";
import { memo, ReactNode, useEffect, useState } from "react";
import { RedoFeaturedIconProps } from "./arbiter-components/featured-icon/redo-featured-icon";
import {
  NotificationBreakpoint,
  NotificationButton,
  RedoNotification,
} from "./arbiter-components/notification/redo-notification";
import { Flex } from "./flex";
import * as notificationProviderCss from "./notification-provider.module.css";

export interface NotificationInstance {
  title: string;
  description: string;
  hideCloseX?: boolean;
  primaryButton?: NotificationButton;
  dismissButton?: Omit<NotificationButton, "onClick">;
  onDismiss?: () => void;
  featuredIcon?: Omit<RedoFeaturedIconProps, "size">;
  fullModalImage?: string;
  breakpoint?: NotificationBreakpoint;
  children?: ReactNode;
}

export let enqueueNotification: (
  instance: NotificationInstance,
) => void = () => {
  console.log("baer");
};

export let popNotification: () => void = () => {
  console.log("pop");
};

export const NotificationProvider = memo(function NotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { front, push, pop } = useQueue<NotificationInstance>();

  const [cardRef, setCardRef] = useState<HTMLDivElement | null>(null);

  const [exit, setExit] = useState(false);

  useHtmlEventListener(cardRef, "animationend", (event) => {
    if (exit) {
      pop();
      setExit(false);
    }
  });

  useEffect(() => {
    enqueueNotification = (instance) => push(instance);
    popNotification = () => pop();
  }, [push, pop]);

  return (
    <>
      {front && (
        <Flex
          className={classNames(
            exit && notificationProviderCss.exit,
            notificationProviderCss.notificationContainer,
          )}
          ref={setCardRef}
        >
          <RedoNotification
            {...front}
            dismiss={() => {
              front?.onDismiss?.();
              setExit(true);
            }}
          />
        </Flex>
      )}
      {children}
    </>
  );
});
