import { useInput } from "@redotech/react-util/form";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { FormTextInput, InputTheme } from "@redotech/redo-web/text-input";
import { input } from "@redotech/ui/form";
import { ClipboardEvent as ReactClipboardEvent, memo, useEffect } from "react";
import { RateCardForm } from "./rate-table-utils";
import * as styles from "./rate-table.module.css";

// We use this to represent the top left cell in the table. We never want it to have a value, but we want it to be there so that we can use it to copy/paste
const stringInput = input<string>();

export const LabelRateTableForm = memo(function LabelRateTableForm({
  input,
  deleteRow,
  deleteColumn,
  yAxisLabel,
  xAxisLabel,
  readonly = false,
}: {
  input: RateCardForm;
  deleteRow: (index: number) => void;
  deleteColumn: (index: number) => void;
  yAxisLabel: string;
  xAxisLabel: string;
  readonly?: boolean;
}) {
  const overridePaste = (
    event: ReactClipboardEvent,
    columnIndex: number,
    rowIndex: number,
    skipCell?: boolean,
  ) => {
    event.preventDefault();
    const data = event.clipboardData.getData("text");
    const grid = data.split("\n").map((row) => row.split("\t"));

    // Strip out anything that isn't a number, period, or negative sign
    // Mostly targeting dollar signs and currency codes
    const sanitized = grid.map((row) =>
      row.map((value) => value.replace(/[^\d.-]+/g, "")),
    );

    const tableData = [...input.value.data];

    for (let i = 0; i < sanitized.length; i++) {
      for (let j = 0; j < sanitized[i].length; j++) {
        // Don't paste into the top left cell - we don't ever want that to have visible data
        if (skipCell && j === 0 && i === 0) {
          continue;
        }
        const existingCell = tableData.at(rowIndex + i)?.at(columnIndex + j);
        if (existingCell) {
          tableData[rowIndex + i][columnIndex + j] = sanitized[i][j];
        }
      }
    }

    input.setValue({ data: tableData, defaultValue: input.value.defaultValue });
  };

  // We use this to represent the top left cell in the table. We never want it to have a value, but we want it to be there so that we can use it to copy/paste
  const upperLeftCellInput = useInput(stringInput, "");

  // We don't want to disable the fake input because that would prevent us from pasting into the table from there
  // So instead if someone changes it we just reset it to an empty string
  useEffect(() => {
    upperLeftCellInput.setValue("");
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upperLeftCellInput.value.length]);

  return (
    <Flex dir="column" w="full">
      <form>
        <fieldset disabled={readonly}>
          <table className={styles.rateTable}>
            <thead>
              <tr>
                <th />
                <th>{yAxisLabel}</th>
                {input.inputs.data.inputs.at(0)?.inputs.map(
                  (_, index) =>
                    index > 0 && (
                      <th key={index}>
                        <RedoButton
                          IconLeading={TrashIcon}
                          onClick={() => deleteColumn(index)}
                          size="sm"
                          text={xAxisLabel}
                          theme="normal"
                        />
                      </th>
                    ),
                )}
              </tr>
            </thead>
            <tbody>
              {input.inputs.data.inputs.map((rowInput, rowIndex) => (
                <tr key={rowIndex}>
                  {rowIndex > 0 ? (
                    <td>
                      <RedoButton
                        IconLeading={TrashIcon}
                        onClick={() => deleteRow(rowIndex)}
                        size="sm"
                        text=""
                        theme="normal"
                      />
                    </td>
                  ) : (
                    <td />
                  )}
                  {rowInput.inputs.map((cellInput, columnIndex) => (
                    <td key={`${rowIndex}-${columnIndex}`}>
                      {rowIndex > 0 || columnIndex > 0 ? (
                        <FormTextInput
                          input={cellInput}
                          label=""
                          labeledInputClassName={styles.rateTableInput}
                          // min={0} // temporarily allow negative numbers to make up for invalid USPS rates
                          onPaste={(event: ReactClipboardEvent) => {
                            overridePaste(event, columnIndex, rowIndex);
                          }}
                          step="any"
                          theme={InputTheme.TABLE}
                          type="number"
                        />
                      ) : (
                        <FormTextInput
                          input={upperLeftCellInput}
                          label=""
                          labeledInputClassName={styles.rateTableInput}
                          onPaste={(event: ReactClipboardEvent) => {
                            overridePaste(event, columnIndex, rowIndex, true);
                          }}
                          theme={InputTheme.TABLE}
                          type="text"
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </fieldset>
      </form>
    </Flex>
  );
});
