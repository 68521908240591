import * as classNames from "classnames";
import { JSXElementConstructor, memo } from "react";
import * as featuredIconColorCSS from "./redo-featured-icon-colors.module.css";
import * as featuredIconCSS from "./redo-featured-icon.module.css";

export const featuredIconSize = ["xxs", "xs", "sm", "md", "lg", "xl"] as const;
export type FeaturedIconSize = (typeof featuredIconSize)[number];

export const featuredIconType = [
  "default",
  "high-contrast",
  "outline",
] as const;
export type FeaturedIconType = (typeof featuredIconType)[number];

export const featuredIconColor = [
  "brand",
  "gray",
  "error",
  "warning",
  "success",
  "black",
] as const;
export type FeaturedIconColor = (typeof featuredIconColor)[number];

export interface RedoFeaturedIconProps {
  Icon: JSXElementConstructor<any>;
  size?: FeaturedIconSize;
  type?: FeaturedIconType;
  color?: FeaturedIconColor;
}

export const RedoFeaturedIcon = memo(function RedoFeaturedIcon({
  Icon,
  size = "sm",
  type = "default",
  color = "brand",
}: RedoFeaturedIconProps) {
  const variantClassnames = [
    typeToBaseClass[type],
    featuredIconCSS[size],
    featuredIconCSS[type],
    typeToColorClass[type],
    featuredIconColorCSS[color],
  ];

  return (
    <div
      className={classNames(featuredIconCSS.iconHighlight, variantClassnames)}
      style={{ boxShadow: getRingCss({ color, size, type }) }}
    >
      <Icon
        className={classNames(
          typeToBaseClass[type],
          featuredIconCSS.icon,
          featuredIconCSS[size],
        )}
      />
    </div>
  );
});

function getRingCss({
  color,
  size,
  type,
}: {
  color: FeaturedIconColor;
  size: FeaturedIconSize;
  type: FeaturedIconType;
}): string | undefined {
  if (type !== "outline") {
    return undefined;
  }
  const [r1, r2, r3, r4] = sizeToRingPixels[size];
  const ring1Color = "#FFFFFF";
  const ring2Color = `${colorToHexString[color]}${opacity10}`;
  const ring3Color = "#FFFFFF";
  const ring4Color = `${colorToHexString[color]}${opacity30}`;
  const shadowCss = `0px 0px 0px ${r1}px ${ring1Color}, 0px 0px 0px ${r2}px ${ring2Color}, 0px 0px 0px ${r3}px ${ring3Color}, 0px 0px 0px ${r4}px ${ring4Color}`;
  return shadowCss;
}

const opacity10 = "4d";
const opacity30 = "1a";

const colorToHexString: Record<FeaturedIconColor, string> = {
  brand: "#FF4405",
  gray: "#525252",
  error: "#D92D20",
  warning: "#DC6803",
  success: "#079455",
  black: "#141414",
};

const sizeToRingPixels: Record<
  FeaturedIconSize,
  [number, number, number, number]
> = {
  xxs: [2, 4, 6, 8],
  xs: [3, 5, 8, 10],
  sm: [3, 5, 9, 11],
  md: [4, 6, 10, 12],
  lg: [5, 7, 12, 14],
  xl: [6, 8, 16, 18],
};

const typeToBaseClass: Record<FeaturedIconType, string> = {
  default: featuredIconCSS.ddefault,
  "high-contrast": featuredIconCSS.highContrast,
  outline: featuredIconCSS.outline,
};

const typeToColorClass: Record<FeaturedIconType, string> = {
  default: featuredIconColorCSS.ddefault,
  "high-contrast": featuredIconColorCSS.highContrast,
  outline: featuredIconColorCSS.outline,
};
