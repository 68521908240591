import { z } from "zod";
import { MarketingEntryPoint } from "../../advanced-flow/schemas/marketing/marketing";

export const subscribeToEmailMarketingSchema = {
  input: z.object({
    email: z.string(),
    entryPoint: z.nativeEnum(MarketingEntryPoint),
    agreementText: z.string(),
  }),
  output: z.object({ success: z.boolean() }),
};
