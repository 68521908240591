import {
  AmpClient,
  AmpRegistrationStatus,
} from "@redotech/redo-model/amp-registration-definition";
import { z } from "zod";

export const checkAmpRegistrationStatus = {
  input: z.object({ emailAddress: z.string() }),
  output: z.object({
    [AmpClient.GMAIL]: z.union([
      z.nativeEnum(AmpRegistrationStatus),
      z.undefined(),
    ]),
    [AmpClient.YAHOO]: z.union([
      z.nativeEnum(AmpRegistrationStatus),
      z.undefined(),
    ]),
  }),
  // output: z.object({}),
};
