import { treatmentZodSchema } from "@redotech/redo-model/split";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { treatmentRequestSchema } from "./create-treatment";

export const updateTreatment = {
  input: treatmentRequestSchema.extend({
    splitId: zExt.objectId(),
    treatmentId: zExt.objectId(),
  }),
  output: z.object({ treatment: treatmentZodSchema }),
};

export type UpdateTreatmentInput = z.infer<typeof updateTreatment.input>;
