import { ExchangesSelection } from "@redotech/redo-model/team";
import { Card } from "@redotech/redo-web/card";
import {
  ColorPickerBasic,
  Direction,
} from "@redotech/redo-web/color-picker-basic";
import { Flex } from "@redotech/redo-web/flex";
import { FormFontSelect } from "@redotech/redo-web/font-select";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import * as labeledInputCss from "@redotech/redo-web/labeled-input.module.css";
import { FormRadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";

export const exchangesForm = groupInput(
  {
    selection: input<ExchangesSelection>(),
    checkoutSelector: input<string>(),
    exchangeBannerColor: input<string>(),
    exchangeBannerText: input<string>(),
    exchangeBannerPositionBottom: input<boolean>(),
    exchangeBannerInsertBeforeBody: input<boolean>(),
    exchangeBannerFont: input<string>(),
    exchangeBannerFontColor: input<string>(),
    exchangeBannerShowDoneButton: input<boolean>(),
    exchangeOptionsPreviewShowOriginalPrice: input<boolean>(),
    enableNewExchangeProcessing: input<boolean>(),
  },
  {
    validator: ({ selection, checkoutSelector }) => {
      return selection === ExchangesSelection.STORE_WEBSITE && !checkoutSelector
        ? ["Checkout selector is required for using the store site"]
        : [];
    },
  },
);

export type ExchangesForm = InputProvider.Form<typeof exchangesForm>;

export type ExchangesValue = InputProvider.Value<typeof exchangesForm>;

export const exchangesDefault: ExchangesValue = {
  selection: ExchangesSelection.RETURN_APP,
  checkoutSelector: "",
  exchangeBannerColor: "",
  exchangeBannerText: "",
  exchangeBannerPositionBottom: false,
  exchangeBannerInsertBeforeBody: false,
  exchangeBannerFont: "",
  exchangeBannerFontColor: "",
  exchangeBannerShowDoneButton: true,
  exchangeOptionsPreviewShowOriginalPrice: false,
  enableNewExchangeProcessing: false,
};

function selectionLabels(value: ExchangesSelection): string {
  return {
    [ExchangesSelection.RETURN_APP]: "Stay in Redo return portal",
    [ExchangesSelection.STORE_WEBSITE]: "Redirect to store site",
  }[value];
}

export const ExchangesCard = memo(function ExchangesCard({
  input,
  setBannerColor,
  setBannerFontColor,
}: {
  input: ExchangesForm;
  setBannerColor: (color: string) => void;
  setBannerFontColor: (color: string) => void;
}) {
  const {
    selection,
    checkoutSelector,
    exchangeBannerColor,
    exchangeBannerText,
    exchangeBannerPositionBottom,
    exchangeBannerInsertBeforeBody,
    exchangeBannerFont,
    exchangeBannerFontColor,
    exchangeBannerShowDoneButton,
    exchangeOptionsPreviewShowOriginalPrice,
    enableNewExchangeProcessing,
  } = input.inputs;

  return (
    <Card title="Exchanges">
      <div className={gridCss.span12}>
        <strong>Advanced exchanges</strong>
      </div>
      <section className={gridCss.grid}>
        <Flex
          className={classNames(gridCss.span12, gridCss.span6Xl)}
          dir="column"
        >
          <FormRadioGroup
            input={selection}
            label="Selection location"
            layout={RadioGroupLayout.VERTICAL}
            optionLabel={selectionLabels}
            options={Object.values(ExchangesSelection)}
          >
            <Flex dir="column" gap="sm">
              <p>Where to take customers when selecting exchange items.</p>

              {selection.value === ExchangesSelection.STORE_WEBSITE ? (
                <>
                  <Text fontSize="xs" fontWeight="medium" textColor="secondary">
                    This will not work for stores with the "Buy it now" button.
                  </Text>
                  <Text fontSize="xs" fontWeight="medium" textColor="secondary">
                    Make sure that either the app embed or script tag are
                    enabled on the site - this is required for shop on-site to
                    work.
                  </Text>
                </>
              ) : (
                <br />
              )}
            </Flex>
          </FormRadioGroup>
          <br />
          <FormTextInput
            description="Selector of all checkout buttons on the store site (slide-out cart, cart page, etc). You will have to find this manually."
            errorOverride={
              selection.value === ExchangesSelection.STORE_WEBSITE &&
              !checkoutSelector.value
            }
            input={checkoutSelector}
            label="Checkout selector"
            placeholder="Default"
          />
          {selection.value === ExchangesSelection.STORE_WEBSITE &&
            !checkoutSelector.value && (
              <div className={labeledInputCss.error}>
                Checkout selector is required for using store site
              </div>
            )}
          <br />
          <FormSwitch
            input={exchangeOptionsPreviewShowOriginalPrice}
            label="Show full price only for exchange options preview"
          >
            Prevent showing the price with the exchange credit applied and the
            original price crossed out in the compensation method modal, show
            only the full price.
          </FormSwitch>
          <FormSwitch
            input={enableNewExchangeProcessing}
            label="Enable new exchange processing"
          >
            <Text textColor="warning">
              WARNING: Before enabling this feature, you must set the merchant's
              "Leftover exchange value policy" setting equal to their "Out of
              stock policies" setting (in merchant dashboard under settings
              -&gt; exchanges)
            </Text>
          </FormSwitch>
        </Flex>
        {selection.value === ExchangesSelection.STORE_WEBSITE && (
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <LabeledInput label="Banner colors">
              <ColorPickerBasic
                label="Banner background"
                onChange={(color) => setBannerColor(color)}
                openDirection={Direction.BELOW}
                value={exchangeBannerColor.value}
              />
              <ColorPickerBasic
                label="Font color"
                onChange={(color) => setBannerFontColor(color)}
                openDirection={Direction.BELOW}
                value={exchangeBannerFontColor.value}
              />
            </LabeledInput>
            <br />
            <FormFontSelect
              disabled={false}
              input={exchangeBannerFont}
              label="Banner Font Family"
            />
            <br />
            <FormTextInput
              description="Text to display on the banner. Use %value% to display the value of the exchange."
              input={exchangeBannerText}
              label="Banner text"
              placeholder="You have $%value% to spend. When done shopping, select done or click Checkout."
            />
            <br />
            <FormSwitch
              input={exchangeBannerShowDoneButton}
              label="Show done button"
            >
              Whether to show a "Done" button on the banner
            </FormSwitch>
            <br />
            <FormSwitch
              input={exchangeBannerPositionBottom}
              label="Exchange banner bottom"
            >
              Whether to show the exchange banner at the bottom of the site
            </FormSwitch>
            <br />
            <FormSwitch
              input={exchangeBannerInsertBeforeBody}
              label="Override banner to top"
            >
              Sets the exchange banner to the top of the body
            </FormSwitch>
          </div>
        )}
      </section>
    </Card>
  );
});
