import { z } from "zod";
import { signupFormSiteTypes } from "../../signup-form";
import { signupFormSchema } from "../../signup-form-schema";

export const getSignupFormSchema = {
  input: z.object({
    shopifyCustomerId: z.string().nullable(),
    customerHash: z.string(),
    formId: z.string().nullable(),
    isOnMobile: z.boolean(),
    site: z.enum(signupFormSiteTypes).optional(),
    utmParams: z.string(),
    forceId: z.string().optional(),
  }),
  output: z.object({
    form: signupFormSchema.nullable(),
    formId: z.string().nullable(),
    skipPhoneSteps: z.boolean(),
    reason: z.string().optional(),
  }),
};

export type GetSignupFormInput = z.infer<typeof getSignupFormSchema.input>;
