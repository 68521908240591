import { ClickAwayListener } from "@mui/base";
import type { PopperPlacementType } from "@mui/base/Popper";
import { JSXElementConstructor, memo, MouseEvent, useMemo } from "react";
import { Dropdown } from "../../dropdown";
import { Flex } from "../../flex";
import { Tooltip } from "../../tooltip/tooltip";
import {
  RedoButton,
  RedoButtonSize,
  RedoButtonTheme,
} from "../buttons/redo-button";
import * as redoCommandMenuCss from "./redo-command-menu.module.css";

export interface RedoCommandMenuItem {
  Icon?: JSXElementConstructor<any>;
  text: string;
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  disabledTooltip?: string;
  theme?: RedoButtonTheme;
  size?: RedoButtonSize;
  show?: boolean;
}

export const RedoCommandMenu = memo(function RedoCommandMenu({
  items,
  open,
  setOpen,
  anchor,
  size = "sm",
  fitToAnchor = false,
  placement,
}: {
  items: RedoCommandMenuItem[];
  open: boolean;
  setOpen(value: boolean): void;
  anchor: HTMLElement | null;
  size?: RedoButtonSize;
  fitToAnchor?: boolean;
  placement?: PopperPlacementType;
}) {
  const atLeastOneIcon = items.some((item) => item.Icon !== undefined);

  const itemsToShow = useMemo(
    () => items.filter((item) => item.show !== false),
    [items],
  );

  const dropdown = (
    <Dropdown
      anchor={anchor}
      fitToAnchor={fitToAnchor}
      open={open}
      placement={placement}
    >
      <Flex dir="column">
        {itemsToShow.map((item, idx) => {
          return (
            <RedoCommandMenuItem
              fitToAnchor={fitToAnchor}
              indentForIcon={atLeastOneIcon}
              item={item}
              itemClicked={() => setOpen(false)}
              key={idx}
              size={size}
            />
          );
        })}
      </Flex>
    </Dropdown>
  );

  return (
    <>
      {open && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          {dropdown}
        </ClickAwayListener>
      )}
    </>
  );
});

/**
 * @param indentForIcon if at least one of the menu items has an icon,
 * we want to space all the buttons evenly
 */
const RedoCommandMenuItem = memo(function RedoCommandMenuItem({
  item,
  indentForIcon,
  itemClicked,
  size = "sm",
  fitToAnchor = false,
}: {
  item: RedoCommandMenuItem;
  indentForIcon: boolean;
  itemClicked(): void;
  size?: RedoButtonSize;
  fitToAnchor?: boolean;
}) {
  const icon = item.Icon || (indentForIcon ? "placeholder" : undefined);

  const button = (
    <RedoButton
      centerItems={false}
      className={
        fitToAnchor
          ? redoCommandMenuCss.fullWidthDropdownButton
          : redoCommandMenuCss.dropdownButton
      }
      disabled={item.disabled}
      hierarchy="tertiary"
      IconLeading={icon}
      onClick={(event) => {
        event.stopPropagation();
        itemClicked();
        item.onClick(event);
      }}
      size={item.size ?? size}
      text={item.text}
      theme={item.theme}
    />
  );
  if (item.disabledTooltip && item.disabled) {
    return (
      <Tooltip arrow title={item.disabledTooltip}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
});
