import { useEffect, useState } from "react";

export enum OverflowDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export function useOverflow(
  ref: HTMLElement | null,
  direction: OverflowDirection,
) {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const element = ref;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (direction === OverflowDirection.Horizontal) {
        setIsOverflowing(element.scrollWidth > element.clientWidth);
      } else {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [direction, ref]);

  return isOverflowing;
}
