import { z } from "zod";
import { PillTheme } from "./pill-theme";
import { reverseRecord } from "./records-utils";
export enum ReturnStatus {
  OPEN = "open",
  IN_TRANSIT = "in_transit",
  DELIVERED = "delivered",
  NEEDS_REVIEW = "needs_review",
  IN_REVIEW = "in_review",
  COMPLETE = "complete",
  REJECTED = "rejected",
  FLAGGED = "flagged",
  PRE_SHIPMENT = "pre-shipment",
  DELETED = "deleted",
}

export const ReturnStatusZod = z.nativeEnum(ReturnStatus);

export const returnStatusToDisplayText: Record<ReturnStatus, string> = {
  [ReturnStatus.COMPLETE]: "Complete",
  [ReturnStatus.DELIVERED]: "Delivered",
  [ReturnStatus.IN_TRANSIT]: "In transit",
  [ReturnStatus.NEEDS_REVIEW]: "Needs review",
  [ReturnStatus.IN_REVIEW]: "In review",
  [ReturnStatus.OPEN]: "Open",
  [ReturnStatus.REJECTED]: "Rejected",
  [ReturnStatus.FLAGGED]: "Flagged",
  [ReturnStatus.PRE_SHIPMENT]: "Pre-shipment",
  [ReturnStatus.DELETED]: "Reset",
};

export const inferredReturnStatusFromDisplayText = reverseRecord(
  returnStatusToDisplayText,
);

export const returnStatusToBadgeColor: Record<ReturnStatus, PillTheme> = {
  [ReturnStatus.COMPLETE]: PillTheme.SUCCESS,
  [ReturnStatus.DELIVERED]: PillTheme.SUCCESS,
  [ReturnStatus.IN_TRANSIT]: PillTheme.NEUTRAL_BLUE,
  [ReturnStatus.NEEDS_REVIEW]: PillTheme.NEUTRAL_YELLOW,
  [ReturnStatus.IN_REVIEW]: PillTheme.NEUTRAL_BLUE,
  [ReturnStatus.OPEN]: PillTheme.NEUTRAL_YELLOW,
  [ReturnStatus.REJECTED]: PillTheme.DANGER,
  [ReturnStatus.FLAGGED]: PillTheme.DANGER,
  [ReturnStatus.PRE_SHIPMENT]: PillTheme.NEUTRAL_BLUE,
  [ReturnStatus.DELETED]: PillTheme.NEUTRAL_YELLOW,
};

export function shouldSkipAutomaticProcessing(status: ReturnStatus) {
  return (
    isClosedReturnStatus(status) ||
    [ReturnStatus.NEEDS_REVIEW, ReturnStatus.FLAGGED].includes(status)
  );
}

export const closedReturnStatuses = [
  ReturnStatus.COMPLETE,
  ReturnStatus.DELETED,
  ReturnStatus.REJECTED,
];

export function isClosedReturnStatus(status: ReturnStatus) {
  return closedReturnStatuses.includes(status);
}

export function isCancelledReturnStatus(status: ReturnStatus) {
  return [ReturnStatus.DELETED, ReturnStatus.REJECTED].includes(status);
}
