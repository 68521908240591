import { COUNTRY_CODES } from "@redotech/locale/countries";
import { CURRENCIES, Currency } from "@redotech/money/currencies";
import { z } from "zod";

export enum LengthUnit {
  INCH = "in",
  CENTIMETER = "cm",
}
export const LengthUnitSchema = z.nativeEnum(LengthUnit);

export function lengthUnitDisplayName(unit: LengthUnit): string {
  switch (unit) {
    case LengthUnit.INCH:
      return "inches";
    case LengthUnit.CENTIMETER:
      return "centimeters";
    default:
      return unit;
  }
}

export enum Precision {
  AT_LEAST = "at-least",
  EXACT = "exact",
}
export const PrecisionSchema = z.nativeEnum(Precision);

export const CountryCodeSchema = z
  .string()
  .refine((code: any) => COUNTRY_CODES.includes(code), {
    message: "countryCode must be found in COUNTRY_CODES",
  });

export const CurrencySchema = z.custom<Currency>(
  (data) => CURRENCIES.includes(data),
  { message: "currencyCode must be found in CURRENCIES" },
);

export const MoneySchema = z.object({
  amount: z.string(),
  currency: CurrencySchema,
});

export type IMoney = z.infer<typeof MoneySchema>;

export enum WeightUnit {
  GRAM = "g",
  KILOGRAM = "kg",
  OUNCE = "oz",
  POUND = "lb",
  TONNES = "t",
}
export const WeightUnitSchema = z
  .custom<WeightUnit>((data) => !!weightTransforms[data], {
    message: "Unknown weight unit",
  })
  .transform((arg) => weightTransforms[arg]!);

export const WeightSchema = z.object({
  unit: WeightUnitSchema,
  value: z.number(),
});

export type Weight = z.infer<typeof WeightSchema>;

const weightTransforms: Record<string, WeightUnit> = {
  GRAMS: WeightUnit.GRAM,
  KILOGRAMS: WeightUnit.KILOGRAM,
  OUNCES: WeightUnit.OUNCE,
  POUNDS: WeightUnit.POUND,
  TONNES: WeightUnit.TONNES,
  g: WeightUnit.GRAM,
  kg: WeightUnit.KILOGRAM,
  lb: WeightUnit.POUND,
  lbs: WeightUnit.POUND,
  oz: WeightUnit.OUNCE,
  t: WeightUnit.TONNES,
  G: WeightUnit.GRAM,
  KG: WeightUnit.KILOGRAM,
  LB: WeightUnit.POUND,
  LBS: WeightUnit.POUND,
  OZ: WeightUnit.OUNCE,
  T: WeightUnit.TONNES,
};

const weightUnitConversion: Record<WeightUnit, Record<WeightUnit, number>> = {
  [WeightUnit.OUNCE]: {
    [WeightUnit.POUND]: 0.0625,
    [WeightUnit.OUNCE]: 1,
    [WeightUnit.GRAM]: 28.349523125,
    [WeightUnit.KILOGRAM]: 0.0283495231,
    [WeightUnit.TONNES]: 0.0000283495,
  },
  [WeightUnit.POUND]: {
    [WeightUnit.OUNCE]: 16,
    [WeightUnit.POUND]: 1,
    [WeightUnit.GRAM]: 453.59237,
    [WeightUnit.KILOGRAM]: 0.45359237,
    [WeightUnit.TONNES]: 0.0004535924,
  },
  [WeightUnit.GRAM]: {
    [WeightUnit.OUNCE]: 0.0352739619,
    [WeightUnit.POUND]: 0.0022046226,
    [WeightUnit.GRAM]: 1,
    [WeightUnit.KILOGRAM]: 0.001,
    [WeightUnit.TONNES]: 0.001,
  },
  [WeightUnit.KILOGRAM]: {
    [WeightUnit.OUNCE]: 35.2739619496,
    [WeightUnit.POUND]: 2.2046226218,
    [WeightUnit.GRAM]: 1000,
    [WeightUnit.KILOGRAM]: 1,
    [WeightUnit.TONNES]: 0.001,
  },
  [WeightUnit.TONNES]: {
    [WeightUnit.OUNCE]: 35273.96195,
    [WeightUnit.POUND]: 2204.6226218,
    [WeightUnit.GRAM]: 1000000,
    [WeightUnit.KILOGRAM]: 1000,
    [WeightUnit.TONNES]: 1,
  },
};

export function convertWeight(weight: Weight, to: WeightUnit): Weight {
  const converted = weightUnitConversion[weight.unit][to] * weight.value;
  return { unit: to, value: roundIfVeryClose(converted) };
}

function roundIfVeryClose(value: number): number {
  const rounded = Math.round(value);
  if (Math.abs(value - rounded) < 0.001) {
    return rounded;
  }
  return value;
}

export const roundWeight = (weight: number) => {
  return Math.round(weight * 100) / 100;
};

export const splitWeight = (
  weight: Weight,
  units: [WeightUnit, WeightUnit],
): [number, number] => {
  const firstPart = convertWeight(weight, units[0]);
  let firstWhole = Math.floor(firstPart.value);
  const secondPart = convertWeight(
    { value: firstPart.value - firstWhole, unit: units[0] },
    units[1],
  );
  let secondWhole = Math.ceil(secondPart.value);
  // Due to rounding errors, rounding up the second unit
  // may make it a whole number of the first unit. (1 lb 16 oz)
  // Account for that here. (2 lb 0 oz)
  if (
    convertWeight({ value: secondWhole, unit: units[1] }, units[0]).value === 1
  ) {
    firstWhole++;
    secondWhole = 0;
  }
  return [firstWhole, secondWhole];
};

export function addWeight(weight: Weight, weightToAdd: Weight): Weight {
  const convertedWeightToAdd = convertWeight(weightToAdd, weight.unit);
  return {
    unit: weight.unit,
    value: weight.value + convertedWeightToAdd.value,
  };
}
