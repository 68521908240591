import PackageIcon from "@redotech/redo-web/arbiter-icon/package.svg";
import { getCarrierLogoUrl } from "@redotech/redo-web/carrier/logo";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import { JSXElementConstructor, memo, useState } from "react";
import { UserImage, UserImageSize } from "../../user-image";
import {
  FeaturedIconSize,
  RedoFeaturedIcon,
  RedoFeaturedIconProps,
} from "../featured-icon/redo-featured-icon";
import * as redoListItemCss from "./redo-list-item.module.css";
import {
  RedoListItemSize,
  itemSizeToSizeClass,
} from "./standard-redo-list-items";

// type PresenceStatus = "active" | "busy" | "offline" | "away";
// interface PresenceDot {
//   type: "presence";
//   status: PresenceStatus;
// }

interface Icon {
  type: "icon";
  Icon: JSXElementConstructor<any>;
}

interface FeaturedIcon {
  type: "featured";
  iconProps: Omit<RedoFeaturedIconProps, "size">;
}

interface Avatar {
  type: "avatar";
  imageUrl: string | null | undefined;
  alt: string;
  name: string | undefined;
}

interface ShippingItem {
  type: "shipping";
  carrier: string | undefined | null;
}

const statusDotColor = ["green", "yellow", "red", "gray"] as const;
export type StatusDotColor = (typeof statusDotColor)[number];

interface StatusDot {
  type: "status";
  color: StatusDotColor;
}

export type LeadingItem =
  | Icon
  | Avatar
  | FeaturedIcon
  | StatusDot
  | ShippingItem;

export const RedoListItemLeadingItem = memo(function RedoListItemLeadingItem({
  leadingItem,
  size,
}: {
  leadingItem: LeadingItem;
  size: RedoListItemSize;
}) {
  switch (leadingItem.type) {
    case "icon":
      return (
        <leadingItem.Icon
          className={classNames(
            redoListItemCss.icon,
            itemSizeToSizeClass[size],
          )}
        />
      );
    case "avatar":
      return <UserImage {...leadingItem} size={avatarSize[size]} />;
    case "featured":
      return (
        <RedoFeaturedIcon
          {...leadingItem.iconProps}
          size={listItemSizeToFeaturedIconSize[size]}
        />
      );
    case "status":
      return (
        <span
          className={classNames(
            redoListItemCss.statusDotContainer,
            itemSizeToSizeClass[size],
          )}
        >
          <span
            className={classNames(
              redoListItemCss.statusDot,
              statusDotColorToClass[leadingItem.color],
              itemSizeToSizeClass[size],
            )}
          />
        </span>
      );
    case "shipping":
      return <ShippingCircle carrier={leadingItem.carrier} size={size} />;
    default:
      assertNever(leadingItem);
  }
});

const ShippingCircle = memo(function ShippingCircle({
  carrier,
  size,
}: {
  size: RedoListItemSize;
  carrier: string | null | undefined;
}) {
  const logoUrl = getCarrierLogoUrl(carrier || "");
  return (
    <ImageCircle
      BackupIcon={PackageIcon}
      imageUrl={logoUrl}
      key={logoUrl}
      size={size}
    />
  );
});

const ImageCircle = memo(function ImageCircle({
  size,
  imageUrl,
  BackupIcon,
}: {
  size: RedoListItemSize;
  imageUrl: string | null | undefined;
  BackupIcon: JSXElementConstructor<any>;
}) {
  const [useBackup, setUseBackup] = useState(false);

  if (!imageUrl || useBackup) {
    return (
      <RedoFeaturedIcon
        Icon={BackupIcon}
        size={listItemSizeToFeaturedIconSize[size]}
      />
    );
  }

  return (
    <img
      className={classNames(
        itemSizeToSizeClass[size],
        redoListItemCss.imageCircle,
      )}
      onError={() => setUseBackup(true)}
      src={imageUrl}
    />
  );
});

const statusDotColorToClass: Record<StatusDotColor, string> = {
  green: redoListItemCss.green,
  yellow: redoListItemCss.yellow,
  red: redoListItemCss.red,
  gray: redoListItemCss.gray,
};

const avatarSize: Record<RedoListItemSize, UserImageSize> = {
  sm: UserImageSize.TINY,
  md: UserImageSize.MICRO,
  lg: UserImageSize.MICRO,
};

const listItemSizeToFeaturedIconSize: Record<
  RedoListItemSize,
  FeaturedIconSize
> = { sm: "xxs", md: "xs", lg: "sm" };
