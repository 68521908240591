// This is a copy of the CountryCode enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyCountryCode {
  /** Ascension Island. */
  Ac = "AC",
  /** Andorra. */
  Ad = "AD",
  /** United Arab Emirates. */
  Ae = "AE",
  /** Afghanistan. */
  Af = "AF",
  /** Antigua & Barbuda. */
  Ag = "AG",
  /** Anguilla. */
  Ai = "AI",
  /** Albania. */
  Al = "AL",
  /** Armenia. */
  Am = "AM",
  /** Netherlands Antilles. */
  An = "AN",
  /** Angola. */
  Ao = "AO",
  /** Argentina. */
  Ar = "AR",
  /** Austria. */
  At = "AT",
  /** Australia. */
  Au = "AU",
  /** Aruba. */
  Aw = "AW",
  /** Åland Islands. */
  Ax = "AX",
  /** Azerbaijan. */
  Az = "AZ",
  /** Bosnia & Herzegovina. */
  Ba = "BA",
  /** Barbados. */
  Bb = "BB",
  /** Bangladesh. */
  Bd = "BD",
  /** Belgium. */
  Be = "BE",
  /** Burkina Faso. */
  Bf = "BF",
  /** Bulgaria. */
  Bg = "BG",
  /** Bahrain. */
  Bh = "BH",
  /** Burundi. */
  Bi = "BI",
  /** Benin. */
  Bj = "BJ",
  /** St. Barthélemy. */
  Bl = "BL",
  /** Bermuda. */
  Bm = "BM",
  /** Brunei. */
  Bn = "BN",
  /** Bolivia. */
  Bo = "BO",
  /** Caribbean Netherlands. */
  Bq = "BQ",
  /** Brazil. */
  Br = "BR",
  /** Bahamas. */
  Bs = "BS",
  /** Bhutan. */
  Bt = "BT",
  /** Bouvet Island. */
  Bv = "BV",
  /** Botswana. */
  Bw = "BW",
  /** Belarus. */
  By = "BY",
  /** Belize. */
  Bz = "BZ",
  /** Canada. */
  Ca = "CA",
  /** Cocos (Keeling) Islands. */
  Cc = "CC",
  /** Congo - Kinshasa. */
  Cd = "CD",
  /** Central African Republic. */
  Cf = "CF",
  /** Congo - Brazzaville. */
  Cg = "CG",
  /** Switzerland. */
  Ch = "CH",
  /** Côte d'Ivoire. */
  Ci = "CI",
  /** Cook Islands. */
  Ck = "CK",
  /** Chile. */
  Cl = "CL",
  /** Cameroon. */
  Cm = "CM",
  /** China. */
  Cn = "CN",
  /** Colombia. */
  Co = "CO",
  /** Costa Rica. */
  Cr = "CR",
  /** Cuba. */
  Cu = "CU",
  /** Cape Verde. */
  Cv = "CV",
  /** Curaçao. */
  Cw = "CW",
  /** Christmas Island. */
  Cx = "CX",
  /** Cyprus. */
  Cy = "CY",
  /** Czechia. */
  Cz = "CZ",
  /** Germany. */
  De = "DE",
  /** Djibouti. */
  Dj = "DJ",
  /** Denmark. */
  Dk = "DK",
  /** Dominica. */
  Dm = "DM",
  /** Dominican Republic. */
  Do = "DO",
  /** Algeria. */
  Dz = "DZ",
  /** Ecuador. */
  Ec = "EC",
  /** Estonia. */
  Ee = "EE",
  /** Egypt. */
  Eg = "EG",
  /** Western Sahara. */
  Eh = "EH",
  /** Eritrea. */
  Er = "ER",
  /** Spain. */
  Es = "ES",
  /** Ethiopia. */
  Et = "ET",
  /** Finland. */
  Fi = "FI",
  /** Fiji. */
  Fj = "FJ",
  /** Falkland Islands. */
  Fk = "FK",
  /** Faroe Islands. */
  Fo = "FO",
  /** France. */
  Fr = "FR",
  /** Gabon. */
  Ga = "GA",
  /** United Kingdom. */
  Gb = "GB",
  /** Grenada. */
  Gd = "GD",
  /** Georgia. */
  Ge = "GE",
  /** French Guiana. */
  Gf = "GF",
  /** Guernsey. */
  Gg = "GG",
  /** Ghana. */
  Gh = "GH",
  /** Gibraltar. */
  Gi = "GI",
  /** Greenland. */
  Gl = "GL",
  /** Gambia. */
  Gm = "GM",
  /** Guinea. */
  Gn = "GN",
  /** Guadeloupe. */
  Gp = "GP",
  /** Equatorial Guinea. */
  Gq = "GQ",
  /** Greece. */
  Gr = "GR",
  /** South Georgia & South Sandwich Islands. */
  Gs = "GS",
  /** Guatemala. */
  Gt = "GT",
  /** Guinea-Bissau. */
  Gw = "GW",
  /** Guyana. */
  Gy = "GY",
  /** Hong Kong SAR. */
  Hk = "HK",
  /** Heard & McDonald Islands. */
  Hm = "HM",
  /** Honduras. */
  Hn = "HN",
  /** Croatia. */
  Hr = "HR",
  /** Haiti. */
  Ht = "HT",
  /** Hungary. */
  Hu = "HU",
  /** Indonesia. */
  Id = "ID",
  /** Ireland. */
  Ie = "IE",
  /** Israel. */
  Il = "IL",
  /** Isle of Man. */
  Im = "IM",
  /** India. */
  In = "IN",
  /** British Indian Ocean Territory. */
  Io = "IO",
  /** Iraq. */
  Iq = "IQ",
  /** Iran. */
  Ir = "IR",
  /** Iceland. */
  Is = "IS",
  /** Italy. */
  It = "IT",
  /** Jersey. */
  Je = "JE",
  /** Jamaica. */
  Jm = "JM",
  /** Jordan. */
  Jo = "JO",
  /** Japan. */
  Jp = "JP",
  /** Kenya. */
  Ke = "KE",
  /** Kyrgyzstan. */
  Kg = "KG",
  /** Cambodia. */
  Kh = "KH",
  /** Kiribati. */
  Ki = "KI",
  /** Comoros. */
  Km = "KM",
  /** St. Kitts & Nevis. */
  Kn = "KN",
  /** North Korea. */
  Kp = "KP",
  /** South Korea. */
  Kr = "KR",
  /** Kuwait. */
  Kw = "KW",
  /** Cayman Islands. */
  Ky = "KY",
  /** Kazakhstan. */
  Kz = "KZ",
  /** Laos. */
  La = "LA",
  /** Lebanon. */
  Lb = "LB",
  /** St. Lucia. */
  Lc = "LC",
  /** Liechtenstein. */
  Li = "LI",
  /** Sri Lanka. */
  Lk = "LK",
  /** Liberia. */
  Lr = "LR",
  /** Lesotho. */
  Ls = "LS",
  /** Lithuania. */
  Lt = "LT",
  /** Luxembourg. */
  Lu = "LU",
  /** Latvia. */
  Lv = "LV",
  /** Libya. */
  Ly = "LY",
  /** Morocco. */
  Ma = "MA",
  /** Monaco. */
  Mc = "MC",
  /** Moldova. */
  Md = "MD",
  /** Montenegro. */
  Me = "ME",
  /** St. Martin. */
  Mf = "MF",
  /** Madagascar. */
  Mg = "MG",
  /** North Macedonia. */
  Mk = "MK",
  /** Mali. */
  Ml = "ML",
  /** Myanmar (Burma). */
  Mm = "MM",
  /** Mongolia. */
  Mn = "MN",
  /** Macao SAR. */
  Mo = "MO",
  /** Martinique. */
  Mq = "MQ",
  /** Mauritania. */
  Mr = "MR",
  /** Montserrat. */
  Ms = "MS",
  /** Malta. */
  Mt = "MT",
  /** Mauritius. */
  Mu = "MU",
  /** Maldives. */
  Mv = "MV",
  /** Malawi. */
  Mw = "MW",
  /** Mexico. */
  Mx = "MX",
  /** Malaysia. */
  My = "MY",
  /** Mozambique. */
  Mz = "MZ",
  /** Namibia. */
  Na = "NA",
  /** New Caledonia. */
  Nc = "NC",
  /** Niger. */
  Ne = "NE",
  /** Norfolk Island. */
  Nf = "NF",
  /** Nigeria. */
  Ng = "NG",
  /** Nicaragua. */
  Ni = "NI",
  /** Netherlands. */
  Nl = "NL",
  /** Norway. */
  No = "NO",
  /** Nepal. */
  Np = "NP",
  /** Nauru. */
  Nr = "NR",
  /** Niue. */
  Nu = "NU",
  /** New Zealand. */
  Nz = "NZ",
  /** Oman. */
  Om = "OM",
  /** Panama. */
  Pa = "PA",
  /** Peru. */
  Pe = "PE",
  /** French Polynesia. */
  Pf = "PF",
  /** Papua New Guinea. */
  Pg = "PG",
  /** Philippines. */
  Ph = "PH",
  /** Pakistan. */
  Pk = "PK",
  /** Poland. */
  Pl = "PL",
  /** St. Pierre & Miquelon. */
  Pm = "PM",
  /** Pitcairn Islands. */
  Pn = "PN",
  /** Palestinian Territories. */
  Ps = "PS",
  /** Portugal. */
  Pt = "PT",
  /** Paraguay. */
  Py = "PY",
  /** Qatar. */
  Qa = "QA",
  /** Réunion. */
  Re = "RE",
  /** Romania. */
  Ro = "RO",
  /** Serbia. */
  Rs = "RS",
  /** Russia. */
  Ru = "RU",
  /** Rwanda. */
  Rw = "RW",
  /** Saudi Arabia. */
  Sa = "SA",
  /** Solomon Islands. */
  Sb = "SB",
  /** Seychelles. */
  Sc = "SC",
  /** Sudan. */
  Sd = "SD",
  /** Sweden. */
  Se = "SE",
  /** Singapore. */
  Sg = "SG",
  /** St. Helena. */
  Sh = "SH",
  /** Slovenia. */
  Si = "SI",
  /** Svalbard & Jan Mayen. */
  Sj = "SJ",
  /** Slovakia. */
  Sk = "SK",
  /** Sierra Leone. */
  Sl = "SL",
  /** San Marino. */
  Sm = "SM",
  /** Senegal. */
  Sn = "SN",
  /** Somalia. */
  So = "SO",
  /** Suriname. */
  Sr = "SR",
  /** South Sudan. */
  Ss = "SS",
  /** São Tomé & Príncipe. */
  St = "ST",
  /** El Salvador. */
  Sv = "SV",
  /** Sint Maarten. */
  Sx = "SX",
  /** Syria. */
  Sy = "SY",
  /** Eswatini. */
  Sz = "SZ",
  /** Tristan da Cunha. */
  Ta = "TA",
  /** Turks & Caicos Islands. */
  Tc = "TC",
  /** Chad. */
  Td = "TD",
  /** French Southern Territories. */
  Tf = "TF",
  /** Togo. */
  Tg = "TG",
  /** Thailand. */
  Th = "TH",
  /** Tajikistan. */
  Tj = "TJ",
  /** Tokelau. */
  Tk = "TK",
  /** Timor-Leste. */
  Tl = "TL",
  /** Turkmenistan. */
  Tm = "TM",
  /** Tunisia. */
  Tn = "TN",
  /** Tonga. */
  To = "TO",
  /** Türkiye. */
  Tr = "TR",
  /** Trinidad & Tobago. */
  Tt = "TT",
  /** Tuvalu. */
  Tv = "TV",
  /** Taiwan. */
  Tw = "TW",
  /** Tanzania. */
  Tz = "TZ",
  /** Ukraine. */
  Ua = "UA",
  /** Uganda. */
  Ug = "UG",
  /** U.S. Outlying Islands. */
  Um = "UM",
  /** United States. */
  Us = "US",
  /** Uruguay. */
  Uy = "UY",
  /** Uzbekistan. */
  Uz = "UZ",
  /** Vatican City. */
  Va = "VA",
  /** St. Vincent & Grenadines. */
  Vc = "VC",
  /** Venezuela. */
  Ve = "VE",
  /** British Virgin Islands. */
  Vg = "VG",
  /** Vietnam. */
  Vn = "VN",
  /** Vanuatu. */
  Vu = "VU",
  /** Wallis & Futuna. */
  Wf = "WF",
  /** Samoa. */
  Ws = "WS",
  /** Kosovo. */
  Xk = "XK",
  /** Yemen. */
  Ye = "YE",
  /** Mayotte. */
  Yt = "YT",
  /** South Africa. */
  Za = "ZA",
  /** Zambia. */
  Zm = "ZM",
  /** Zimbabwe. */
  Zw = "ZW",
  /** Unknown Region. */
  Zz = "ZZ",
}

export const RedoShopifyCountryNames: Record<RedoShopifyCountryCode, string> = {
  [RedoShopifyCountryCode.Ac]: "Ascension Island",
  [RedoShopifyCountryCode.Ad]: "Andorra",
  [RedoShopifyCountryCode.Ae]: "United Arab Emirates",
  [RedoShopifyCountryCode.Af]: "Afghanistan",
  [RedoShopifyCountryCode.Ag]: "Antigua & Barbuda",
  [RedoShopifyCountryCode.Ai]: "Anguilla",
  [RedoShopifyCountryCode.Al]: "Albania",
  [RedoShopifyCountryCode.Am]: "Armenia",
  [RedoShopifyCountryCode.An]: "Netherlands Antilles",
  [RedoShopifyCountryCode.Ao]: "Angola",
  [RedoShopifyCountryCode.Ar]: "Argentina",
  [RedoShopifyCountryCode.At]: "Austria",
  [RedoShopifyCountryCode.Au]: "Australia",
  [RedoShopifyCountryCode.Aw]: "Aruba",
  [RedoShopifyCountryCode.Ax]: "Åland Islands",
  [RedoShopifyCountryCode.Az]: "Azerbaijan",
  [RedoShopifyCountryCode.Ba]: "Bosnia & Herzegovina",
  [RedoShopifyCountryCode.Bb]: "Barbados",
  [RedoShopifyCountryCode.Bd]: "Bangladesh",
  [RedoShopifyCountryCode.Be]: "Belgium",
  [RedoShopifyCountryCode.Bf]: "Burkina Faso",
  [RedoShopifyCountryCode.Bg]: "Bulgaria",
  [RedoShopifyCountryCode.Bh]: "Bahrain",
  [RedoShopifyCountryCode.Bi]: "Burundi",
  [RedoShopifyCountryCode.Bj]: "Benin",
  [RedoShopifyCountryCode.Bl]: "St. Barthélemy",
  [RedoShopifyCountryCode.Bm]: "Bermuda",
  [RedoShopifyCountryCode.Bn]: "Brunei",
  [RedoShopifyCountryCode.Bo]: "Bolivia",
  [RedoShopifyCountryCode.Bq]: "Caribbean Netherlands",
  [RedoShopifyCountryCode.Br]: "Brazil",
  [RedoShopifyCountryCode.Bs]: "Bahamas",
  [RedoShopifyCountryCode.Bt]: "Bhutan",
  [RedoShopifyCountryCode.Bv]: "Bouvet Island",
  [RedoShopifyCountryCode.Bw]: "Botswana",
  [RedoShopifyCountryCode.By]: "Belarus",
  [RedoShopifyCountryCode.Bz]: "Belize",
  [RedoShopifyCountryCode.Ca]: "Canada",
  [RedoShopifyCountryCode.Cc]: "Cocos (Keeling) Islands",
  [RedoShopifyCountryCode.Cd]: "Congo - Kinshasa",
  [RedoShopifyCountryCode.Cf]: "Central African Republic",
  [RedoShopifyCountryCode.Cg]: "Congo - Brazzaville",
  [RedoShopifyCountryCode.Ch]: "Switzerland",
  [RedoShopifyCountryCode.Ci]: "Côte d'Ivoire",
  [RedoShopifyCountryCode.Ck]: "Cook Islands",
  [RedoShopifyCountryCode.Cl]: "Chile",
  [RedoShopifyCountryCode.Cm]: "Cameroon",
  [RedoShopifyCountryCode.Cn]: "China",
  [RedoShopifyCountryCode.Co]: "Colombia",
  [RedoShopifyCountryCode.Cr]: "Costa Rica",
  [RedoShopifyCountryCode.Cu]: "Cuba",
  [RedoShopifyCountryCode.Cv]: "Cape Verde",
  [RedoShopifyCountryCode.Cw]: "Curaçao",
  [RedoShopifyCountryCode.Cx]: "Christmas Island",
  [RedoShopifyCountryCode.Cy]: "Cyprus",
  [RedoShopifyCountryCode.Cz]: "Czechia",
  [RedoShopifyCountryCode.De]: "Germany",
  [RedoShopifyCountryCode.Dj]: "Djibouti",
  [RedoShopifyCountryCode.Dk]: "Denmark",
  [RedoShopifyCountryCode.Dm]: "Dominica",
  [RedoShopifyCountryCode.Do]: "Dominican Republic",
  [RedoShopifyCountryCode.Dz]: "Algeria",
  [RedoShopifyCountryCode.Ec]: "Ecuador",
  [RedoShopifyCountryCode.Ee]: "Estonia",
  [RedoShopifyCountryCode.Eg]: "Egypt",
  [RedoShopifyCountryCode.Eh]: "Western Sahara",
  [RedoShopifyCountryCode.Er]: "Eritrea",
  [RedoShopifyCountryCode.Es]: "Spain",
  [RedoShopifyCountryCode.Et]: "Ethiopia",
  [RedoShopifyCountryCode.Fi]: "Finland",
  [RedoShopifyCountryCode.Fj]: "Fiji",
  [RedoShopifyCountryCode.Fk]: "Falkland Islands",
  [RedoShopifyCountryCode.Fo]: "Faroe Islands",
  [RedoShopifyCountryCode.Fr]: "France",
  [RedoShopifyCountryCode.Ga]: "Gabon",
  [RedoShopifyCountryCode.Gb]: "United Kingdom",
  [RedoShopifyCountryCode.Gd]: "Grenada",
  [RedoShopifyCountryCode.Ge]: "Georgia",
  [RedoShopifyCountryCode.Gf]: "French Guiana",
  [RedoShopifyCountryCode.Gg]: "Guernsey",
  [RedoShopifyCountryCode.Gh]: "Ghana",
  [RedoShopifyCountryCode.Gi]: "Gibraltar",
  [RedoShopifyCountryCode.Gl]: "Greenland",
  [RedoShopifyCountryCode.Gm]: "Gambia",
  [RedoShopifyCountryCode.Gn]: "Guinea",
  [RedoShopifyCountryCode.Gp]: "Guadeloupe",
  [RedoShopifyCountryCode.Gq]: "Equatorial Guinea",
  [RedoShopifyCountryCode.Gr]: "Greece",
  [RedoShopifyCountryCode.Gs]: "South Georgia & South Sandwich Islands",
  [RedoShopifyCountryCode.Gt]: "Guatemala",
  [RedoShopifyCountryCode.Gw]: "Guinea-Bissau",
  [RedoShopifyCountryCode.Gy]: "Guyana",
  [RedoShopifyCountryCode.Hk]: "Hong Kong SAR",
  [RedoShopifyCountryCode.Hm]: "Heard & McDonald Islands",
  [RedoShopifyCountryCode.Hn]: "Honduras",
  [RedoShopifyCountryCode.Hr]: "Croatia",
  [RedoShopifyCountryCode.Ht]: "Haiti",
  [RedoShopifyCountryCode.Hu]: "Hungary",
  [RedoShopifyCountryCode.Id]: "Indonesia",
  [RedoShopifyCountryCode.Ie]: "Ireland",
  [RedoShopifyCountryCode.Il]: "Israel",
  [RedoShopifyCountryCode.Im]: "Isle of Man",
  [RedoShopifyCountryCode.In]: "India",
  [RedoShopifyCountryCode.Io]: "British Indian Ocean Territory",
  [RedoShopifyCountryCode.Iq]: "Iraq",
  [RedoShopifyCountryCode.Ir]: "Iran",
  [RedoShopifyCountryCode.Is]: "Iceland",
  [RedoShopifyCountryCode.It]: "Italy",
  [RedoShopifyCountryCode.Je]: "Jersey",
  [RedoShopifyCountryCode.Jm]: "Jamaica",
  [RedoShopifyCountryCode.Jo]: "Jordan",
  [RedoShopifyCountryCode.Jp]: "Japan",
  [RedoShopifyCountryCode.Ke]: "Kenya",
  [RedoShopifyCountryCode.Kg]: "Kyrgyzstan",
  [RedoShopifyCountryCode.Kh]: "Cambodia",
  [RedoShopifyCountryCode.Ki]: "Kiribati",
  [RedoShopifyCountryCode.Km]: "Comoros",
  [RedoShopifyCountryCode.Kn]: "St. Kitts & Nevis",
  [RedoShopifyCountryCode.Kp]: "North Korea",
  [RedoShopifyCountryCode.Kr]: "South Korea",
  [RedoShopifyCountryCode.Kw]: "Kuwait",
  [RedoShopifyCountryCode.Ky]: "Cayman Islands",
  [RedoShopifyCountryCode.Kz]: "Kazakhstan",
  [RedoShopifyCountryCode.La]: "Laos",
  [RedoShopifyCountryCode.Lb]: "Lebanon",
  [RedoShopifyCountryCode.Lc]: "St. Lucia",
  [RedoShopifyCountryCode.Li]: "Liechtenstein",
  [RedoShopifyCountryCode.Lk]: "Sri Lanka",
  [RedoShopifyCountryCode.Lr]: "Liberia",
  [RedoShopifyCountryCode.Ls]: "Lesotho",
  [RedoShopifyCountryCode.Lt]: "Lithuania",
  [RedoShopifyCountryCode.Lu]: "Luxembourg",
  [RedoShopifyCountryCode.Lv]: "Latvia",
  [RedoShopifyCountryCode.Ly]: "Libya",
  [RedoShopifyCountryCode.Ma]: "Morocco",
  [RedoShopifyCountryCode.Mc]: "Monaco",
  [RedoShopifyCountryCode.Md]: "Moldova",
  [RedoShopifyCountryCode.Me]: "Montenegro",
  [RedoShopifyCountryCode.Mf]: "St. Martin",
  [RedoShopifyCountryCode.Mg]: "Madagascar",
  [RedoShopifyCountryCode.Mk]: "North Macedonia",
  [RedoShopifyCountryCode.Ml]: "Mali",
  [RedoShopifyCountryCode.Mm]: "Myanmar (Burma)",
  [RedoShopifyCountryCode.Mn]: "Mongolia",
  [RedoShopifyCountryCode.Mo]: "Macao SAR",
  [RedoShopifyCountryCode.Mq]: "Martinique",
  [RedoShopifyCountryCode.Mr]: "Mauritania",
  [RedoShopifyCountryCode.Ms]: "Montserrat",
  [RedoShopifyCountryCode.Mt]: "Malta",
  [RedoShopifyCountryCode.Mu]: "Mauritius",
  [RedoShopifyCountryCode.Mv]: "Maldives",
  [RedoShopifyCountryCode.Mw]: "Malawi",
  [RedoShopifyCountryCode.Mx]: "Mexico",
  [RedoShopifyCountryCode.My]: "Malaysia",
  [RedoShopifyCountryCode.Mz]: "Mozambique",
  [RedoShopifyCountryCode.Na]: "Namibia",
  [RedoShopifyCountryCode.Nc]: "New Caledonia",
  [RedoShopifyCountryCode.Ne]: "Niger",
  [RedoShopifyCountryCode.Nf]: "Norfolk Island",
  [RedoShopifyCountryCode.Ng]: "Nigeria",
  [RedoShopifyCountryCode.Ni]: "Nicaragua",
  [RedoShopifyCountryCode.Nl]: "Netherlands",
  [RedoShopifyCountryCode.No]: "Norway",
  [RedoShopifyCountryCode.Np]: "Nepal",
  [RedoShopifyCountryCode.Nr]: "Nauru",
  [RedoShopifyCountryCode.Nu]: "Niue",
  [RedoShopifyCountryCode.Nz]: "New Zealand",
  [RedoShopifyCountryCode.Om]: "Oman",
  [RedoShopifyCountryCode.Pa]: "Panama",
  [RedoShopifyCountryCode.Pe]: "Peru",
  [RedoShopifyCountryCode.Pf]: "French Polynesia",
  [RedoShopifyCountryCode.Pg]: "Papua New Guinea",
  [RedoShopifyCountryCode.Ph]: "Philippines",
  [RedoShopifyCountryCode.Pk]: "Pakistan",
  [RedoShopifyCountryCode.Pl]: "Poland",
  [RedoShopifyCountryCode.Pm]: "St. Pierre & Miquelon",
  [RedoShopifyCountryCode.Pn]: "Pitcairn Islands",
  [RedoShopifyCountryCode.Ps]: "Palestinian Territories",
  [RedoShopifyCountryCode.Pt]: "Portugal",
  [RedoShopifyCountryCode.Py]: "Paraguay",
  [RedoShopifyCountryCode.Qa]: "Qatar",
  [RedoShopifyCountryCode.Re]: "Réunion",
  [RedoShopifyCountryCode.Ro]: "Romania",
  [RedoShopifyCountryCode.Rs]: "Serbia",
  [RedoShopifyCountryCode.Ru]: "Russia",
  [RedoShopifyCountryCode.Rw]: "Rwanda",
  [RedoShopifyCountryCode.Sa]: "Saudi Arabia",
  [RedoShopifyCountryCode.Sb]: "Solomon Islands",
  [RedoShopifyCountryCode.Sc]: "Seychelles",
  [RedoShopifyCountryCode.Sd]: "Sudan",
  [RedoShopifyCountryCode.Se]: "Sweden",
  [RedoShopifyCountryCode.Sg]: "Singapore",
  [RedoShopifyCountryCode.Sh]: "St. Helena",
  [RedoShopifyCountryCode.Si]: "Slovenia",
  [RedoShopifyCountryCode.Sj]: "Svalbard & Jan Mayen",
  [RedoShopifyCountryCode.Sk]: "Slovakia",
  [RedoShopifyCountryCode.Sl]: "Sierra Leone",
  [RedoShopifyCountryCode.Sm]: "San Marino",
  [RedoShopifyCountryCode.Sn]: "Senegal",
  [RedoShopifyCountryCode.So]: "Somalia",
  [RedoShopifyCountryCode.Sr]: "Suriname",
  [RedoShopifyCountryCode.Ss]: "South Sudan",
  [RedoShopifyCountryCode.St]: "São Tomé & Príncipe",
  [RedoShopifyCountryCode.Sv]: "El Salvador",
  [RedoShopifyCountryCode.Sx]: "Sint Maarten",
  [RedoShopifyCountryCode.Sy]: "Syria",
  [RedoShopifyCountryCode.Sz]: "Eswatini",
  [RedoShopifyCountryCode.Ta]: "Tristan da Cunha",
  [RedoShopifyCountryCode.Tc]: "Turks & Caicos Islands",
  [RedoShopifyCountryCode.Td]: "Chad",
  [RedoShopifyCountryCode.Tf]: "French Southern Territories",
  [RedoShopifyCountryCode.Tg]: "Togo",
  [RedoShopifyCountryCode.Th]: "Thailand",
  [RedoShopifyCountryCode.Tj]: "Tajikistan",
  [RedoShopifyCountryCode.Tk]: "Tokelau",
  [RedoShopifyCountryCode.Tl]: "Timor-Leste",
  [RedoShopifyCountryCode.Tm]: "Turkmenistan",
  [RedoShopifyCountryCode.Tn]: "Tunisia",
  [RedoShopifyCountryCode.To]: "Tonga",
  [RedoShopifyCountryCode.Tr]: "Türkiye",
  [RedoShopifyCountryCode.Tt]: "Trinidad & Tobago",
  [RedoShopifyCountryCode.Tv]: "Tuvalu",
  [RedoShopifyCountryCode.Tw]: "Taiwan",
  [RedoShopifyCountryCode.Tz]: "Tanzania",
  [RedoShopifyCountryCode.Ua]: "Ukraine",
  [RedoShopifyCountryCode.Ug]: "Uganda",
  [RedoShopifyCountryCode.Um]: "U.S. Outlying Islands",
  [RedoShopifyCountryCode.Us]: "United States",
  [RedoShopifyCountryCode.Uy]: "Uruguay",
  [RedoShopifyCountryCode.Uz]: "Uzbekistan",
  [RedoShopifyCountryCode.Va]: "Vatican City",
  [RedoShopifyCountryCode.Vc]: "St. Vincent & Grenadines",
  [RedoShopifyCountryCode.Ve]: "Venezuela",
  [RedoShopifyCountryCode.Vg]: "British Virgin Islands",
  [RedoShopifyCountryCode.Vn]: "Vietnam",
  [RedoShopifyCountryCode.Vu]: "Vanuatu",
  [RedoShopifyCountryCode.Wf]: "Wallis & Futuna",
  [RedoShopifyCountryCode.Ws]: "Samoa",
  [RedoShopifyCountryCode.Xk]: "Kosovo",
  [RedoShopifyCountryCode.Ye]: "Yemen",
  [RedoShopifyCountryCode.Yt]: "Mayotte",
  [RedoShopifyCountryCode.Za]: "South Africa",
  [RedoShopifyCountryCode.Zm]: "Zambia",
  [RedoShopifyCountryCode.Zw]: "Zimbabwe",
  [RedoShopifyCountryCode.Zz]: "Unknown Region",
};

export function isRedoShopifyCountryCode(
  countryCode: string,
): countryCode is RedoShopifyCountryCode {
  return Object.values(RedoShopifyCountryCode).includes(
    countryCode as RedoShopifyCountryCode,
  );
}

export function getCountryNameFromCode(
  countryCode: RedoShopifyCountryCode,
): string {
  return RedoShopifyCountryNames?.[countryCode] ?? countryCode;
}
