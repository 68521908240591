import { LoadState } from "@redotech/react-util/load";
import CheckCircleIcon from "@redotech/redo-web/arbiter-icon/check-circle_filled.svg";
import RefreshIcon from "@redotech/redo-web/arbiter-icon/refresh-cw-04_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { isDef } from "@redotech/util/checks";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import * as css from "./save-indicator.module.css";

export const SaveIndicator = memo(function SaveIndicator({
  isSaving,
  displayTextOnSaveSuccess,
}: {
  isSaving: boolean;
  displayTextOnSaveSuccess?: string;
}) {
  const SAVE_STATE_DURATION = 3000;

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(
    undefined,
  );
  const [previousIsSaving, setPreviousIsSaving] = useState(isSaving);
  const [saveState, setSaveState] = useState<SaveIndicatorState>(
    SaveIndicatorState.HIDDEN,
  );

  useEffect(() => {
    if (isSaving && !previousIsSaving) {
      setSaveState(SaveIndicatorState.SAVING);
      setPreviousIsSaving(isSaving);
      clearTimeout(timeoutId);
      setTimeoutId(undefined);
    } else if (!isSaving && previousIsSaving) {
      setSaveState(SaveIndicatorState.SAVED);
      setPreviousIsSaving(isSaving);
      setTimeoutId(
        setTimeout(() => {
          setSaveState(SaveIndicatorState.HIDDEN);
        }, SAVE_STATE_DURATION),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  return (
    <SaveIndicatorComponent
      displayTextOnSaveSuccess={displayTextOnSaveSuccess}
      state={saveState}
    />
  );
});

enum SaveIndicatorState {
  HIDDEN = "HIDDEN",
  SAVING = "SAVING",
  SAVED = "SAVED",
}

const SaveIndicatorComponent = memo(function SaveIndicator({
  state,
  displayTextOnSaveSuccess,
}: {
  state: SaveIndicatorState;
  displayTextOnSaveSuccess: string | undefined;
}) {
  switch (state) {
    case SaveIndicatorState.HIDDEN:
      return null;
    case SaveIndicatorState.SAVING:
      return <RefreshIcon className={css.savingIndicator} />;
    case SaveIndicatorState.SAVED:
      return (
        <Flex className={css.savedIndicator} gap="sm">
          <CheckCircleIcon className={css.icon} />
          {displayTextOnSaveSuccess && (
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              Saved
            </Text>
          )}
        </Flex>
      );
  }
});

/**
 * Handles debounced saving of a value without disrupting the ability of the
 * user to update the value locally.
 */
export function useAutoSave<T>({
  setIsSaving,
  isValidValueToSave,
  equals = (a, b) => a === b,
  debounceMs = 600,
  onSave,
  savedValueLoad,
  defaultValue,
}: {
  setIsSaving: (isSaving: boolean) => void;
  isValidValueToSave: (value: T) => boolean;
  equals?: (a: T, b: T) => boolean;
  debounceMs?: number;
  onSave: (newValue: T) => Promise<void>;
  savedValueLoad: LoadState<T | undefined>;
  defaultValue: T;
}) {
  const [savedValue, setSavedValue] = useState<T>(defaultValue);
  const [newValue, setNewValue] = useState<T>(defaultValue);
  const debouncedNewValue = useDebounce(newValue, debounceMs);

  useEffect(() => {
    if (isDef(savedValueLoad.value)) {
      setSavedValue(savedValueLoad.value);
      setNewValue(savedValueLoad.value);
    }
  }, [savedValueLoad.value]);

  useEffect(() => {
    if (
      !savedValueLoad.pending &&
      isValidValueToSave(debouncedNewValue) &&
      !equals(debouncedNewValue, savedValue)
    ) {
      setIsSaving(true);
      void onSave(debouncedNewValue)
        .then(() => {
          setSavedValue(debouncedNewValue);
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNewValue]);

  return { value: newValue, setValue: setNewValue };
}
