import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getSupportAiPricing = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.object({
    monthlyOrders: z.number(),
    platformPrice: z.number(),
    aiPrice: z.number(),
    aiResolutionPriceInCents: z.number(),
  }),
};
