import { useRequiredContext } from "@redotech/react-util/context";
import {
  DEFAULT_TEXT,
  EWWidgetTextProps,
  ExtendedWarrantyBlurbType,
  ExtendedWarrantyDataTarget,
  ExtendedWarrantyPdpNoCoverageType,
  ExtendedWarrantyTilesSize,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { OfferingTiles } from "@redotech/redo-web/expanded-warranties/offering-tiles";
import { Flex, FlexProps } from "@redotech/redo-web/flex";
import { memo, useMemo } from "react";
import { LearnMoreLink } from "../learn-more-link";
import { EwSettingsContext } from "../provider/ew-settings-provider";
import { ExtendedWarrantyBlurb } from "./blurb";

export const ExtendedWarrantyTilesModal = memo(
  function ExtendedWarrantyTilesModal({
    customCss,
    offerings,
    selectedOffering,
    onTileClick,
    onLearnMoreClick,
    text,
    foundSelector,
  }: {
    customCss?: string;
    offerings: Offering[];
    selectedOffering?: Offering;
    onTileClick: (offering: Offering) => void;
    onLearnMoreClick: () => void;
    text: EWWidgetTextProps;
    foundSelector?: string;
  }) {
    const { getWidgetConfig } = useRequiredContext(EwSettingsContext);

    const widgetConfig = useMemo(() => {
      if (!getWidgetConfig) return undefined;

      return getWidgetConfig();
    }, [getWidgetConfig]);

    const view = useMemo(() => {
      if (!widgetConfig) return undefined;

      return {
        noCoverageLabel:
          widgetConfig.pdpCoverageDisabledLabel ||
          DEFAULT_TEXT.pdpCoverageDisabledLabel,
        pdpNoCoverageType:
          widgetConfig.pdpNoCoverageType ||
          ExtendedWarrantyPdpNoCoverageType.BASIC,
        pdpNoCoveragePrice:
          widgetConfig.pdpNoCoveragePrice || DEFAULT_TEXT.pdpNoCoveragePrice,
        pdpLabel: widgetConfig.pdpLabel || DEFAULT_TEXT.pdpLabel,
      };
    }, [widgetConfig]);

    const pdpBlurbType =
      widgetConfig?.pdpBlurbType || ExtendedWarrantyBlurbType.REGULAR;
    const pdpTilesSize =
      widgetConfig?.pdpTilesSize || ExtendedWarrantyTilesSize.REGULAR;

    let backdropProps: FlexProps = {};

    if (widgetConfig?.pdpTilesBackdropEnabled) {
      backdropProps = {
        className: warrantyStyles.pdpTilesBackground,
        p: "xl",
        gap: "xs",
        radius: "sm",
      };
    }

    if (!widgetConfig || !view) return null;

    return (
      <>
        {customCss && <style>{customCss}</style>}
        {foundSelector && <meta found-selector={foundSelector} />}
        <Flex flexDirection="column" {...backdropProps}>
          <ExtendedWarrantyBlurb
            onInfoClick={onLearnMoreClick}
            showIcon
            text={text}
            type={pdpBlurbType}
          />
          <OfferingTiles
            offerings={offerings}
            onTileClick={onTileClick}
            selectedOffering={selectedOffering}
            tilesSize={pdpTilesSize}
            view={view}
          />
          {pdpBlurbType !== ExtendedWarrantyBlurbType.COMPACT && (
            <Flex
              data-target={ExtendedWarrantyDataTarget.PDP_LEARN_MORE_CONTAINER}
              pl="xxs"
            >
              <LearnMoreLink onClick={onLearnMoreClick} />
            </Flex>
          )}
        </Flex>
      </>
    );
  },
);
