import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const startHalfRedoCheckoutAbTest = {
  input: z.object({
    teamId: zExt.objectId(),
    checkoutExperienceId: zExt.objectId(),
  }),
  output: z.null(),
};
