// extracted by mini-css-extract-plugin
export var actions = "_g-0";
export var actionsContainer = "_g-4";
export var border = "_g-1";
export var childFullHeight = "_g-b";
export var collapse = "_g-2";
export var content = "_g-6";
export var contentChild = "_g-a";
export var fullscreen = "_g-8";
export var hidePadding = "_g-7";
export var noScrollX = "_g-c";
export var noScrollY = "_g-d";
export var optOutTopPadding = "_g-9";
export var pagePadding = "_g-5";
export var profile = "_g-e";
export var profileContainer = "_g-f";
export var profileImage = "_g-g";
export var profileMain = "_g-h";
export var profileSubtitle = "_g-j";
export var profileTitle = "_g-i";
export var tabsWrapper = "_g-3";
export var title = "_g-k";