import { JSXElementConstructor, memo } from "react";
import DotsSpinner from "../../spinner-dots.svg";
import LineSpinner from "../../spinner-line.svg";
import TrackSpinner from "../../spinner-track.svg";

export const redoSpinnerSize = ["xs", "sm", "md", "lg", "xl"] as const;
export type RedoSpinnerSize = (typeof redoSpinnerSize)[number];

export const redoSpinnerStyle = ["track", "line", "dot"] as const;
export type RedoSpinnerStyle = (typeof redoSpinnerStyle)[number];

export const RedoSpinner = memo(function RedoSpinner({
  size = "sm",
  style = "track",
}: {
  size?: RedoSpinnerSize;
  style?: RedoSpinnerStyle;
}) {
  const SvgElement = styleToSvg[style];

  return (
    <SvgElement
      height={spinnerSizeToBoxSize[size]}
      width={spinnerSizeToBoxSize[size]}
    />
  );
});

const styleToSvg: Record<RedoSpinnerStyle, JSXElementConstructor<any>> = {
  track: TrackSpinner,
  line: LineSpinner,
  dot: DotsSpinner,
};

const spinnerSizeToBoxSize: Record<RedoSpinnerSize, number> = {
  xs: 20,
  sm: 28,
  md: 48,
  lg: 56,
  xl: 64,
};
