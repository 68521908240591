import { useSkipAnimations } from "@redotech/react-animation/transition";
import { Input } from "@redotech/ui/form";
import * as classnames from "classnames";
import { ReactNode, memo } from "react";
import * as checkboxCss from "./checkbox.module.css";
import CheckIcon from "./icon-old/check.svg";
import MinusIcon from "./icon-old/minus.svg";

export enum CheckboxTheme {
  NORMAL = 0,
  GHOST = 1,
}

const checkboxThemeClasses = new Map<CheckboxTheme, string>();
checkboxThemeClasses.set(CheckboxTheme.NORMAL, checkboxCss.normal);
checkboxThemeClasses.set(CheckboxTheme.GHOST, checkboxCss.ghost);

/**
 * @deprecated use RedoCheckboxGroup instead
 */
export const CheckboxGroup = memo(function CheckboxGroup({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  return <div className={checkboxCss.checkboxGroup}>{children}</div>;
});

/**
 * @deprecated use RedoCheckbox
 */
export const Checkbox = memo(function Checkbox({
  children,
  disabled,
  theme = CheckboxTheme.NORMAL,
  value,
  onChange,
  clickStopPropagation = false,
  id,
  indeterminate,
}: {
  children?: ReactNode;
  disabled?: boolean;
  theme?: CheckboxTheme;
  value: boolean;
  onChange?(value: boolean): void;
  clickStopPropagation?: boolean;
  id?: string;
  indeterminate?: boolean;
}) {
  const skipAnimations = useSkipAnimations();

  return (
    <label
      className={classnames(
        checkboxCss.checkboxButton,
        checkboxThemeClasses.get(theme),
        skipAnimations,
        { [checkboxCss.disabled]: disabled },
      )}
      id={id}
      onClick={(event) => clickStopPropagation && event.stopPropagation()}
    >
      <input
        checked={value}
        className={checkboxCss.input}
        disabled={disabled}
        onChange={(event) => onChange && onChange(event.target.checked)}
        type="checkbox"
      />
      <div className={checkboxCss.box}>
        {indeterminate ? (
          <MinusIcon />
        ) : (
          <CheckIcon className={checkboxCss.check} />
        )}
      </div>
      {children && <div className={checkboxCss.label}>{children}</div>}
    </label>
  );
});

/**
 * @deprecated use RedoCheckbox
 */
export const FormCheckbox = memo(function FormCheckbox({
  children,
  disabled,
  theme = CheckboxTheme.NORMAL,
  input,
}: {
  children?: ReactNode;
  disabled?: boolean;
  theme?: CheckboxTheme;
  input: Input<boolean>;
}) {
  return (
    <Checkbox
      disabled={disabled}
      onChange={input.setValue}
      theme={theme}
      value={input.value}
    >
      {children}
    </Checkbox>
  );
});
