import { PopperPlacementType } from "@mui/base/Popper";
import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { EnterKey } from "@redotech/web-util/key";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { Dropdown } from "../../dropdown";
import { Flex } from "../../flex";
import { CheckboxValue } from "../checkbox/redo-checkbox";
import { BaseRedoInput } from "../input/base-redo-text-input";
import { RedoDropdownInputSize } from "../select-dropdown/redo-single-select-dropdown-input";
import { RedoList, RedoListItem, RedoListProps } from "./redo-list";
import {
  dropdownSizeToListSize,
  listItemSizeToInputSize,
} from "./redo-list-dropdown";
import { TextRedoListItem } from "./standard-redo-list-items";

export const RedoMultiselectDropdown = genericMemo(
  function RedoMultiselectDropdown<T>({
    dropdownOpen,
    setDropdownOpen,
    dropdownAnchor,
    fitToAnchor = false,
    dropdownPlacement,
    searchString,
    setSearchString,
    searchSubmitted,
    searchPlaceholder,
    size = "xs",
    selectionVariant,
    selectAllState,
    selectAllToggled,
    selectedItems,
    setSelectedItems,
    closeOnSelection,
    items,
    staticItems,
    staticItemSelected,
    requireNItems: requireNItems,
    ...redoListProps
  }: {
    closeOnSelection?: boolean;
    selectedItems: RedoListItem<T>[];
    setSelectedItems: (value: RedoListItem<T>[]) => void;
    searchString?: string;
    setSearchString?: (search: string) => void;
    searchSubmitted?: (searchString: string) => void;
    selectAllToggled?: (value: boolean) => void;
    selectAllState?: CheckboxValue;
    searchPlaceholder?: string;
    dropdownOpen: boolean;
    setDropdownOpen: (open: boolean) => void;
    dropdownAnchor: HTMLElement | null;
    fitToAnchor?: boolean;
    dropdownPlacement?: PopperPlacementType;
    size?: RedoDropdownInputSize;
    staticItems?: RedoListItem<void>[];
    staticItemSelected?: (item: RedoListItem<void>) => void;
    requireNItems?: number;
  } & Omit<
    RedoListProps<T>,
    | "focusedIndex"
    | "setFocusedIndex"
    | "refToListenTo"
    | "itemSelected"
    | "selectedItems"
    | "size"
  >) {
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
      undefined,
    );

    selectAllState = selectAllState ?? false;

    const [searchRef, setSearchRef] = useState<HTMLDivElement | null>(null);

    const selectedItemIds = useMemo(
      () => selectedItems.map((i) => i.id),
      [selectedItems],
    );

    const handleItemClicked = useHandler(
      ({
        item,
        selected,
      }: {
        item: RedoListItem<T | void>;
        selected: boolean;
      }) => {
        if (staticItems) {
          const staticItem = staticItems.find((i) => i.id === item.id);
          if (staticItem && staticItemSelected) {
            staticItemSelected(staticItem);
            return;
          }
        }

        if (selected) {
          setSelectedItems([...selectedItems, item as RedoListItem<T>]);
        } else {
          if (requireNItems && selectedItems.length === requireNItems) {
            return;
          }
          setSelectedItems(
            selectedItems.filter((selectedItem) => selectedItem.id !== item.id),
          );
        }
        if (closeOnSelection) {
          setDropdownOpen(false);
        }
      },
    );

    const [clickAwayEvent, setClickAwayEvent] = useState<undefined | false>(
      undefined,
    );

    const maybeHandleSearchSubmit = useHandler((e: KeyboardEvent) => {
      if (
        searchSubmitted &&
        searchString &&
        e.key === EnterKey &&
        focusedIndex === undefined
      ) {
        e.preventDefault();
        e.stopPropagation();
        searchSubmitted(searchString);
      }
    });

    // We need to delay the click event changing by a render cycle
    // to prevent the click that opened it from immediately closing it
    useEffect(() => {
      if (dropdownOpen) {
        setClickAwayEvent(undefined);
      } else {
        setClickAwayEvent(false);
      }
    }, [dropdownOpen]);

    const itemsWithStatic: RedoListItem<T | void>[] = useMemo(() => {
      if (!staticItems) {
        return items;
      }
      return [...items, ...staticItems];
    }, [items, staticItems]);

    return (
      <ClickAwayListener
        mouseEvent={clickAwayEvent}
        onClickAway={() => {
          setDropdownOpen(false);
        }}
        touchEvent={clickAwayEvent}
      >
        <Dropdown
          anchor={dropdownAnchor}
          constrainHeight
          fitToAnchor={fitToAnchor}
          flexProps={{ p: "none" }}
          open={dropdownOpen}
          placement={dropdownPlacement}
        >
          <Flex dir="column" gap="none">
            {selectAllToggled && (
              <Flex
                borderBottomWidth="1px"
                borderColor="secondary"
                borderStyle="solid"
                px="lg"
                py="sm"
              >
                <TextRedoListItem
                  indeterminate={selectAllState === "indeterminate"}
                  onItemSelected={selectAllToggled}
                  selected={selectAllState === true}
                  selectionVariant={selectionVariant}
                  size={dropdownSizeToListSize[size]}
                  text="Select all"
                />
              </Flex>
            )}
            {setSearchString && (
              <Flex
                borderBottomWidth="1px"
                borderColor="primary"
                borderStyle="solid"
                px="lg"
                py="md"
              >
                <BaseRedoInput
                  autoFocus
                  onKeyDown={maybeHandleSearchSubmit}
                  placeholder={searchPlaceholder || "Search items..."}
                  ref={setSearchRef}
                  setValue={setSearchString}
                  size={listItemSizeToInputSize[size]}
                  value={searchString || ""}
                />
              </Flex>
            )}
            <Flex px="lg" py="md">
              <RedoList
                focusedIndex={focusedIndex}
                items={itemsWithStatic}
                itemSelected={handleItemClicked}
                refToListenTo={searchRef || dropdownAnchor}
                selectedItems={selectedItemIds}
                selectionVariant={selectionVariant}
                setFocusedIndex={setFocusedIndex}
                size={dropdownSizeToListSize[size]}
                {...redoListProps}
              />
            </Flex>
          </Flex>
        </Dropdown>
      </ClickAwayListener>
    );
  },
);
