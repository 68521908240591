import type { RedoShopifyRpcClient } from "@redotech/shopify-sdk/rpc/client";
import { createRedoShopifyRpcClient } from "@redotech/shopify-sdk/rpc/client";
import {
  STORE_URL_HEADER,
  WIDGET_ID_HEADER,
} from "@redotech/shopify-sdk/rpc/custom-http-headers";
import { Context, createContext, memo, ReactNode, useMemo } from "react";

export const RedoShopifyRpcClientContext: Context<
  RedoShopifyRpcClient | undefined
> = createContext<RedoShopifyRpcClient | undefined>(undefined);

export const RedoShopifyRpcClientContextProvider = memo(
  function RedoShopifyRpcClientContextProvider({
    children,
    baseUrl,
    authToken,
    widgetId,
    storeUrl,
  }: {
    children: ReactNode | ReactNode[];
    baseUrl?: string | undefined;
    authToken?: string | undefined;
    widgetId?: string | undefined;
    storeUrl?: string | undefined;
  }) {
    const client = useMemo(() => {
      if (!baseUrl) {
        return undefined;
      }

      const authHeaders: Record<string, string> = authToken
        ? { Authorization: `Bearer ${authToken}` }
        : {};
      const widgetHeaders: Record<string, string> = widgetId
        ? { [WIDGET_ID_HEADER]: widgetId }
        : {};
      const storeUrlHeaders: Record<string, string> = storeUrl
        ? { [STORE_URL_HEADER]: storeUrl }
        : {};
      return createRedoShopifyRpcClient({
        baseURL: new URL(`${baseUrl}/rpc`),
        headers: { ...widgetHeaders, ...storeUrlHeaders, ...authHeaders },
      });
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseUrl, authToken]);

    return client ? (
      <RedoShopifyRpcClientContext.Provider value={client}>
        {children}
      </RedoShopifyRpcClientContext.Provider>
    ) : null;
  },
);
