import {
  AmpClient,
  AmpRegistrationStatus,
} from "@redotech/redo-model/amp-registration-definition";
import { z } from "zod";

export const sendDemoAmpEmailsForTeam = {
  input: z.object({ teamId: z.string() }),
  output: z.object({
    [AmpClient.GMAIL]: z.union([
      z.nativeEnum(AmpRegistrationStatus),
      z.undefined(),
    ]),
    [AmpClient.YAHOO]: z.union([
      z.nativeEnum(AmpRegistrationStatus),
      z.undefined(),
    ]),
  }),
};
