import { OverflowDirection, useOverflow } from "@redotech/react-util/overflow";
import { memo } from "react";
import { OptionalTooltip } from "./optional-tooltip";
import { RedoTooltipProps } from "./tooltip";

interface OverflowTooltipProps {
  children: React.ReactElement;
  overflowRef: HTMLElement | null;
  tooltipProps: Omit<RedoTooltipProps, "children">;
  wrapperClass?: string;
  wrapperStyle?: React.CSSProperties;
  direction?: OverflowDirection;
}

export const OverflowTooltip = memo(function OverflowTooltip({
  overflowRef,
  children,
  tooltipProps,
  wrapperClass,
  wrapperStyle,
  direction = OverflowDirection.Horizontal,
}: OverflowTooltipProps): React.ReactElement {
  const isOverflowing = useOverflow(overflowRef, direction);
  return (
    <OptionalTooltip
      meetsCondition={isOverflowing}
      tooltipProps={tooltipProps}
      wrapperClass={wrapperClass}
      wrapperStyle={wrapperStyle}
    >
      {children}
    </OptionalTooltip>
  );
});
