import styled from "@mui/material/styles/styled";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { memo } from "react";
import AlertDangerIcon from "./icon-old/alert-danger.svg";
import AlertSuccessIcon from "./icon-old/alert-success.svg";

const StyledContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent": {
    backgroundColor: "white",
    fontWeight: 600,
    fontSize: "var(--redo-body-small-text-size, 14px)",
    color: "var(--redo-color-text-secondary, #424242)",
    borderRadius: "var(--redo-corner-medium-radius, 12px)",
    display: "flex",
    minHeight: "48px",
    width: "calc(100vw - 32px)",
    maxWidth: "640px",
    padding: "var(--redo-spacing, 8px)",
    boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
    border: "1px solid var(--redo-border-color-dark, #d6d6d6)",
  },
  "&.notistack-MuiContent #notistack-snackbar": { padding: 0, flex: 1 },
  "&.notistack-MuiContent #notistack-snackbar svg": {
    marginRight: "var(--redo-spacing, 8px)",
    minWidth: "fit-content",
  },
  "&.notistack-MuiContent div:last-child": {
    paddingRight: "var(--redo-spacing, 8px)",
  },
  "&.notistack-MuiContent div:last-child > button > svg": {
    fill: "#a3a3a3",
    marginLeft: "auto",
    height: "var(--redo-icon-medium-size)",
    width: "var(--redo-icon-medium-size)",
  },
}));

export const CustomSnackbarProvider = memo(
  function CustomSnackbarProvider(props: { children: React.ReactNode }) {
    return (
      <SnackbarProvider
        Components={{
          default: StyledContent,
          success: StyledContent,
          error: StyledContent,
        }}
        iconVariant={{
          success: <AlertSuccessIcon />,
          error: <AlertDangerIcon />,
        }}
      >
        {props.children}
      </SnackbarProvider>
    );
  },
);
