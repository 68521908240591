// extracted by mini-css-extract-plugin
export var bubble = "_15-0";
export var container = "_15-3";
export var contrastBorder = "_15-4";
export var greyOut = "_15-2";
export var initialsWrapper = "_15-1";
export var large = "_15-9";
export var medium = "_15-8";
export var mediumSmall = "_15-7";
export var micro = "_15-5";
export var small = "_15-6";
export var tiny = "_15-b";
export var xTiny = "_15-a";