import { Currency, getCurrencySymbol } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { RegistrationRewardType } from "@redotech/redo-model/extended-warranty";
import { getResource } from "@redotech/redo-model/localization/resource";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Card } from "@redotech/redo-web/card";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput, LabelTheme } from "@redotech/redo-web/labeled-input";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormSwitch, Switch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  numberValidator,
} from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import classNames from "classnames";
import { memo, SyntheticEvent, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../client/context";
import { getCollections } from "../../../client/team";
import { TeamContext } from "../../team";
import { CollectionAutocomplete } from "../returns-package-protection/collections-autocomplete";

// TODO: Move to experience design when we want to customize
const registrationRewardTypeToTitle: Record<RegistrationRewardType, string> = {
  [RegistrationRewardType.DISABLED]: "Disabled",
  [RegistrationRewardType.PRODUCT_DISCOUNT]: "Product discount",
  [RegistrationRewardType.EMAIL_DISCOUNT]: "Email discount",
  [RegistrationRewardType.REVIEW_DISCOUNT]: "Review discount",
};

// TODO: Remove each type once they are implemented
const disabledRegistrationRewardTypes = [
  RegistrationRewardType.REVIEW_DISCOUNT,
];

export const repairPricingForm = groupInput(
  {
    minPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
    maxPrice: input<string>({ validator: numberValidator({ min: 0.01 }) }),
    collectionHandle: input<string>(),
    collectionTitle: input<string>(),
  },
  {},
);

export const repairPricingDefault = {
  minPrice: "1",
  maxPrice: "1",
  collectionHandle: "",
  collectionTitle: "",
};

export const warrantiesForm = groupInput(
  {
    enabled: input<boolean>(),
    customersCanStartClaims: input<boolean>(),
    registrationEnabled: input<boolean>(),
    registrationRewardType: input<RegistrationRewardType>(),
    registrationRewardDiscountId: input<string | undefined>(),
    repairFeeEnabled: input<boolean>(),
    labelCoverageEnabled: input<boolean>(),
    portalFourRegistration: input<boolean>(),
    defaultRepairMinPrice: input<string>({
      validator: numberValidator({ min: 0.01 }),
    }),
    defaultRepairMaxPrice: input<string>({
      validator: numberValidator({ min: 0.01 }),
    }),
    repairPricingCustomCollections: input<string[]>({
      equal: arrayEqual(stringEqual),
    }),
    repairPricingRuleSet: listInput(
      () => repairPricingForm,
      () => repairPricingDefault,
      (repairPricing) => repairPricing.collectionHandle,
    ),
    warrantyRegistrationCollection: input<string | undefined>(),
  },
  {},
);

export type WarrantiesForm = InputProvider.Form<typeof warrantiesForm>;

export type WarrantiesValue = InputProvider.Value<typeof warrantiesForm>;

export const warrantiesDefault: WarrantiesValue = {
  enabled: false,
  defaultRepairMaxPrice: "1",
  defaultRepairMinPrice: "1",
  customersCanStartClaims: false,
  registrationEnabled: false,
  registrationRewardType: RegistrationRewardType.DISABLED,
  registrationRewardDiscountId: undefined,
  repairFeeEnabled: false,
  labelCoverageEnabled: false,
  warrantyRegistrationCollection: undefined,
  repairPricingCustomCollections: [],
  repairPricingRuleSet: [],
  portalFourRegistration: false,
};

export const Warranties = memo(function Warranties({
  input,
}: {
  input: WarrantiesForm;
}) {
  const {
    enabled,
    registrationEnabled,
    repairFeeEnabled,
    customersCanStartClaims,
    warrantyRegistrationCollection,
    defaultRepairMaxPrice,
    defaultRepairMinPrice,
    repairPricingCustomCollections,
    repairPricingRuleSet,
    registrationRewardType,
    portalFourRegistration,
  } = input.inputs;

  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];

  const selectedCollection = collections.find(
    (collection) => collection.id === warrantyRegistrationCollection.value,
  );

  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.title
          .toLowerCase()
          .includes(searchString?.toLowerCase() || ""),
      )
    : collections;

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleToggle = useHandler((value: boolean) => {
    enabled.setValue(value);
    if (!value) {
      repairFeeEnabled.setValue(false);
      customersCanStartClaims.setValue(false);
      registrationEnabled.setValue(false);
    }
  });

  const handleValueChange = useHandler(
    (collection: { id: string; title: string; handle: string } | null) => {
      warrantyRegistrationCollection.setValue(collection?.id);
    },
  );

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  const currencyDisplay = getCurrencySymbol(
    team?._shopify.currency || Currency.USD,
  );

  const handleSetCustomCollections = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      repairPricingCustomCollections.setValue(
        collections.map((collection) => collection.handle),
      );
      repairPricingRuleSet.setValue(
        collections.map((collection) => ({
          minPrice: "1",
          maxPrice: "1",
          collectionHandle: collection.handle,
          collectionTitle: collection.title,
        })),
      );
    },
  );

  return (
    <Card title="Warranties">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput
            description="Display the warranty flow to merchants"
            label="Warranty flow"
          >
            <Switch onChange={handleToggle} value={enabled.value} />
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={customersCanStartClaims}
            label="Allows customers to start claims"
          >
            Show the '{getResource({ variant: "warranty" }).PORTAL_BUTTON_TEXT}'
            button on the customer portal.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={registrationEnabled} label="Warranty registration">
            Allow customers to register their warranties and submit claims on
            them.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormSelectDropdown
            input={registrationRewardType}
            label="Registration reward type"
            options={Object.values(RegistrationRewardType).filter(
              (type) => !disabledRegistrationRewardTypes.includes(type),
            )}
          >
            {(option) => registrationRewardTypeToTitle[option]}
          </FormSelectDropdown>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={portalFourRegistration}
            label="Portal 4.0 registration"
          >
            Use the new portal 4.0 registration flow.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput label="Registration collection">
            <Autocomplete
              getLabel={(collection) => collection.title}
              keyFn={(collection) => collection.handle}
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={selectedCollection ?? null}
              valueChange={handleValueChange}
            >
              {(collection) => collection.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={input.inputs.labelCoverageEnabled}
            label="Use return coverage to cover warranty labels"
          >
            Allow return coverage to cover the cost of warranty labels.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>Repair fees</strong>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch input={repairFeeEnabled} label="Repair fees enabled">
            Charge customers a flat rate for repairs.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <strong>Default pricing</strong>
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            input={defaultRepairMinPrice}
            label="Min price"
            labelTheme={LabelTheme.THIN}
            min={0.01}
            prefix={currencyDisplay}
            step={0.01}
            type="number"
          />
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            input={defaultRepairMaxPrice}
            label="Max price"
            labelTheme={LabelTheme.THIN}
            min={0.01}
            prefix={currencyDisplay}
            step={0.01}
            type="number"
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Select the collections that will have a custom repair fee."
            label="Custom collections"
          >
            <CollectionAutocomplete
              keyFn={(collection) => collection.handle}
              setValueCollections={handleSetCustomCollections}
              value={repairPricingCustomCollections.value ?? []}
            />
          </LabeledInput>
        </div>
        {repairPricingRuleSet.inputs?.map((repairPricing, index) => (
          <div className={gridCss.span12} key={index}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <strong>{repairPricing.value.collectionTitle}</strong>
              </div>
              <div className={classNames(gridCss.span12, gridCss.span6L)}>
                <FormTextInput
                  input={repairPricing.inputs.minPrice}
                  label="Min price"
                  labelTheme={LabelTheme.THIN}
                  min={0.01}
                  prefix={currencyDisplay}
                  step={0.01}
                  type="number"
                />
              </div>
              <div className={classNames(gridCss.span12, gridCss.span6L)}>
                <FormTextInput
                  input={repairPricing.inputs.maxPrice}
                  label="Max price"
                  labelTheme={LabelTheme.THIN}
                  min={0.01}
                  prefix={currencyDisplay}
                  step={0.01}
                  type="number"
                />
              </div>
            </div>
          </div>
        ))}
      </section>
    </Card>
  );
});
