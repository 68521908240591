import { boolean, object, string, z } from "zod";

export const ConciergeTreatmentSettingsSchema = object({
  productConciergeEnabled: boolean(),
});

export type ConciergeTreatmentSettings = z.infer<
  typeof ConciergeTreatmentSettingsSchema
>;

export const ValidatedConciergeTreatmentSchema = object({
  id: string(),
  settings: ConciergeTreatmentSettingsSchema,
  weight: z.number(),
});

export type ValidatedConciergeTreatment = z.infer<
  typeof ValidatedConciergeTreatmentSchema
>;
