import { memo } from "react";
import { Text } from "../../text";
import { TextColorValue, TextSizeValue } from "../../theme/typography";
import * as descriptorCss from "./descriptors.module.css";

export type DescriptorSize = Omit<TextSizeValue, "xxs">;

export const LabelText = memo(function LabelText({
  label,
  size = "sm",
  disabled,
}: {
  label?: string;
  size?: TextSizeValue;
  disabled?: boolean;
}) {
  if (!label) {
    return null;
  }

  const labelTextColor: TextColorValue =
    (disabled && "disabled") || "secondary";

  return (
    <Text
      className={descriptorCss.text}
      fontSize={size}
      fontWeight="medium"
      textColor={labelTextColor}
    >
      {label}
    </Text>
  );
});

export const DescriptionText = memo(function DescriptionText({
  description,
  size = "sm",
  error,
  disabled,
}: {
  description?: string;
  size?: TextSizeValue;
  error?: boolean;
  disabled?: boolean;
}) {
  if (!description) {
    return null;
  }

  const descriptionTextColor: TextColorValue =
    (disabled && "disabled") || (error && "error") || "tertiary";

  return (
    <Text
      className={descriptorCss.text}
      fontSize={size}
      fontWeight="regular"
      textColor={descriptionTextColor}
    >
      {description}
    </Text>
  );
});
