import { Card } from "@redotech/redo-web/card";
import { DateInput } from "@redotech/redo-web/date-picker";
import { Flex } from "@redotech/redo-web/flex";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo } from "react";

export const supportGeneralForm = groupInput({
  enabled: input<boolean>(),
  pricePerMonth: input<string>(),
  freeTrialEndDate: input<Date | null>(),
});

export type SupportGeneralForm = InputProvider.Form<typeof supportGeneralForm>;

export type SupportGeneralValue = InputProvider.Value<
  typeof supportGeneralForm
>;

export const supportGeneralDefault: SupportGeneralValue = {
  enabled: false,
  pricePerMonth: "0",
  freeTrialEndDate: null,
};

export const SupportGeneralCard = memo(function SupportGeneralCard({
  input,
}: {
  input: SupportGeneralForm;
}) {
  const { enabled, pricePerMonth, freeTrialEndDate } = input.inputs;

  return (
    <Card title="Support">
      <Flex dir="column">
        <FormSwitch input={enabled} label="Enabled">
          Enable Customer Support product
        </FormSwitch>

        <Text fontSize="md" fontWeight="semibold">
          Free trial end date
        </Text>
        <DateInput
          value={freeTrialEndDate.value}
          valueChange={freeTrialEndDate.setValue}
        />
        <FormTextInput
          input={pricePerMonth}
          label="Subscription fee"
          min={0}
          prefix="$"
          step={0.01}
          type="number"
        />
      </Flex>
    </Card>
  );
});
