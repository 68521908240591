import { UnableToStartHalfRedoCheckoutAbTestReason } from "@redotech/redo-model/checkouts/checkout-ab-test-utils";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const canStartHalfRedoCheckoutAbTest = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.union([
    z.object({ canStart: z.literal(true) }),
    z.object({
      canStart: z.literal(false),
      reason: z.nativeEnum(UnableToStartHalfRedoCheckoutAbTestReason),
    }),
  ]),
};
