import { signupFormSchema } from "@redotech/redo-model/signup-form-schema";
import { z } from "zod";

export const getEmbeddedForms = {
  input: z.object({}),
  output: z.array(
    z.object({
      id: z.string(),
      form: signupFormSchema,
      publishedAt: z.date().nullable(),
    }),
  ),
};
