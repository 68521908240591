import Amazon from "./amazon.png?url";
import Dhl from "./dhl.png?url";
import Fedex from "./fedex.png?url";
import Sendle from "./sendle.png?url";
import Ups from "./ups.png?url";
import Usps from "./usps.png?url";

export function getCarrierLogoUrl(carrier: string): string {
  if (!carrier) {
    return "";
  }
  // TODO (jaden): use svg
  if (carrier.toLowerCase().includes("ups")) {
    return Ups;
  } else if (carrier.toLowerCase().includes("fedex")) {
    return Fedex;
  } else if (carrier.toLowerCase().includes("usps")) {
    return Usps;
  } else if (carrier.toLowerCase().includes("dhl")) {
    return Dhl;
  } else if (carrier.toLowerCase().includes("sendle")) {
    return Sendle;
  } else if (carrier.toLowerCase().includes("amazon")) {
    return Amazon;
  }
  return "";
}
