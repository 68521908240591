export enum Hazmat {
  // FedEx and DHL eCommerce
  PRIMARY_CONTAINED = "PRIMARY_CONTAINED",
  PRIMARY_PACKED = "PRIMARY_PACKED",
  PRIMARY = "PRIMARY",
  SECONDARY_CONTAINED = "SECONDARY_CONTAINED",
  SECONDARY_PACKED = "SECONDARY_PACKED",
  SECONDARY = "SECONDARY",
  ORMD = "ORMD",
  LITHIUM = "LITHIUM",

  // FedEx, DHL eCommerce, and USPS
  LIMITED_QUANTITY = "LIMITED_QUANTITY",

  // USPS
  AIR_ELIGIBLE_ETHANOL = "AIR_ELIGIBLE_ETHANOL",
  CLASS_1 = "CLASS_1",
  CLASS_3 = "CLASS_3",
  CLASS_7 = "CLASS_7",
  CLASS_8_CORROSIVE = "CLASS_8_CORROSIVE",
  CLASS_8_WET_BATTERY = "CLASS_8_WET_BATTERY",
  CLASS_9_NEW_LITHIUM_INDIVIDUAL = "CLASS_9_NEW_LITHIUM_INDIVIDUAL",
  CLASS_9_USED_LITHIUM = "CLASS_9_USED_LITHIUM",
  CLASS_9_NEW_LITHIUM_DEVICE = "CLASS_9_NEW_LITHIUM_DEVICE",
  CLASS_9_DRY_ICE = "CLASS_9_DRY_ICE",
  CLASS_9_UNMARKED_LITHIUM = "CLASS_9_UNMARKED_LITHIUM",
  CLASS_9_MAGNETIZED = "CLASS_9_MAGNETIZED",
  DIVISION_4_1 = "DIVISION_4_1",
  DIVISION_5_1 = "DIVISION_5_1",
  DIVISION_5_2 = "DIVISION_5_2",
  DIVISION_6_1 = "DIVISION_6_1",
  DIVISION_6_2 = "DIVISION_6_2",
  EXCEPTED_QUANTITY_PROVISION = "EXCEPTED_QUANTITY_PROVISION",
  GROUND_ONLY = "GROUND_ONLY",
  ID8000 = "ID8000",
  LIGHTERS = "LIGHTERS",
  SMALL_QUANTITY_PROVISION = "SMALL_QUANTITY_PROVISION",
}
