import { Currency } from "@redotech/money/currencies";
import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { zExt } from "../../common/zod-util";
import {
  Carriers,
  FedexServices,
  ServiceLevel,
  UpsServices,
  UspsServices,
} from "../../fulfillments/fulfillment-carriers-and-services";

export enum RateTableType {
  FIXED = "fixed",
  DYNAMIC = "dynamic",
}

export enum ConditionalPricingCondition {
  WEIGHT = "weight",
  PRICE = "price",
}

export enum DynamicRateSelection {
  CHEAPEST = "cheapest",
  FASTEST = "fastest",
}

export enum DynamicRateRounding {
  /**
   * E.g. 1.13 -> 2.00
   */
  NEAREST_WHOLE = "NEAREST_WHOLE",
  /**
   * E.g. 1.13 -> 1.50
   */
  NEAREST_HALF = "NEAREST_HALF",
  /**
   * E.g. 1.13 -> 1.25
   */
  NEAREST_QUARTER = "NEAREST_QUARTER",
  /**
   * E.g. 1.13 -> 1.20
   */
  NEAREST_TENTH = "NEAREST_TENTH",
  /**
   * E.g. 1.13 -> 1.15
   */
  NEAREST_TWENTIETH = "NEAREST_TWENTIETH",
}

export const dynamicRateRoundingToLabel: Record<DynamicRateRounding, string> = {
  [DynamicRateRounding.NEAREST_WHOLE]: "Nearest whole",
  [DynamicRateRounding.NEAREST_HALF]: "Nearest half",
  [DynamicRateRounding.NEAREST_QUARTER]: "Nearest quarter",
  [DynamicRateRounding.NEAREST_TENTH]: "Nearest tenth",
  [DynamicRateRounding.NEAREST_TWENTIETH]: "Nearest twentieth",
};

export const dynamicRateRoundingToExample: Record<DynamicRateRounding, string> =
  {
    [DynamicRateRounding.NEAREST_WHOLE]: "1.13 -> 2.00",
    [DynamicRateRounding.NEAREST_HALF]: "1.13 -> 1.50",
    [DynamicRateRounding.NEAREST_QUARTER]: "1.13 -> 1.25",
    [DynamicRateRounding.NEAREST_TENTH]: "1.13 -> 1.20",
    [DynamicRateRounding.NEAREST_TWENTIETH]: "1.13 -> 1.15",
  };

const roundingKey = ["0", "1", "2", "3", "4"] as const;
type RoundingKey = (typeof roundingKey)[number];
export function isDynamicRateRoundingServiceCodeSerializedKey(
  key: string,
): key is RoundingKey {
  return roundingKey.includes(key as RoundingKey);
}
export const dynamicRateRoundingToServiceCodeSerialization: Record<
  DynamicRateRounding,
  (typeof roundingKey)[number]
> = {
  // CAUTION: Do not change these constants. If you do, carefully consider
  // backwards compatibility. Adding new ones is fine. Do not remove or modify
  // existing ones.
  [DynamicRateRounding.NEAREST_WHOLE]: "0",
  [DynamicRateRounding.NEAREST_HALF]: "1",
  [DynamicRateRounding.NEAREST_QUARTER]: "2",
  [DynamicRateRounding.NEAREST_TENTH]: "3",
  [DynamicRateRounding.NEAREST_TWENTIETH]: "4",
};
export const dynamicRateRoundingToServiceCodeDeserialization: Record<
  (typeof roundingKey)[number],
  DynamicRateRounding
> = {
  // CAUTION: Do not change these constants. If you do, carefully consider
  // backwards compatibility. Adding new ones is fine. Do not remove or modify
  // existing ones.
  ["0"]: DynamicRateRounding.NEAREST_WHOLE,
  ["1"]: DynamicRateRounding.NEAREST_HALF,
  ["2"]: DynamicRateRounding.NEAREST_QUARTER,
  ["3"]: DynamicRateRounding.NEAREST_TENTH,
  ["4"]: DynamicRateRounding.NEAREST_TWENTIETH,
};

export const ShippingRateLocationSchema = z.object({
  country: z.string(),
  provinces: z.array(z.string()),
});
export type ShippingRateLocation = z.infer<typeof ShippingRateLocationSchema>;

export type Rate = FixedRate | DynamicRate;

const WeightConditionSchema = z.object({
  type: z.literal(ConditionalPricingCondition.WEIGHT),
  minGrams: z.number().min(0),
  maxGrams: z.number().min(0),
});
export type WeightCondition = z.infer<typeof WeightConditionSchema>;

const PriceConditionSchema = z.object({
  type: z.literal(ConditionalPricingCondition.PRICE),
  minPrice: z.number().min(0),
  maxPrice: z.number().min(0),
  currency: z.string(),
});
export type PriceCondition = z.infer<typeof PriceConditionSchema>;

const RateConditionSchema = z.union([
  WeightConditionSchema,
  PriceConditionSchema,
]);
export type RateCondition = z.infer<typeof RateConditionSchema>;

const BaseRateSchema = z.object({
  enabled: z.boolean().optional(),
  merchantPaidCoverageTypes: z
    .object({
      return: z.boolean().optional(),
      packageProtection: z.boolean().optional(),
    })
    .optional(),
});

const BaseShippingRateTableSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  description: z.string(),
  code: z.string(),
  type: z.nativeEnum(RateTableType),
  originLocations: z.array(ShippingRateLocationSchema),
  destinationLocations: z.array(ShippingRateLocationSchema),
  coverageTypes: z
    .object({
      return: z.boolean().optional(),
      packageProtection: z.boolean().optional(),
    })
    .optional(),
});

const FixedRateSchema = BaseRateSchema.extend({
  price: z.string(),
  conditions: z.array(RateConditionSchema),
  currency: z.string(),
});
export type FixedRate = z.infer<typeof FixedRateSchema>;

export const FixedShippingRateTableSchema = BaseShippingRateTableSchema.extend({
  type: z.literal(RateTableType.FIXED),
  rates: z.array(FixedRateSchema).min(1),
});
export type FixedShippingRateTable = z.infer<
  typeof FixedShippingRateTableSchema
>;
const DisplayedFixedShippingRateTableSchema = FixedShippingRateTableSchema.omit(
  {
    _id: true,
    originLocations: true,
    destinationLocations: true,
    coverageTypes: true,
    code: true,
  },
).extend({ id: z.string() });
export type DisplayedFixedShippingRateTable = z.infer<
  typeof DisplayedFixedShippingRateTableSchema
>;

const CarrierAndServiceSchema = z.union([
  z.object({
    carrier: z.literal(Carriers.FEDEX),
    service: z.nativeEnum(FedexServices),
  }),
  z.object({
    carrier: z.literal(Carriers.UPS),
    service: z.nativeEnum(UpsServices),
  }),
  z.object({
    carrier: z.literal(Carriers.USPS),
    service: z.nativeEnum(UspsServices),
  }),
]);
export type CarrierAndService = z.infer<typeof CarrierAndServiceSchema>;

const DynamicRateSchema = BaseRateSchema.extend({
  markup: z
    .object({
      amount: z.string().optional(),
      percentage: z.string().optional(),
    })
    .optional(),
  carrierService: CarrierAndServiceSchema,
});
export type DynamicRate = z.infer<typeof DynamicRateSchema>;

export const DynamicShippingRateTableSchema =
  BaseShippingRateTableSchema.extend({
    type: z.literal(RateTableType.DYNAMIC),
    serviceLevelBucketedServiceSelection: z.nativeEnum(ServiceLevel).optional(),
    markup: z
      .object({
        amount: z
          .object({ currency: z.nativeEnum(Currency), value: z.string() })
          .optional(),
        percentage: z.string().optional(),
      })
      .optional(),
    rounding: z.nativeEnum(DynamicRateRounding).nullish(),
    rates: z.array(DynamicRateSchema),
    multipleDynamicRateSelection: z.nativeEnum(DynamicRateSelection).optional(),
    fulfillmentDelayDaysOverride: z.number().optional(),
    conditions: z.array(RateConditionSchema).optional(),
    excludedCarriers: z.array(z.nativeEnum(Carriers)).optional(),
  });
export type DynamicShippingRateTable = z.infer<
  typeof DynamicShippingRateTableSchema
>;
const DisplayedDynamicShippingRateTableSchema =
  DynamicShippingRateTableSchema.omit({
    _id: true,
    originLocations: true,
    destinationLocations: true,
    coverageTypes: true,
    code: true,
  }).extend({ id: z.string() });
export type DisplayedDynamicShippingRateTable = z.infer<
  typeof DisplayedDynamicShippingRateTableSchema
>;

export const ShippingRateTableSchema = z.discriminatedUnion("type", [
  FixedShippingRateTableSchema,
  DynamicShippingRateTableSchema,
]);
export type ShippingRateTable = z.infer<typeof ShippingRateTableSchema>;

export const DisplayedShippingRateTableSchema = z.discriminatedUnion("type", [
  DisplayedFixedShippingRateTableSchema,
  DisplayedDynamicShippingRateTableSchema,
]);
export type DisplayedShippingRateTable = z.infer<
  typeof DisplayedShippingRateTableSchema
>;

export const ShippingRateTableArraySchema = z.array(ShippingRateTableSchema);

const CreateFixedShippingRateTableInputSchema =
  FixedShippingRateTableSchema.omit({
    _id: true,
    code: true,
    originLocations: true,
    destinationLocations: true,
  });
export type CreateFixedShippingRateTableInput = z.infer<
  typeof CreateFixedShippingRateTableInputSchema
>;

const CreateDynamicShippingRateTableInputSchema =
  DynamicShippingRateTableSchema.omit({
    _id: true,
    code: true,
    originLocations: true,
    destinationLocations: true,
  });
export type CreateDynamicShippingRateTableInput = z.infer<
  typeof CreateDynamicShippingRateTableInputSchema
>;

export const CreateShippingRateTableInputSchema = z.discriminatedUnion("type", [
  CreateFixedShippingRateTableInputSchema,
  CreateDynamicShippingRateTableInputSchema,
]);
export type CreateShippingRateTableInput = z.infer<
  typeof CreateShippingRateTableInputSchema
>;

export function isFixedRateTable(
  rateTable: ShippingRateTable,
): rateTable is FixedShippingRateTable {
  return rateTable.type === RateTableType.FIXED;
}

export function isDisplayedFixedRateTable(
  rateTable: DisplayedShippingRateTable,
): rateTable is DisplayedFixedShippingRateTable {
  return rateTable.type === RateTableType.FIXED;
}

export function isDynamicRateTable(
  rateTable: ShippingRateTable,
): rateTable is DynamicShippingRateTable {
  return rateTable.type === RateTableType.DYNAMIC;
}

export function isDisplayedDynamicRateTable(
  rateTable: DisplayedShippingRateTable,
): rateTable is DisplayedDynamicShippingRateTable {
  return rateTable.type === RateTableType.DYNAMIC;
}

export function isFixedRate(
  rate: ShippingRateTable["rates"][number],
): rate is FixedRate {
  return "price" in rate;
}

export function isDynamicRate(
  rate: ShippingRateTable["rates"][number],
): rate is DynamicRate {
  return "markup" in rate;
}

export function isWeightCondition(
  condition: RateCondition,
): condition is WeightCondition {
  return condition.type === ConditionalPricingCondition.WEIGHT;
}

export function isPriceCondition(
  condition: RateCondition,
): condition is PriceCondition {
  return condition.type === ConditionalPricingCondition.PRICE;
}

export function doesRateMatchConditions({
  conditions,
  evaluatePriceConditionsOnPostDiscountOrderValue,
  orderTotalValue,
  orderTotalWeightInGrams,
}: {
  conditions: FixedShippingRateConditionWithExchangeRate[];
  evaluatePriceConditionsOnPostDiscountOrderValue: boolean;
  orderTotalValue: number;
  orderTotalWeightInGrams: number;
}) {
  let ratePassesAllConditions = true;

  for (const condition of conditions) {
    let conditionPassed = false;
    switch (condition.type) {
      case ConditionalPricingCondition.PRICE: {
        if (evaluatePriceConditionsOnPostDiscountOrderValue) {
          // Shopify sends the rates endpoint the orderTotalValue before
          // discount codes are applied. Therefore, this function is unable to
          // apply the conditions to the post-discount order value. However, we
          // do have access to this information in the delivery customization
          // function so we include all rates with price conditions and let the
          // delivery customization decide which rates to ultimately display.
          conditionPassed = true;
        } else {
          const priceAfterExchangeRate =
            orderTotalValue * condition.exchangeRate;
          conditionPassed =
            priceAfterExchangeRate >= condition.minPrice &&
            priceAfterExchangeRate < condition.maxPrice;
        }
        break;
      }
      case ConditionalPricingCondition.WEIGHT:
        conditionPassed =
          orderTotalWeightInGrams >= condition.minGrams &&
          orderTotalWeightInGrams < condition.maxGrams;
        break;
      default:
        assertNever(condition);
    }

    if (!conditionPassed) {
      ratePassesAllConditions = false;
      break;
    }
  }

  return ratePassesAllConditions;
}

type FixedShippingRateConditionWithExchangeRate =
  | (Extract<
      FixedShippingRateTable["rates"][number]["conditions"][number],
      { type: ConditionalPricingCondition.PRICE }
    > & { exchangeRate: number })
  | Exclude<
      FixedShippingRateTable["rates"][number]["conditions"][number],
      { type: ConditionalPricingCondition.PRICE }
    >;
