import {
  EWWidgetTextProps,
  ExtendedWarrantyBlurbType,
  ExtendedWarrantyDataTarget,
} from "@redotech/redo-model/extended-warranty";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import InfoCircleIcon from "@redotech/redo-web/arbiter-icon/info-circle.svg";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import htmlReactParser from "html-react-parser";
import { memo } from "react";
import * as RadioModalCss from "./radio-modal.module.css";

export const ExtendedWarrantyBlurb = memo(function ExtendedWarrantyBlurb({
  type,
  text,
  onInfoClick,
  showIcon,
}: {
  type: ExtendedWarrantyBlurbType;
  text: EWWidgetTextProps;
  showIcon?: boolean;
  onInfoClick?: () => void;
}) {
  const { pdpTitle, pdpDescription, pdpCompactBlurbTitle } = text;

  switch (type) {
    case ExtendedWarrantyBlurbType.REGULAR:
      return (
        <RegularBlurb pdpDescription={pdpDescription} pdpTitle={pdpTitle} />
      );
    case ExtendedWarrantyBlurbType.COMPACT:
      return (
        <CompactBlurb
          onInfoClick={onInfoClick}
          pdpCompactBlurbTitle={pdpCompactBlurbTitle}
          showIcon={showIcon}
        />
      );
  }
});

const RegularBlurb = memo(function RegularBlurb({
  pdpTitle,
  pdpDescription,
}: {
  pdpTitle: string;
  pdpDescription: string;
}) {
  return (
    <Flex
      align="center"
      data-target={ExtendedWarrantyDataTarget.PDP_CONTAINER}
      gap="xs"
      justify="space-between"
    >
      <Flex
        align="flex-start"
        data-target={ExtendedWarrantyDataTarget.PDP_CONTENT}
        gap="xs"
      >
        <ShieldTickIcon
          className={warrantyStyles.shieldIcon}
          data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
        />
        <div>
          <div
            className={warrantyStyles.title}
            data-target={ExtendedWarrantyDataTarget.PDP_TITLE}
          >
            {htmlReactParser(pdpTitle)}
          </div>
          <div
            className={warrantyStyles.subtitle}
            data-target={ExtendedWarrantyDataTarget.PDP_SUBTITLE}
          >
            {htmlReactParser(pdpDescription)}
          </div>
        </div>
      </Flex>
    </Flex>
  );
});

const CompactBlurb = memo(function CompactBlurb({
  pdpCompactBlurbTitle,
  onInfoClick,
  showIcon,
}: {
  pdpCompactBlurbTitle: string;
  onInfoClick?: () => void;
  showIcon?: boolean;
}) {
  return (
    <Flex align="center" flexDirection="row" gap="xs">
      {showIcon && (
        <ShieldTickIcon
          className={warrantyStyles.shieldIconCompact}
          data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
        />
      )}
      <Text className={RadioModalCss.title} fontSize="sm" fontWeight="semibold">
        {htmlReactParser(pdpCompactBlurbTitle)}
      </Text>
      {onInfoClick && (
        <RedoButton
          className={RadioModalCss.infoButton}
          hierarchy="link"
          IconLeading={InfoCircleIcon}
          onClick={onInfoClick}
          size="sm"
        />
      )}
    </Flex>
  );
});
