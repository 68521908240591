// extracted by mini-css-extract-plugin
export var alignBaseline = "_5-8";
export var alignCenter = "_5-4";
export var alignFlexEnd = "_5-6";
export var alignFlexStart = "_5-5";
export var alignSelfBaseline = "_5-d";
export var alignSelfCenter = "_5-9";
export var alignSelfFlexEnd = "_5-b";
export var alignSelfFlexStart = "_5-a";
export var alignSelfStretch = "_5-c";
export var alignStretch = "_5-7";
export var column = "_5-3";
export var flex = "_5-0";
export var justifyCenter = "_5-e";
export var justifyFlexEnd = "_5-g";
export var justifyFlexStart = "_5-f";
export var justifySpaceAround = "_5-i";
export var justifySpaceBetween = "_5-h";
export var justifySpaceEvenly = "_5-j";
export var reverse = "_5-2";
export var row = "_5-1";
export var wrapNowrap = "_5-l";
export var wrapWrap = "_5-k";
export var wrapWrapReverse = "_5-m";