import { z } from "zod";

export const DaysScheduleSchema = z.object({
  days: z.object({
    monday: z.boolean(),
    tuesday: z.boolean(),
    wednesday: z.boolean(),
    thursday: z.boolean(),
    friday: z.boolean(),
    saturday: z.boolean(),
    sunday: z.boolean(),
  }),
});
export type DaysSchedule = z.infer<typeof DaysScheduleSchema>;

export const ScheduleSchema = z.object({
  second: z.array(
    z.object({ start: z.number(), end: z.number(), step: z.number() }),
  ),
  minute: z.array(
    z.object({ start: z.number(), end: z.number(), step: z.number() }),
  ),
  hour: z.array(
    z.object({ start: z.number(), end: z.number(), step: z.number() }),
  ),
  dayOfMonth: z.array(
    z.object({ start: z.number(), end: z.number(), step: z.number() }),
  ),
  month: z.array(
    z.object({
      start: z.enum([
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ]),
      end: z.enum([
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER",
      ]),
      step: z.number(),
    }),
  ),
  year: z.array(z.number()),
  dayOfWeek: z.array(
    z.object({
      start: z.enum([
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ]),
      end: z.enum([
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ]),
      step: z.number(),
    }),
  ),
  comment: z.string().optional(),
});

export type Schedule = z.infer<typeof ScheduleSchema>;

export const upsertBalanceSchedule = {
  input: z.object({
    schedule: DaysScheduleSchema.nullish(),
    cronExpression: z.string().nullish(),
    teamId: z.string(),
  }),
  output: z.null(),
};
