import { countries } from "@redotech/locale/countries";
import { Provider } from "../../../order";
import { ReviewPrioritizationOption } from "../../../team";
import { Trackable, TrackableType, trackingStatuses } from "../../../trackable";
import { SchemaInstance, defineSchema } from "../../type-system/schema";

const exampleTrackable: Trackable = {
  type: TrackableType.ORDER,
  id: "1234567890",
  customer: { email: "example@example.com" },
  fulfillments: [],
  lineItems: [],
  currentEmailFlows: [],
  createdAt: new Date(),
  teamId: "team-id",
  originalOrder: {
    id: "1234567890",
    shopify: {
      id: "1234567890",
      number: 1001,
      name: "#1001",
      fulfillments: [],
      lineItems: [],
      createdAt: new Date(),
      contact_email: "contact@example.com",
      customer: { id: "1234567890" },
      shippingLines: [],
      priceBeforeDiscounts: 100,
      discounts: { individualDiscounts: [] },
      presentmentCurrency: "USD",
      currentSubtotalPriceSet: { presentment_money: { amount: 100 } },
      currentTotalPriceSet: { presentment_money: { amount: 100 } },
      currentTotalTaxSet: { presentment_money: { amount: 100 } },
      tags: [],
    },
  },
};

export const baseTrackingSchema = defineSchema({
  fields: {
    orderNumber: {
      dataType: "Text",
      documentation: "The shopify order number",
    },
    redoId: {
      dataType: "Text",
      documentation: "The unique identifier for the trackable item in Redo",
    },
    currentDate: { dataType: "Date", documentation: "The current date" },
    trackableType: {
      dataType: "Enum",
      documentation: 'The type of trackable item: "order" or "return"',
      values: ["order", "return"],
    },
    // order-specific name for backwards compatibility
    orderSummary: {
      dataType: "Trackable Summary",
      documentation: "The summary of the order or return",
    },
    reviewRequest: {
      dataType: "Review Request",
      documentation: "A review request for the current order items",
    },
    customerCountry: {
      dataType: "Enum",
      documentation: "Which country the customer is in",
      values: countries.map((country) => country.name),
    },
    productTags: {
      dataType: "Multiple Text",
      documentation: "The tags of all the products in the order",
    },
    redoCustomerId: {
      dataType: "Metadata",
      documentation: "The Redo customer ID.",
    },

    // TODO: make this work
    // productProperties: {
    //   dataType: "Multiple Text",
    //   documentation:
    //     "True if any products in the order has any of these properties",
    // },
    deliveryMethod: {
      dataType: "Multiple Text",
      documentation: "The delivery method of the shipment",
    },
    deliveryStatus: {
      dataType: "Enum",
      values: trackingStatuses.filter((s) => s !== "unknown"),
      documentation: "The delivery status of the shipment.",
    },
    skus: {
      dataType: "Multiple Text",
      documentation: "The SKUs of the products in the order",
    },
    orderTags: {
      dataType: "Multiple Text",
      documentation: "The tags of the order",
    },

    // TODO: make this work
    collections: {
      dataType: "Multiple Text",
      documentation: "All the collections that contain a product in the order",
    },

    totalPrice: {
      dataType: "Number",
      documentation: "The total price of the order (or items being returned)",
    },
    packageNumberDescription: {
      dataType: "Maybe Text",
      documentation:
        "Text that specifies which fulfillment received a tracking update, if applicable",
    },
    relevantLineItems: {
      dataType: "Multiple Line Item",
      documentation: "The line items relevant to the shipment",
    },
    otherLineItems: {
      dataType: "Maybe Multiple Line Item",
      documentation: "Other line items",
    },

    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    subscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to subscribe to email updates. Only displays when the customer is not subscribed",
    },
    trackingLink: {
      dataType: "Url",
      documentation: "The link to track the shipment",
    },
    orderDetailsLink: {
      dataType: "Url",
      documentation: "The link to view the order details",
    },
    shopifyOrderDetailsLink: {
      dataType: "Maybe Url",
      documentation: "The link to view the order details in Shopify",
    },
    carrierLink: {
      dataType: "Maybe Url",
      documentation: "The link to view the carrier details",
    },
    tracker: {
      dataType: "Tracker",
      documentation: "The tracker for the shipment",
    },
    cartContext: {
      dataType: "Cart Context",
      documentation:
        "Data related to the order or return that is useful for generating product recommendations for an interactive cart",
    },
    customerFullName: {
      dataType: "Maybe Text",
      documentation: "The full name of the customer",
    },
    customerFirstName: {
      dataType: "Maybe Text",
      documentation: "The first name of the customer",
    },
    customerLastName: {
      dataType: "Maybe Text",
      documentation: "The last name of the customer",
    },
    customerEmailSubscriber: {
      dataType: "Boolean",
      documentation:
        "True if the customer is subscribed to email marketing updates",
    },
    customerSmsSubscriber: {
      dataType: "Boolean",
      documentation:
        "True if the customer is subscribed to transactional SMS updates",
    },
    customerEmail: {
      dataType: "Email",
      documentation: "The email address of the customer",
    },
    customerPhoneNumber: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer",
    },
    trackingNumber: {
      dataType: "Maybe Text",
      documentation: "The tracking number of the shipment",
    },
    ecommerceProvider: {
      dataType: "Enum",
      values: [Provider.COMMENTSOLD, Provider.SHOPIFY, Provider.COMMERCE_CLOUD],
      documentation: "The eccommerce provider of the trackable item",
    },
    metafields: {
      dataType: "Object",
      documentation:
        "The metafields of the trackable item (Use `.` to access nested fields)",
    },
    returnSubmitted: {
      dataType: "Boolean",
      documentation: "Whether a return was submitted for any item in the order",
    },
  },
});

export const exampleBaseTrackingSchemaInstance: SchemaInstance<
  typeof baseTrackingSchema
> = {
  orderNumber: "#1001",
  redoId: "65b81f41da22f4000718b34e",
  redoCustomerId: "66b131782f9829bd1ed05bd7",
  currentDate: Temporal.Now.instant().toZonedDateTimeISO("UTC").toPlainDate(),
  trackableType: "Order",
  orderSummary: {
    trackable: exampleTrackable,
    trackingPageUrl: "https://example.com",
    trackableType: {
      lowerCase: "order",
      titleCase: "Order",
      verb: "purchased",
    },
    orderDetails: {
      orderDate: new Date(),
      orderId: "1234567890",
      fulfilled: true,
    },
    relevantLineItems: [
      {
        title: "Product 1",
        variantTitle: "Variant 1",
        image: { src: "https://placehold.co/150?text=Product+1" },
        price: 10.0,
        isRedo: false,
        requiresShipping: true,
        tags: [],
        properties: {},
        sku: "SKU-1",
        quantity: 1,
        id: 1,
        green_return: true,
        priceSet: { presentment_money: { amount: 10.0, currency_code: "USD" } },
      },
    ],
    otherLineItems: [
      {
        title: "Product 2",
        variantTitle: "Variant 2",
        image: { src: "https://placehold.co/150?text=Product+2" },
        price: 10.0,
        isRedo: false,
        requiresShipping: true,
        tags: [],
        properties: {},
        sku: "SKU-2",
        quantity: 1,
        id: 2,
        green_return: true,
        priceSet: { presentment_money: { amount: 10.0, currency_code: "USD" } },
      },
    ],
    costSummary: {
      shippingProduct: "Shipping method",
      shippingProductCost: "$10.00",
      taxCost: "$1.00",
      totalCost: "$11.00",
      priceBeforeDiscounts: "$10.00",
      discountStrings: ["10% off", "$1.00 off"],
    },
    customerInformation: {
      shippingAddress: {
        name: "John Doe",
        address1: "123 Main St",
        address2: "Suite 100",
        city: "San Francisco",
        province: "CA",
        postalCode: "94107",
        phone: "+1234567890",
      },
      billingAddress: {
        name: "John Doe",
        address1: "123 Main St",
        address2: "Suite 100",
        city: "San Francisco",
        province: "CA",
        postalCode: "94107",
        phone: "+1234567890",
      },
    },
  },
  reviewRequest: {
    relevantLineItems: [
      {
        title: "Product 1",
        variantTitle: "Variant 1",
        image: { src: "https://placehold.co/150?text=Product+1" },
        price: 10.0,
        isRedo: false,
        requiresShipping: true,
        tags: [],
        properties: {},
        sku: "SKU-1",
        quantity: 1,
        id: 1,
        green_return: true,
        priceSet: { presentment_money: { amount: 10.0, currency_code: "USD" } },
      },
    ],
    orderNumber: "#1001",
    storeUrl: "https://example.com",
    reviewPrioritization: ReviewPrioritizationOption.HighestPrice,
    customerEmail: "example@test.com",
  },
  customerEmailSubscriber: true,
  customerSmsSubscriber: false,
  customerCountry: "United States",
  productTags: ["tag1", "tag2"],
  skus: ["sku1", "sku2"],
  orderTags: ["tag1", "tag2"],
  collections: ["collection1", "collection2"],
  packageNumberDescription: "Package 1 of 2",
  relevantLineItems: [
    {
      product: {
        id: 1,
        title: "Product 1",
        variantTitle: "Variant 1",
        image: { src: "https://placehold.co/150?text=Product+1" },
        price: 10.0,
        priceSet: { presentment_money: { amount: 10.0, currency_code: "USD" } },
        isRedo: false,
        requiresShipping: true,
        tags: [],
        properties: {},
        sku: "SKU-1",
        green_return: true,
      },
      quantity: 1,
    },
  ],
  totalPrice: 10.0,
  otherLineItems: undefined,
  unsubscribeLink: undefined,
  oneClickUnsubscribeLink: undefined,
  subscribeLink: "https://example.com/subscribe",
  trackingLink: "https://example.com/track",
  orderDetailsLink: "https://example.com/order-details",
  shopifyOrderDetailsLink: "https://example.com/shopify-order-details",
  carrierLink: "https://example.com/carrier-link",
  tracker: {
    trackableType: {
      lowerCase: "order",
      titleCase: "Order",
      verb: "purchased",
    },
    orderCreatedAt: new Date(),
    code: "1234567890",
    link: "https://example.com",
    deliveryEstimate: new Date(),
    status: "pre_transit",
    details: [
      {
        message: "Order received",
        status: "pre_transit",
        datetime: new Date(),
      },
    ],
    carrier: "USPS",
    carrierDetail: {
      service: "USPS First-Class Package Service",
      container_type: "envelope",
      est_delivery_date_local: "2025-01-01T00:00:00",
      est_delivery_time_local: "2025-01-01T00:00:00",
      guaranteed_delivery_date: "2025-02-01T00:00:00",
      alternate_identifier: "alternate-identifier",
      initial_delivery_attempt: "2025-01-01T00:00:00",
      origin_location: "San Francisco, CA, US, 94107",
      origin_tracking_location: {
        city: "San Francisco",
        state: "CA",
        country: "US",
        zip: "94107",
        object: "TrackingLocation",
      },
      destination_location: "New York, NY, US, 10001",
      destination_tracking_location: {
        city: "New York",
        state: "NY",
        country: "US",
        zip: "10001",
        object: "TrackingLocation",
      },
      object: "CarrierDetail",
    },
    trackable: exampleTrackable,
  },
  cartContext: {
    productIdsForRecommendations: [],
    productIdsAlreadyInCart: [],
    alreadyInCart: [],
    existingCartId: null,
  },
  customerFullName: "John Doe",
  customerFirstName: "John",
  customerLastName: "Doe",
  customerEmail: "johndoe@example.com",
  customerPhoneNumber: "+1234567890",
  trackingNumber: "1234567890",
  deliveryMethod: ["Economy Shipping (7-10 Business Days)"],
  deliveryStatus: "pre_transit",
  ecommerceProvider: Provider.SHOPIFY,
  metafields: { custom_metafield_1: "custom value 1" },
  returnSubmitted: false,
};
