import { CustomerDocument } from "../../../customer";

/**
 * Base data types in the email flow's type system.
 * Email, Url, and Text are all strings under the hood,
 * but they have different domain meaning and are thus treated as separate types.
 *
 * Metadata is designed to not be exposed to the merchants in the builder, but
 * allows us to store additional information about the schema instance that may be useful
 */
export type BaseDataTypes = {
  Number: number;
  Text: string;
  Url: string;
  Email: string;
  Phone: string;
  Date: Temporal.PlainDate;
  DateTime: Temporal.Instant;
  Boolean: boolean;
  Enum: string;
  /** Like enum, but values are only known at runtime */
  Token: string;
  Name: string;
  Metadata: boolean | number | string;
  Object: Record<string, unknown>;
  Customer: CustomerDocument;
};

export enum AdvancedFlowTokenType {
  CUSTOMER_GROUP_ID = "customer_group_id",
}

export const baseDataTypeKeys = [
  "Number",
  "Text",
  "Url",
  "Email",
  "Phone",
  "Date",
  "DateTime",
  "Boolean",
  "Enum",
  "Name",
  "Metadata",
  "Object",
  "Customer",
] as const satisfies (keyof BaseDataTypes)[];
