import {
  EWWidgetTextProps,
  ExtendedWarrantyCartToggleExperience as ExtendedWarrantyCartToggleExperienceEnum,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { memo } from "react";
import { ExtendedWarrantyCartToggleExperience } from "./base-experience";
import { ExtendedWarrantyCartToggleModalExperience } from "./modal-experience";

export const ExtendedWarrantyCartToggleContent = memo(
  function ExtendedWarrantyCartToggleContent({
    addWarranty,
    removeWarranty,
    variantId,
    selection,
    offerings,
    cartToggleExperience,
    customCss,
    customModalCss,
    logo,
    sideImage,
    text,
    isPreview,
    foundSelector,
  }: {
    addWarranty: (variantId: string, selectedOffering: Offering) => void;
    removeWarranty: (variantId: string) => void;
    variantId: string;
    selection?: Offering;
    offerings: Offering[];
    cartToggleExperience?: ExtendedWarrantyCartToggleExperienceEnum;
    customCss?: string;
    customModalCss?: string;
    logo?: string;
    sideImage?: string;
    text: EWWidgetTextProps;
    isPreview?: boolean;
    foundSelector?: string;
  }) {
    return (
      <>
        {customCss && <style>{customCss}</style>}
        {foundSelector && <meta found-selector={foundSelector} />}
        {cartToggleExperience ===
        ExtendedWarrantyCartToggleExperienceEnum.MODAL ? (
          <ExtendedWarrantyCartToggleModalExperience
            addWarranty={addWarranty}
            customModalCss={customModalCss}
            foundSelector={foundSelector}
            isPreview={isPreview}
            logo={logo}
            offerings={offerings}
            removeWarranty={removeWarranty}
            selection={selection}
            sideImage={sideImage}
            text={text}
            variantId={variantId}
          />
        ) : (
          <ExtendedWarrantyCartToggleExperience
            addWarranty={addWarranty}
            cartToggleExperience={cartToggleExperience}
            offerings={offerings}
            removeWarranty={removeWarranty}
            selection={selection}
            text={text}
            variantId={variantId}
          />
        )}
      </>
    );
  },
);
