import { z } from "zod";

export const logSignupFormAnalyticsSchema = {
  input: z.object({
    idempotencyKey: z.string(),
    formId: z.string(),
    type: z.enum(["view", "submit"]),
  }),
  output: z.object({ success: z.boolean() }),
};
