import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { BalanceTypes } from "@redotech/redo-model/balance/balances";
import { Team } from "@redotech/redo-model/team";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { groupInput, input, numberValidator } from "@redotech/ui/form";
import { memo, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";

export const creditForm = groupInput({
  amount: input<number>({ validator: numberValidator({ min: 0, max: 100 }) }),
});

export const AddBalanceCreditCard = memo(function BillingCard({
  team,
}: {
  team: Team | undefined;
}) {
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);
  const [balanceAccount, setBalanceAccount] = useState<BalanceTypes>(
    BalanceTypes.OUTBOUND_LABELS,
  );
  const [balance, setBalance] = useState<number | null>(null);
  const input = useInput(creditForm, { amount: 0 });

  const balanceLoad = useLoad(
    async (signal) => {
      if (!team) {
        return;
      }
      const balance = await rpcClient.getBalance(
        { account: balanceAccount, teamId: team._id },
        { signal },
      );
      balance?.balance && setBalance(Number(balance.balance));
    },
    [team, balanceAccount],
  );

  const [addBalanceLoad, addBalance] = useTriggerLoad(async (signal) => {
    if (!team) {
      return;
    }
    const balance = await rpcClient.addBalanceCredit(
      {
        account: balanceAccount,
        amount: input.inputs.amount.value,
        teamId: team._id,
      },
      { signal },
    );

    input.inputs.amount.setValue(0);
    balance?.balance && setBalance(Number(balance.balance));
  });

  const accountOptions: RedoListItem<BalanceTypes>[] = useMemo(
    () => [
      {
        id: BalanceTypes.OUTBOUND_LABELS,
        type: "text",
        text: BalanceTypes.OUTBOUND_LABELS,
        value: BalanceTypes.OUTBOUND_LABELS,
      },
    ],
    [],
  );

  return (
    <Card title="Add balance credit">
      {balanceLoad.pending ? (
        <Text>Loading...</Text>
      ) : (
        <Flex flexDirection="column">
          <Flex>
            <Text>{`Current Balance: $${balance ?? "0.00"}`}</Text>
          </Flex>
          <Flex flexDirection="row" justify="space-between">
            <Flex>
              <Text>Amount:</Text>
              <RedoIncrementDecrement
                placeholder="0"
                prefix="$"
                setValue={(value) => input.inputs.amount.setValue(value ?? 0)}
                size="small"
                value={input.inputs.amount.value ?? 0}
              />
            </Flex>
            <RedoButton
              disabled={(input.inputs.amount.value ?? 0) === 0}
              hierarchy="primary"
              onClick={() => {
                addBalance();
              }}
              pending={addBalanceLoad.pending}
              size="sm"
              text="Add funds"
              theme="normal"
            />
            <RedoSingleSelectDropdownInput<BalanceTypes>
              options={accountOptions}
              optionSelected={({ item }) => {
                setBalanceAccount(item.value);
              }}
              selectedItem={accountOptions.find(
                (o) => o.value === balanceAccount,
              )}
              size="xs"
            />
          </Flex>
        </Flex>
      )}
    </Card>
  );
});
