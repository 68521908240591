import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { getKey } from "@redotech/react-util/key";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  isUpsService,
  upsServiceToLabel,
} from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { LengthUnit } from "@redotech/redo-model/outbound-labels";
import {
  AxisKind,
  isOutboundRateTable,
  OutboundRateTable,
  OutboundRateTableType,
  RateTable,
  RateTableType,
  RateTableValueMode,
  WeightUnit,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  FedexRateTableServiceLevel,
  RateTableCarrier,
  rateTableCarrierLabel,
  RateTableServiceLevel,
  RateTableServiceLevelsMapping,
} from "@redotech/redo-model/rate-table/rate-table-mapping";
import { toast } from "@redotech/redo-web/alert";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import CopyIcon from "@redotech/redo-web/icon-old/copy.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { memo, useEffect, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../../app/redo-admin-rpc-client-provider";
import { TeamContext } from "../../../team";
import { LabelRateTableForm } from "../../common/rate-tables/rate-table";
import {
  rateCardForm,
  RateCardValue,
  rateTableToTableData,
  tableDataToMinMaxCubicSizes,
  tableDataToMinMaxWeights,
  tableDataToRateData,
  tableDataToZones,
} from "../../common/rate-tables/rate-table-utils";
import { defaultNewOutboundRateTable } from "./default-new-rate-table";
import * as styles from "./rate-table-card.module.css";

export const OutboundLabelRateCardCard = memo(
  function OutboundLabelRateCardCard({
    readonly = false,
  }: {
    readonly: boolean;
  }) {
    const team = useRequiredContext(TeamContext);
    const client = useRequiredContext(RedoAdminRpcClientContext);
    const [rateCardLoad, triggerRateCardLoad] = useTriggerLoad<
      OutboundRateTable[]
    >(async () => {
      const tables = await client.getRateTables({ teamId: team._id });
      const filtered: OutboundRateTable[] = tables.rateTables.filter(
        (table: RateTable) => isOutboundRateTable(table),
      );
      return filtered;
    });

    const [selectedCarrier, setSelectedCarrier] = useState<RateTableCarrier>(
      RateTableCarrier.USPS,
    );

    const [selectedServiceLevel, setSelectedServiceLevel] =
      useState<RateTableServiceLevel>("Default");

    const [selectedValueMode, setSelectedValueMode] =
      useState<RateTableValueMode>(RateTableValueMode.Spread);

    const [selectedRateTableType, setSelectedRateTableType] =
      useState<OutboundRateTableType>(RateTableType.OUTBOUND_WEIGHT_BY_ZONE);

    // Service levels depends on the carrier
    const serviceLevelOptions = useMemo(() => {
      return RateTableServiceLevelsMapping[selectedCarrier];
    }, [selectedCarrier]);

    useEffect(() => {
      // Default service levels should always be spread rate tables
      if (selectedServiceLevel === "Default") {
        setSelectedValueMode(RateTableValueMode.Spread);
        setSelectedRateTableType(RateTableType.OUTBOUND_WEIGHT_BY_ZONE);
      }
    }, [selectedServiceLevel]);

    const valueModeOptions = useMemo(() => {
      const oneRateServiceLevels: RateTableServiceLevel[] = [
        FedexRateTableServiceLevel.OneRateStandard,
        FedexRateTableServiceLevel.OneRatePriorityOvernight,
        FedexRateTableServiceLevel.OneRateFirstOvernight,
        FedexRateTableServiceLevel.OneRateTwoDay,
        FedexRateTableServiceLevel.OneRateTwoDayAM,
        FedexRateTableServiceLevel.OneRateExpressSaver,
      ];

      if (
        oneRateServiceLevels.includes(selectedServiceLevel) ||
        selectedCarrier === RateTableCarrier.UPS ||
        selectedServiceLevel === "Default"
      ) {
        return [RateTableValueMode.Spread];
      }
      return Object.values(RateTableValueMode);
    }, [selectedServiceLevel, selectedCarrier]);

    // Select the first value mode for that carrier when the service level options change
    useEffect(() => {
      setSelectedValueMode(valueModeOptions[0]);
    }, [valueModeOptions]);

    // Select the first service level for that carrier when the service level options change
    useEffect(() => {
      setSelectedServiceLevel(serviceLevelOptions[0]);
    }, [serviceLevelOptions]);

    const rateTableTypeOptions = useMemo<OutboundRateTableType[]>(() => {
      if (
        selectedCarrier === RateTableCarrier.USPS &&
        selectedServiceLevel !== "Default"
      ) {
        return [
          RateTableType.OUTBOUND_WEIGHT_BY_ZONE,
          RateTableType.OUTBOUND_SIZE_BY_ZONE,
        ];
      }
      return [RateTableType.OUTBOUND_WEIGHT_BY_ZONE];
    }, [selectedCarrier, selectedServiceLevel]);

    useEffect(() => {
      setSelectedRateTableType(rateTableTypeOptions[0]);
    }, [rateTableTypeOptions]);

    useEffect(() => {
      triggerRateCardLoad();
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team, client]);

    const rateTableInDB = useMemo<RateCardValue | undefined>(() => {
      const rateTable = rateCardLoad.value?.find(
        (rateTable: OutboundRateTable) =>
          rateTable.carrier === selectedCarrier &&
          rateTable.serviceLevel === selectedServiceLevel &&
          rateTable.valueMode === selectedValueMode &&
          rateTable.rateTableType === selectedRateTableType,
      );
      return rateTable
        ? { ...rateTable, data: rateTableToTableData(rateTable) }
        : undefined;
    }, [
      rateCardLoad.value,
      selectedCarrier,
      selectedServiceLevel,
      selectedValueMode,
      selectedRateTableType,
    ]);

    return (
      <OutboundLabelRateCardCardInternal
        key={getKey(rateTableInDB)}
        rateTableInDB={rateTableInDB}
        rateTableTypeOptions={rateTableTypeOptions}
        readonly={readonly}
        selectedCarrier={selectedCarrier}
        selectedRateTableType={selectedRateTableType}
        selectedServiceLevel={selectedServiceLevel}
        selectedValueMode={selectedValueMode}
        serviceLevelOptions={serviceLevelOptions}
        setSelectedCarrier={setSelectedCarrier}
        setSelectedRateTableType={setSelectedRateTableType}
        setSelectedServiceLevel={setSelectedServiceLevel}
        setSelectedValueMode={setSelectedValueMode}
        triggerReload={triggerRateCardLoad}
        valueModeOptions={valueModeOptions}
      />
    );
  },
);

const OutboundLabelRateCardCardInternal = memo(
  function OutboundLabelRateCardCardInternal({
    rateTableInDB,
    triggerReload,
    serviceLevelOptions,
    valueModeOptions,
    rateTableTypeOptions,
    selectedCarrier,
    selectedServiceLevel,
    selectedValueMode,
    selectedRateTableType,
    setSelectedCarrier,
    setSelectedServiceLevel,
    setSelectedValueMode,
    setSelectedRateTableType,
    readonly,
  }: {
    rateTableInDB: RateCardValue | undefined;
    triggerReload: () => void;
    serviceLevelOptions: RateTableServiceLevel[];
    valueModeOptions: RateTableValueMode[];
    rateTableTypeOptions: OutboundRateTableType[];
    selectedCarrier: RateTableCarrier;
    selectedServiceLevel: RateTableServiceLevel;
    selectedValueMode: RateTableValueMode;
    selectedRateTableType: OutboundRateTableType;
    setSelectedCarrier: (carrier: RateTableCarrier) => void;
    setSelectedServiceLevel: (serviceLevel: RateTableServiceLevel) => void;
    setSelectedValueMode: (valueMode: RateTableValueMode) => void;
    setSelectedRateTableType: (rateTableType: OutboundRateTableType) => void;
    readonly: boolean;
  }) {
    const client = useRequiredContext(RedoAdminRpcClientContext);
    const team = useRequiredContext(TeamContext);
    const [savePending, setSavePending] = useState(false);

    const rateTableDataInput = useInput(
      rateCardForm,
      rateTableInDB ??
        defaultNewOutboundRateTable(
          selectedCarrier,
          selectedServiceLevel,
          selectedValueMode,
          selectedRateTableType,
        ),
    );

    const saveDisabled = useMemo(
      () => savePending || rateTableDataInput.allErrors.length > 0 || readonly,
      [savePending, rateTableDataInput.allErrors.length, readonly],
    );

    const saveRateCard = async () => {
      if (readonly) {
        alert("Insufficient permissions to save rate table");
        return;
      }
      const tableData = tableDataToRateData(rateTableDataInput.value.data);
      if (selectedValueMode === RateTableValueMode.Spread) {
        const hasLargeValue = tableData.some((row) =>
          row.some((value) => Number(value) > 5),
        );
        if (hasLargeValue) {
          const confirmed = window.confirm(
            "Spread values should be less than $5. Do you want to continue?",
          );
          if (!confirmed) {
            return;
          }
        }
      } else if (selectedValueMode === RateTableValueMode.Rate) {
        const hasSmallValue = tableData.some((row) =>
          row.some((value) => Number(value) < 3.3),
        );
        if (hasSmallValue) {
          const confirmed = window.confirm(
            "Rate values should be greater than $3.3. Do you want to continue?",
          );
          if (!confirmed) {
            return;
          }
        }
      }

      setSavePending(true);
      let table: RateTable;
      if (selectedRateTableType === RateTableType.OUTBOUND_WEIGHT_BY_ZONE) {
        table = {
          rateTableType: RateTableType.OUTBOUND_WEIGHT_BY_ZONE,
          valueMode: selectedValueMode,
          carrier: selectedCarrier,
          serviceLevel: selectedServiceLevel,
          data: tableData,
          xAxis: {
            values: tableDataToZones(rateTableDataInput.value.data),
            kind: AxisKind.ZONE,
          },
          yAxis: {
            values: tableDataToMinMaxWeights(rateTableDataInput.value.data),
            kind: AxisKind.WEIGHT,
            units: WeightUnit.LB,
          },
          defaultValue: rateTableDataInput.value.defaultValue,
        };
      } else {
        table = {
          rateTableType: RateTableType.OUTBOUND_SIZE_BY_ZONE,
          valueMode: selectedValueMode,
          carrier: selectedCarrier,
          serviceLevel: selectedServiceLevel,
          data: tableData,
          xAxis: {
            values: tableDataToZones(rateTableDataInput.value.data),
            kind: AxisKind.ZONE,
          },
          yAxis: {
            values: tableDataToMinMaxCubicSizes(rateTableDataInput.value.data),
            kind: AxisKind.CUBIC_SIZE,
            units: LengthUnit.INCH,
          },
          defaultValue: rateTableDataInput.value.defaultValue,
        };
      }

      try {
        await client.saveRateTable({ rateTable: table, teamId: team._id });
      } catch (error) {
        toast("Failed to save rate table", { variant: "error" });
        console.error(error);
      } finally {
        setSavePending(false);
        triggerReload();
      }
    };

    const deleteRateTable = useHandler(async () => {
      if (readonly) {
        alert("Insufficient permissions to delete rate table");
        return;
      }
      const confirmed = window.confirm(
        "Are you sure you want to delete this rate table?",
      );
      if (confirmed) {
        await client.deleteRateTable({
          teamId: team._id,
          carrier: selectedCarrier,
          serviceLevel: selectedServiceLevel,
          valueMode: selectedValueMode,
          rateTableType: selectedRateTableType,
        });
        triggerReload();
      }
    });

    const addBottomRow = () => {
      const bottomRow =
        rateTableDataInput.value.data.at(-1)?.map((_) => "0") ?? [];
      rateTableDataInput.inputs.data.setValue([
        ...rateTableDataInput.value.data,
        bottomRow,
      ]);
    };

    const addRightColumn = () => {
      const newColumn =
        rateTableDataInput.value.data
          .map((row) => row.at(-1))
          .map((_) => "0") ?? [];
      const newCharges = rateTableDataInput.value.data.map((row, index) => [
        ...row,
        newColumn.at(index) ?? "0",
      ]);
      rateTableDataInput.inputs.data.setValue(newCharges);
    };

    const deleteRow = (index: number) => {
      rateTableDataInput.inputs.data.setValue(
        rateTableDataInput.value.data.filter((_, i) => i !== index),
      );
    };

    const deleteColumn = (columnIndex: number) => {
      rateTableDataInput.inputs.data.setValue(
        rateTableDataInput.value.data.map((row) =>
          row.filter((_, i) => i !== columnIndex),
        ),
      );
    };

    const addRateTable = async () => {
      await client.saveRateTable({
        rateTable: defaultNewOutboundRateTable(
          selectedCarrier,
          selectedServiceLevel,
          selectedValueMode,
          selectedRateTableType,
        ),
        teamId: team._id,
      });
      triggerReload();
    };

    const copyTable = () => {
      const data = rateTableDataInput.value.data.map((row) => [...row]);
      // Make sure we copy a blank cell for the upper left cell, it's useless
      data[0][0] = "";
      const excelData = data.map((row) => row.join("\t")).join("\n");
      void navigator.clipboard.writeText(excelData);
    };

    const getServiceLabel = (service: RateTableServiceLevel) => {
      if (isUpsService(service)) {
        return upsServiceToLabel[service];
      }

      return service;
    };

    return (
      <Card title="Outbound rate tables">
        <Flex dir="row" justify="space-between">
          <Flex dir="row" justify="space-between" w="full">
            <Flex dir="column" w="full">
              <Flex dir="row" justify="space-between" w="full">
                <Flex dir="row">
                  <RedoSingleSelectDropdownInput<RateTableCarrier>
                    className={styles.rateTableCarrierDropdown}
                    options={Object.values(RateTableCarrier).map<
                      RedoListItem<RateTableCarrier>
                    >((s) => ({
                      id: s,
                      type: "text",
                      text: rateTableCarrierLabel(s),
                      value: s,
                    }))}
                    optionSelected={({ item }) => {
                      setSelectedCarrier(item.value);
                    }}
                    selectedItem={{
                      id: selectedCarrier,
                      type: "text",
                      text: rateTableCarrierLabel(selectedCarrier),
                      value: selectedCarrier,
                    }}
                    size="sm"
                  />
                  <RedoSingleSelectDropdownInput<RateTableServiceLevel>
                    className={styles.rateTableServiceLevelDropdown}
                    options={serviceLevelOptions.map((s) => ({
                      id: s,
                      type: "text",
                      text: getServiceLabel(s),
                      value: s,
                    }))}
                    optionSelected={({ item }) => {
                      setSelectedServiceLevel(item.value);
                    }}
                    selectedItem={{
                      id: selectedServiceLevel,
                      type: "text",
                      text: getServiceLabel(selectedServiceLevel),
                      value: selectedServiceLevel,
                    }}
                    size="md"
                  />
                  <RedoSingleSelectDropdownInput<RateTableValueMode>
                    className={styles.rateTableValueModeDropdown}
                    options={valueModeOptions.map((s) => ({
                      id: s,
                      type: "text",
                      text: s,
                      value: s,
                    }))}
                    optionSelected={({ item }) => {
                      setSelectedValueMode(item.value);
                    }}
                    selectedItem={{
                      id: selectedValueMode,
                      type: "text",
                      text: selectedValueMode,

                      value: selectedValueMode,
                    }}
                    size="md"
                  />
                  <RedoSingleSelectDropdownInput<OutboundRateTableType>
                    className={styles.rateTableTypeDropdown}
                    options={rateTableTypeOptions.map((s) => ({
                      id: s,
                      type: "text",
                      text:
                        s === RateTableType.OUTBOUND_WEIGHT_BY_ZONE
                          ? "Weight"
                          : "Size",

                      value: s,
                    }))}
                    optionSelected={({ item }) => {
                      setSelectedRateTableType(item.value);
                    }}
                    selectedItem={{
                      id: selectedRateTableType,
                      type: "text",
                      text:
                        selectedRateTableType ===
                        RateTableType.OUTBOUND_WEIGHT_BY_ZONE
                          ? "Weight"
                          : "Size",
                      value: selectedRateTableType,
                    }}
                    size="md"
                  />
                  {!rateTableInDB ? (
                    <RedoButton
                      disabled={readonly}
                      hierarchy="primary"
                      onClick={addRateTable}
                      size="lg"
                      text="Create"
                      theme="normal"
                    />
                  ) : (
                    rateTableDataInput.changed && (
                      <RedoButton
                        disabled={saveDisabled}
                        hierarchy="primary"
                        onClick={saveRateCard}
                        pending={savePending}
                        size="lg"
                        text="Save table"
                        theme="normal"
                      />
                    )
                  )}
                </Flex>
                {rateTableInDB && (
                  <Flex dir="row">
                    <RedoButton
                      disabled={readonly}
                      hierarchy="tertiary"
                      IconLeading={TrashIcon}
                      onClick={deleteRateTable}
                      size="lg"
                      text="Delete"
                      theme="destructive"
                    />
                    <RedoButton
                      hierarchy="tertiary"
                      IconLeading={CopyIcon}
                      onClick={copyTable}
                      size="lg"
                      text="Copy table"
                      theme="normal"
                    />
                    <RedoButton
                      disabled={readonly}
                      hierarchy="tertiary"
                      IconLeading={PlusIcon}
                      onClick={addRightColumn}
                      size="lg"
                      text="Add column"
                      theme="normal"
                    />
                    <RedoButton
                      disabled={readonly}
                      hierarchy="tertiary"
                      IconLeading={PlusIcon}
                      onClick={addBottomRow}
                      size="lg"
                      text="Add row"
                      theme="normal"
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {rateTableInDB && (
          <Flex dir="column">
            <Flex dir="row">
              <LabelRateTableForm
                deleteColumn={deleteColumn}
                deleteRow={deleteRow}
                input={rateTableDataInput}
                readonly={readonly}
                xAxisLabel="Zone"
                yAxisLabel={
                  selectedRateTableType ===
                  RateTableType.OUTBOUND_WEIGHT_BY_ZONE
                    ? "Max LB"
                    : "Max Cubic feet"
                }
              />
            </Flex>
            <Flex dir="column" pt="md" w="xs">
              <FormTextInput
                disabled={readonly}
                input={rateTableDataInput.inputs.defaultValue}
                label="Default spread (used if no match is found in the table)"
                type="number"
              />
            </Flex>
          </Flex>
        )}
      </Card>
    );
  },
);
