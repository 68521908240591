import {
  isPriceCondition,
  isWeightCondition,
  RateCondition,
} from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import {
  convertWeight,
  WeightUnit,
} from "@redotech/redo-model/weight-conversion";
import { isDef } from "@redotech/util/checks";

export function getTextFromConditions(conditions: RateCondition[]): string[] {
  const conditionsText: string[] = [];

  const minMaxPounds = getMinMaxPoundsFromConditions(conditions);
  if (isDef(minMaxPounds.minPounds) && isDef(minMaxPounds.maxPounds)) {
    conditionsText.push(
      `Orders greater than or equal to ${minMaxPounds.minPounds}lbs and less than ${minMaxPounds.maxPounds}lbs`,
    );
  }

  const minMaxPrices = getMinMaxPriceFromConditions(conditions);
  if (isDef(minMaxPrices.minPrice) && isDef(minMaxPrices.maxPrice)) {
    conditionsText.push(
      `Orders greater than or equal to ${CURRENCY_FORMAT(minMaxPrices.currency).format(minMaxPrices.minPrice)} and less than ${CURRENCY_FORMAT(minMaxPrices.currency).format(minMaxPrices.maxPrice)}`,
    );
  }

  return conditionsText;
}

export function getMinMaxPoundsFromConditions(
  conditions: RateCondition[],
):
  | { minPounds: string; maxPounds: string }
  | { minPounds: undefined; maxPounds: undefined } {
  const minMaxPounds = conditions.filter(isWeightCondition).map((condition) => {
    return {
      minPounds: convertWeight(
        condition.minGrams,
        WeightUnit.GRAMS,
        WeightUnit.POUNDS,
      ),
      maxPounds: convertWeight(
        condition.maxGrams,
        WeightUnit.GRAMS,
        WeightUnit.POUNDS,
      ),
    };
  });

  if (minMaxPounds.length === 0) {
    return { minPounds: undefined, maxPounds: undefined };
  }

  // Find the highest minPounds and the lowest maxPounds since weight
  // conditions are stored as two ranges.
  // E.g. the condition 5-10 is stored as [0, 10] and [5, <some large number>]
  const minPounds = minMaxPounds.reduce((min, current) => {
    return Math.max(min, current.minPounds);
  }, -Infinity);
  const maxPounds = minMaxPounds.reduce((max, current) => {
    return Math.min(max, current.maxPounds);
  }, Infinity);

  if (isFinite(minPounds) && isFinite(maxPounds)) {
    return { minPounds: minPounds.toFixed(2), maxPounds: maxPounds.toFixed(2) };
  }
  return { minPounds: undefined, maxPounds: undefined };
}

/**
 * Assumes conditions are all in the same currency.
 */
export function getMinMaxPriceFromConditions(
  conditions: RateCondition[],
):
  | { minPrice: number; maxPrice: number; currency: string }
  | { minPrice: undefined; maxPrice: undefined; currency: undefined } {
  const priceConditions = conditions.filter(isPriceCondition);
  if (
    priceConditions.length > 0 &&
    !priceConditions.every(
      (condition) => condition.currency === priceConditions[0].currency,
    )
  ) {
    throw new Error("Price conditions are not in the same currency");
  }
  if (priceConditions.length === 0) {
    return { minPrice: undefined, maxPrice: undefined, currency: undefined };
  }

  // Find the highest minPrice and the lowest maxPrice since price
  // conditions are stored as two ranges.
  // E.g. the condition 5-10 is stored as [0, 10] and [5, <some large number>]
  const minPrice = priceConditions.reduce<{
    price: number;
    currency: string | undefined;
  }>(
    (min, current) => {
      return current.minPrice > min.price
        ? { price: current.minPrice, currency: current.currency }
        : min;
    },
    { price: -Infinity, currency: undefined },
  );
  const maxPrice = priceConditions.reduce<{
    price: number;
    currency: string | undefined;
  }>(
    (max, current) => {
      return current.maxPrice < max.price
        ? { price: current.maxPrice, currency: current.currency }
        : max;
    },
    { price: Infinity, currency: undefined },
  );

  if (isFinite(minPrice.price) && isFinite(maxPrice.price)) {
    return {
      minPrice: minPrice.price,
      maxPrice: maxPrice.price,
      currency: priceConditions[0].currency,
    };
  }
  return { minPrice: undefined, maxPrice: undefined, currency: undefined };
}
