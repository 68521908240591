import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { CheckoutTreeNode, CheckoutTreeNodeZodSchema } from "./checkout-tree";
import { FulfillmentCutoffScheduleParser } from "./fulfillment-cutoff";

export enum CheckoutOptimizationWidgetType {
  COUNTDOWN_TIMER = "COUNTDOWN_TIMER",
  THRESHOLD_UPSELL = "THRESHOLD_UPSELL",
}

export const checkoutOptimizationWidgetTypeToLabel: Record<
  CheckoutOptimizationWidgetType,
  string
> = {
  [CheckoutOptimizationWidgetType.COUNTDOWN_TIMER]: "Countdown timer",
  [CheckoutOptimizationWidgetType.THRESHOLD_UPSELL]: "Threshold upsell",
};

// targets for purchase.checkout.block.render (as of Mar. 2025)
export enum PurchaseCheckoutBlockRenderTarget {
  INFORMATION1 = "INFORMATION1",
  INFORMATION2 = "INFORMATION2",
  DELIVERY1 = "DELIVERY1",
  PAYMENT1 = "PAYMENT1",
  PAYMENT2 = "PAYMENT2",
  ORDER_SUMMARY1 = "ORDER_SUMMARY1",
  ORDER_SUMMARY2 = "ORDER_SUMMARY2",
  ORDER_SUMMARY3 = "ORDER_SUMMARY3",
  ORDER_SUMMARY4 = "ORDER_SUMMARY4",
}

export const PurchaseCheckoutBlockRenderTargetToDisplayText: Record<
  PurchaseCheckoutBlockRenderTarget,
  string
> = {
  [PurchaseCheckoutBlockRenderTarget.INFORMATION1]: "Information 1",
  [PurchaseCheckoutBlockRenderTarget.INFORMATION2]: "Information 2",
  [PurchaseCheckoutBlockRenderTarget.DELIVERY1]: "Delivery 1",
  [PurchaseCheckoutBlockRenderTarget.PAYMENT1]: "Payment 1",
  [PurchaseCheckoutBlockRenderTarget.PAYMENT2]: "Payment 2",
  [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY1]: "Order summary 1",
  [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY2]: "Order summary 2",
  [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY3]: "Order summary 3",
  [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY4]: "Order summary 4",
};

export const COUNTDOWN_TIMER_WIDGET_ORDER_CUTOFF_TIME_PLACEHOLDER =
  "{{order_cutoff_time}}";
export const COUNTDOWN_TIMER_WIDGET_DAY_OF_ORDER_CUTOFF_TIME_PLACEHOLDER =
  "{{day_of_order_cutoff_time}}";

const CheckoutOptimizationCountdownTimerWidgetSchema = z.object({
  type: z.literal(CheckoutOptimizationWidgetType.COUNTDOWN_TIMER),
  _id: zExt.objectId(),
  text: z.string(),
});
export type CheckoutOptimizationCountdownTimerWidget = z.infer<
  typeof CheckoutOptimizationCountdownTimerWidgetSchema
>;

export const THRESHOLD_UPSELL_WIDGET_AMOUNT_LEFT_PLACEHOLDER =
  "{{amount_left}}";

export const THRESHOLD_UPSELL_WIDGET_ADD_TO_CART_BUTTON_TEXT = "Add";

const CheckoutOptimizationThresholdUpsellWidgetSchema = z.object({
  type: z.literal(CheckoutOptimizationWidgetType.THRESHOLD_UPSELL),
  _id: zExt.objectId(),
  threshold: z.object({ amount: z.number(), currency: z.string() }),
  gteThresholdText: z.string(),
  ltThresholdText: z.string(),
  addToCartButtonText: z.string(),
  addToCartButtonTextColor: z.string().optional(),
  addToCartButtonColor: z.string().optional(),
  progressBarColor: z
    .object({ filled: z.string().optional(), unfilled: z.string().optional() })
    .optional(),
  excludedProductTags: z.array(z.string()).optional(),
});
export type CheckoutOptimizationThresholdUpsellWidget = z.infer<
  typeof CheckoutOptimizationThresholdUpsellWidgetSchema
>;

const WidgetTargetConfigSchema = z.object({
  widgets: z.array(
    z.discriminatedUnion("type", [
      CheckoutOptimizationCountdownTimerWidgetSchema,
      CheckoutOptimizationThresholdUpsellWidgetSchema,
    ]),
  ),
});
export type WidgetTargetConfigWidgets = z.infer<
  typeof WidgetTargetConfigSchema.shape.widgets
>;

export const WidgetConfigForCheckoutExperienceSchema = z.object({
  targetToConfig: z.object({
    [PurchaseCheckoutBlockRenderTarget.INFORMATION1]: WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.INFORMATION2]: WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.DELIVERY1]: WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.PAYMENT1]: WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.PAYMENT2]: WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY1]:
      WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY2]:
      WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY3]:
      WidgetTargetConfigSchema,
    [PurchaseCheckoutBlockRenderTarget.ORDER_SUMMARY4]:
      WidgetTargetConfigSchema,
  } satisfies Record<
    PurchaseCheckoutBlockRenderTarget,
    typeof WidgetTargetConfigSchema
  >),
});
export type WidgetConfigForCheckoutExperience = z.infer<
  typeof WidgetConfigForCheckoutExperienceSchema
>;

export const WidgetCheckoutOptimizationConfigSchema = z.object({
  checkoutTreeNode: CheckoutTreeNodeZodSchema.nullable(),
  checkoutExperienceIdToWidgetConfig: z.record(
    z.string(),
    WidgetConfigForCheckoutExperienceSchema,
  ),
  cutoffTimeSchedule: FulfillmentCutoffScheduleParser,
});
export type WidgetCheckoutOptimizationConfig = z.infer<
  typeof WidgetCheckoutOptimizationConfigSchema
>;
export type ManuallyTypedWidgetCheckoutOptimizationConfig = Omit<
  WidgetCheckoutOptimizationConfig,
  "checkoutTreeNode"
> & { checkoutTreeNode: CheckoutTreeNode | null };
