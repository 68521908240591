import { SignUpFormGoal } from "@redotech/redo-model/signup-form";
import { signupFormSchema } from "@redotech/redo-model/signup-form-schema";
import { z } from "zod";

export const getFormsByGoal = {
  input: z.object({ goal: z.nativeEnum(SignUpFormGoal) }),
  output: z.array(
    z.object({
      id: z.string(),
      form: signupFormSchema,
      publishedAt: z.date().nullable(),
    }),
  ),
};
