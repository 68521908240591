import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export enum GetShopifyPlanResult {
  SUCCESS = "SUCCESS",
  ERROR__NO_SHOPIFY_INTEGRATION_FOUND = "ERROR__NO_SHOPIFY_INTEGRATION_FOUND",
  ERROR__SHOPIFY_PLAN_NOT_KNOWN = "ERROR__SHOPIFY_PLAN_NOT_KNOWN",
}

export const getShopifyPlan = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.union([
    z.object({
      result: z.literal(GetShopifyPlanResult.SUCCESS),
      displayName: z.string(),
      shopifyPlus: z.boolean(),
      lastSyncedAt: z.date(),
    }),
    z.object({
      result: z.literal(
        GetShopifyPlanResult.ERROR__NO_SHOPIFY_INTEGRATION_FOUND,
      ),
    }),
    z.object({
      result: z.literal(GetShopifyPlanResult.ERROR__SHOPIFY_PLAN_NOT_KNOWN),
    }),
  ]),
};
export type GetShopifyPlanOutput = z.infer<typeof getShopifyPlan.output>;
