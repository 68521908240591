import * as classNames from "classnames";
import { memo, ReactNode } from "react";
import CloseIcon from "../../arbiter-icon/x-close.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoButton } from "../buttons/redo-button";
import {
  RedoFeaturedIcon,
  RedoFeaturedIconProps,
} from "../featured-icon/redo-featured-icon";
import * as redoNotificationCss from "./redo-notification.module.css";

export interface NotificationButton {
  text: string;
  onClick(): void | Promise<void>;
}

export const notificationBreakpoint = ["mobile", "desktop"] as const;
export type NotificationBreakpoint = (typeof notificationBreakpoint)[number];

export interface NotificationProps {
  title: string;
  description: string;
  hideCloseX?: boolean;
  dismiss: () => void;
  primaryButton?: NotificationButton;
  dismissButton?: Omit<NotificationButton, "onClick">;
  featuredIcon?: Omit<RedoFeaturedIconProps, "size">;
  breakpoint?: NotificationBreakpoint;
  children?: ReactNode;
  fullModalImage?: string;
}

/**
 * To actually show a notification, use the function {@link showNotification}
 */
export const RedoNotification = memo(function RedoNotification({
  title,
  description,
  primaryButton,
  dismissButton,
  hideCloseX,
  dismiss,
  breakpoint = "desktop",
  featuredIcon,
  children = null,
  fullModalImage,
}: NotificationProps) {
  return (
    <Flex
      bgColor="primary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      className={redoNotificationCss.container}
      dir={breakpoint === "desktop" ? "row" : "column"}
      gap="xl"
      p="xl"
      radius="md"
    >
      {fullModalImage && (
        <Flex className={redoNotificationCss.fullModalImage} radius="md">
          <img alt="Full modal image" src={fullModalImage} />
        </Flex>
      )}
      <Flex
        className={classNames(
          redoNotificationCss.contentContainer,
          breakpointToClass[breakpoint],
          fullModalImage ? redoNotificationCss.withImage : "",
        )}
      >
        {featuredIcon && (
          <Flex>
            <RedoFeaturedIcon {...featuredIcon} size="md" />
          </Flex>
        )}
        <Flex
          dir="column"
          gap="lg"
          grow={1}
          pr={breakpoint === "desktop" ? "4xl" : "none"}
        >
          <Flex dir="column" gap="xs">
            <Text fontSize="sm" fontWeight="semibold" textColor="primary">
              {title}
            </Text>
            <Text fontSize="sm" fontWeight="regular" textColor="secondary">
              {description}
            </Text>
          </Flex>
          {children}
          <Flex>
            {dismissButton && (
              <RedoButton
                hierarchy="secondary"
                onClick={dismiss}
                size="sm"
                text={dismissButton.text}
              />
            )}
            {primaryButton && (
              <RedoButton
                hierarchy="primary"
                onClick={async () => {
                  await primaryButton.onClick();
                  dismiss();
                }}
                size="sm"
                text={primaryButton.text}
              />
            )}
          </Flex>
        </Flex>
        {!hideCloseX && (
          <RedoButton
            className={redoNotificationCss.closeButton}
            hierarchy="tertiary"
            IconLeading={CloseIcon}
            onClick={dismiss}
            size="md"
          />
        )}
      </Flex>
    </Flex>
  );
});

const breakpointToClass: Record<NotificationBreakpoint, string> = {
  mobile: redoNotificationCss.mobile,
  desktop: redoNotificationCss.desktop,
};
