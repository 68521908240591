import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getCheckoutOutboundRatesSettings = {
  input: z.object({ teamId: zExt.objectId() }),
  output: z.object({
    enabled: z.boolean(),
    demoEnabled: z.boolean(),
    hideNonRedoRatesWhenRedoPresent: z.boolean(),
  }),
};
