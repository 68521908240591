import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { useObjectUrl } from "@redotech/react-util/url";
import { UploadFileError } from "@redotech/redo-model/upload-file";
import { Input } from "@redotech/ui/form";
import {
  ChangeEventHandler,
  createContext,
  DragEventHandler,
  memo,
  useId,
  useState,
} from "react";
import { RedoButton } from "./arbiter-components/buttons/redo-button";
import PlusCircleIcon from "./arbiter-icon/plus-circle.svg";
import CircleSpinner from "./circle-spinner.svg";
import { Flex } from "./flex";
import XIcon from "./icon-old/x.svg";
import * as imageUploadCss from "./image-upload.module.css";
import { LabeledInput } from "./labeled-input";
import { Text } from "./text";

export interface ImageUploadProps {
  id?: string;
  value: URL | undefined;
  onChange?(value: URL | undefined): void;
  disabled?: boolean;
  className?: string;
  caption?: string;
  maxFileSizeKb?: number | undefined;
  showErrorMessages?: boolean | undefined;
  accept?: string[] | undefined;
}

/**
 * Input an image which is immediately uploaded.
 */
export const ImageUpload = memo(function ImageUpload({
  id,
  onChange,
  value,
  className,
  disabled = false,
  caption,
  maxFileSizeKb,
  showErrorMessages = false,
  accept,
}: ImageUploadProps) {
  return (
    <MediaUpload
      accept={accept}
      caption={caption}
      className={className}
      disabled={disabled}
      id={id}
      maxFileSizeKb={maxFileSizeKb}
      mediaType="image"
      onChange={onChange}
      showErrorMessages={showErrorMessages}
      value={value}
    />
  );
});

export const VideoUpload = memo(function VideoUpload({
  id,
  onChange,
  value,
  className,
  disabled = false,
  caption,
}: ImageUploadProps) {
  return (
    <MediaUpload
      caption={caption}
      className={className}
      disabled={disabled}
      id={id}
      mediaType="video"
      onChange={onChange}
      value={value}
    />
  );
});

const MediaUpload = memo(function MediaUpload({
  id,
  onChange,
  value,
  className,
  disabled = false,
  caption,
  mediaType,
  maxFileSizeKb,
  showErrorMessages = false,
  accept,
}: ImageUploadProps & {
  mediaType: "image" | "video";
  maxFileSizeKb?: number;
}) {
  const uploader = useRequiredContext(UploaderContext);

  const [inputElement, setInputElement] = useState<HTMLInputElement | null>();

  const [file, setFile] = useState<File | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const setUploadErrorMessage = useHandler((error: unknown) => {
    if (
      typeof error === "object" &&
      error !== null &&
      "response" in error &&
      typeof error.response === "object" &&
      error.response !== null &&
      "data" in error.response &&
      typeof error.response.data === "object" &&
      error.response.data !== null &&
      "error" in error.response.data &&
      "errorMessage" in error.response.data &&
      typeof error.response.data.errorMessage === "string" &&
      error.response.data.error ===
        UploadFileError.FILE_SIZE_LARGER_THAN_TARGET_MAX_SIZE
    ) {
      setErrorMessage(error.response.data.errorMessage);
    } else {
      setErrorMessage("Something went wrong. Please try again.");
    }
  });

  const [uploadLoad, doUpload] = useTriggerLoad(async (signal) => {
    try {
      setErrorMessage(undefined);
      const newValue = file
        ? await uploader({ file, maxFileSizeKb, signal })
        : undefined;
      setFile(undefined);
      onChange && onChange(newValue);
    } catch (error) {
      setFile(undefined);
      setUploadErrorMessage(error);
    }
  });

  const objectUrl = useObjectUrl(file);

  const handleRemove = useHandler(() => {
    setFile(undefined);
    setErrorMessage(undefined);
    doUpload();
  });
  const handleUpload = useHandler(() => inputElement?.click());

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setFile(event.target.files![0]);
    doUpload();
  };

  const dropHandler: DragEventHandler = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      event.dataTransfer.items[0];
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const item = event.dataTransfer.items[i];
        const file = item.getAsFile();
        if (file) {
          setFile(file);
          doUpload();
        }
      }
    } else if (event.dataTransfer.files) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        setFile(file);
        doUpload();
      }
    }
  };

  const cancelDrag: DragEventHandler = (event) => {
    event.preventDefault();
  };

  const url = objectUrl || value?.toString();

  return (
    <Flex
      className={className}
      dir="column"
      onDragOver={cancelDrag}
      onDrop={dropHandler}
      position="relative"
    >
      <input
        accept={accept?.join(",")}
        className={imageUploadCss.input}
        id={id}
        onChange={handleChange}
        ref={setInputElement}
        type="file"
      />

      <Flex
        bgColor="primary"
        borderColor="secondary"
        borderStyle="solid"
        borderWidth="1px"
        className={imageUploadCss.imageButtonContainer}
        cursor="pointer"
        overflow="hidden"
        position="relative"
        radius="sm"
      >
        {mediaType === "video" && url && (
          <Flex
            className={imageUploadCss.videoContainer}
            height="full"
            justify="center"
            position="absolute"
            w="full"
          >
            <video>
              <source src={url} />
              <a href={url}>Download video</a>
            </video>
          </Flex>
        )}
        <button
          className={imageUploadCss.imageButton}
          disabled={disabled}
          onClick={handleUpload}
          {...(mediaType === "image" &&
            url && { style: { backgroundImage: `url(${url})` } })}
          type="button"
        >
          {!url && (
            <Flex
              align="center"
              dir="column"
              gap="xs"
              justify="center"
              position="relative"
              px="3xl"
              py="xl"
            >
              <PlusCircleIcon />
              <Text fontSize="sm" mt="md" textColor="primary">
                Add {mediaType === "image" ? "Image" : "Video"}
              </Text>
            </Flex>
          )}
        </button>
        {url && (
          <RedoButton
            className={imageUploadCss.removeButton}
            disabled={disabled}
            hierarchy="secondary"
            IconLeading={XIcon}
            onClick={handleRemove}
            size="xs"
            type="button"
          />
        )}
        {uploadLoad.pending && (
          <div className={imageUploadCss.spinnerContainer}>
            <CircleSpinner className={imageUploadCss.spinner} />
          </div>
        )}
      </Flex>
      {caption && (
        <Text fontSize="xs" textColor="tertiary">
          {caption}
        </Text>
      )}
      {showErrorMessages && errorMessage && (
        <Text fontSize="xs" textColor="error">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
});

export const FormImageUpload = memo(function FormImageUpload({
  label,
  description,
  input,
  disabled = false,
  manualErrors,
  className,
  maxFileSizeKb,
  ...props
}: {
  description?: string;
  input: Input<URL | undefined>;
  label: string;
  disabled?: boolean;
  manualErrors?: string[];
  className?: string;
} & Omit<ImageUploadProps, "value" | "onChange">) {
  const id = useId();
  return (
    <LabeledInput
      description={description}
      errors={
        manualErrors?.length ? manualErrors : input.changed ? input.errors : []
      }
      id={id}
      label={label}
    >
      <ImageUpload
        className={className}
        disabled={disabled}
        id={id}
        maxFileSizeKb={maxFileSizeKb}
        onChange={input.setValue}
        value={input.value}
        {...props}
      />
    </LabeledInput>
  );
});

/**
 * Upload image
 */
export interface Uploader {
  ({
    file,
    maxFileSizeKb,
    signal,
  }: {
    file: File;
    maxFileSizeKb?: number | undefined;
    signal: AbortSignal;
  }): Promise<URL>;
}

export const UploaderContext = createContext<Uploader | undefined>(undefined);
