import { AttachmentStrategy } from "@redotech/redo-model/team";
import {
  termsAndConditionsSchema,
  widgetConfigSchema,
} from "@redotech/redo-model/widget-schema";
import { z } from "zod";
import { PatchCode } from "./patch-codes";

const newWidgetConfigsBase = z.object({
  widgetConfigs: z.array(widgetConfigSchema),
});

const oldWidgetConfigsBase = z.object({});

const clickStandardCheckoutButtonSchema = z.object({
  enabled: z.boolean(),
  checkoutButtonSelector: z.string(),
});

export const updateWidgetConfigsPatchSchema = z.object({
  code: z.literal(PatchCode.UPDATE_WIDGET_CONFIGS),
  teamId: z.string(),
  /** Discriminated unions can only ever have one primary key at a time, so we have to technically make a different schema for each attachment strategy */
  widgetsInfo: z.discriminatedUnion("attachmentStrategy", [
    newWidgetConfigsBase.extend({
      attachmentStrategy: z.literal(AttachmentStrategy.CHECKOUT_BUTTON_PRODUCT),
    }),
    newWidgetConfigsBase.extend({
      attachmentStrategy: z.literal(
        AttachmentStrategy.CHECKOUT_BUTTON_SHIPPING,
      ),
    }),
    oldWidgetConfigsBase.extend({
      attachmentStrategy: z.literal(AttachmentStrategy.CART_CARD),
    }),
  ]),
  customToggleCss: z.string().optional(),
  termsAndConditions: termsAndConditionsSchema.optional(),
  hideProductEnabled: z.boolean().optional(),
  hideCartProductSelector: z.string().optional(),
  hideCheckoutButtonSelector: z.string().optional(),
  groupNearbyToggleElementsTogether: z.boolean().optional(),
  forceDoubleCheckoutToggle: z.boolean().optional(),
  usingCartAndCheckoutToggle: z.boolean().optional(),
  clickStandardCheckoutButton: clickStandardCheckoutButtonSchema.optional(),
  cartElementImage: z.string().optional(),
});

export type UpdateWidgetConfigsPatch = z.infer<
  typeof updateWidgetConfigsPatchSchema
>;

export const patchWidgetConfigs = {
  input: updateWidgetConfigsPatchSchema,
  output: z.object({}),
};
