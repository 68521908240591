import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { OutboundRate } from "@redotech/redo-model/outbound-labels/outbound-labels";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdownInput } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useMemo, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";

export const LookupRateDetailsCard = memo(function LookupRateDetailsCard() {
  const client = useRequiredContext(RedoAdminRpcClientContext);

  const [groupId, setGroupId] = useState<string>("");

  const [rateDetailsLoad, triggerRateDetailsLoad] = useTriggerLoad(async () => {
    const { carrier, service } = selectedRate ?? {};

    const result = await client.getShipmentRateDetails({
      fulfillmentGroupId: groupId,
    });

    let matchingRate = result.rateDetails.find(
      (rate: OutboundRate) =>
        rate.carrier === carrier && rate.service === service,
    );

    if (!matchingRate) {
      matchingRate = result.rateDetails[0];
    }

    setSelectedRate(matchingRate);
    return result.rateDetails;
  });

  const [selectedRate, setSelectedRate] = useState<OutboundRate | null>(null);

  const options: RedoListItem<OutboundRate>[] = useMemo(() => {
    return (
      rateDetailsLoad.value?.map((rate: OutboundRate) => ({
        value: rate,
        type: "text",
        id: rate.id,
        text: `${rate.carrier} ${rate.service}`,
      })) ?? []
    );
  }, [rateDetailsLoad.value]);

  return (
    <Card
      subtitle="This will only load the details of the rates we already have. To see changes from the rate table reflected, you will need to reload the rates from the OMS"
      title="Lookup Rate Details"
    >
      <Flex dir="row">
        <RedoTextInput
          fullWidth
          placeholder="67bdcae4433b1abda5387360"
          setValue={setGroupId}
          value={groupId}
        />
        <RedoButton
          disabled={groupId.length === 0}
          hierarchy="primary"
          onClick={() => {
            triggerRateDetailsLoad();
          }}
          pending={rateDetailsLoad.pending}
          text="Load rates"
          theme="success"
        />
      </Flex>
      {rateDetailsLoad.value && (
        <Flex dir="column">
          <RedoSingleSelectDropdownInput<OutboundRate>
            disabled={!rateDetailsLoad.value}
            options={options}
            optionSelected={({ item }) => {
              setSelectedRate(item.value);
            }}
            placeholder="Select rate"
            selectedItem={options.find((o) => o.id === selectedRate?.id)}
          />
          {selectedRate && (
            <ul>
              <li>{`Margin: $${(
                Number(selectedRate?.rate) - Number(selectedRate?.provider_cost)
              ).toFixed(2)}`}</li>
              <li>{`Merchant cost: $${selectedRate?.rate}`}</li>
              <li>{`Redo cost: $${selectedRate?.provider_cost}`}</li>
              <li>{`Fedex zone: ${selectedRate?.fedex_zone}`}</li>
              <li>{`USPS zone: ${selectedRate?.usps_zone}`}</li>
              <li>{`Carrier account: ${selectedRate?.carrier_account_id}`}</li>
            </ul>
          )}
        </Flex>
      )}
    </Card>
  );
});
