import { isEnumValue } from "@redotech/util/enum";
import { assertNever } from "@redotech/util/type";
import { z } from "zod";

// The names of the carriers that we should be using in our system
export enum StandardCarrier {
  FEDEX = "FedEx",
  UPS = "UPS",
  USPS = "USPS",
}

// All of the carrier names that easypost gives us. Each carrier account can have a different name.
export enum Carriers {
  FEDEX = "FedEx",
  UPS = "UPS",
  UPS_SUREPOST = "UPSSurePost",
  USPS = "USPS",
  USPS_RETURNS = "USPSReturns",
  USPS_REDO = "UspsShip",
  FEDEX_SMART_POST = "FedExSmartPost",
  AMAZON = "AmazonShipping",
  // CAUTION: If you ever add a new carrier here, please make sure to add it to the
  // RedoCarrierServiceSchema in redo/model/src/integration/redo-in-shipping/service-code.ts.
  // The type system will catch some Record<Carriers, ...> in that file, but won't catch
  // handling it in that parser.
}

export const carrierToLabel: Record<Carriers, string> = {
  [Carriers.FEDEX]: "FedEx",
  [Carriers.FEDEX_SMART_POST]: "FedEx",
  [Carriers.UPS]: "UPS",
  [Carriers.UPS_SUREPOST]: "UPS",
  [Carriers.USPS]: "USPS",
  [Carriers.USPS_RETURNS]: "USPS",
  [Carriers.USPS_REDO]: "USPS",
  [Carriers.AMAZON]: "Amazon",
};

export function isCarrier(carrier: string): carrier is Carriers {
  return isEnumValue(carrier, Carriers);
}

export function getTrackingLink(
  carrier: Carriers,
  trackingCode: string,
  publicUrl: string,
) {
  switch (carrier) {
    case Carriers.USPS:
    case Carriers.USPS_RETURNS:
    case Carriers.USPS_REDO:
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingCode}`;
    case Carriers.UPS:
    case Carriers.UPS_SUREPOST:
      return `https://www.ups.com/track?loc=en_US&tracknum=${trackingCode}`;
    case Carriers.FEDEX:
    case Carriers.FEDEX_SMART_POST:
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingCode}`;
    case Carriers.AMAZON:
      return `https://track.amazon.com/tracking/${trackingCode}`;
    default:
      return publicUrl;
  }
}

export function isUspsCarrier(carrier: string): boolean {
  const USPS_CARRIERS = [
    Carriers.USPS,
    Carriers.USPS_RETURNS,
    Carriers.USPS_REDO,
  ];
  return isCarrier(carrier) && USPS_CARRIERS.includes(carrier);
}

/**
 * Includes both FedEx and FedExSmartPost
 */
export enum FedexServices {
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS = "FEDEX_INTERNATIONAL_PRIORITY_EXPRESS",
  FEDEX_INTERNATIONAL_CONNECT_PLUS = "FEDEX_INTERNATIONAL_CONNECT_PLUS",
  INTERNATIONAL_FIRST = "INTERNATIONAL_FIRST",
  FEDEX_GROUND = "FEDEX_GROUND",
  FEDEX_GROUND_HOME_DELIVERY = "GROUND_HOME_DELIVERY",
  FEDEX_2_DAY = "FEDEX_2_DAY",
  PRIORITY_OVERNIGHT = "PRIORITY_OVERNIGHT",
  FEDEX_FIRST_FREIGHT = "FEDEX_FIRST_FREIGHT",
  FEDEX_EXPRESS_SAVER = "FEDEX_EXPRESS_SAVER",
  FEDEX_3_DAY_FREIGHT = "FEDEX_3_DAY_FREIGHT",
  FEDEX_2_DAY_AM = "FEDEX_2_DAY_AM",
  FEDEX_2_DAY_FREIGHT = "FEDEX_2_DAY_FREIGHT",
  INTERNATIONAL_ECONOMY = "INTERNATIONAL_ECONOMY",
  FIRST_OVERNIGHT = "FIRST_OVERNIGHT",
  STANDARD_OVERNIGHT = "STANDARD_OVERNIGHT",
  SMART_POST = "SMART_POST",
  FEDEX_1_DAY_FREIGHT = "FEDEX_1_DAY_FREIGHT",
  INTERNATIONAL_PRIORITY = "INTERNATIONAL_PRIORITY",
  FEDEX_INTERNATIONAL_PRIORITY = "FEDEX_INTERNATIONAL_PRIORITY",
}

export function isFedexService(
  service: FedexServices | UpsServices | UspsServices | string,
): service is FedexServices {
  return isEnumValue(service, FedexServices);
}

/**
 * Includes both USPS and USPSReturns
 */
export enum UspsServices {
  GroundAdvantageReturn = "GroundAdvantageReturn",
  ExpressMailInternational = "ExpressMailInternational",
  PriorityMailInternational = "PriorityMailInternational",
  FirstClassPackageInternationalService = "FirstClassPackageInternationalService",
  GroundAdvantage = "GroundAdvantage",
  PriorityMailReturn = "PriorityMailReturn",
  Priority = "Priority",
  Express = "Express",
  FirstClass = "First",
  PriorityMailExpressReturn = "PriorityMailExpressReturn",
  MediaMail = "MediaMail",
  LibraryMail = "LibraryMail",
}

export function isUspsService(
  service: FedexServices | UpsServices | UspsServices | string,
): service is UspsServices {
  return isEnumValue(service, UspsServices);
}

export enum UpsServices {
  Ground = "Ground",
  NextDayAir = "NextDayAir",
  ThreeDaySelect = "3DaySelect",
  SecondDayAirAM = "2ndDayAirAM",
  UPSSaver = "UPSSaver",
  NextDayAirEarlyAM = "NextDayAirEarlyAM",
  ExpressPlus = "ExpressPlus",
  Express = "Express",
  NextDayAirSaver = "NextDayAirSaver",
  UPSStandard = "UPSStandard",
  SecondDayAir = "2ndDayAir",
  Expedited = "Expedited",
  SurePostUnder1Lb = "SurePostUnder1Lb",
  SurePostOver1Lb = "SurePostOver1Lb",
}

export function isUpsService(
  service: FedexServices | UpsServices | UspsServices | AmazonServices | string,
): service is UpsServices {
  return isEnumValue(service, UpsServices);
}

export enum AmazonServices {
  Ground = "Amazon Shipping Ground",
}

export function isAmazonService(
  service: FedexServices | UpsServices | UspsServices | AmazonServices | string,
): service is AmazonServices {
  return isEnumValue(service, AmazonServices);
}

export enum ServiceLevel {
  STANDARD = "Standard",
  PLUS = "Plus",
  EXPRESS = "Express",
}

const fedexServiceToServiceLevel: Record<FedexServices, ServiceLevel> = {
  // FedEx
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS]: ServiceLevel.PLUS,
  [FedexServices.FEDEX_INTERNATIONAL_CONNECT_PLUS]: ServiceLevel.STANDARD,
  [FedexServices.INTERNATIONAL_FIRST]: ServiceLevel.PLUS,
  [FedexServices.FEDEX_GROUND]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_GROUND_HOME_DELIVERY]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_2_DAY]: ServiceLevel.PLUS,
  [FedexServices.PRIORITY_OVERNIGHT]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_FIRST_FREIGHT]: ServiceLevel.EXPRESS, // or PLUS?
  [FedexServices.FEDEX_EXPRESS_SAVER]: ServiceLevel.PLUS,
  [FedexServices.FEDEX_3_DAY_FREIGHT]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_2_DAY_AM]: ServiceLevel.PLUS,
  [FedexServices.FEDEX_2_DAY_FREIGHT]: ServiceLevel.PLUS,
  [FedexServices.INTERNATIONAL_ECONOMY]: ServiceLevel.STANDARD,
  [FedexServices.FIRST_OVERNIGHT]: ServiceLevel.EXPRESS,
  [FedexServices.STANDARD_OVERNIGHT]: ServiceLevel.EXPRESS,
  [FedexServices.SMART_POST]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_1_DAY_FREIGHT]: ServiceLevel.EXPRESS,
  [FedexServices.INTERNATIONAL_PRIORITY]: ServiceLevel.PLUS,
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY]: ServiceLevel.PLUS,
};

const upsServiceToServiceLevel: Record<UpsServices, ServiceLevel> = {
  [UpsServices.Ground]: ServiceLevel.STANDARD,
  [UpsServices.NextDayAir]: ServiceLevel.EXPRESS,
  [UpsServices.ThreeDaySelect]: ServiceLevel.STANDARD,
  [UpsServices.SecondDayAirAM]: ServiceLevel.PLUS,
  [UpsServices.UPSSaver]: ServiceLevel.PLUS,
  [UpsServices.NextDayAirEarlyAM]: ServiceLevel.EXPRESS,
  [UpsServices.ExpressPlus]: ServiceLevel.EXPRESS,
  [UpsServices.Express]: ServiceLevel.PLUS,
  [UpsServices.NextDayAirSaver]: ServiceLevel.EXPRESS,
  [UpsServices.UPSStandard]: ServiceLevel.STANDARD,
  [UpsServices.SecondDayAir]: ServiceLevel.PLUS,
  [UpsServices.Expedited]: ServiceLevel.STANDARD,
  [UpsServices.SurePostOver1Lb]: ServiceLevel.STANDARD,
  [UpsServices.SurePostUnder1Lb]: ServiceLevel.STANDARD,
};

const uspsServiceToServiceLevel: Record<UspsServices, ServiceLevel> = {
  [UspsServices.MediaMail]: ServiceLevel.STANDARD,
  [UspsServices.LibraryMail]: ServiceLevel.STANDARD,
  [UspsServices.GroundAdvantageReturn]: ServiceLevel.STANDARD,
  [UspsServices.ExpressMailInternational]: ServiceLevel.PLUS,
  [UspsServices.PriorityMailInternational]: ServiceLevel.PLUS,
  [UspsServices.FirstClassPackageInternationalService]: ServiceLevel.STANDARD,
  [UspsServices.GroundAdvantage]: ServiceLevel.STANDARD,
  [UspsServices.FirstClass]: ServiceLevel.STANDARD,
  [UspsServices.PriorityMailReturn]: ServiceLevel.PLUS,
  [UspsServices.Priority]: ServiceLevel.PLUS,
  [UspsServices.Express]: ServiceLevel.PLUS,
  [UspsServices.PriorityMailExpressReturn]: ServiceLevel.EXPRESS,
};

export function carrierAndServiceToServiceLevel(
  carrier: Carriers,
  service: FedexServices | UpsServices | UspsServices,
) {
  switch (carrier) {
    case Carriers.FEDEX:
    case Carriers.FEDEX_SMART_POST: {
      if (isFedexService(service)) {
        return fedexServiceToServiceLevel[service];
      }
      break;
    }
    case Carriers.UPS_SUREPOST:
    case Carriers.UPS: {
      if (isUpsService(service)) {
        return upsServiceToServiceLevel[service];
      }
      break;
    }
    case Carriers.USPS:
    case Carriers.USPS_REDO:
    case Carriers.USPS_RETURNS: {
      if (isUspsService(service)) {
        return uspsServiceToServiceLevel[service];
      }
      break;
    }
    case Carriers.AMAZON:
      if (isAmazonService(service)) {
        return ServiceLevel.STANDARD;
      }
      break;
    default:
      assertNever(carrier);
  }

  throw new Error(
    `Unknown carrier-service combination: ${carrier} and ${service}`,
  );
}

export function getAllServicesArray() {
  return [
    ...Object.values(FedexServices),
    ...Object.values(UpsServices),
    ...Object.values(UspsServices),
    ...Object.values(AmazonServices),
  ];
}

export const ServiceSchema = z.union([
  z.nativeEnum(FedexServices),
  z.nativeEnum(UpsServices),
  z.nativeEnum(UspsServices),
  z.nativeEnum(AmazonServices),
]);
export type Service = z.infer<typeof ServiceSchema>;

export const CarrierSchema = z.nativeEnum(Carriers);

export type Carrier = z.infer<typeof CarrierSchema>;

export const CarrierAndServiceSchema = z.object({
  carrier: CarrierSchema,
  service: ServiceSchema,
});
export type CarrierAndService = z.infer<typeof CarrierAndServiceSchema>;

export const SelectedShippingRateSchema = CarrierAndServiceSchema.extend({
  carrier_account_id: z.string().nullish(),
});

export type SelectedShippingRate = z.infer<typeof SelectedShippingRateSchema>;

export const CorrelatedCarrierServiceSchema = z.union([
  z.object({
    carrier: z.enum([Carriers.FEDEX, Carriers.FEDEX_SMART_POST]),
    service: z.nativeEnum(FedexServices),
  }),
  z.object({
    carrier: z.enum([Carriers.UPS, Carriers.UPS_SUREPOST]),
    service: z.nativeEnum(UpsServices),
  }),
  z.object({
    carrier: z.enum([Carriers.USPS, Carriers.USPS_RETURNS, Carriers.USPS_REDO]),
    service: z.nativeEnum(UspsServices),
  }),
  z.object({
    carrier: z.enum([Carriers.AMAZON]),
    service: z.nativeEnum(AmazonServices),
  }),
]);
export type CorrelatedCarrierService = z.infer<
  typeof CorrelatedCarrierServiceSchema
>;

export function getCarriersAndServices() {
  const carriersAndServices: CarrierAndService[] = [];
  for (const service of Object.values(FedexServices)) {
    carriersAndServices.push({ service, carrier: Carriers.FEDEX });
  }
  for (const service of Object.values(UpsServices)) {
    carriersAndServices.push({ service, carrier: Carriers.UPS });
  }
  for (const service of Object.values(UspsServices)) {
    carriersAndServices.push({ service, carrier: Carriers.USPS });
  }
  for (const service of Object.values(AmazonServices)) {
    carriersAndServices.push({ service, carrier: Carriers.AMAZON });
  }
  return carriersAndServices;
}

export const fedexServiceToLabel: Record<FedexServices, string> = {
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS]:
    "FedEx International Priority Express",
  [FedexServices.FEDEX_INTERNATIONAL_CONNECT_PLUS]:
    "FedEx International Connect Plus",
  [FedexServices.INTERNATIONAL_FIRST]: "FedEx International First",
  [FedexServices.FEDEX_GROUND]: "FedEx Ground",
  [FedexServices.FEDEX_GROUND_HOME_DELIVERY]: "FedEx Ground Home Delivery",
  [FedexServices.FEDEX_2_DAY]: "FedEx 2 Day",
  [FedexServices.PRIORITY_OVERNIGHT]: "FedEx Priority Overnight",
  [FedexServices.FEDEX_FIRST_FREIGHT]: "FedEx First Freight",
  [FedexServices.FEDEX_EXPRESS_SAVER]: "FedEx Express Saver",
  [FedexServices.FEDEX_3_DAY_FREIGHT]: "FedEx 3 Day Freight",
  [FedexServices.FEDEX_2_DAY_AM]: "FedEx 2 Day AM",
  [FedexServices.FEDEX_2_DAY_FREIGHT]: "FedEx 2 Day Freight",
  [FedexServices.INTERNATIONAL_ECONOMY]: "FedEx International Economy",
  [FedexServices.FIRST_OVERNIGHT]: "FedEx First Overnight",
  [FedexServices.STANDARD_OVERNIGHT]: "FedEx Standard Overnight",
  [FedexServices.SMART_POST]: "FedEx Smart Post",
  [FedexServices.FEDEX_1_DAY_FREIGHT]: "FedEx 1 Day Freight",
  [FedexServices.INTERNATIONAL_PRIORITY]: "FedEx International Priority",
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY]: "FedEx International Priority",
};

export const upsServiceToLabel: Record<UpsServices, string> = {
  [UpsServices.Ground]: "UPS Ground",
  [UpsServices.NextDayAir]: "UPS Next Day Air",
  [UpsServices.ThreeDaySelect]: "UPS 3 Day Select",
  [UpsServices.SecondDayAirAM]: "UPS 2 Day Air AM",
  [UpsServices.UPSSaver]: "UPS Saver",
  [UpsServices.NextDayAirEarlyAM]: "UPS Next Day Air Early AM",
  [UpsServices.ExpressPlus]: "UPS Express Plus",
  [UpsServices.Express]: "UPS Express",
  [UpsServices.NextDayAirSaver]: "UPS Next Day Air Saver",
  [UpsServices.UPSStandard]: "UPS Standard",
  [UpsServices.SecondDayAir]: "UPS 2 Day Air",
  [UpsServices.Expedited]: "UPS Expedited",
  [UpsServices.SurePostUnder1Lb]: "Sure Post Under 1 Lb",
  [UpsServices.SurePostOver1Lb]: "Sure Post Over 1 Lb",
};

export const uspsServiceToLabel: Record<UspsServices, string> = {
  [UspsServices.GroundAdvantageReturn]: "USPS Ground Advantage Return",
  [UspsServices.ExpressMailInternational]: "USPS Express Mail International",
  [UspsServices.PriorityMailInternational]: "USPS Priority Mail International",
  [UspsServices.FirstClassPackageInternationalService]:
    "USPS First Class Package International Service",
  [UspsServices.GroundAdvantage]: "USPS Ground Advantage",
  [UspsServices.PriorityMailReturn]: "USPS Priority Mail Return",
  [UspsServices.Priority]: "USPS Priority",
  [UspsServices.Express]: "USPS Express",
  [UspsServices.FirstClass]: "USPS First Class",
  [UspsServices.PriorityMailExpressReturn]: "USPS Priority Mail Express Return",
  [UspsServices.MediaMail]: "USPS Media Mail",
  [UspsServices.LibraryMail]: "USPS Library Mail",
};

export const amazonServiceToLabel: Record<AmazonServices, string> = {
  [AmazonServices.Ground]: "Amazon Shipping Ground",
};

export const getServiceLabel = (service: Service) => {
  if (isFedexService(service)) {
    return fedexServiceToLabel[service];
  } else if (isUpsService(service)) {
    return upsServiceToLabel[service];
  } else if (isUspsService(service)) {
    return uspsServiceToLabel[service];
  } else if (isAmazonService(service)) {
    return amazonServiceToLabel[service];
  }
  assertNever(service);
};

export function validateCarrierService(
  carrier: Carriers,
  service: Service,
):
  | {
      carrier: Carriers.FEDEX | Carriers.FEDEX_SMART_POST;
      service: FedexServices;
    }
  | { carrier: Carriers.UPS | Carriers.UPS_SUREPOST; service: UpsServices }
  | { carrier: Carriers.AMAZON; service: AmazonServices }
  | {
      carrier: Carriers.USPS | Carriers.USPS_RETURNS | Carriers.USPS_REDO;
      service: UspsServices;
    } {
  if (carrier === Carriers.FEDEX || carrier === Carriers.FEDEX_SMART_POST) {
    if (isFedexService(service)) {
      return { carrier, service };
    }

    throw new Error(`Invalid FedEx service: ${service}`);
  } else if (carrier === Carriers.UPS || carrier === Carriers.UPS_SUREPOST) {
    if (isUpsService(service)) {
      return { carrier, service };
    }

    throw new Error(`Invalid UPS service: ${service}`);
  } else if (
    carrier === Carriers.USPS ||
    carrier === Carriers.USPS_RETURNS ||
    carrier === Carriers.USPS_REDO
  ) {
    if (isUspsService(service)) {
      return { carrier, service };
    }

    throw new Error(`Invalid USPS service: ${service}`);
  } else if (carrier === Carriers.AMAZON) {
    if (isAmazonService(service)) {
      return { carrier, service };
    }
    throw new Error(`Invalid Amazon service: ${service}`);
  }

  assertNever(carrier);
}
