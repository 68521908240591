import { ServiceInvoiceType } from "@redotech/redo-model/billing/service-invoice";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const refreshBilling = {
  input: z.object({
    teamId: zExt.objectId(),
    type: z.nativeEnum(ServiceInvoiceType),
  }),
  output: z.null(),
};
