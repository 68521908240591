import { z } from "zod";

export const getConversionSettings = {
  input: z.object({ teamId: z.string() }),
  output: z.object({
    checkoutOptimizationEnabled: z.boolean(),
    checkoutOptimizationBilling: z
      .object({
        enabled: z.boolean(),
        startDate: z.date().optional(),
        pricePerOrderInCents: z.number(),
      })
      .optional(),
  }),
};
