import { z } from "zod";

export const DeliveryProvinceSchema = z.object({
  code: z.string(),
  id: z.string(),
  name: z.string(),
  translatedName: z.string(),
});
export const DeliveryCountryCodeOrRestOfWorldSchema = z.object({
  countryCode: z.string().nullish(),
  restOfWorld: z.boolean(),
});

export const DeliveryCountrySchema = z.object({
  code: DeliveryCountryCodeOrRestOfWorldSchema,
  id: z.string(),
  name: z.string(),
  provinces: z.array(DeliveryProvinceSchema),
  translatedName: z.string(),
});

export const DeliveryZoneSchema = z.object({
  countries: z.array(DeliveryCountrySchema),
  id: z.string(),
  name: z.string(),
});
export type DeliveryZone = z.infer<typeof DeliveryZoneSchema>;

export const DeliveryMethodDefinitionCountsSchema = z.object({
  participantDefinitionsCount: z.number(),
  rateDefinitionsCount: z.number(),
});

export const DeliveryConditionSchema = z.object({
  id: z.string(),
  field: z.enum(["TOTAL_PRICE", "TOTAL_WEIGHT"]),
  conditionCriteria: z.union([
    z.object({ unit: z.string(), value: z.number() }),
    z.object({ amount: z.string(), currencyCode: z.string() }),
  ]),
  operator: z.enum(["GREATER_THAN_OR_EQUAL_TO", "LESS_THAN_OR_EQUAL_TO"]),
});

export type DeliveryCondition = z.infer<typeof DeliveryConditionSchema>;
export const DeliveryMethodDefinitionSchema = z.object({
  id: z.string(),
  active: z.boolean(),
  name: z.string(),
  description: z.string().optional(),
  methodConditions: z.array(DeliveryConditionSchema),
  rateProvider: z
    .object({
      price: z.object({ amount: z.string(), currencyCode: z.string() }),
    })
    .optional(),
});
export type DeliveryMethodDefinition = z.infer<
  typeof DeliveryMethodDefinitionSchema
>;

export const DeliveryLocationGroupZoneSchema = z.object({
  //methodDefinitionCounts: DeliveryMethodDefinitionCountsSchema,
  methodDefinitions: z.array(DeliveryMethodDefinitionSchema),
  zone: DeliveryZoneSchema,
});
export type DeliveryLocationGroupZone = z.infer<
  typeof DeliveryLocationGroupZoneSchema
>;

export const LocationAddressSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  country: z.string().nullish(),
  countryCode: z.string().nullish(),
  formatted: z.array(z.string()).nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  phone: z.string().nullish(),
  province: z.string().nullish(),
  zip: z.string().nullish(),
});
export const LocationSchema = z.object({
  //activatable: z.boolean(),
  address: LocationAddressSchema,
  //addressVerified: z.boolean(),
  //createdAt: z.date(),
  //deactivatable: z.boolean(),
  //deactivatedAt: z.date().nullish(),
  //deletable: z.boolean(),
  //id: z.string(),
  isActive: z.boolean(),
  name: z.string(),
  //updatedAt: z.date(),
});
export const DeliveryLocationGroupSchema = z.object({
  id: z.string(),
  locations: z.array(LocationSchema),
});

export const DeliveryCountryAndZoneSchema = z.object({
  country: DeliveryCountrySchema,
  zone: z.string(),
});

export const ProfileLocationGroupSchema = z.object({
  locationGroup: DeliveryLocationGroupSchema,
  locationGroupZones: z.array(DeliveryLocationGroupZoneSchema),
});

export type ProfileLocationGroup = z.infer<typeof ProfileLocationGroupSchema>;

export const DeliveryProfileSchema = z.object({
  activeMethodDefinitionsCount: z.number(),
  default: z.boolean(),
  id: z.string(),
  legacyMode: z.boolean(),
  locationsWithoutRatesCount: z.number(),

  name: z.string(),
  originLocationCount: z.number(),

  profileLocationGroups: z.array(ProfileLocationGroupSchema),
});

export type DeliveryProfile = z.infer<typeof DeliveryProfileSchema>;
