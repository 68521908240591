import { CountryCode } from "@redotech/locale/countries";
import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { zExt } from "../common/zod-util";

export enum ContentsType {
  MERCHANDISE = "merchandise",
  RETURNED_GOODS = "returned_goods",
  DOCUMENTS = "documents",
  GIFT = "gift",
  SAMPLE = "sample",
}

export type DefaultCustomsSettings = {
  contentsType: ContentsType;
  nonDeliveryOption: NonDeliveryOption;
  uspsExemptionCode: USPSExemptionCode;
};

export enum NonDeliveryOption {
  RETURN = "return",
  TREAT_AS_ABANDONED = "abandon",
}

export enum CustomsDeclarationType {
  CREATE = "create",
  PREDEFINED_VALUES = "predefined_values",
  LEAVE_BLANK = "leave_blank",
}

export enum AESExemptionType {
  ITN_TAX_ID = "itn_tax_id",
  USPS = "usps",
}

export enum USPSExemptionCode {
  NOEEI_30_36 = "NOEEI 30.36",
  NOEEI_30_37_A = "NOEEI 30.37(a)",
  NOEEI_30_37_B = "NOEEI 30.37(b)",
  NOEEI_30_37_C = "NOEEI 30.37(c)",
  NOEEI_30_37_D = "NOEEI 30.37(d)",
  NOEEI_30_37_F = "NOEEI 30.37(f)",
  NOEEI_30_37_G = "NOEEI 30.37(g)",
  NOEEI_30_37_H = "NOEEI 30.37(h)",
  NOEEI_30_37_I = "NOEEI 30.37(i)",
  NOEEI_30_37_J = "NOEEI 30.37(j)",
  NOEEI_30_37_K = "NOEEI 30.37(k)",
  NOEEI_30_37_L = "NOEEI 30.37(l)",
  NOEEI_30_37_M = "NOEEI 30.37(m)",
  NOEEI_30_37_N = "NOEEI 30.37(n)",
  NOEEI_30_37_O = "NOEEI 30.37(o)",
  NOEEI_30_37_P = "NOEEI 30.37(p)",
  NOEEI_30_37_S = "NOEEI 30.37(s)",
  NOEEI_30_37_T = "NOEEI 30.37(t)",
  NOEEI_30_37_U = "NOEEI 30.37(u)",
  NOEEI_30_37_V = "NOEEI 30.37(v)",
  NOEEI_30_37_W = "NOEEI 30.37(w)",
  NOEEI_30_37_X = "NOEEI 30.37(x)",
  NOEEI_30_37_Y = "NOEEI 30.37(y)",
}

export enum TaxIdUsage {
  "ALL" = "all",
  "OVER_2500" = "over_2500",
}

export enum TaxIdType {
  EIN = "EIN",
  EORI = "EORI",
  IOSS = "IOSS",
  PAN = "PAN",
  SSN = "SSN",
  TIN = "TIN",
  UKIMS = "UKIMS",
  VAT = "VAT",
  VOEC = "VOEC",
}

export const TaxIdentifierSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  createdAt: z.date(),
  updatedAt: z.date(),
  nickname: z.string(),
  taxIdType: z.nativeEnum(TaxIdType),
  issuingAuthority: z.string() as z.ZodType<CountryCode>,
  number: z.string(),
});
export type TaxIdentifier = z.infer<typeof TaxIdentifierSchema>;

export const CreationTaxIdentifierSchema = TaxIdentifierSchema.omit({
  _id: true,
  team: true,
  createdAt: true,
  updatedAt: true,
});
export type CreationTaxIdentifier = z.infer<typeof CreationTaxIdentifierSchema>;

export const RedactedTaxIdentifierSchema = z.object({
  _id: zExt.objectId(),
  nickname: z.string(),
  taxIdType: z.nativeEnum(TaxIdType),
  issuingAuthority: z.string() as z.ZodType<CountryCode>,
  redactedNumber: z.string(),
});
export type RedactedTaxIdentifier = z.infer<typeof RedactedTaxIdentifierSchema>;

export function redactTaxIdNumber(number: string): string {
  if (number.length < 2) {
    return "********";
  }
  return number[0] + "********" + number[number.length - 1];
}
export function getTaxIdTypeLabel(type: TaxIdType): string {
  switch (type) {
    case TaxIdType.EIN:
      return "EIN";
    case TaxIdType.EORI:
      return "EORI";
    case TaxIdType.IOSS:
      return "IOSS";
    case TaxIdType.PAN:
      return "PAN";
    case TaxIdType.SSN:
      return "SSN";
    case TaxIdType.TIN:
      return "TIN";
    case TaxIdType.UKIMS:
      return "UKIMS";
    case TaxIdType.VAT:
      return "VAT";
    case TaxIdType.VOEC:
      return "VOEC";
    default:
      return assertNever(type);
  }
}

export function getContentsTypeLabel(type: ContentsType): string {
  switch (type) {
    case ContentsType.DOCUMENTS:
      return "Documents";
    case ContentsType.GIFT:
      return "Gift";
    case ContentsType.MERCHANDISE:
      return "Merchandise";
    case ContentsType.RETURNED_GOODS:
      return "Returned goods";
    case ContentsType.SAMPLE:
      return "Sample";
    default:
      return assertNever(type);
  }
}

export function getNonDeliveryOptionLabel(option: NonDeliveryOption): string {
  switch (option) {
    case NonDeliveryOption.RETURN:
      return "Return to sender";
    case NonDeliveryOption.TREAT_AS_ABANDONED:
      return "Treat as abandoned";
    default:
      return assertNever(option);
  }
}

export function getCustomsDeclarationTypeLabel(
  type: CustomsDeclarationType,
): string {
  switch (type) {
    case CustomsDeclarationType.CREATE:
      return "Create declarations from order items";
    case CustomsDeclarationType.PREDEFINED_VALUES:
      return "Use pre-defined values";
    case CustomsDeclarationType.LEAVE_BLANK:
      return "Leave blank (enter manually)";
    default:
      return assertNever(type);
  }
}

export function getAESExemptionTypeLabel(code: AESExemptionType): string {
  switch (code) {
    case AESExemptionType.ITN_TAX_ID:
      return "ITN/Tax ID";
    case AESExemptionType.USPS:
      return "USPS exemption code";
    default:
      return assertNever(code);
  }
}

export function getATaxIdUsageLabel(usage: TaxIdUsage): string {
  switch (usage) {
    case TaxIdUsage.ALL:
      return "All international shipments";
    case TaxIdUsage.OVER_2500:
      return "International shipments where the total value is over $2500";
    default:
      return assertNever(usage);
  }
}
