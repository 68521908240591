// extracted by mini-css-extract-plugin
export var alternate = "_1h-h";
export var black = "_1h-3";
export var blue = "_1h-d";
export var blueLight = "_1h-c";
export var brand = "_1h-7";
export var error = "_1h-9";
export var gray = "_1h-6";
export var grayBlue = "_1h-b";
export var iconWrapper = "_1h-4";
export var indigo = "_1h-e";
export var orange = "_1h-g";
export var pink = "_1h-f";
export var primary = "_1h-0";
export var purple = "_1h-8";
export var success = "_1h-5";
export var warning = "_1h-a";
export var white = "_1h-1";
export var whiteSuccess = "_1h-2";