import { countries, CountryCode } from "@redotech/locale/countries";
import { USAProvinceCode, usaProvinces } from "@redotech/locale/provinces";
import { z } from "zod";
import { zExt } from "../common/zod-util";

export enum CheckoutTreeNodeType {
  SPLIT_TEST = "SPLIT_TEST",
  CHECKOUT_EXPERIENCE = "CHECKOUT_EXPERIENCE",
  SEGMENT = "SEGMENT",
}

// Checkout experience node
const CheckoutTreeCheckoutExperienceNodeSchema: z.ZodDiscriminatedUnionOption<"nodeType"> =
  z.object({
    nodeType: z.literal(CheckoutTreeNodeType.CHECKOUT_EXPERIENCE),
    checkoutExperienceId: zExt.objectId(),
  });
export type CheckoutTreeCheckoutExperienceNode = {
  nodeType: CheckoutTreeNodeType.CHECKOUT_EXPERIENCE;
  checkoutExperienceId: string;
};

// Split test node
const CheckoutTreeSplitTestEdgeSchema = z.lazy(() =>
  z.object({
    childNode: z.lazy(() => CheckoutTreeNodeZodSchema),
    treatmentId: zExt.objectId(),
  }),
);
export type CheckoutTreeSplitTestEdge = {
  childNode: CheckoutTreeNode;
  treatmentId: string;
};

const CheckoutTreeSplitTestNodeSchema: z.ZodDiscriminatedUnionOption<"nodeType"> =
  z.object({
    nodeType: z.literal(CheckoutTreeNodeType.SPLIT_TEST),
    splitTestId: zExt.objectId(),
    edges: z.array(CheckoutTreeSplitTestEdgeSchema),
  });
export type CheckoutTreeSplitTestNode = {
  nodeType: CheckoutTreeNodeType.SPLIT_TEST;
  splitTestId: string;
  edges: CheckoutTreeSplitTestEdge[];
};
export function isCheckoutTreeSplitTestNode(
  node: CheckoutTreeNode,
): node is CheckoutTreeSplitTestNode {
  return node.nodeType === CheckoutTreeNodeType.SPLIT_TEST;
}

// Segment node
export enum SegmentableCheckoutCharacteristic {
  /**
   * The distance between the destination address and the fulfillment center.
   */
  DELIVERY_DISTANCE_MILES = "DELIVERY_DISTANCE_MILES",
  /**
   * Number of customer's previous orders.
   */
  ORDER_COUNT = "ORDER_COUNT",
  /**
   * The location of the delivery address.
   */
  DELIVERY_LOCATION = "DELIVERY_LOCATION",
  /**
   * Customer's tags.
   */
  CUSTOMER_TAGS = "CUSTOMER_TAGS",
  /**
   * Product tags.
   */
  PRODUCT_TAGS = "PRODUCT_TAGS",
  /**
   * Product vendor.
   */
  PRODUCT_VENDOR = "PRODUCT_VENDOR",
}

export enum SegmentNumberOperator {
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  EQUAL = "EQUAL",
}

export enum SegmentListSingleItemMembershipOperator {
  IN = "IN",
  NOT_IN = "NOT_IN",
}

export enum SegmentListMultipleItemMembershipOperator {
  ALL_IN = "ALL_IN",
  ANY_IN = "ANY_IN",
  NONE_IN = "NONE_IN",
}

const CheckoutTreeSegmentEdgeSchema = z.lazy(() =>
  z.object({ childNode: CheckoutTreeNodeZodSchema }),
);
export type CheckoutTreeSegmentEdge = { childNode: CheckoutTreeNode };

// Delivery distance miles segment
const DeliveryDistanceMilesSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.DELIVERY_DISTANCE_MILES,
    ),
    miles: z.number(),
    operator: z.nativeEnum(SegmentNumberOperator),
  });
type DeliveryDistanceMilesSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.DELIVERY_DISTANCE_MILES;
  miles: number;
  operator: SegmentNumberOperator;
};

export function isDeliveryDistanceMilesSegment(
  segment: Segment,
): segment is DeliveryDistanceMilesSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.DELIVERY_DISTANCE_MILES
  );
}

// Order count segment
const OrderCountSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.ORDER_COUNT,
    ),
    numOrders: z.number(),
    operator: z.nativeEnum(SegmentNumberOperator),
  });
export type OrderCountSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.ORDER_COUNT;
  numOrders: number;
  operator: SegmentNumberOperator;
};
export function isOrderCountSegment(
  segment: Segment,
): segment is OrderCountSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.ORDER_COUNT
  );
}

// Delivery location segment
const DeliveryLocationSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.DELIVERY_LOCATION,
    ),
    locationCountry: z.object({
      countries: z.array(
        z.enum(countries.map((c) => c.code) as [string, ...string[]]),
      ),
      operator: z.nativeEnum(SegmentListSingleItemMembershipOperator),
    }),
    // Right now, we only support USA provinces.
    locationProvince: z
      .object({
        provinces: z.array(
          z.enum(usaProvinces.map((p) => p.code) as [string, ...string[]]),
        ),
        operator: z.nativeEnum(SegmentListSingleItemMembershipOperator),
      })
      .optional(),
  });
type DeliveryLocationSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.DELIVERY_LOCATION;
  locationCountry: {
    countries: CountryCode[];
    operator: SegmentListSingleItemMembershipOperator;
  };
  locationProvince?:
    | {
        provinces: USAProvinceCode[];
        operator: SegmentListSingleItemMembershipOperator;
      }
    | undefined;
};

export function isDeliveryLocationSegment(
  segment: Segment,
): segment is DeliveryLocationSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.DELIVERY_LOCATION
  );
}

// Customer tags segment
/**
 * Due to limitations in Shopify's delivery customization function, we can only
 * support this subset of multiple item list membership operators for customer
 * tags segments.
 */
const CustomerTagsSegmentListMultipleItemMembershipOperator = [
  SegmentListMultipleItemMembershipOperator.ANY_IN,
  SegmentListMultipleItemMembershipOperator.NONE_IN,
] as const;
type CustomerTagsSegmentListMultipleItemMembershipOperator =
  (typeof CustomerTagsSegmentListMultipleItemMembershipOperator)[number];

/**
 * Due to limitations in Shopify's delivery customization function, we only allow
 * two customer tags segments nodes in a checkout tree for now.
 */
export enum CustomerTagsSegmentNodeIdentifier {
  CUSTOMER_TAGS_SEGMENT_NODE_ONE = "CUSTOMER_TAGS_SEGMENT_NODE_ONE",
  CUSTOMER_TAGS_SEGMENT_NODE_TWO = "CUSTOMER_TAGS_SEGMENT_NODE_TWO",
}

const CustomerTagsSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.CUSTOMER_TAGS,
    ),
    tags: z.array(z.string()),
    operator: z.enum(CustomerTagsSegmentListMultipleItemMembershipOperator),
    customerTagsSegmentNodeIdentifier: z.nativeEnum(
      CustomerTagsSegmentNodeIdentifier,
    ),
  });
type CustomerTagsSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.CUSTOMER_TAGS;
  tags: string[];
  operator: CustomerTagsSegmentListMultipleItemMembershipOperator;
  customerTagsSegmentNodeIdentifier: CustomerTagsSegmentNodeIdentifier;
};

export function isCustomerTagsSegment(
  segment: Segment,
): segment is CustomerTagsSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.CUSTOMER_TAGS
  );
}

// Product tags segment
/**
 * Due to limitations in Shopify's delivery customization function, we can only
 * support this subset of multiple item list membership operators for product
 * tags segments.
 */
const ProductTagsSegmentListMultipleItemMembershipOperator = [
  SegmentListMultipleItemMembershipOperator.ANY_IN,
  SegmentListMultipleItemMembershipOperator.NONE_IN,
] as const;
type ProductTagsSegmentListMultipleItemMembershipOperator =
  (typeof ProductTagsSegmentListMultipleItemMembershipOperator)[number];

/**
 * Due to limitations in Shopify's delivery customization function, we only allow
 * one product tags segment node in a checkout tree for now.
 */
export enum ProductTagsSegmentNodeIdentifier {
  PRODUCT_TAGS_SEGMENT_NODE_ONE = "PRODUCT_TAGS_SEGMENT_NODE_ONE",
}

const ProductTagsSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.PRODUCT_TAGS,
    ),
    tags: z.array(z.string()),
    operator: z.enum(ProductTagsSegmentListMultipleItemMembershipOperator),
    cartOperator: z.nativeEnum(SegmentListMultipleItemMembershipOperator),
    productTagsSegmentNodeIdentifier: z.nativeEnum(
      ProductTagsSegmentNodeIdentifier,
    ),
  });
type ProductTagsSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.PRODUCT_TAGS;
  tags: string[];
  operator: ProductTagsSegmentListMultipleItemMembershipOperator;
  cartOperator: SegmentListMultipleItemMembershipOperator;
  productTagsSegmentNodeIdentifier: ProductTagsSegmentNodeIdentifier;
};

export function isProductTagsSegment(
  segment: Segment,
): segment is ProductTagsSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.PRODUCT_TAGS
  );
}

// Product vendor segment
/**
 * Product vendors list with list membership operators for product vendor segments.
 */
const ProductVendorSegmentSchema: z.ZodDiscriminatedUnionOption<"segmentableCharacteristic"> =
  z.object({
    segmentableCharacteristic: z.literal(
      SegmentableCheckoutCharacteristic.PRODUCT_VENDOR,
    ),
    vendors: z.array(z.string()),
    operator: z.nativeEnum(SegmentListSingleItemMembershipOperator),
    cartOperator: z.nativeEnum(SegmentListMultipleItemMembershipOperator),
  });

type ProductVendorSegment = {
  segmentableCharacteristic: SegmentableCheckoutCharacteristic.PRODUCT_VENDOR;
  vendors: string[];
  operator: SegmentListSingleItemMembershipOperator;
  cartOperator: SegmentListMultipleItemMembershipOperator;
};

export function isProductVendorSegment(
  segment: Segment,
): segment is ProductVendorSegment {
  return (
    segment.segmentableCharacteristic ===
    SegmentableCheckoutCharacteristic.PRODUCT_VENDOR
  );
}

const SegmentSchema = z.discriminatedUnion("segmentableCharacteristic", [
  DeliveryDistanceMilesSegmentSchema,
  OrderCountSegmentSchema,
  DeliveryLocationSegmentSchema,
  CustomerTagsSegmentSchema,
  ProductTagsSegmentSchema,
  ProductVendorSegmentSchema,
]);
type Segment =
  | DeliveryDistanceMilesSegment
  | OrderCountSegment
  | DeliveryLocationSegment
  | CustomerTagsSegment
  | ProductTagsSegment
  | ProductVendorSegment;

const CheckoutTreeSegmentNodeSchema: z.ZodDiscriminatedUnionOption<"nodeType"> =
  z.object({
    nodeType: z.literal(CheckoutTreeNodeType.SEGMENT),
    matchingEdge: CheckoutTreeSegmentEdgeSchema,
    nonMatchingEdge: CheckoutTreeSegmentEdgeSchema,
    segment: SegmentSchema,
  });
export type CheckoutTreeSegmentNode = {
  nodeType: CheckoutTreeNodeType.SEGMENT;
  matchingEdge: CheckoutTreeSegmentEdge;
  nonMatchingEdge: CheckoutTreeSegmentEdge;
  segment: Segment;
};

export function isCheckoutTreeSegmentNode(
  node: CheckoutTreeNode,
): node is CheckoutTreeSegmentNode {
  return node.nodeType === CheckoutTreeNodeType.SEGMENT;
}

// Checkout tree node
export const CheckoutTreeNodeZodSchema = z.lazy(() =>
  z.discriminatedUnion("nodeType", [
    CheckoutTreeCheckoutExperienceNodeSchema,
    CheckoutTreeSplitTestNodeSchema,
    CheckoutTreeSegmentNodeSchema,
  ]),
);
export type CheckoutTreeNode =
  | CheckoutTreeCheckoutExperienceNode
  | CheckoutTreeSplitTestNode
  | CheckoutTreeSegmentNode;

export const CheckoutTreeZodSchema = z.object({
  rootNode: CheckoutTreeNodeZodSchema,
});
// Explicit types because zod inferred types using z.lazy() results in poor
// type safety. Uncomment the following line to see the inferred type.
// type PoorlyTypedCheckoutTree = z.infer<typeof CheckoutTreeZodSchema>;
export type CheckoutTree = { rootNode: CheckoutTreeNode };

export function parseCheckoutTree(checkoutTree: unknown): CheckoutTree {
  return CheckoutTreeZodSchema.parse(checkoutTree) as CheckoutTree;
}
