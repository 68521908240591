import { z } from "zod";
import { PatchCode } from "./patch-codes";

export const patchStorefrontTreatmentSchema = z.object({
  code: z.literal(PatchCode.PATCH_STOREFRONT_TREATMENT),
  teamId: z.string(),
  splitId: z.string(),
  treatmentId: z.string(),
});

export const patchStorefrontTreatment = {
  input: patchStorefrontTreatmentSchema,
  output: z.object({}),
};
