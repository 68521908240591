// extracted by mini-css-extract-plugin
export var chevron = "_1w-b";
export var disabled = "_1w-a";
export var hideBorder = "_1w-1";
export var icon = "_1w-c";
export var input = "_1w-0";
export var open = "_1w-8";
export var placeholder = "_1w-9";
export var regular = "_1w-4";
export var singleSelectInput = "_1w-2 _1w-0";
export var small = "_1w-3";
export var spinnerContainer = "_1w-e";
export var tag = "_1w-6";
export var tagsContainer = "_1w-7";
export var tagsInput = "_1w-5 _1w-0";
export var xSmall = "_1w-d";