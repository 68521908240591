import {
  genericForwardRefMemo,
  genericMemo,
} from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import * as classNames from "classnames";
import { CSSProperties, ForwardedRef, MouseEvent, useState } from "react";
import { Flex } from "../../flex";
import { Icon } from "../../icon";
import { TextSizeValue } from "../../theme/typography";
import { DescriptionText, LabelText } from "../common/descriptors";
import {
  ListItemRender,
  RedoListItem,
  RedoListItemSelectedSource,
} from "../list/redo-list";
import {
  RedoListDropdown,
  dropdownSizeToListSize,
} from "../list/redo-list-dropdown";
import { TextRedoListItem } from "../list/standard-redo-list-items";
import * as redoDropdownInputCss from "./redo-dropdown-input.module.css";

export const redoDropdownInputSize = ["xs", "sm", "md"] as const;
export type RedoDropdownInputSize = (typeof redoDropdownInputSize)[number];

export const RedoSingleSelectDropdownInput = genericMemo(
  function RedoSingleSelectDropdownInput<T>({
    options,
    optionSelected,
    disabledItems,
    selectedItem,
    emptyListMessage,
    disabled = false,
    readonly = false,
    placeholder,
    className,
    size = "xs",
    fitToAnchor = true,
    label,
    description,
    flexGrow = true,
    searchString,
    setSearchString,
    searchPlaceholder,
    itemsLoading,
    dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
    hideBorder,
  }: {
    options: RedoListItem<T>[];
    optionSelected(args: {
      item: RedoListItem<T>;
      source: RedoListItemSelectedSource;
      index: number;
      selected: boolean;
    }): void;
    disabledItems?: (string | number)[] | (string | number);
    selectedItem?: RedoListItem<T>;
    size?: RedoDropdownInputSize;
    emptyListMessage?: string;
    itemsLoading?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    placeholder?: string;
    className?: string;
    fitToAnchor?: boolean;
    label?: string;
    description?: string;
    flexGrow?: boolean;
    searchString?: string;
    setSearchString?: (search: string) => void;
    searchPlaceholder?: string;
    dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
    hideBorder?: boolean;
  }) {
    const [dropdownButtonRef, setDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownStateChange = useHandler((open: boolean) => {
      setSearchString?.("");
      setDropdownOpen(open);
    });

    const descriptorFontSize = sizeToDescriptorTextProps[size];

    const onItemSelected = useHandler(
      (args: {
        item: RedoListItem<T>;
        source: RedoListItemSelectedSource;
        index: number;
        selected: boolean;
      }) => {
        handleDropdownStateChange(false);
        optionSelected(args);
      },
    );

    return (
      <>
        <Flex dir="column" gap="sm" grow={flexGrow ? 1 : undefined}>
          <LabelText label={label} size={descriptorFontSize} />
          <RedoSingleSelectDropdownInputButton
            className={className}
            dangerousStyleThatShouldOnlyBeUsedForMerchantBranding={
              dangerousStyleThatShouldOnlyBeUsedForMerchantBranding
            }
            disabled={disabled}
            hideBorder={hideBorder}
            isDropdownOpen={dropdownOpen}
            onClick={(e) => {
              handleDropdownStateChange(!dropdownOpen);
            }}
            placeholder={placeholder}
            readonly={readonly}
            ref={setDropdownButtonRef}
            selectedItem={selectedItem}
            size={size}
          />
          <DescriptionText
            description={description}
            size={descriptorFontSize}
          />
        </Flex>
        <RedoListDropdown
          disabledItems={disabledItems}
          dropdownAnchor={dropdownButtonRef}
          dropdownOpen={dropdownOpen}
          emptyListMessage={emptyListMessage}
          fitToAnchor={fitToAnchor}
          items={options}
          itemSelected={onItemSelected}
          itemsLoading={itemsLoading}
          key={`${dropdownOpen}`}
          searchPlaceholder={searchPlaceholder}
          searchString={searchString}
          selectedItems={selectedItem?.id}
          setDropdownOpen={handleDropdownStateChange}
          setSearchString={setSearchString}
          size={size}
        />
      </>
    );
  },
);

interface RedoSingleSelectDropdownInputButtonProps<T> {
  size?: RedoDropdownInputSize;
  isDropdownOpen: boolean;
  selectedItem?: RedoListItem<T>;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  className?: string;
  onClick?: (e: MouseEvent) => void;
  ref?: any;
  hideBorder?: boolean;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
}

export const RedoSingleSelectDropdownInputButton = genericForwardRefMemo(
  function RedoSingleSelectDropdownInputButton<T>(
    {
      selectedItem,
      isDropdownOpen,
      size = "sm",
      disabled = false,
      readonly = false,
      placeholder,
      className,

      hideBorder = false,
      onClick,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
    }: RedoSingleSelectDropdownInputButtonProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const { backgroundColor } =
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding || {};
    const buttonOverrideStyles: CSSProperties = { backgroundColor };

    if (dangerousStyleThatShouldOnlyBeUsedForMerchantBranding) {
      buttonOverrideStyles.paddingTop = 0;
      buttonOverrideStyles.paddingBottom = 0;
    }

    const content = (
      <Flex align="center" dir="row" gap="sm" overflow="hidden">
        {selectedItem ? (
          <ListItemRender
            card={false}
            disabled={disabled}
            focused={false}
            idx={0}
            item={selectedItem}
            selected={false}
            selectionVariant="none"
            size={dropdownSizeToListSize[size]}
            useWrapper={false}
          />
        ) : (
          <TextRedoListItem
            placeholder
            size={dropdownSizeToListSize[size]}
            text={placeholder || ""}
            useWrapper={false}
          />
        )}
      </Flex>
    );

    if (readonly) {
      return content;
    }

    return (
      <button
        className={classNames(
          className,
          hideBorder && redoDropdownInputCss.hideBorder,
          redoDropdownInputCss.singleSelectInput,
          sizeClassToCssClass[size],
          isDropdownOpen && redoDropdownInputCss.open,
          disabled && redoDropdownInputCss.disabled,
        )}
        disabled={disabled || readonly}
        onClick={onClick}
        ref={ref}
        style={buttonOverrideStyles}
        type="button"
      >
        {content}
        <Icon
          arbiterIconSvg={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
          className={redoDropdownInputCss.chevron}
          color="ghost"
        />
      </button>
    );
  },
);

const sizeToDescriptorTextProps: Record<RedoDropdownInputSize, TextSizeValue> =
  { xs: "xs", sm: "xs", md: "sm" };

const sizeClassToCssClass: Record<RedoDropdownInputSize, string> = {
  xs: redoDropdownInputCss.xSmall,
  sm: redoDropdownInputCss.small,
  md: redoDropdownInputCss.regular,
};
