import { baseSplitZodSchema, TreatmentType } from "@redotech/redo-model/split";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const createSplit = {
  input: z.object({
    teamId: zExt.objectId(),
    name: z.string(),
    treatmentType: z.nativeEnum(TreatmentType),
  }),
  output: z.object({ split: baseSplitZodSchema }),
};
