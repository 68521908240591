import ArrowDownIcon from "@redotech/redo-web/arbiter-icon/arrow-down_filled.svg";
import ArrowRightIcon from "@redotech/redo-web/arbiter-icon/arrow-right_filled.svg";
import ArrowUpIcon from "@redotech/redo-web/arbiter-icon/arrow-up_filled.svg";
import { interleave } from "@redotech/util/array";
import { isUserOnMac } from "@redotech/util/browser-agent";
import {
  AltKey,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ControlKey,
  EscapeKey,
  MetaKey,
  ShiftKey,
} from "@redotech/web-util/key";
import { JSXElementConstructor, memo, useMemo } from "react";
import ArrowLeftIcon from "../../arbiter-icon/arrow-left_filled.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import * as commandCss from "./redo-command-menu.module.css";

const redoCommandShortcutSize = ["sm", "md", "lg"] as const;
export type RedoCommandShortcutSize = (typeof redoCommandShortcutSize)[number];

export const RedoCommandShortcut = memo(function RedoCommandShortcut({
  hotkey,
  size = "sm",
}: {
  hotkey: (string | number)[] | string;
  size?: RedoCommandShortcutSize;
}) {
  const isMac = isUserOnMac();

  const trueHotkeys: string[] = useMemo(() => {
    if (Array.isArray(hotkey)) {
      return hotkey.flatMap((key) => {
        return key.toString();
      });
    }
    return hotkey.split("");
  }, [hotkey]);

  return (
    <Flex
      align="center"
      bgColor="secondary-alt"
      className={commandCss.shortcutContainer}
      gap="none"
      px="sm"
      py="xxs"
    >
      {interleave(
        trueHotkeys.map((key) => {
          const display = getKeyDisplay(key, isMac);
          if (typeof display === "string") {
            return (
              <Text
                fontSize={sizeToFontSize[size]}
                key={key}
                textColor="tertiary"
              >
                {display}
              </Text>
            );
          } else {
            const Icon = display;
            return (
              <Icon
                className={commandCss.icon}
                key={key}
                style={{
                  width: sizeToIconSize[size],
                  height: sizeToIconSize[size],
                }}
              />
            );
          }
        }),
        (_, idx) => {
          return (
            <Text
              fontSize={sizeToFontSize[size]}
              key={`+${idx}`}
              textColor="tertiary"
            >
              +
            </Text>
          );
        },
      )}
    </Flex>
  );
});

const sizeToFontSize: Record<RedoCommandShortcutSize, TextSizeValue> = {
  sm: "xxs",
  md: "xs",
  lg: "sm",
};

const sizeToIconSize: Record<RedoCommandShortcutSize, number> = {
  sm: 10,
  md: 12,
  lg: 14,
};

function getKeyDisplay(
  key: string,
  isMac: boolean,
): string | JSXElementConstructor<any> {
  if (key === ControlKey || key === MetaKey || key === "⌘" || key === "Ctrl") {
    return isMac ? "⌘" : "Ctrl";
  }
  if (key === AltKey || key === "⌥" || key === "Alt") {
    return isMac ? "⌥" : "Alt";
  }
  if (key === ShiftKey) {
    return "⇧";
  }
  if (key === EscapeKey) {
    return "ESC";
  }
  if (key === ArrowUp) {
    return ArrowUpIcon;
  }
  if (key === ArrowDown) {
    return ArrowDownIcon;
  }
  if (key === ArrowRight) {
    return ArrowRightIcon;
  }
  if (key === ArrowLeft) {
    return ArrowLeftIcon;
  }
  return key;
}
