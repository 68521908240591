import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { getKey } from "@redotech/react-util/key";
import { assertNever } from "@redotech/util/type";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import {
  Dispatch,
  JSXElementConstructor,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
} from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoBadgeProps } from "../badge/redo-badge";
import { RedoCommandMenuItem } from "../command-menu/redo-command-menu";
import { RedoSpinner } from "../loading/redo-spinner";
import { LeadingItem } from "./redo-list-item-lead";
import * as redoListItemCss from "./redo-list-item.module.css";
import * as redoListCss from "./redo-list.module.css";
import {
  BadgeRedoListItem,
  itemSizeToTextSize,
  RedoListItemContainer,
  RedoListItemSelectionVariant,
  RedoListItemSize,
  TextRedoListItem,
} from "./standard-redo-list-items";

interface BaseRedoListItem<T> {
  id: string | number | symbol;
  value: T;
  menu?: RedoCommandMenuItem[] | { onClick: () => void };
  hotkey?: string[] | string;
  infoBadge?: Omit<RedoBadgeProps, "size">;
  TrailingIcon?: JSXElementConstructor<any>;
}
export interface TextRedoListItem<T> extends BaseRedoListItem<T> {
  type: "text";
  text: string;
  supportingText?: string;
  leadingItem?: LeadingItem;
}

export interface BadgeRedoListItem<T> extends BaseRedoListItem<T> {
  type: "badge";
  badge: Omit<RedoBadgeProps, "size">;
}

export interface CustomRedoListItem<T> extends BaseRedoListItem<T> {
  type: "custom";
  render: ReactNode;
  useWrapper: boolean;
}

export type RedoListItem<T> =
  | TextRedoListItem<T>
  | BadgeRedoListItem<T>
  | CustomRedoListItem<T>;

const listItemSelectedSource = ["keyboard", "mouse"] as const;
export type RedoListItemSelectedSource =
  (typeof listItemSelectedSource)[number];

export interface RedoListProps<T> {
  items: RedoListItem<T>[];
  itemSelected(args: {
    item: RedoListItem<T>;
    source: RedoListItemSelectedSource;
    index: number;
    selected: boolean;
  }): void;
  selectedItems?: (string | number | symbol)[] | (string | number | symbol);
  disabledItems?: (string | number | symbol)[] | (string | number | symbol);
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  selectionVariant?: RedoListItemSelectionVariant;
  size?: RedoListItemSize;
  itemsLoading?: boolean;
  emptyListMessage?: string;
  card?: boolean;
}

export const RedoList = genericMemo(function RedoList<T>({
  items,
  itemSelected,
  selectedItems,
  disabledItems,
  refToListenTo,
  focusedIndex,
  setFocusedIndex,
  selectionVariant = "checkmark",
  size = "sm",
  itemsLoading,
  emptyListMessage = "No items",
  card = false,
}: RedoListProps<T>) {
  const isItemDisabled = useHandler((item: RedoListItem<T>) => {
    if (!disabledItems) {
      return false;
    }
    if (Array.isArray(disabledItems)) {
      return disabledItems.includes(item.id);
    }
    return disabledItems === item.id;
  });

  const isItemSelected = useHandler((item: RedoListItem<T>) => {
    if (selectedItems === undefined) {
      return false;
    }
    if (Array.isArray(selectedItems)) {
      return selectedItems.includes(item.id);
    }
    return selectedItems === item.id;
  });

  const handleKeyPress = useHandler(
    (event: KeyboardEvent | React.KeyboardEvent) => {
      if (event.key === EnterKey) {
        event.preventDefault();
        if (focusedIndex === undefined) {
          return;
        }
        event.stopPropagation();
        const selectedItem = items[focusedIndex];
        if (!isItemDisabled(selectedItem)) {
          itemSelected({
            item: selectedItem,
            source: "keyboard",
            index: focusedIndex,
            selected: !isItemSelected(selectedItem),
          });
        }
      } else if (event.key === ArrowDown) {
        event.preventDefault();
        const oldIndex = focusedIndex ?? -1;
        let newIndex = Math.min(oldIndex + 1, items.length - 1);
        while (newIndex < items.length && isItemDisabled(items[newIndex])) {
          newIndex++;
        }
        if (newIndex < items.length) {
          setFocusedIndex(newIndex);
        }
      } else if (event.key === ArrowUp) {
        event.preventDefault();
        const oldIndex = focusedIndex ?? items.length;
        let newIndex = Math.min(oldIndex - 1, items.length - 1);
        while (newIndex >= 0 && isItemDisabled(items[newIndex])) {
          newIndex--;
        }
        if (newIndex >= 0) {
          setFocusedIndex(newIndex);
        } else {
          setFocusedIndex(undefined);
        }
      }
    },
  );

  useEffect(() => {
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
    };
  }, [refToListenTo, handleKeyPress]);

  const itemSelectedStable = useHandler(itemSelected);
  const setFocusedIndexStable = useHandler(setFocusedIndex);

  return (
    <Flex grow={1} overflow="hidden">
      {itemsLoading ? (
        <LoadingList />
      ) : (
        <Flex
          className={redoListCss.childrenContainer}
          dir="column"
          gap="sm"
          onKeyDown={handleKeyPress}
          tabIndex={0}
        >
          {items.length === 0 && (
            <EmptyList message={emptyListMessage} size={size} />
          )}
          {items.map((item, idx) => (
            <ListItemRender
              card={card}
              disabled={isItemDisabled(item)}
              focused={focusedIndex === idx}
              idx={idx}
              item={item}
              itemSelected={itemSelectedStable}
              key={getKey(item.id)}
              selected={isItemSelected(item)}
              selectionVariant={selectionVariant}
              setFocusedIndex={setFocusedIndexStable}
              size={size}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
});

export const ListItemRender = genericMemo(function ListItemRender<T>({
  item,
  idx,
  setFocusedIndex,
  focused,
  itemSelected,
  selectionVariant,
  size,
  disabled,
  selected,
  card,
  useWrapper = true,
}: {
  item: RedoListItem<T>;
  idx: number;
  focused: boolean;
  disabled: boolean;
  selected: boolean;
  setFocusedIndex?: Dispatch<SetStateAction<number | undefined>>;
  itemSelected?(args: {
    item: RedoListItem<T>;
    source: RedoListItemSelectedSource;
    index: number;
    selected: boolean;
  }): void;
  selectionVariant: RedoListItemSelectionVariant;
  size: RedoListItemSize;
  card: boolean;
  useWrapper?: boolean;
}) {
  const onItemHovered = useHandler((hovered: boolean) => {
    if (hovered && !disabled) {
      setFocusedIndex?.(idx);
    } else {
      setFocusedIndex?.((currentFocusedIndex) => {
        const thisItemIsFocused = currentFocusedIndex === idx;
        return thisItemIsFocused ? undefined : currentFocusedIndex;
      });
    }
  });

  const handleItemSelected = useHandler((selected: boolean) => {
    itemSelected?.({ item, source: "mouse", index: idx, selected });
  });

  if (item.type === "text") {
    return (
      <TextRedoListItem
        card={card}
        disabled={disabled}
        focused={focused}
        {...item}
        onItemHovered={setFocusedIndex ? onItemHovered : undefined}
        onItemSelected={itemSelected ? handleItemSelected : undefined}
        selected={selected}
        selectionVariant={selectionVariant}
        size={size}
        useWrapper={useWrapper}
      />
    );
  }
  if (item.type === "badge") {
    return (
      <BadgeRedoListItem
        badge={item.badge}
        card={card}
        disabled={disabled}
        focused={focused}
        hotkey={item.hotkey}
        menu={item.menu}
        onItemHovered={onItemHovered}
        onItemSelected={handleItemSelected}
        selected={selected}
        selectionVariant={selectionVariant}
        size={size}
        useWrapper={useWrapper}
      />
    );
  }
  if (item.type === "custom") {
    if (item.useWrapper && useWrapper !== false) {
      return (
        <RedoListItemContainer
          card={card}
          disabled={disabled}
          focused={focused}
          {...item}
          onItemHovered={onItemHovered}
          onItemSelected={handleItemSelected}
          selected={selected}
          selectionVariant={selectionVariant}
          size={size}
        >
          {item.render}
        </RedoListItemContainer>
      );
    }
    return <>{item.render}</>;
  }
  assertNever(item);
});

const EmptyList = memo(function EmptyList({
  message,
  size,
}: {
  message: string | undefined;
  size: RedoListItemSize;
}) {
  if (!message) {
    return null;
  }
  return (
    <Flex>
      <Text
        className={redoListItemCss.text}
        fontSize={itemSizeToTextSize[size]}
        fontWeight="regular"
        overflow="hidden"
        textColor="primary"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {message}
      </Text>
    </Flex>
  );
});

// Todo: replace with skeleton?
const LoadingList = memo(function LoadingList() {
  return (
    <Flex grow={1} justify="center">
      <Flex p="xl">
        <RedoSpinner style="line" />
      </Flex>
    </Flex>
  );
});
