import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { Category } from "@redotech/redo-model/advanced-flow/triggers";
import { DiscountValueType } from "@redotech/redo-model/discount";
import { UpsellProductSource } from "@redotech/redo-model/team";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { InputProvider, groupInput } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../client/context";
import {
  createDefaultEmailFlows,
  createDefaultEmailTemplates,
  getTeam,
  updateTeam,
} from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import {
  OneClickUpsellTeamSettingsCard,
  oneClickUpsellTeamSettingsDefault,
  oneClickUpsellTeamSettingsForm,
} from "./orders/one-click-upsell-team-settings";
import {
  OrderTrackingGeneralCard,
  orderTrackingGeneralDefault,
  orderTrackingGeneralForm,
} from "./orders/order-tracking";
import {
  OrderTrackingBillingCard,
  orderTrackingBillingDefault,
  orderTrackingBillingForm,
} from "./orders/order-tracking-billing";
import {
  ReviewsGeneralCard,
  reviewsGeneralDefault,
  reviewsGeneralForm,
} from "./orders/reviews";

export const OrderTrackingPage = memo(function OrderTrackingPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const value = useMemo<OrderTrackingValue | undefined>(() => {
    if (!team) {
      return;
    }

    const postPurchaseSettingsSource =
      team.settings.orderTracking?.postPurchaseUpsell;
    const oneClickUpsell: InputProvider.Value<
      typeof oneClickUpsellTeamSettingsForm
    > = {
      enabled: postPurchaseSettingsSource?.enabled ?? false,
      billing: {
        billingEnabled:
          postPurchaseSettingsSource?.billing?.billingEnabled ?? false,
        freeUntilDate: postPurchaseSettingsSource?.billing?.freeUntilDate,
        freeUpsellsShownPerMonth: `${postPurchaseSettingsSource?.billing?.freeUpsellsShownPerMonth ?? 0}`,
        pricePerUpsellInDollars: `${(postPurchaseSettingsSource?.billing?.pricePerUpsellShownInCents ?? 0) / 100}`,
      },
      createOrderOnUpsell:
        postPurchaseSettingsSource?.createOrderOnUpsell ?? false,
    };

    const orderTrackingBilling = team.settings?.orderTracking?.billing;
    return {
      general: {
        enabled: team.settings?.orderTracking?.enabled ?? false,
        enabledCommentsold:
          team.settings?.orderTracking?.enabledCommentsold ?? false,
        returnTrackingEnabled: team.settings?.returnTracking?.enabled ?? false,
        createTrackers: team.settings?.orderTracking?.createTrackers ?? false,
        showPDPDeliveryEstimate:
          team.settings?.orderTracking?.showPDPDeliveryEstimate ?? false,
        useRedoTrackingUrl:
          team.settings?.orderTracking?.useRedoTrackingUrl ?? false,
        stalledDaysFulfillment:
          team.settings?.orderTracking?.stalledDaysFulfillment?.toString() ??
          "5",
        disableDefaultEmails:
          team.settings?.returnTracking?.disableDefaultEmails ?? false,
        excludeTrackMyOrderClicks:
          team.settings?.orderTracking?.excludeTrackMyOrderClicks ?? false,
        enabledOneClickUpsell:
          team.settings?.orderTracking?.postPurchaseUpsell?.enabled ?? false,
      },
      billing: orderTrackingBilling
        ? {
            enabled: orderTrackingBilling.enabled,
            continueIfNotAccepted: orderTrackingBilling.continueIfNotAccepted,
            pricePerOrder: orderTrackingBilling?.pricePerOrder.toString(),
            freeOrdersPerMonth:
              orderTrackingBilling?.freeOrdersPerMonth.toString(),
            freeUntilDateEnabled:
              orderTrackingBilling?.freeUntilDateEnabled || false,
            freeUntilDate: orderTrackingBilling?.freeUntilDate || "",
            texts: {
              enabled: orderTrackingBilling.texts?.enabled ?? false,
              pricePerSms:
                orderTrackingBilling.texts?.pricePerSms.toString() ?? "0.01",
              pricePerMms:
                orderTrackingBilling.texts?.pricePerMms.toString() ?? "0.03",
            },
            rerouteBillingToMarketing:
              orderTrackingBilling.rerouteBillingToMarketing || false,
          }
        : orderTrackingBillingDefault,
      outboundLabelsGeneral: {
        newOmsEnabled: team.settings?.outboundLabels?.newOmsEnabled ?? false,
        blockedCarrierAccounts:
          team.settings?.outboundLabels?.blockedCarrierAccounts || [],
      },
      reviews: { enabled: team.settings?.reviews?.enabled ?? false },
      oneClickUpsell,
    };
  }, [team]);

  return (
    <OrderTrackingForm
      id={teamId}
      key={+!team}
      onSave={handleSave}
      value={value}
    />
  );
});

const orderTrackingForm = groupInput({
  general: orderTrackingGeneralForm,
  billing: orderTrackingBillingForm,
  reviews: reviewsGeneralForm,
  oneClickUpsell: oneClickUpsellTeamSettingsForm,
});

type OrderTrackingForm = InputProvider.Form<typeof orderTrackingForm>;

type OrderTrackingValue = InputProvider.Value<typeof orderTrackingForm>;

const orderTrackingDefault: OrderTrackingValue = {
  general: orderTrackingGeneralDefault,
  billing: orderTrackingBillingDefault,
  reviews: reviewsGeneralDefault,
  oneClickUpsell: oneClickUpsellTeamSettingsDefault,
};

const OrderTrackingForm = memo(function OrderTrackingForm({
  onSave,
  id,
  value,
}: {
  id: string;
  value: OrderTrackingValue | undefined;
  onSave?: (value: OrderTrackingValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input = useInput(orderTrackingForm, value || orderTrackingDefault);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });
    const value = input.value;

    const orderTrackingEnabledPreviously =
      !!team.settings.orderTracking?.enabled;
    const returnTrackingEnabledPreviously =
      !!team.settings.returnTracking?.enabled;
    const oneClickUpsellEnabledPreviously =
      !!team.settings.orderTracking?.postPurchaseUpsell?.enabled;
    team.settings.orderTracking = {
      ...team.settings.orderTracking,
      enabled: value.general.enabled,
      billing: {
        // there is a slight possibility of a race condition:
        // 1. cron updates activeSubscriptions
        // 2. this form is submitted, overwriting the newer data from webhook
        // But this is unlikely, so... #OstrichAlgorithm
        // https://en.wikipedia.org/wiki/Ostrich_algorithm
        ...team.settings.orderTracking?.billing,
        ...value.billing,
        pricePerOrder: Number(value.billing.pricePerOrder),
        freeOrdersPerMonth: Number(value.billing.freeOrdersPerMonth),
        freeUntilDateEnabled: value.billing.freeUntilDateEnabled,
        freeUntilDate: value.billing.freeUntilDate,
        texts: {
          ...value.billing.texts,
          pricePerSms: Number(value.billing.texts.pricePerSms),
          pricePerMms: Number(value.billing.texts.pricePerMms),
        },
        continueIfNotAccepted: value.billing.continueIfNotAccepted,
        rerouteBillingToMarketing: value.billing.rerouteBillingToMarketing,
      },
      createTrackers: value.general.createTrackers,
      showPDPDeliveryEstimate: value.general.showPDPDeliveryEstimate,
      useRedoTrackingUrl: value.general.useRedoTrackingUrl,
      stalledDaysFulfillment: Number(value.general.stalledDaysFulfillment),
      excludeTrackMyOrderClicks: value.general.excludeTrackMyOrderClicks,
    };
    team.settings.returnTracking = {
      enabled: value.general.returnTrackingEnabled,
      disableDefaultEmails: value.general.disableDefaultEmails,
    };
    team.settings.reviews = { enabled: value.reviews.enabled };

    const freeUpsellsShownPerMonth = Number(
      value.oneClickUpsell.billing.freeUpsellsShownPerMonth,
    );
    const pricePerUpsellShownInCents =
      Number(value.oneClickUpsell.billing.pricePerUpsellInDollars) * 100;

    team.settings.orderTracking.postPurchaseUpsell = {
      enabled: value.oneClickUpsell.enabled,
      billing: {
        ...team.settings.orderTracking.postPurchaseUpsell?.billing,
        ...value.oneClickUpsell.billing,
        freeUpsellsShownPerMonth,
        pricePerUpsellShownInCents,
      },
      createOrderOnUpsell: value.oneClickUpsell.createOrderOnUpsell ?? false,
      type:
        team.settings.orderTracking.postPurchaseUpsell?.type ||
        UpsellProductSource.RECOMMENDATIONS,
      discountEnabled:
        team.settings.orderTracking.postPurchaseUpsell?.discountEnabled ||
        false,
      discountType:
        team.settings.orderTracking.postPurchaseUpsell?.discountType ||
        DiscountValueType.PERCENTAGE,
      discountAmount:
        team.settings.orderTracking.postPurchaseUpsell?.discountAmount || "0",
    };
    if (value.general.enabled && !team.settings.orderTracking.upsellProducts) {
      team.settings.orderTracking.upsellProducts = {
        source: UpsellProductSource.RECOMMENDATIONS,
      };
    }

    const createDefaults: Category[] = [];
    if (value.general.enabled && !orderTrackingEnabledPreviously) {
      createDefaults.push("Order tracking");
    }
    if (
      value.general.returnTrackingEnabled &&
      !returnTrackingEnabledPreviously
    ) {
      // the backend is smart enough to not create defaults
      // if the team already has them
      createDefaults.push("Return tracking");
    }
    if (
      value.general.enabledOneClickUpsell &&
      !oneClickUpsellEnabledPreviously
    ) {
      createDefaults.push("Upsell");
    }

    for (const category of createDefaults) {
      await createDefaultEmailFlows(client, { teamId: id, category, signal });
      await createDefaultEmailTemplates(client, {
        teamId: id,
        category,
        signal,
      });
    }

    await updateTeam(client, { teamId: id, team, signal });
    onSave && onSave(value);
  });

  const { general } = input.inputs;

  return (
    <div className={teamCss.container}>
      <PageForm
        initial={value || orderTrackingDefault}
        input={input}
        save={save}
      >
        <div className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <OrderTrackingGeneralCard input={general} />
              </div>
              <div className={gridCss.span12}>
                <OrderTrackingBillingCard input={input.inputs.billing} />
              </div>
            </div>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <ReviewsGeneralCard input={input.inputs.reviews} />
              </div>
              <div className={gridCss.span12}>
                <OneClickUpsellTeamSettingsCard
                  input={input.inputs.oneClickUpsell}
                />
              </div>
            </div>
          </div>
        </div>
      </PageForm>
    </div>
  );
});
