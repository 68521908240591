import {
  EWWidgetTextProps,
  ExtendedWarrantyPdpExperience,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { assertNever } from "@redotech/util/type";
import { memo } from "react";
import { ExtendedWarrantyRadioModal } from "./radio-modal";
import { ExtendedWarrantyTilesModal } from "./tiles-modal";
import { ExtendedWarrantyUpsellModal } from "./upsell-modal";

export const ExtendedWarrantyOptionsExperience = memo(
  function ExtendedWarrantyOptionsExperience({
    customCss,
    experience,
    offerings,
    selectedOffering,
    cheapestOffering,
    onTileClick,
    openModal,
    text,
    foundSelector,
  }: {
    customCss?: string;
    experience: ExtendedWarrantyPdpExperience;
    offerings: Offering[];
    selectedOffering?: Offering;
    cheapestOffering?: Offering;
    onTileClick: (offering: Offering) => void;
    openModal: () => void;
    text: EWWidgetTextProps;
    foundSelector?: string;
  }) {
    switch (experience) {
      case ExtendedWarrantyPdpExperience.TILES_MODAL:
        return (
          <ExtendedWarrantyTilesModal
            customCss={customCss}
            foundSelector={foundSelector}
            offerings={offerings}
            onLearnMoreClick={openModal}
            onTileClick={onTileClick}
            selectedOffering={selectedOffering}
            text={text}
          />
        );
      case ExtendedWarrantyPdpExperience.UPSELL_MODAL:
        return (
          <ExtendedWarrantyUpsellModal
            cheapestOffering={cheapestOffering}
            customCss={customCss}
            foundSelector={foundSelector}
            onAddClick={openModal}
            selectedOffering={selectedOffering}
            text={text}
          />
        );
      case ExtendedWarrantyPdpExperience.RADIO_MODAL:
        return (
          <ExtendedWarrantyRadioModal
            customCss={customCss}
            foundSelector={foundSelector}
            offerings={offerings}
            onInfoClick={openModal}
            onTileClick={onTileClick}
            selectedOffering={selectedOffering}
            text={text}
          />
        );
      default:
        assertNever(experience);
    }
  },
);
