import { useHandler } from "@redotech/react-util/hook";
import * as classNames from "classnames";
import {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  KeyboardEventHandler,
  memo,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ChevronDownSvg from "../../arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "../../arbiter-icon/chevron-up_filled.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { TextSizeValue } from "../../theme/typography";
import * as css from "./redo-increment-decrement.module.css";

export const incrementDecrementSizes = ["regular", "small"] as const;
export type IncrementDecrementSize = (typeof incrementDecrementSizes)[number];

/**
 * @param required -- when used in a form, will prevent the form from submitting if the input is empty.
 *
 * @param dangerousStyleThatShouldOnlyBeUsedForMerchantBranding -- this prop should only be defined
 * when we want the input to follow a merchant's branding. Do not use it to style this component for a Redo use case.
 */
export interface RedoIncrementDecrementProps {
  className?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  label?: string;
  max?: number;
  min?: number;
  name?: string;
  /**
   * @param text text of the input when it loses focus
   */
  onBlur?(text: string): void;
  placeholder?: string;
  prefix?: string;
  readonly?: boolean;
  required?: boolean;
  selectOnFocus?: boolean;
  setValue(value: number): void;
  size?: IncrementDecrementSize;
  step?: number | "any";
  suffix?: string;
  /**
   * Number, or undefined if blank.
   * If the placeholder is numeric, it will be used instead of undefined.
   */
  value: number | undefined;
}

const MAX_PRECISION = 6;

export const RedoIncrementDecrement = memo(
  forwardRef(function RedoIncrementDecrement(
    {
      className,
      dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
      description,
      disabled,
      error,
      fullWidth,
      label,
      max,
      min,
      name,
      onBlur,
      placeholder,
      prefix,
      readonly,
      required,
      selectOnFocus = true,
      setValue,
      size = "regular",
      step,
      suffix,
      value,
    }: RedoIncrementDecrementProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const parse = (text: string): number =>
      text ? +text : !isNaN(+placeholder!) ? +placeholder! : 0;
    const stringify = (value: number | undefined) =>
      // limit length for floating point math, e.g. increment 0.1 thrice
      value !== undefined
        ? value.toFixed(MAX_PRECISION).replace(/\.?0*$/, "") || "0"
        : "";

    const descriptorFontSize = sizeToDescriptorTextProps[size];
    const stepSize = step === "any" ? 1 : (step ?? 1);

    const hiddenRef = useRef<HTMLSpanElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const incrementRef = useRef<HTMLButtonElement>(null);
    const decrementRef = useRef<HTMLButtonElement>(null);
    const wrapperRef = useRef<HTMLElement>(null);
    const [text, setText] = useState("");
    const [width, setWidth] = useState<number | undefined>();

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => inputRef.current,
    );

    const clamp = (value: number) =>
      Math.min(Math.max(value, min ?? -Infinity), max ?? Infinity);

    useLayoutEffect(() => {
      // set the input value, if not currently being edited
      if (wrapperRef.current?.contains(document.activeElement)) {
        return;
      }
      setText(stringify(value));
    }, [value]);

    useLayoutEffect(() => {
      // set the input width
      if (!hiddenRef.current || !inputRef.current) {
        return;
      }
      const { width } = hiddenRef.current.getBoundingClientRect();
      setWidth(width);
    }, [text]);

    const handleBlur = useHandler(() => {
      onBlur?.(inputRef.current!.value);
      setText(stringify(value));
    });

    const handleChange: ChangeEventHandler<HTMLInputElement> = useHandler(
      (event) => {
        const text = event.target.value;
        setText(text);
        setValue(clamp(parse(text)));
      },
    );

    const handleFocus = useHandler(() => {
      if (selectOnFocus) {
        inputRef.current?.select();
      }
    });

    const handleIncrement = useHandler(() => {
      const value = clamp((parse(text) ?? 0) + stepSize);
      setText(stringify(value));
      setValue(value);
    });

    const handleDecrement = useHandler(() => {
      const value = clamp((parse(text) ?? 0) - stepSize);
      setText(stringify(value));
      setValue(value);
    });

    const handleKeyDown: KeyboardEventHandler = useHandler((e) => {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          incrementRef.current?.focus();
          handleIncrement();
          break;
        case "ArrowDown":
          e.preventDefault();
          decrementRef.current?.focus();
          handleDecrement();
          break;
        case "Backspace":
          if (inputRef.current && document.activeElement !== inputRef.current) {
            inputRef.current.focus();
            const text = inputRef.current.value.slice(0, -1);
            setText(text);
            setValue(parse(text));
          }
          break;
      }
    });

    return (
      <Flex dir="column" gap="sm" w={fullWidth ? "full" : "fit-content"}>
        {label && <Text fontSize={descriptorFontSize}>{label}</Text>}
        <Flex
          align="center"
          as="label"
          className={classNames(
            disabled && css.disabled,
            css.inputWrapper,
            error && css.error,
            className,
            css[size],
          )}
          fontSize={size === "small" ? "xs" : "md"}
          justify="space-between"
          pl={sizeToLeftPadding[size]}
          pr="none"
          py={sizeToVerticalPadding[size]}
          radius={size === "small" ? "xs" : "md"}
          ref={wrapperRef}
          style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
          w={fullWidth ? "full" : "fit-content"}
        >
          <Flex align="flex-start" gap="xs">
            {prefix ? <Text>{prefix}</Text> : null}
            <span className={css.hiddenText} ref={hiddenRef}>
              {text || placeholder}
            </span>
            <input
              className={classNames(css.input, css[size])}
              disabled={disabled}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
              readOnly={readonly}
              ref={inputRef}
              required={required}
              step={step}
              style={{
                width: width !== undefined ? `${width + 4}px` : undefined,
              }}
              type="number"
              value={text}
            />
            {suffix ? <Text>{suffix}</Text> : null}
          </Flex>
          <Flex
            align="flex-end"
            className={css.arrows}
            dir="column"
            gap="none"
            justify="space-between"
          >
            <button
              className={css.incrementer}
              disabled={readonly || disabled}
              onClick={handleIncrement}
              onKeyDown={handleKeyDown}
              ref={incrementRef}
              tabIndex={-1}
              type="button"
            >
              <ChevronUpSvg />
            </button>
            <button
              className={css.decrementer}
              disabled={readonly || disabled}
              onClick={handleDecrement}
              onKeyDown={handleKeyDown}
              ref={decrementRef}
              tabIndex={-1}
              type="button"
            >
              <ChevronDownSvg />
            </button>
          </Flex>
        </Flex>
        {description && (
          <Text
            fontSize={descriptorFontSize}
            textColor={error ? "error" : "tertiary"}
          >
            {description}
          </Text>
        )}
      </Flex>
    );
  }),
);

const sizeToVerticalPadding: Record<IncrementDecrementSize, SpacingValue> = {
  regular: "md",
  small: "xxs",
};

const sizeToLeftPadding: Record<IncrementDecrementSize, SpacingValue> = {
  regular: "none", // 14px in CSS module (not an arbiter spacing value)
  small: "md",
};

const sizeToDescriptorTextProps: Record<IncrementDecrementSize, TextSizeValue> =
  { regular: "sm", small: "xs" };
