import { Currency, getCurrencySymbol } from "@redotech/money/currencies";
import { Card } from "@redotech/redo-web/card";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import {
  PackageProtectionForm,
  PackageProtectionPricing,
} from "@redotech/redo-web/settings-elements/package-protection-elements";
import { FormSwitch } from "@redotech/redo-web/switch";
import * as classNames from "classnames";
import { memo, useContext } from "react";
import { TeamContext } from "../../../team";

export const PackageProtectionCoverageCard = memo(
  function PackageProtectionCoverageCard({
    input,
  }: {
    input: PackageProtectionForm;
  }) {
    const { coverage, protectionExcludeTags } = input.inputs;
    const team = useContext(TeamContext);

    return (
      <Card title="Package Protection coverage">
        <section className={gridCss.grid}>
          <div className={gridCss.span6L}>
            <FormSwitch input={coverage} label="Coverage">
              Enable customers to buy coverage
            </FormSwitch>
          </div>
          <div className={gridCss.span12}>
            <FormChipInput
              delimiter={ChipDelimiter.NEWLINE}
              description="Product tags that will exclude products from package protection coverage."
              input={protectionExcludeTags}
              label="Package Protection Excluded Tags"
              trimWhitespace
            />
          </div>
          <div className={classNames(gridCss.span12)}>
            <Divider />
          </div>
          <div className={classNames(gridCss.span12)}>
            <strong>Pricing</strong>
          </div>
          <div className={gridCss.span12}>
            <PackageProtectionPricing
              adminApp
              canEditSettings
              currencyDisplay={getCurrencySymbol(
                team?._shopify.currency || Currency.USD,
              )}
              input={input}
            />
          </div>
        </section>
      </Card>
    );
  },
);
