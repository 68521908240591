// extracted by mini-css-extract-plugin
export var arrows = "_1v-5";
export var decrementer = "_1v-9";
export var disabled = "_1v-3";
export var error = "_1v-1";
export var hiddenText = "_1v-a";
export var incrementer = "_1v-8";
export var input = "_1v-7";
export var inputWrapper = "_1v-0";
export var readonly = "_1v-2";
export var regular = "_1v-6";
export var small = "_1v-4";