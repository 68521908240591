// extracted by mini-css-extract-plugin
export var button = "_o-0";
export var buttonContent = "_o-a";
export var dontRestrainIconWidth = "_o-7";
export var dropdownButton = "_o-8";
export var iconContainer = "_o-6";
export var lg = "_o-4";
export var md = "_o-3";
export var pending = "_o-b";
export var sm = "_o-2";
export var spinner = "_o-9";
export var xl = "_o-5";
export var xs = "_o-1";