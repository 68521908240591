import { z } from "zod";

export const getCustomerAuthFromCustomerWidgetAuth = {
  input: z.object({ customerWidgetAuthToken: z.string() }),
  output: z.object({
    customerAuthToken: z.string(),
    customerInfo: z.object({
      email: z.string(),
      name: z.string().optional(),
      customerId: z.string().optional(),
    }),
  }),
};
