import { PopperPlacementType } from "@mui/base/Popper";
import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { useEffect, useState } from "react";
import { Dropdown } from "../../dropdown";
import { Flex } from "../../flex";
import { BaseRedoInput, RedoInputSize } from "../input/base-redo-text-input";
import { RedoDropdownInputSize } from "../select-dropdown/redo-single-select-dropdown-input";
import {
  RedoList,
  RedoListItem,
  RedoListItemSelectedSource,
  RedoListProps,
} from "./redo-list";
import { RedoListItemSize } from "./standard-redo-list-items";

export const RedoListDropdown = genericMemo(function RedoListDropdown<T>({
  dropdownOpen,
  setDropdownOpen,
  dropdownAnchor,
  fitToAnchor = false,
  dropdownPlacement,
  searchString,
  setSearchString,
  darkMode,
  searchPlaceholder,
  size = "xs",
  itemSelected,
  preventCloseOnAnchorClick = false,
  ...redoListProps
}: {
  searchString?: string;
  setSearchString?: (search: string) => void;
  searchPlaceholder?: string;
  dropdownOpen: boolean;
  setDropdownOpen: (open: boolean) => void;
  dropdownAnchor: HTMLElement | null;
  fitToAnchor?: boolean;
  dropdownPlacement?: PopperPlacementType;
  darkMode?: boolean;
  size?: RedoDropdownInputSize;
  preventCloseOnAnchorClick?: boolean;
} & Omit<
  RedoListProps<T>,
  "focusedIndex" | "setFocusedIndex" | "refToListenTo" | "size"
>) {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  );

  const [searchRef, setSearchRef] = useState<HTMLDivElement | null>(null);

  const [clickAwayEvent, setClickAwayEvent] = useState<undefined | false>();

  // We need to delay the click event changing by a render cycle
  // to prevent the click that opened it from immediately closing it
  useEffect(() => {
    if (dropdownOpen) {
      setClickAwayEvent(undefined);
    } else {
      setClickAwayEvent(false);
    }
  }, [dropdownOpen]);

  const handleItemSelected = useHandler(
    (args: {
      item: RedoListItem<T>;
      source: RedoListItemSelectedSource;
      index: number;
      selected: boolean;
    }) => {
      setDropdownOpen(false);
      itemSelected(args);
    },
  );

  return (
    <ClickAwayListener
      mouseEvent={clickAwayEvent}
      onClickAway={(event) => {
        if (
          preventCloseOnAnchorClick &&
          dropdownAnchor &&
          dropdownAnchor.contains(event.target as Node)
        ) {
          return;
        }
        setDropdownOpen(false);
      }}
      touchEvent={clickAwayEvent}
    >
      <Dropdown
        anchor={dropdownAnchor}
        constrainHeight
        darkMode={darkMode}
        fitToAnchor={fitToAnchor}
        flexProps={{ p: "none" }}
        open={dropdownOpen}
        placement={dropdownPlacement}
      >
        <Flex dir="column" gap="none">
          {setSearchString && (
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              px="lg"
              py="md"
            >
              <BaseRedoInput
                autoFocus
                placeholder={searchPlaceholder || "Search items..."}
                ref={setSearchRef}
                setValue={setSearchString}
                size={listItemSizeToInputSize[size]}
                value={searchString || ""}
              />
            </Flex>
          )}
          <Flex px="lg" py="md">
            <RedoList
              focusedIndex={focusedIndex}
              itemSelected={handleItemSelected}
              refToListenTo={searchRef || dropdownAnchor}
              setFocusedIndex={setFocusedIndex}
              size={dropdownSizeToListSize[size]}
              {...redoListProps}
            />
          </Flex>
        </Flex>
      </Dropdown>
    </ClickAwayListener>
  );
});

export const listItemSizeToInputSize: Record<
  RedoDropdownInputSize,
  RedoInputSize
> = { xs: "sm", sm: "sm", md: "md" };

export const dropdownSizeToListSize: Record<
  RedoDropdownInputSize,
  RedoListItemSize
> = { xs: "sm", sm: "lg", md: "lg" };
