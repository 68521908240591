import * as classNames from "classnames";
import { JSXElementConstructor, memo, ReactNode } from "react";
import { ButtonSize, IconButton } from "../../button";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import * as lightModeCss from "../../theme/component-colors-light.module.css";
import { TextSizeValue } from "../../theme/typography";
import { UserImage, UserImageSize } from "../../user-image";
import * as badgeColorCss from "./redo-badge-colors.module.css";
import * as badgeCss from "./redo-badge.module.css";

export const redoBadgeSize = ["xs", "sm", "md", "lg"] as const;
export type RedoBadgeSize = (typeof redoBadgeSize)[number];

export const redoBadgeColor = [
  "gray",
  "brand",
  "error",
  "warning",
  "success",
  "grayBlue",
  "blueLight",
  "blue",
  "indigo",
  "purple",
  "pink",
  "orange",
  "black",
  "white",
  "whiteSuccess",
] as const;
export type RedoBadgeColor = (typeof redoBadgeColor)[number];

export const redoBadgeType = ["primary", "alternate"] as const;
export type RedoBadgeType = (typeof redoBadgeType)[number];

type BadgeSegment = ColorSwatch | BadgeIcon;
export type BadgeIcon = {
  type: "icon";
  Icon: JSXElementConstructor<any>;
  onClick?: () => void;
};
interface ColorSwatch {
  type: "color";
  color: string;
}

/**
 * @param type if you are doing something other than pill color, it needs to be fleshed out in the colors module.
 */
export interface RedoBadgeProps {
  segmentLeading?: BadgeSegment;
  iconTrailing?: BadgeSegment;
  avatar?: { alt: string; name?: string; imageUrl: string | null } | undefined;
  text?: string | ReactNode;
  size?: RedoBadgeSize;
  type?: RedoBadgeType;
  color?: RedoBadgeColor;
  iconColor?: RedoBadgeColor;
  setRef?: (ref: HTMLElement | null) => void;
  shadow?: boolean;
  className?: string;
}

export const RedoBadge = memo(function RedoBadge({
  iconTrailing,
  segmentLeading,
  avatar,
  text,
  size = "sm",
  type = "primary",
  color = "gray",
  setRef,
  iconColor,
  shadow = false,
  className,
}: RedoBadgeProps) {
  const px = text ? regularPaddingX[size] : iconOnlyPaddingX[size];
  const py = text ? paddingY[size] : iconOnlyPaddingY[size];
  const textSize = fontSize[size];

  const variantClassnames = [
    badgeCss[size],
    badgeColorCss[type],
    badgeColorCss[color],
  ];
  const iconWrapperClassNames = classNames(
    variantClassnames,
    badgeColorCss.iconWrapper,
    badgeCss.iconWrapper,
    badgeCss[size],
  );

  function renderSegment(badge: BadgeSegment) {
    if (badge.type === "icon") {
      const icon = <badge.Icon />;
      // FIXME icon color does nothing
      const wrappedIcon = badge.onClick ? (
        <IconButton
          className={classNames(
            badgeCss.iconButton,
            iconColor && badgeColorCss[iconColor],
          )}
          onClick={badge.onClick}
          size={ButtonSize.SMALL}
        >
          {icon}
        </IconButton>
      ) : (
        icon
      );
      return <Flex className={iconWrapperClassNames}>{wrappedIcon}</Flex>;
    } else if (badge.type === "color") {
      const colorSwatch = badge.color;
      return (
        <div
          className={badgeCss.colorSwatch}
          style={{ backgroundColor: colorSwatch }}
        />
      );
    } else {
      throw new Error("Invalid badge type");
    }
  }

  return (
    <Flex
      align="center"
      className={classNames(
        lightModeCss.lightMode,
        badgeCss.arbiterBadgeContainer,
        variantClassnames,
        className,
        { [badgeCss.shadow]: shadow },
      )}
      gap={itemGap[size]}
      px={px}
      py={py}
    >
      {segmentLeading && renderSegment(segmentLeading)}
      {avatar && (
        <UserImage
          alt={avatar.alt}
          imageUrl={avatar.imageUrl}
          name={avatar.name}
          size={avatarSize[size]}
        />
      )}
      {text && (
        <Text
          fontSize={textSize}
          fontWeight="medium"
          overflow="hidden"
          ref={setRef}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {text}
        </Text>
      )}
      {iconTrailing && renderSegment(iconTrailing)}
    </Flex>
  );
});

const fontSize: Record<RedoBadgeSize, TextSizeValue> = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
  lg: "sm",
};

const iconOnlyPaddingY: Record<RedoBadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "xs",
  md: "sm",
  lg: "md",
};

const paddingY: Record<RedoBadgeSize, SpacingValue> = {
  xs: "xxs",
  sm: "xxs",
  md: "xxs",
  lg: "xs",
};

const iconOnlyPaddingX: Record<RedoBadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "xs",
  md: "sm",
  lg: "md",
};

const regularPaddingX: Record<RedoBadgeSize, SpacingValue> = {
  xs: "xs",
  sm: "md",
  md: "md",
  lg: "lg",
};

const itemGap: Record<RedoBadgeSize, SpacingValue> = {
  xs: "xxs",
  sm: "xs",
  md: "sm",
  lg: "sm",
};

const avatarSize: Record<RedoBadgeSize, UserImageSize> = {
  xs: UserImageSize.X_TINY,
  sm: UserImageSize.TINY,
  md: UserImageSize.TINY,
  lg: UserImageSize.TINY,
};
