// extracted by mini-css-extract-plugin
export var brand = "_2b-7";
export var compact = "_2b-5";
export var extraSmall = "_2b-a";
export var icon = "_2b-0";
export var large = "_2b-3";
export var light = "_2b-1";
export var medium = "_2b-4";
export var outline = "_2b-9";
export var primaryDark = "_2b-8";
export var primaryLight = "_2b-6";
export var primaryModern = "_2b-b";
export var success = "_2b-2";