import { z } from "zod";

export const ReviewResultSchema = z.object({
  risk: z
    .enum(["low", "medium", "high"])
    .describe(
      "The assessed risk level of the return. Low means the return is unlikely to be fraudulent. Medium means the return is likely to be fraudulent. High means the return is definitely fraudulent.",
    ),
  approved: z
    .boolean()
    .describe(
      "Indicates whether the return should be approved (true) or denied (false).",
    ),
  explanation: z
    .string()
    .describe(
      "Explanation detailing the reason for the decision made. Start with explaining the decision, then justify that decision by going through the relevant information and evidence.",
    ),
});

export type ReviewResult = z.infer<typeof ReviewResultSchema>;
