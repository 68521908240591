import { CustomerReturnCreatedAttachmentGenerationParameters } from "./email-builder";
import { Order, Provider, SesEvent } from "./order";
import { getReturner, Return, ReturnAddress, ReturnWarning } from "./return";
import { TimelineEvent } from "./timeline";

import { ITracker as _EasyPostTracker } from "@easypost/api/types/Tracker/Tracker";
import { ReturnZod } from "./return";
import { PickupLocation, pickupLocationOptions } from "./return-flow";
export enum TrackableType {
  ORDER = "order",
  RETURN = "return",
}

/** The company providing shipment tracking */
export enum TrackingPlatform {
  EASYPOST = "easypost",
  TRACK123 = "track123",
}

/** A unique identifier for a shipment tracker */
interface TrackingPlatformIdentifier {
  /** The company providing tracking information (Optional for backwards compatibility) */
  trackingPlatform: TrackingPlatform;
  /** The identifier used by the tracking company */
  id: string;
}

/** Easypost tracker type with some fields removed and with the optional types fixed.*/
export interface _FixedEasyPostTracker {
  /**
   * The tracking code provided by the carrier
   */
  tracking_code: _EasyPostTracker["tracking_code"];

  /**
   * The current status of the package, possible values are "unknown", "pre_transit", "in_transit", "out_for_delivery", "delivered", "available_for_pickup", "return_to_sender", "failure", "cancelled" or "error"
   * "expired" is track123 specific
   */
  status: _EasyPostTracker["status"] | "expired";

  /**
   * Additional details about the current status, possible values are "unknown", "status_update", "departed_facility", "arrived_at_facility", "out_for_delivery", "arrived_at_destination"
   */
  status_detail: _EasyPostTracker["status_detail"];

  /**
   * The name of the carrier handling the shipment
   */
  carrier: _EasyPostTracker["carrier"];

  /**
   * Array of the associated TrackingDetail objects
   */
  tracking_details: Array<
    Omit<
      _EasyPostTracker["tracking_details"][number],
      "source" | "object" | "tracking_location"
    > & {
      tracking_location: Partial<
        Omit<
          _EasyPostTracker["tracking_details"][number]["tracking_location"],
          "object"
        >
      >;
    }
  >;

  /**
   * URL to a publicly-accessible html page that shows tracking details for this tracker
   */
  public_url: _EasyPostTracker["public_url"];

  /**
   * The estimated delivery date provided by the carrier (if available)
   */
  est_delivery_date?: _EasyPostTracker["est_delivery_date"];

  /**
   * The id of the EasyPost Shipment object associated with the Tracker (if any)
   *
   * @remarks
   * Redo Returns uses this field to store reference the Return object.
   */
  shipment_id?: _EasyPostTracker["shipment_id"];

  /**
   * Carrier details including the address the package is being delivered to
   */
  carrier_detail?: _EasyPostTracker["carrier_detail"];
}

/**
 * Representation of a shipment tracker event sent from a third-party tracking company.
 *
 * @remarks
 * We need an interface to fit multiple tracking providers (Easypost and Track123).
 * Heavily based on the Easypost Tracker object.
 */
export type ExternalShipmentTracker = TrackingPlatformIdentifier &
  _FixedEasyPostTracker;

/**
 * Interface for a set of packages that can be tracked.
 * Essentially a stripped-down union of the Order and Return models,
 * molded into the ideal shape for order tracking use cases.
 *
 * @remarks
 * This interface is intended for use with the Order Tracking and Return Tracking products,
 * so that the emails and SMS messages can be generated agnostically.
 */
export interface BaseTrackable {
  /**
   * The Redo ID of the order or return (MongoDB ObjectID)
   */
  id: string;
  customer: {
    shopifyId?: string;
    email: string;
    name?: string;
    firstName?: string;
    lastName?: string;
  };
  fulfillments: TrackableFulfillment[];
  lineItems: TrackableLineItem[];
  currentEmailFlows: {
    emailFlowId: string;
    currentStep: number;
    continueDate: Date;
    fulfillmentId?: string;
  }[];

  /**
   * The time this object was created in Redo's system
   */
  createdAt: Date;

  /**
   * On orders, this is field is just a reference to itself.
   * On returns, it references the original order.
   */
  originalOrder: OriginalOrder;
  teamId: string;
  discount?: {
    id?: string | null;
    code?: string | null;
    description?: string | null;
    expirationDateTime?: string | null;
    status?: string | null;
  };
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
    trackingCode?: string;
  }[];
  provider?: Provider;
}

export interface TrackableFulfillment {
  id: string;
  /**
   * Easypost trackers. This list may be empty.
   */
  trackers: ExternalShipmentTracker[];
  lineItems: TrackableLineItem[];
  isShipped: boolean;
  updatedAt?: Date;
  tracking_numbers?: string[];
  status: string;
  shipment_status?: string;
  tracking_company?: string;
  tracking_url?: string;
  locationId?: string;
}

/**
 * Similar interfaces already exist for line items,
 * but for maximum control and speed, I'm making a new one (Josh)
 */
export interface TrackableLineItem {
  id: number;
  variantId?: string;
  productId?: string;
  title: string;
  variantTitle: string;
  quantity: number;
  /**
   * @deprecated Use priceSet instead
   */
  price: number;
  priceSet: { presentment_money: { amount: number; currency_code: string } };
  isRedo: boolean;
  requiresShipping: boolean;
  image: { src: string };
  tags: string[];
  properties: { [key: string]: string };
  sku: string;
  green_return: boolean;
  vendor?: string;
  handle?: string;
  compareAtPrice?: string | null;
}

interface OriginalOrder {
  id: string;
  shopify: ShopifyOrder;
}

/**
 * Shopify tracking info. Not as useful as Easypost data but guaranteed to exist.
 */
export interface ShopifyFulfillment {
  id: string;
  updatedAt?: Date;
  shipmentStatus: string;
  isShipped: boolean;
  locationId?: string;
  tracking: {
    /*
     * Tracking numbers as entered in Shopify.
     * This list will always have at least one entry (although it may be null)
     * In rare cases, it may have more than one.
     */
    numbers: (string | null)[];
    /*
     * Tracking URLs as entered in Shopify.
     * This list will always have at least one entry (although it may be null)
     * In rare cases, it may have more than one.
     */
    urls: (string | null)[];
    company: string | null;
  };
}

/**
 * Similar interfaces already exist for shopify order data,
 * but for maximum control and speed, I'm making a new one (Josh)
 */ export interface ShopifyOrder {
  id: string;
  number: number;
  name: string;
  createdAt: Date;
  contact_email: string;
  cancelReason?: string;
  /*
   * Intended for estimating delivery dates only, not general use.
   * Favor Trackable.lineItems instead, and avoid adding unnecessary fields here.
   */
  lineItems: { productId: string | null }[];
  fulfillments: ShopifyFulfillment[];
  customer: { id: string } | null;
  shippingLines: {
    title: string;
    price: number;
    discountedPrice: number;
    discountedPriceSet: { presentment_money: { amount: number } };
    carrierIdentifier: string;
    code: string;
  }[];
  priceBeforeDiscounts: number;
  discounts: {
    individualDiscounts: {
      discountType: "fixed_amount" | "percentage";
      amount: number;
    }[];
  };
  presentmentCurrency: string;
  currentSubtotalPriceSet: { presentment_money: { amount: number } };
  currentTotalTaxSet: { presentment_money: { amount: number } };
  currentTotalPriceSet: { presentment_money: { amount: number } };
  shippingAddress?: {
    name: string;
    address1: string;
    address2: string;
    city: string;
    province: string;
    provinceCode: string;
    zip: string;
    country: string;
    countryCode: string;
    phone: string;
  };
  billingAddress?: {
    name: string;
    address1: string;
    address2: string;
    city: string;
    province: string;
    zip: string;
    country: string;
    countryCode: string;
    phone: string;
  };
  sourceName?: string;
  source?: string;
  financialStatus?:
    | "authorized"
    | "paid"
    | "partially_paid"
    | "partially_refunded"
    | "pending"
    | "refunded"
    | "voided";
  note?: string;
  tags: string[];
}

export type Trackable = BaseTrackable &
  (
    | { type: TrackableType.ORDER }
    | {
        type: TrackableType.RETURN;
        returnProducts: Return["products"] | ReturnZod["products"];
        status: Return["status"];
        shipment: Return["shipment"] | ReturnZod["shipment"];
        shipments: Return["shipments"] | ReturnZod["shipments"];
        reShipments: Return["reShipments"] | ReturnZod["reShipments"];
        customerReturnCreatedAttachmentGenerationParameters: CustomerReturnCreatedAttachmentGenerationParameters; // best name ever
        notes?: Return["notes"] | ReturnZod["notes"];
        rejectReason?: string;
        pickup?: Return["pickup"] | ReturnZod["pickup"];
        returnType: Return["type"] | ReturnZod["type"];
        returnTypes: Return["returnType"] | ReturnZod["returnType"];
        markedForManualReview?: boolean;
        draftOrderURL?: string;
        warnings?: ReturnWarning[];
        inStoreReturn?: boolean;
        shipping_address?: ReturnAddress;
        merchant_address?: ReturnAddress;
        exchangeOrder: any[];
        form_label?: string;
        postage_label?: string;
        happyReturnsData?: any;
        pickupDayOfWeek?: string;
        pickupDetail?: string;
        packingSlipLink?: string;
        repair?: Return["repair"];
      }
  );

export function orderToTrackable(order: Order): Trackable {
  const shopifyOrder = mapShopify(order);
  const lineItems =
    order.shopify.line_items?.map(orderLineItemToTrackableLineItem) ?? [];
  return {
    type: TrackableType.ORDER,
    id: order._id.toString(),
    customer: {
      shopifyId: order.shopify.customer?.id,
      email: order.shopify.email,
      name: order.customer_name,
      firstName: order.shopify.customer?.first_name,
      lastName: order.shopify.customer?.last_name,
    },
    fulfillments: order.shopify.fulfillments.map((fulfillment) => ({
      id: fulfillment.id?.toString(),
      trackers: order.trackers
        .filter((t) => {
          try {
            return t.fulfillmentID.toString() === fulfillment.id?.toString();
          } catch (e) {
            console.error(
              `Error comparing tracker fulfillment ID ${t.fulfillmentID} to fulfillment ID ${fulfillment.id}, order ID ${order._id}`,
            );
            return false;
          }
        })
        .map((t) => t._tracker),
      // fulfillment line items have less info than root line items
      lineItems: fulfillment.line_items.map(
        (lineItem) =>
          lineItems.find((li) => li.id === lineItem.id) ??
          orderLineItemToTrackableLineItem(lineItem),
      ),
      isShipped: !!fulfillment.shipment_status,
      updatedAt: fulfillment.updated_at
        ? new Date(fulfillment.updated_at)
        : undefined,
      tracking_numbers: fulfillment.tracking_numbers,
      status: fulfillment.status,
      shipment_status: fulfillment.shipment_status ?? "unknown",
      tracking_company: fulfillment.tracking_company ?? undefined,
      tracking_url: fulfillment.tracking_url ?? undefined,
      locationId: fulfillment.location_id,
    })),

    lineItems:
      order.shopify.line_items?.map(orderLineItemToTrackableLineItem) ?? [],
    createdAt: new Date(order.createdAt),
    originalOrder: { id: order._id.toString(), shopify: shopifyOrder },
    currentEmailFlows:
      order.currentEmailFlows?.map((f) => ({
        ...f,
        continueDate: new Date(f.continueDate),
      })) ?? [],
    teamId: order.team.toString(),
    discount: order.discount,
    trackingEmailsSent: order.trackingEmailsSent,
    provider: order.provider,
  };
}

export function returnToTrackable(return_: Return, order: Order): Trackable {
  // returns don't track the same details about line items,
  // so we have to get the ones from the order object
  const returnProducts = return_.products ?? [];
  const lineItems = (order.shopify.line_items ?? [])
    .filter((lineItem) =>
      return_.products.some(
        (product) => product.line_item_id.toString() === lineItem.id.toString(),
      ),
    )
    .map(orderLineItemToTrackableLineItem);

  const shipments =
    return_.shipments?.length > 0
      ? return_.shipments
      : return_.shipment
        ? [return_.shipment]
        : [];

  const returner = getReturner(return_);
  if (!returner.email) {
    throw new Error("No email found on returner");
  }

  const pickupDate = return_.pickup?.pickupDate
    ? Temporal.PlainDate.from(return_.pickup.pickupDate)
    : undefined;
  const pickupDayOfWeek = pickupDate?.toLocaleString(undefined, {
    weekday: "long",
  });

  const packagePickupLocation = return_.pickup?.pickupLocation?.packageLocation;
  const packagePickupSpecialInstructions =
    return_.pickup?.pickupLocation?.specialInstructions;

  const packagePickupDetail = return_.pickup
    ? packagePickupLocation === PickupLocation.OTHER
      ? `with the following instructions: "${packagePickupSpecialInstructions}"`
      : pickupLocationOptions.get(packagePickupLocation as PickupLocation)
          ?.detail
    : undefined;

  return {
    returnProducts,
    type: TrackableType.RETURN,
    status: return_.status,
    returnType: return_.type,
    returnTypes: return_.returnType,
    exchangeOrder: return_.exchangeOrder,
    id: return_._id.toString(),
    customer: {
      email: returner.email!,
      name: returner.name,
      firstName: returner.firstName || "",
      lastName: returner.lastName || "",
      shopifyId: order.shopify.customer?.id,
    },
    fulfillments: shipments.map((shipment) => ({
      id: shipment._shipment.id,
      trackers: [shipment._shipment.tracker],
      // returns do not track lineItems per shipment,
      // so we'll just put all lineItems on all shipments
      lineItems,
      isShipped: !["unknown", "pre_transit"].includes(
        shipment._shipment.status,
      ),
      updatedAt: shipment._shipment.updated_at
        ? new Date(shipment._shipment.updated_at)
        : undefined,
      status: shipment._shipment.status,
      shipment_status: shipment._shipment.status,
    })),
    lineItems,
    createdAt: new Date(return_.createdAt),
    originalOrder: { id: order._id.toString(), shopify: mapShopify(order) },
    currentEmailFlows:
      return_.currentEmailFlows?.map((f) => ({
        ...f,
        continueDate: new Date(f.continueDate),
      })) ?? [],
    teamId: order.team.toString(),
    discount: return_.discount,
    shipment: return_.shipment,
    shipments: return_.shipments,
    reShipments: return_.reShipments,
    packingSlipLink: return_.packingSlipLink,
    // populating the following interface made me deeply concerned about the quality of the return product code
    customerReturnCreatedAttachmentGenerationParameters: {
      shipments: return_.shipments.map((shipment) => ({
        postage_label: shipment.postage_label,
        form_label: shipment.form_label!,
        _shipment: shipment._shipment,
      })),
      variables: {
        postage_label: return_.postage_label, // why are there two postage label fields??
        form_label: return_.form_label, // why are there two form label fields??
        commercialInvoice: return_.shipment?._shipment?.forms?.find(
          (form: any) => {
            return form.form_type === "commercial_invoice";
          },
        )?.form_url,
      },
      pickup_details: return_.shipment?.pickup,
    },
    notes: { notesToCustomer: return_.notes?.notesToCustomer },
    rejectReason: return_.rejectReason ?? "No reason provided",
    trackingEmailsSent: return_.trackingEmailsSent,
    pickup: return_.pickup,
    markedForManualReview: return_.markedForManualReview,
    draftOrderURL: return_.draftOrderURL,
    warnings: return_.warnings,
    inStoreReturn: return_.inStoreReturn,
    merchant_address: return_.merchant_address,
    shipping_address: return_.shipping_address,
    form_label: return_.form_label,
    postage_label: return_.postage_label,
    happyReturnsData: return_.happyReturnsData,
    provider: return_?.provider ?? undefined,
    pickupDayOfWeek,
    pickupDetail: packagePickupDetail,
    repair: return_.repair,
  };
}

export function mapShopify(order: Order): ShopifyOrder {
  const { shipping_address, billing_address, ...shopify } = order.shopify;
  const shippingAddress = shipping_address
    ? {
        name: shipping_address.name,
        address1: shipping_address.address1,
        address2: shipping_address.address2,
        city: shipping_address.city,
        province: shipping_address.province,
        provinceCode: shipping_address.province_code,
        zip: shipping_address.zip,
        country: shipping_address.country,
        countryCode: shipping_address.country_code,
        phone: shipping_address.phone,
      }
    : undefined;
  const billingAddress = billing_address
    ? {
        name: billing_address.name,
        address1: billing_address.address1,
        address2: billing_address.address2,
        city: billing_address.city,
        province: billing_address.province,
        provinceCode: billing_address.province_code,
        zip: billing_address.zip,
        country: billing_address.country,
        countryCode: billing_address.country_code,
        phone: billing_address.phone,
      }
    : undefined;
  return {
    id: shopify.id?.toString(),
    number: shopify.order_number,
    name: shopify.name,
    cancelReason: shopify.cancel_reason,
    contact_email: shopify.contact_email,
    createdAt: new Date(shopify.created_at),
    customer: shopify.customer ? { id: shopify.customer.id } : null,
    fulfillments: shopify.fulfillments.map((fulfillment) => ({
      id: fulfillment.id?.toString(),
      updatedAt: fulfillment.updated_at
        ? new Date(fulfillment.updated_at)
        : undefined,
      shipmentStatus: fulfillment.shipment_status || "unknown",
      isShipped: !["unknown", "pre_transit"].includes(
        fulfillment.shipment_status || "unknown",
      ),
      tracking: {
        numbers: fulfillment.tracking_numbers ?? [],
        urls: fulfillment.tracking_urls ?? [],
        company: fulfillment.tracking_company,
      },
      locationId: fulfillment.location_id,
    })),
    lineItems: shopify.line_items.map((line) => ({
      productId: line.product_id?.toString() ?? null,
    })),
    shippingLines: shopify.shipping_lines.map((line: any) => ({
      title: line.title,
      price: parseFloat(line.price),
      discountedPrice: parseFloat(line.discounted_price),
      discountedPriceSet: line.discounted_price_set,
      carrierIdentifier: line.carrier_identifier,
      code: line.code,
    })),
    presentmentCurrency: shopify.presentment_currency,
    priceBeforeDiscounts: parseFloat(shopify.total_line_items_price),
    currentSubtotalPriceSet: shopify.current_subtotal_price_set,
    discounts: {
      individualDiscounts: shopify.discount_applications?.map(
        ({ value, value_type }: any) => ({
          discountType: value_type,
          amount: parseFloat(value),
        }),
      ),
    },
    currentTotalTaxSet: shopify.current_total_tax_set || null,
    currentTotalPriceSet: shopify.current_total_price_set,
    shippingAddress,
    billingAddress,
    note: shopify.note,
    tags: shopify.tags?.split(",").map((tag: string) => tag.trim()) ?? [],
    sourceName: shopify.source_name,
    source: shopify.source,
    financialStatus: shopify.financial_status as
      | "authorized"
      | "paid"
      | "partially_paid"
      | "partially_refunded"
      | "pending"
      | "refunded"
      | "voided"
      | undefined,
  };
}

function orderLineItemToTrackableLineItem(
  lineItem: Order["shopify"]["line_items"][number],
): TrackableLineItem {
  return {
    id: lineItem.id,
    title: lineItem.title,
    productId: lineItem.product_id?.toString() ?? undefined,
    variantId: lineItem.variant_id?.toString() ?? undefined,
    variantTitle: lineItem.variant_title,
    quantity: lineItem.quantity,
    price: parseFloat(lineItem.price),
    priceSet: lineItem.price_set,
    isRedo: ["re:do", "redo"].includes(lineItem.vendor),
    requiresShipping: lineItem.requires_shipping,
    image: lineItem.image?.src
      ? lineItem.image
      : {
          src: `https://placehold.co/150?text=${lineItem.title.toLowerCase().includes("return") ? "Return" : lineItem.title}`,
        },
    tags: lineItem.tags ?? [],
    properties: lineItem.properties.reduce(
      (acc, prop) => {
        acc[prop.name] = prop.value;
        return acc;
      },
      {} as Record<string, string>,
    ),
    sku: lineItem.sku,
    green_return: lineItem.green_return,
    vendor: lineItem.vendor,
  };
}

/** Intended for use on Order and Return models, not general use */
export interface ITrackable {
  _id: string;
  timeline: TimelineEvent[];
  trackers: { _tracker: ExternalShipmentTracker; fulfillmentID: string }[];
  trackingTimeline: any[]; // TODO: define this type
  trackingAnalytics: {
    email: SesEvent[];
    page: {
      url: string;
      eventType: "ad" | "upsell";
      image?: string;
      createdAt: string;
    }[];
  };
  trackingTextsSent?: { sid: string; mms: boolean; sentAt: string }[];
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
    trackingCode?: string;
  }[];
  trackingBillingStatus?: "billed" | "free";
  discount?: {
    id: string;
    description: string;
    code: string;
    expirationDateTime: string;
  };
}

export interface CostSummary {
  shippingProduct: string;
  shippingProductCost: string;
  taxCost: string;
  totalCost: string;
  priceBeforeDiscounts: string;
  discountStrings: string[];
}

// Record type ensures exhaustiveness
const trackingStatusHelper: Record<
  ExternalShipmentTracker["status"],
  undefined
> = {
  unknown: undefined,
  pre_transit: undefined,
  in_transit: undefined,
  out_for_delivery: undefined,
  available_for_pickup: undefined,
  cancelled: undefined,
  delivered: undefined,
  error: undefined,
  failure: undefined,
  return_to_sender: undefined,
  expired: undefined,
} as const;

export const trackingStatuses = Object.keys(
  trackingStatusHelper,
) as (keyof typeof trackingStatusHelper)[];
