import { z } from "zod";

export const getProductById = {
  input: z.object({ productId: z.string() }),
  output: z.object({
    imageSrc: z.string().optional(),
    title: z.string(),
    variants: z.array(z.object({ id: z.string(), available: z.boolean() })),
  }),
};
