import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import {
  DEFAULT_TEXT,
  EWWidgetTextProps,
  ExtendedWarrantyDataTarget,
  ExtendedWarrantyPdpNoCoverageType,
  ExtendedWarrantyTilesSize,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import LinkExternalIcon from "@redotech/redo-web/arbiter-icon/link-external-02.svg";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { OfferingTiles } from "@redotech/redo-web/expanded-warranties/offering-tiles";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { useIsMobile } from "@redotech/redo-web/utils/check-mobile";
import * as classNames from "classnames";
import htmlReactParser from "html-react-parser";
import { memo, useEffect, useMemo, useState } from "react";
import { ModalContentV2ImageWrapper, ModalLogo } from "../modal";
import { EwSettingsContext } from "./provider/ew-settings-provider";

export const ExtendedWarrantyModal = memo(function ExtendedWarrantyModal({
  open,
  onClose,
  sideImage,
  logo,
  offerings,
  selectedOffering,
  onTileClick,
  customCss,
  isPreview,
  text,
  seeDetailsUrl,
  foundSelector,
}: {
  open: boolean;
  onClose?(): void;
  sideImage?: string;
  logo?: string;
  offerings: Offering[];
  selectedOffering?: Offering;
  onTileClick: (offering: Offering) => void;
  customCss?: string;
  isPreview?: boolean;
  seeDetailsUrl?: string;
  text: EWWidgetTextProps;
  foundSelector?: string;
}) {
  const isMobile = useIsMobile();

  const { getWidgetConfig } = useRequiredContext(EwSettingsContext);

  const widgetConfig = useMemo(() => {
    if (!getWidgetConfig) return undefined;

    return getWidgetConfig();
  }, [getWidgetConfig]);

  const view = useMemo(() => {
    if (!widgetConfig) return undefined;

    return {
      noCoverageLabel:
        widgetConfig.pdpCoverageDisabledLabel ||
        DEFAULT_TEXT.pdpCoverageDisabledLabel,
      pdpNoCoverageType:
        widgetConfig.pdpNoCoverageType ||
        ExtendedWarrantyPdpNoCoverageType.BASIC,
      pdpNoCoveragePrice:
        widgetConfig.pdpNoCoveragePrice || DEFAULT_TEXT.pdpNoCoveragePrice,
      pdpLabel: widgetConfig.pdpLabel || DEFAULT_TEXT.pdpLabel,
    };
  }, [widgetConfig]);

  const {
    modalTitle,
    modalCoverageHeader,
    modalDescription,
    modalBulletPoints,
    modalPlanHeader,
  } = text;

  const [modalSelectedOffering, setModalSelectedOffering] = useState<
    Offering | undefined
  >(selectedOffering);

  const handleTileClick = useHandler((offering: Offering) => {
    setModalSelectedOffering(offering);
  });

  const handleAccept = useHandler(() => {
    if (modalSelectedOffering) {
      onTileClick(modalSelectedOffering);
    }
    onClose?.();
  });

  const handleDecline = useHandler(() => {
    const noCoverageOffering = offerings.find(
      (offering) => offering.id === NO_COVERAGE_TIME_ID,
    );
    if (noCoverageOffering) {
      onTileClick(noCoverageOffering);
    }
    onClose?.();
  });

  const handleSeeDetailsClick = useHandler(() => {
    if (seeDetailsUrl) {
      window.open(seeDetailsUrl, "_blank");
    }
  });

  useEffect(() => {
    if (selectedOffering && selectedOffering.id !== NO_COVERAGE_TIME_ID) {
      setModalSelectedOffering(selectedOffering);
    } else {
      setModalSelectedOffering(offerings[0]);
    }
  }, [selectedOffering, offerings]);

  if (!view) return null;

  return (
    <ModalContentV2ImageWrapper
      customCss={customCss}
      foundSelector={foundSelector}
      isExtendedWarranty
      isPreview={isPreview}
      onClose={onClose}
      open={open}
      sideImage={sideImage}
    >
      <Flex
        className={warrantyStyles.extendedWarrantyModal}
        data-target={ExtendedWarrantyDataTarget.MODAL_CONTAINER}
        flexDirection="column"
        gap={isMobile ? "none" : "lg"}
      >
        <ModalLogo logo={logo} />
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_1}
          flexDirection="column"
          gap="sm"
        >
          <Text fontSize="xl" fontWeight="semibold">
            {htmlReactParser(modalTitle)}
          </Text>
          <Text fontSize="sm" fontWeight="thin">
            {htmlReactParser(modalDescription)}
          </Text>
        </Flex>
        {modalBulletPoints.length > 0 && (
          <Flex
            data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_2}
            flexDirection="column"
            gap="sm"
          >
            <Text fontSize="md" fontWeight="semibold">
              {htmlReactParser(modalCoverageHeader)}
            </Text>
            <ul
              className={classNames(
                warrantyStyles.bullets,
                warrantyStyles.subtitle,
              )}
              data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_2_LIST}
            >
              {modalBulletPoints.map((bulletPoint, index) => (
                <li
                  data-target={
                    ExtendedWarrantyDataTarget.MODAL_CONTENT_2_LIST_ITEM
                  }
                  key={index}
                >
                  <Text fontSize="sm" fontWeight="thin">
                    {htmlReactParser(bulletPoint)}
                  </Text>
                </li>
              ))}
            </ul>
          </Flex>
        )}

        {!!seeDetailsUrl && (
          <Flex
            align="center"
            className={warrantyStyles.seeDetailsLink}
            data-target={ExtendedWarrantyDataTarget.SEE_DETAILS_LINK}
            flexDirection="row"
            gap="sm"
            onClick={handleSeeDetailsClick}
            w="fit-content"
          >
            <Text fontSize="xs" fontWeight="regular">
              See more details
            </Text>
            <LinkExternalIcon className={warrantyStyles.seeDetailsIcon} />
          </Flex>
        )}
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_CONTENT_3}
          flexDirection="column"
          gap="xs"
        >
          <Text fontSize="md" fontWeight="semibold">
            {htmlReactParser(modalPlanHeader)}
          </Text>
          <OfferingTiles
            excludeNoCoverage
            offerings={offerings}
            onTileClick={handleTileClick}
            selectedOffering={modalSelectedOffering}
            tilesSize={ExtendedWarrantyTilesSize.REGULAR}
            view={view}
          />
        </Flex>
        <Flex
          data-target={ExtendedWarrantyDataTarget.MODAL_BUTTON_CONTAINER}
          gap="xs"
          justify="flex-end"
          pt="3xl"
        >
          <RedoButton
            hierarchy="link"
            onClick={handleDecline}
            size="md"
            text="Decline coverage"
          />
          <RedoButton
            hierarchy="primary"
            onClick={handleAccept}
            size="md"
            text="Add protection"
          />
        </Flex>
      </Flex>
    </ModalContentV2ImageWrapper>
  );
});
