import { ShippingCoverageZodSchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export interface GetRisIntegrationDef {
  input: z.ZodObject<{
    teamId: z.ZodType<string>;
    integrationType: z.ZodString;
  }>;
  output: z.ZodObject<{
    risIntegration: z.ZodNullable<typeof ShippingCoverageZodSchema>;
    hasShopifyIntegration: z.ZodBoolean;
  }>;
}

export const getRisIntegration: GetRisIntegrationDef = {
  input: z.object({ teamId: zExt.objectId(), integrationType: z.string() }),
  output: z.object({
    risIntegration: ShippingCoverageZodSchema.nullable(),
    hasShopifyIntegration: z.boolean(),
  }),
};
