import {
  GetDeliveryProfileOutput,
  GetDeliveryProfileResult,
} from "@redotech/admin-sdk/rpc/schema/shopify/get-delivery-profile";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  CoverageProductEnum,
  DeliveryFilterType,
  DeliverySortType,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import {
  ShippingRateTable,
  ShippingRateTableArraySchema,
  isFixedRateTable,
} from "@redotech/redo-model/integration/redo-in-shipping/shipping-rate-table";
import {
  DeliveryLocationGroupZone,
  DeliveryMethodDefinition,
  ProfileLocationGroup,
} from "@redotech/redo-model/integration/shopify/delivery-profile";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCheckboxAdvanced } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox-advanced";
import RefreshIcon from "@redotech/redo-web/arbiter-icon/refresh-cw-02_filled.svg";
import { Card } from "@redotech/redo-web/card";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/icon-old/three-dots-horizontal.svg";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { PackageProtectionForm } from "@redotech/redo-web/settings-elements/package-protection-elements";
import { FormSwitch } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { getTextFromConditions } from "@redotech/redo-web/utils/shipping-rates";
import { assertNever } from "@redotech/util/type";
import { memo, useEffect, useState } from "react";
import { RedoAdminRpcClientContext } from "../../../../app/redo-admin-rpc-client-provider";
import { RisForm } from "../coverage-product";
import { EditRate } from "./edit-rate";

//RateTables don't have a good unique key so just use a counter for now
function counter() {
  let value = 0;
  return () => value++;
}

export const ShippingZones = memo(function ShippingZones({
  input,
  packageProtectionInput,
  hasReturnsCoverage,
  hasCarrierServices,
  teamId,
}: {
  input: RisForm;
  packageProtectionInput: PackageProtectionForm;
  hasReturnsCoverage: boolean;
  hasCarrierServices: boolean;
  teamId: string;
}) {
  const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

  const [conversionError, setConversionError] = useState<string | undefined>();
  const [editRate, setEditRate] = useState<ShippingRateTable | undefined>();
  //Regardless if they hit refresh or not, we still want to load their delivery profile (but don't show it on the UI until they click load)
  const [deliveryProfileLoad, triggerDeliveryProfileLoad] = useTriggerLoad(
    async () => {
      const result: GetDeliveryProfileOutput =
        await rpcClient.getDeliveryProfile({ teamId });
      switch (result.result) {
        case GetDeliveryProfileResult.SUCCESS:
          return result.deliveryProfile;
        case GetDeliveryProfileResult.ERROR__GRAPHQL_ACCESS_DENIED:
          throw new Error(
            "In order to enable RIS, this merchant must update our collaborator access to include Shipping and Delivery permissions. Please SAVE (on this page) and tell the merchant to go to their Redo dashboard and click 'Update Permission' in the banner that shows.",
          );
        case GetDeliveryProfileResult.NOT_FOUND:
          throw new Error(
            "No delivery profiles found for this team. Please create a delivery profile in Shopify and try again.",
          );
        default:
          assertNever(result);
      }
    },
  );

  useEffect(() => {
    triggerDeliveryProfileLoad();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rateTables = input.value?.rateTables;

  const packageProtectionEnabled =
    packageProtectionInput.value.enabled &&
    packageProtectionInput.value.coverage;
  const loadFromProfile = useHandler(async () => {
    if (!deliveryProfileLoad.value) {
      return;
    }

    setConversionError(undefined);

    try {
      const rates = await rpcClient.getRateTablesFromDeliveryProfile({
        deliveryProfile: deliveryProfileLoad.value,
        hasPackageProtection: packageProtectionEnabled,
        hasReturnCoverage: hasReturnsCoverage,
        hasCarrierServices: hasCarrierServices,
      });

      input?.input?.inputs.rateTables.setValue(rates);
      triggerDeliveryProfileLoad();
    } catch (e) {
      setConversionError(e?.toString());
    }
  });

  if (!rateTables) {
    return null;
  }

  function getTitle(shippingRateLocation: ShippingRateTable) {
    return `${shippingRateLocation.destinationLocations[0].country} - Warehouse (${
      shippingRateLocation.originLocations[0].country +
      " " +
      shippingRateLocation.originLocations[0].provinces.join(",")
    })`;
  }

  function getCountryTitle(index: number, rateTables: ShippingRateTable[]) {
    const currentTitle = getTitle(rateTables[index]);

    if (index === 0) {
      return currentTitle;
    }

    const previousTitle = getTitle(rateTables[index - 1]);
    if (currentTitle === previousTitle) {
      return null;
    }
    return currentTitle;
  }

  return (
    <Card
      headerExtra={
        <Flex align="flex-start">
          <RedoButton
            disabled={deliveryProfileLoad.pending === true}
            hierarchy="secondary"
            IconTrailing={RefreshIcon}
            onClick={loadFromProfile}
            text={
              deliveryProfileLoad.pending
                ? "Fetching"
                : rateTables.length > 0
                  ? "Refresh"
                  : "Load"
            }
          />
        </Flex>
      }
      subtitle="Select which rates have Redo Coverage included."
      title="Shipping Zones"
      titleBadge={
        hasCarrierServices ? (
          <RedoBadge color="success" text="Carrier services" />
        ) : undefined
      }
    >
      {deliveryProfileLoad.error || conversionError ? (
        <Text fontSize="md" fontWeight="regular" textColor="error">
          {deliveryProfileLoad.error?.message}
          {conversionError}
        </Text>
      ) : (
        <>
          {rateTables.length > 0 &&
            input.input?.inputs.enabled !== undefined && (
              <FormSwitch
                input={input.input.inputs.enabled}
                label="Enabled"
                onChange={function (_: boolean): void {
                  input.input?.inputs.coverage.setValue(
                    CoverageProductEnum.SHIPPING,
                  );
                }}
              />
            )}
          {/* For now, these settings will only apply to FIXED rate tables */}
          {rateTables.filter(isFixedRateTable).map((rateTable, tableIndex) => {
            const countryTitle = getCountryTitle(tableIndex, rateTables);
            return (
              <Flex dir="column" gap="xs" key={counter().toString()}>
                {countryTitle && (
                  <Flex>
                    <Text>{countryTitle}</Text>
                  </Flex>
                )}
                {rateTable.rates.map((rate, rateIndex) => (
                  <RedoCheckboxAdvanced
                    checked={rate.enabled === true}
                    key={counter().toString()}
                    onClick={function (): void {
                      const isEnabled = !rate.enabled;

                      const newRateTables =
                        ShippingRateTableArraySchema.safeParse(
                          rateTables.map((rateTable, tIndex) => ({
                            ...rateTable,
                            rates: rateTable.rates.map((rateOld, rIndex) => ({
                              ...rateOld,
                              //If they have carrier services just update single rate, if they are basic every rate must be the same
                              enabled:
                                tableIndex === tIndex &&
                                (rateIndex === rIndex || !hasCarrierServices)
                                  ? isEnabled
                                  : rateOld.enabled,
                            })),
                          })),
                        );

                      if (!newRateTables.success) {
                        console.error(
                          "Error parsing new rate tables",
                          newRateTables.error,
                        );
                        return;
                      }

                      input?.input?.inputs.rateTables.setValue(
                        newRateTables.data,
                      );
                    }}
                    rightContent={
                      <Flex gap="lg">
                        <RedoBadge
                          color="warning"
                          text={CURRENCY_FORMAT(rate.currency).format(
                            +rate.price,
                          )}
                        />
                        {hasCarrierServices && (
                          <RedoButton
                            hierarchy="secondary"
                            IconLeading={ThreeDotsHorizontalIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditRate(rateTable);
                            }}
                          />
                        )}
                      </Flex>
                    }
                    title={rateTable.name}
                  >
                    {getTextFromConditions(rate.conditions).join("\n")}
                  </RedoCheckboxAdvanced>
                ))}
              </Flex>
            );
          })}
          {rateTables.length > 0 && input.input && (
            <>
              <FormSelectDropdown
                description="Sorting of shipping (If RIC is enabled, this will be ignored)"
                input={input.input?.inputs.deliverySortType}
                label="Shipping sorting"
                options={Object.values(DeliverySortType)}
              >
                {(s) => s}
              </FormSelectDropdown>
              <FormSelectDropdown
                description="Filtering of shipping"
                input={input.input?.inputs.deliveryFilterType}
                label="Shipping filtering"
                options={Object.values(DeliveryFilterType)}
              >
                {(s) => s}
              </FormSelectDropdown>
            </>
          )}
        </>
      )}

      {editRate && (
        <EditRate
          close={() => setEditRate(undefined)}
          rateTable={editRate}
          save={(newRateTable) => {
            //Make sure the names are unique in our rates
            const namesAreUnique =
              rateTables.filter(
                (rateTable) =>
                  rateTable.name === newRateTable.name &&
                  rateTable.code !== newRateTable.code,
              ).length === 0;
            if (!namesAreUnique) {
              return "Name already exists";
            }
            //Make sure names are unique in shopify
            if (hasCarrierServices) {
              const shopifyRateNames =
                deliveryProfileLoad.value?.profileLocationGroups
                  .flatMap(
                    (profileLocationGroup: ProfileLocationGroup) =>
                      profileLocationGroup.locationGroupZones,
                  )
                  .flatMap(
                    (locationGroupZone: DeliveryLocationGroupZone) =>
                      locationGroupZone.methodDefinitions,
                  )
                  .flatMap(
                    (methodDefinition: DeliveryMethodDefinition) =>
                      methodDefinition.name,
                  );

              if (shopifyRateNames?.includes(newRateTable.name)) {
                return "Name already exists in Shopify";
              }
            }
            const newRateTables = rateTables.map((rateTable) => {
              if (rateTable.code === newRateTable.code) {
                return newRateTable;
              }
              return rateTable;
            });
            input?.input?.inputs.rateTables.setValue(newRateTables);
            setEditRate(undefined);

            return undefined;
          }}
        />
      )}
    </Card>
  );
});
