/**
 * Create value that references itelf, similar to ycombinator
 */
export function self<T>(fn: (value: () => T) => T): T {
  const value = fn(() => value);
  return value;
}

export const fn = () => {};

export function identity<T>(value: T): T {
  return value;
}
