import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { RouterOutletFade } from "@redotech/react-router-util/outlet-fade";
import { useHtmlEventListener } from "@redotech/react-util/event";
import * as classnames from "classnames";
import * as classNames from "classnames";
import * as React from "react";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Location, useLocation } from "react-router-dom";
import { Breadcrumbs } from "./breadcrumb";
import { Flex } from "./flex";
import * as pageCss from "./page.module.css";

export const Action0PortalContext = createContext<HTMLElement | undefined>(
  undefined,
);

export const ActionPortalContext = createContext<HTMLElement | undefined>(
  undefined,
);

export const TabsPortalContext = createContext<{
  portalNode: HTMLElement | undefined;
}>({ portalNode: undefined });

/**
 * A gotcha: The rendered ReactNode wont be able to access any of its parents contexts,
 * so it must be defined with that in mind.
 */
export const HeaderOverrideContext = createContext<
  (node: ReactNode | null) => void
>(() => {});

/**
 * Used to correctly calculate infinite scroll boundaries
 */
export const PageMainContentRefContext = createContext<
  React.RefObject<HTMLDivElement> | undefined
>(undefined);

export const Actions = memo(function Actions({
  show,
  children,
  noBorder = false,
}: {
  children: ReactNode | ReactNode[];
  show: boolean;
  noBorder?: boolean;
}) {
  const transitionState = useContext(OverlapTransitionStateContext);

  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [goal, setGoal] = useState(show);
  const [finished, setFinished] = useState(true);

  useHtmlEventListener(ref, "transitionend", (e) => {
    if (e.propertyName !== "opacity") {
      return;
    }
    setFinished(true);
  });

  useLayoutEffect(() => {
    if (!ref || show === goal) {
      return;
    }
    void ref.offsetHeight;
    setGoal(show);
    setFinished(false);
  }, [show, ref, goal]);

  if (
    (!show && !goal && finished) ||
    transitionState === OverlapTransitionState.EXIT
  ) {
    return null;
  }

  return (
    <div
      className={classnames(pageCss.actions, !noBorder && pageCss.border, {
        [pageCss.collapse]: !goal,
      })}
      ref={setRef}
    >
      {children}
    </div>
  );
});

let scrollXCount = 0;

export function useScrollableWidth() {
  useLayoutEffect(() => {
    if (!scrollXCount++) {
      document.documentElement.classList.add(pageCss.noScrollX);
    }
    return () => {
      if (!--scrollXCount) {
        document.documentElement.classList.remove(pageCss.noScrollX);
      }
    };
  }, []);
}

let scrollYCount = 0;

export function useScrollableHeight() {
  useLayoutEffect(() => {
    if (!scrollYCount++) {
      document.documentElement.classList.add(pageCss.noScrollY);
    }
    return () => {
      if (!--scrollYCount) {
        document.documentElement.classList.remove(pageCss.noScrollY);
      }
    };
  }, []);
}

export enum TabContentChangedEventType {
  CHECK_CHILDREN = "CHECK_CHILDREN",
  EXPLICITLY_TOLD_ZERO_CHILDREN = "CHECK_GRANDCHILDREN",
}

export const Page = memo(function Page({
  nav,
  banner,
  profile,
  hidePadding,
  hideHeader = (url: string) => false,
  ErrorBoundary,
  showNewIntercomMessage,
}: {
  nav: ReactNode;
  banner?: ReactNode;
  profile?: ReactNode;
  hidePadding?: boolean;
  hideHeader?: (url: string, search: { search: string }) => boolean;
  ErrorBoundary?: React.ComponentType<{
    children: React.ReactNode;
    location: Location;
    showNewIntercomMessage?: () => void;
  }>;
  showNewIntercomMessage?: () => void;
}) {
  const [actions0Element, setActions0Element] = useState<HTMLElement | null>(
    null,
  );
  const [actionsElement, setActionsElement] = useState<HTMLElement | null>(
    null,
  );
  const [headerOverrideElement, setHeaderOverrideElement] =
    useState<ReactNode | null>(null);
  const [tabsElement, setTabsElement] = useState<HTMLElement | null>(null);

  const location = useLocation();

  const [hideHeader_, setHideHeader_] = useState(false);

  useEffect(() => {
    setHideHeader_(hideHeader(location.pathname, { search: location.search }));
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const mainContentRef = useRef<HTMLDivElement>(null);

  let content = <RouterOutletFade element="div" elementRef={mainContentRef} />;
  if (ErrorBoundary) {
    content = (
      <ErrorBoundary
        location={location}
        showNewIntercomMessage={showNewIntercomMessage}
      >
        {content}
      </ErrorBoundary>
    );
  }

  const pageContents = (
    <Flex
      dir="column"
      gap="none"
      grow={1}
      overflow="hidden"
      style={{ maxWidth: "100vw" }}
    >
      {banner}
      <Flex gap="none" grow={1} overflow="hidden">
        <PageNavigation navigation={nav} />
        <Flex as="main" dir="column" gap="none" grow={1} overflow="hidden">
          <PageHeader
            headerOverrideElement={headerOverrideElement}
            hideHeader={hideHeader_}
            profile={profile}
            setActions0Element={setActions0Element}
            setActionsElement={setActionsElement}
            setTabsElement={setTabsElement}
          />
          <PageContent
            ErrorBoundary={ErrorBoundary}
            hidePadding={hidePadding}
            mainContentRef={mainContentRef}
            showNewIntercomMessage={showNewIntercomMessage}
          />
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <PageMainContentRefContext.Provider value={mainContentRef ?? undefined}>
      <HeaderOverrideContext.Provider value={setHeaderOverrideElement}>
        <Action0PortalContext.Provider value={actions0Element || undefined}>
          <ActionPortalContext.Provider value={actionsElement || undefined}>
            <TabsPortalContext.Provider
              value={{ portalNode: tabsElement || undefined }}
            >
              {pageContents}
            </TabsPortalContext.Provider>
          </ActionPortalContext.Provider>
        </Action0PortalContext.Provider>
      </HeaderOverrideContext.Provider>
    </PageMainContentRefContext.Provider>
  );
});

const PageContent = memo(function PageContent({
  ErrorBoundary,
  showNewIntercomMessage,
  mainContentRef,
  hidePadding = false,
}: {
  ErrorBoundary?: React.ComponentType<{
    children: React.ReactNode;
    location: Location;
    showNewIntercomMessage?: () => void;
  }>;
  showNewIntercomMessage?: () => void;
  mainContentRef: React.RefObject<HTMLDivElement>;
  hidePadding: boolean | undefined;
}) {
  const location = useLocation();

  const content = (
    <RouterOutletFade
      childClassName={classNames(!hidePadding && pageCss.pagePadding)}
      element="div"
      elementRef={mainContentRef}
    />
  );
  if (ErrorBoundary) {
    return (
      <ErrorBoundary
        location={location}
        showNewIntercomMessage={showNewIntercomMessage}
      >
        {content}
      </ErrorBoundary>
    );
  }
  return content;
});

const PageNavigation = memo(function PageNavigation({
  navigation,
}: {
  navigation: ReactNode;
}) {
  return <Flex as="nav">{navigation}</Flex>;
});

const PageHeader = memo(function PageHeader({
  headerOverrideElement,
  hideHeader,
  setActions0Element,
  setActionsElement,
  setTabsElement,
  profile,
}: {
  hideHeader: boolean;
  headerOverrideElement: ReactNode | null;
  setActions0Element: (node: HTMLElement | null) => void;
  setActionsElement: (node: HTMLElement | null) => void;
  setTabsElement: (node: HTMLElement | null) => void;
  profile: ReactNode | undefined;
}) {
  if (hideHeader) {
    return null;
  }

  if (headerOverrideElement) {
    return <HeaderContainer>{headerOverrideElement}</HeaderContainer>;
  }

  return (
    <HeaderContainer>
      <>
        <Flex justify="space-between" py="3xl">
          <Breadcrumbs />
          <Flex>
            <div
              className={pageCss.actionsContainer}
              ref={setActions0Element}
            />
            <div className={pageCss.actionsContainer} ref={setActionsElement} />
            {profile}
          </Flex>
        </Flex>
        <div className={pageCss.tabsWrapper} ref={setTabsElement} />
      </>
    </HeaderContainer>
  );
});

const HeaderContainer = memo(function HeaderContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Flex
      as="header"
      bgColor="primary"
      borderBottomWidth="1px"
      borderColor="primary"
      borderStyle="solid"
      className={classnames(pageCss.headerContainer)}
      dir="column"
      gap="none"
      px="3xl"
    >
      {children}
    </Flex>
  );
});
