import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";
import { CustomerAccountsPage } from "./team/customer-accounts";
import { OrderTrackingPage } from "./team/orders";
import { OutboundLabelsPage } from "./team/outbound-labels";
import { ReturnsPackageProtectionPage } from "./team/returns-package-protection";
import { SupportPage } from "./team/support";
import { WarrantiesPage } from "./team/warranties";
const teamBreadcrumb = breadcrumbSlot();

export const teamRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Merchants" },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { TeamsPage } = await import("./teams");
          return { Component: TeamsPage };
        },
      },
      {
        path: "create",
        handle: { breadcrumb: "Create Team" },
        async lazy() {
          const { CreateTeamPage } = await import("./create-team");
          return { Component: CreateTeamPage };
        },
      },
      {
        handle: { breadcrumb: teamBreadcrumb },
        path: ":teamId",
        children: [
          { index: true, element: <Navigate replace to="general" /> },
          {
            path: "",
            async lazy() {
              const { TeamPage } = await import("./team");
              return { element: <TeamPage breadcrumb={teamBreadcrumb} /> };
            },
            children: [
              {
                path: "general",
                async lazy() {
                  const { TeamPage } = await import("./team/general");
                  return { Component: TeamPage };
                },
              },
              {
                path: "customer-support",
                async lazy() {
                  return { Component: SupportPage };
                },
              },
              {
                path: "orders",
                async lazy() {
                  return { Component: OrderTrackingPage };
                },
              },
              {
                path: "outbound-labels",
                async lazy() {
                  return { Component: OutboundLabelsPage };
                },
              },
              {
                path: "returns-package-protection",
                async lazy() {
                  return { Component: ReturnsPackageProtectionPage };
                },
              },
              {
                path: "warranties",
                async lazy() {
                  return { Component: WarrantiesPage };
                },
              },
              {
                path: "customer-accounts",
                async lazy() {
                  return { Component: CustomerAccountsPage };
                },
              },
              {
                path: "marketing",
                async lazy() {
                  const { MarketingPage } = await import("./team/marketing");
                  return { Component: MarketingPage };
                },
              },
              {
                path: "conversion",
                async lazy() {
                  const { ConversionPage } = await import("./team/conversion");
                  return { Component: ConversionPage };
                },
              },
              {
                path: "coverage",
                async lazy() {
                  const { CoveragePage } = await import(
                    "./team/coverage/coverage"
                  );
                  return { Component: CoveragePage };
                },
              },
            ],
          },
          {
            handle: { breadcrumb: "A/B Tests" },
            path: "split",

            children: [
              {
                index: true,
                async lazy() {
                  const { SplitsPage } = await import("./split/split-tests");
                  return {
                    element: <SplitsPage breadcrumb={teamBreadcrumb} />,
                  };
                },
              },
              {
                path: ":splitId",
                async lazy() {
                  const { SplitPageResolver } = await import(
                    "./split/split-details"
                  );
                  return { element: <SplitPageResolver newSplit={false} /> };
                },
              },
              {
                path: "create",
                async lazy() {
                  const { SplitPageResolver } = await import(
                    "./split/split-details"
                  );
                  return { element: <SplitPageResolver newSplit /> };
                },
              },
              {
                path: "create/:splitId",
                async lazy() {
                  const { SplitPageResolver } = await import(
                    "./split/split-details"
                  );
                  return { element: <SplitPageResolver newSplit /> };
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
