import { z } from "zod";

export const submitBackInStockForm = {
  input: z.object({
    email: z.string().email(),
    productId: z.string(),
    variantId: z.string(),
  }),
  output: z.object({ success: z.boolean() }),
};
