import { z } from "zod";

const textAnswerSchema = z.object({
  type: z.literal("text"),
  question: z.string(),
  answer: z.string(),
});

const checkboxAnswerSchema = z.object({
  type: z.literal("checkbox"),
  question: z.string(),
  answer: z.boolean(),
});

const radioGroupAnswerSchema = z.object({
  type: z.literal("radio"),
  question: z.string(),
  answer: z.string(),
});

const answerSchema = z.union([
  textAnswerSchema,
  checkboxAnswerSchema,
  radioGroupAnswerSchema,
]);

export const getAmpRegistrationFormAnswers = {
  input: z.object({ teamId: z.string() }),
  output: z.tuple([
    z.array(answerSchema),
    z.array(answerSchema),
    z.array(answerSchema),
    z.array(answerSchema),
  ]),
};

export type TextAnswer = z.infer<typeof textAnswerSchema>;
export type CheckboxAnswer = z.infer<typeof checkboxAnswerSchema>;
export type RadioGroupAnswer = z.infer<typeof radioGroupAnswerSchema>;
export type Answer = z.infer<typeof answerSchema>;
