import { useTriggerLoad } from "@redotech/react-util/load";
import { isString } from "@redotech/util/type";
import * as classNames from "classnames";
import { JSXElementConstructor, memo, ReactNode } from "react";
import CloseIcon from "../../arbiter-icon/x-close.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { DisabledTooltip } from "../../tooltip/disabled-tooltip";
import { RedoButton, RedoButtonTheme } from "../buttons/redo-button";
import { RedoBaseModal } from "./redo-base-modal";
import * as redoModalCss from "./redo-modal.module.css";

export const redoModalSize = ["sm", "md", "lg"] as const;
export type RedoModalSize = (typeof redoModalSize)[number];

export const redoModalTheme = [
  "error",
  "warn",
  "success",
  "info",
  "none",
  "neutral",
] as const;
export type RedoModalTheme = (typeof redoModalTheme)[number];

export const redoModalHeaderIconAlignment = ["above", "inline"] as const;
export type RedoModalHeaderIconAlignment =
  (typeof redoModalHeaderIconAlignment)[number];

export const redoModalButtonPlacement = ["full", "tight"] as const;
export type RedoModalButtonPlacement =
  (typeof redoModalButtonPlacement)[number];

/**
 * @field pending - the onClick promise will also imply pending. Use this if you're managing the pending state yourself.
 */
export interface RedoModalButtonProps {
  text: string;
  onClick(): void | Promise<void>;
  disabled?: boolean | string;
  pending?: boolean;
  theme?: RedoButtonTheme;
  IconLeading?: JSXElementConstructor<unknown>;
}

/**
 *
 * If you feel the need to add a prop here, consider using <RedoBaseModal> instead
 *
 * If you still feel strongly that a prop is missing that should be on the base modal,
 * please double check with someone on the Arbiter team.
 *
 * @param onModalCloseRequested - Called when x clicked, the user presses escape, or clicks away
 */
export interface RedoModalProps {
  title: string;
  subtitle?: string | ReactNode;
  primaryButton?: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  children?: ReactNode | undefined;
  onModalCloseRequested: () => void;
  TitleIcon?: JSXElementConstructor<unknown>;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  headerBorder?: boolean;
  theme?: RedoModalTheme;
  hideCloseButton?: boolean;
  modalSize?: RedoModalSize;
  footerBorder?: boolean;
  centerContent?: boolean;
  isOpen?: boolean;
  buttonPlacement?: RedoModalButtonPlacement;
  contentScrollable?: boolean;
}

export const RedoModal = memo(function RedoModal({
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  children,
  onModalCloseRequested,
  TitleIcon,
  headerIconAlignment = "above",
  headerBorder = false,
  theme = "none",
  hideCloseButton = false,
  modalSize = "md",
  footerBorder = false,
  centerContent = false,
  isOpen = true,
  buttonPlacement,
  contentScrollable = false,
}: RedoModalProps) {
  return (
    <RedoBaseModal
      isOpen={isOpen}
      onModalCloseRequested={onModalCloseRequested}
      otherClasses={[
        modalSizeClasses[modalSize],
        centerContent && redoModalCss.centered,
      ]}
    >
      <>
        <RedoModalHeader
          cancelClicked={onModalCloseRequested}
          centerContent={centerContent}
          headerBorder={headerBorder}
          headerIconAlignment={headerIconAlignment}
          hideCloseButton={hideCloseButton}
          subtitle={subtitle}
          theme={theme}
          title={title}
          TitleIcon={TitleIcon}
        />
        {children && (
          <section
            className={classNames(redoModalCss.main, {
              [redoModalCss.scrollable]: contentScrollable,
            })}
          >
            {children}
          </section>
        )}
        <RedoModalFooter
          buttonPlacement={buttonPlacement}
          footerBorder={footerBorder}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          theme={theme}
        />
      </>
    </RedoBaseModal>
  );
});

export const RedoModalHeader = memo(function RedoModalHeader({
  TitleIcon = undefined,
  theme = "none",
  title,
  subtitle,
  hideCloseButton = false,
  centerContent = false,
  headerIconAlignment = "above",
  headerBorder = false,
  cancelClicked,
}: {
  TitleIcon?: JSXElementConstructor<unknown> | undefined;
  theme?: RedoModalTheme;
  title: string;
  subtitle?: string | ReactNode;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  hideCloseButton?: boolean;
  headerBorder?: boolean;
  centerContent?: boolean;
  cancelClicked(): void;
}) {
  return (
    <header
      className={classNames(
        headerBorder && redoModalCss.border,
        centerContent && redoModalCss.center,
        redoModalCss.header,
        headerIconAlignmentClasses[headerIconAlignment],
      )}
    >
      {TitleIcon && (
        <div
          className={classNames(
            headerIconAlignmentClasses[headerIconAlignment],
            redoModalCss.titleIcon,
          )}
        >
          <span
            className={classNames(redoModalCss.icon, iconThemeClasses[theme])}
          >
            <TitleIcon />
          </span>
        </div>
      )}
      <Flex dir="column" gap="xs">
        <Text fontSize="lg" fontWeight="semibold" textColor="primary">
          {title}
        </Text>
        {subtitle && (
          <Text fontSize="sm" textColor="tertiary">
            {subtitle}
          </Text>
        )}
      </Flex>

      {!hideCloseButton && (
        <RedoButton
          className={redoModalCss.closeButton}
          hierarchy="tertiary"
          IconLeading={CloseIcon}
          onClick={cancelClicked}
          size="md"
        />
      )}
    </header>
  );
});

export const RedoModalFooter = memo(function RedoModalFooter({
  primaryButton,
  secondaryButton,
  theme = "none",
  footerBorder,
  buttonPlacement = "tight",
}: {
  primaryButton?: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  theme?: RedoModalTheme;
  footerBorder?: boolean;
  buttonPlacement?: RedoModalButtonPlacement;
}) {
  const [isSubmitting, doSubmit] = useTriggerLoad(async () => {
    await primaryButton?.onClick();
  });

  const submitButton = primaryButton && (
    <DisabledTooltip
      disabledMessage={
        isString(primaryButton.disabled) && primaryButton.disabled
      }
      wrapperClass={redoModalCss.disabledWrapper}
    >
      <RedoButton
        className={redoModalCss.button}
        disabled={!!primaryButton.disabled}
        hierarchy="primary"
        IconLeading={primaryButton.IconLeading}
        onClick={() => doSubmit()}
        pending={primaryButton.pending ?? isSubmitting.pending}
        size="xl"
        text={primaryButton.text}
        theme={primaryButton.theme ?? redoModalThemeToButtonTheme(theme)}
      />
    </DisabledTooltip>
  );
  const cancelButton = secondaryButton && (
    <DisabledTooltip
      disabledMessage={
        isString(secondaryButton.disabled) && secondaryButton.disabled
      }
      wrapperClass={redoModalCss.disabledWrapper}
    >
      <RedoButton
        className={redoModalCss.button}
        disabled={isSubmitting.pending || !!secondaryButton.disabled}
        hierarchy="secondary"
        IconLeading={secondaryButton.IconLeading}
        onClick={secondaryButton.onClick}
        pending={secondaryButton.pending ?? isSubmitting.pending}
        size="xl"
        text={secondaryButton.text}
        theme={secondaryButton.theme}
      />
    </DisabledTooltip>
  );

  return (
    <footer
      className={classNames(
        redoModalCss.footer,
        footerBorder && redoModalCss.border,
        buttonPlacementClasses[buttonPlacement],
      )}
    >
      <div
        className={classNames(
          redoModalCss.footerButtons,
          buttonPlacementClasses[buttonPlacement],
        )}
      >
        {cancelButton}
        {submitButton}
      </div>
    </footer>
  );
});

function redoModalThemeToButtonTheme(theme: RedoModalTheme): RedoButtonTheme {
  switch (theme) {
    case "error":
      return "destructive";
    default:
      return "normal";
  }
}

const modalSizeClasses: Record<RedoModalSize, string> = {
  ["sm"]: redoModalCss.small,
  ["md"]: "",
  ["lg"]: redoModalCss.large,
};

const iconThemeClasses: Record<RedoModalTheme, string> = {
  ["error"]: redoModalCss.error,
  ["warn"]: redoModalCss.warn,
  ["success"]: redoModalCss.success,
  ["info"]: redoModalCss.info,
  ["none"]: redoModalCss.none,
  ["neutral"]: redoModalCss.neutral,
};

const headerIconAlignmentClasses = {
  ["above"]: undefined,
  ["inline"]: redoModalCss.inline,
};

const buttonPlacementClasses: Record<RedoModalButtonPlacement, string> = {
  ["full"]: redoModalCss.fill,
  ["tight"]: redoModalCss.tight,
};
