import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { FormSwitch } from "@redotech/redo-web/switch";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo } from "react";
import { OutboundLabelForm } from "../outbound-labels";

export const carrierAccountForm = groupInput({
  fedex: input<boolean>(),
  fedexSmartpost: input<boolean>(),
  upsGround: input<boolean>(),
  easypostUSPS: input<boolean>(),
  uspsShip: input<boolean>(),
  upsGroundSaver: input<boolean>(),
});

export type CarrierAccountForm = InputProvider.Form<typeof carrierAccountForm>;

export type CarrierAccountValue = InputProvider.Value<
  typeof carrierAccountForm
>;

export const carrierAccountDefault: CarrierAccountValue = {
  fedex: false,
  fedexSmartpost: false,
  upsGround: false,
  easypostUSPS: false,
  uspsShip: false,
  upsGroundSaver: false,
};

export const BlockCarrierAccountsCard = memo(function BlockCarrierAccountsCard({
  input,
}: {
  input: OutboundLabelForm;
}) {
  const { blockedCarrierAccounts: carrierAccount } = input.inputs;

  return (
    <Card title="Block Carrier Accounts">
      <Flex dir="column">
        <FormSwitch input={carrierAccount.inputs.fedex} label="Fedex" />
        <FormSwitch
          input={carrierAccount.inputs.fedexSmartpost}
          label="Fedex Smartpost"
        />
        <FormSwitch
          input={carrierAccount.inputs.upsGround}
          label="UPS Ground"
        />
        <FormSwitch
          input={carrierAccount.inputs.easypostUSPS}
          label="EasyPost USPS"
        />
        <FormSwitch input={carrierAccount.inputs.uspsShip} label="USPS Ship" />
        <FormSwitch
          input={carrierAccount.inputs.upsGroundSaver}
          label="UPS Ground Saver"
        />
      </Flex>
    </Card>
  );
});
