import {
  expandedWarrantyTreatmentZodSchema,
  TreatmentType,
  treatmentZodSchema,
} from "@redotech/redo-model/split";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

const expandedWarrantyTreatmentInputSchema = z.object({
  type: z.literal(TreatmentType.EXPANDED_WARRANTY),
  settings: expandedWarrantyTreatmentZodSchema.shape.settings.optional(),
  widgetConfig:
    expandedWarrantyTreatmentZodSchema.shape.ewWidgetConfig.optional(),
});

export type ExpandedWarrantyTreatmentInput = z.infer<
  typeof expandedWarrantyTreatmentInputSchema
>;
export const isExpandedWarrantyTreatmentInput = (
  input: TreatmentInput,
): input is ExpandedWarrantyTreatmentInput => {
  return input.type === TreatmentType.EXPANDED_WARRANTY;
};

export const treatmentInputSchema = z.discriminatedUnion("type", [
  expandedWarrantyTreatmentInputSchema,
]);

type TreatmentInput = z.infer<typeof treatmentInputSchema>;

export const treatmentRequestSchema = z.object({
  name: z.string(),
  active: z.boolean(),
  weight: z.number(),
  treatmentInput: treatmentInputSchema,
});

export type TreatmentRequest = z.infer<typeof treatmentRequestSchema>;

export const createTreatment = {
  input: treatmentRequestSchema.extend({ splitId: zExt.objectId() }),
  output: z.object({ treatment: treatmentZodSchema }),
};

export type CreateTreatmentInput = z.infer<typeof createTreatment.input>;
