import { ExtendedWarrantyDataTarget } from "@redotech/redo-model/extended-warranty";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { memo } from "react";

export const LearnMoreLink = memo(function LearnMoreLink({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <a
      className={warrantyStyles.learnMore}
      data-target={ExtendedWarrantyDataTarget.LEARN_MORE_LINK}
      onClick={onClick}
    >
      Learn more
    </a>
  );
});
