import {
  EWWidgetTextProps,
  ExtendedWarrantyDataTarget,
  NO_COVERAGE_TIME_ID,
  Offering,
} from "@redotech/redo-model/extended-warranty";
import { CURRENCY_FORMAT } from "@redotech/redo-model/money";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";
import * as warrantyStyles from "@redotech/redo-web/expanded-warranties/extended-warranty.module.css";
import { Flex } from "@redotech/redo-web/flex";
import htmlReactParser from "html-react-parser";
import { memo } from "react";
import { ToggleAddButton } from "../toggle-add-button";

export const ExtendedWarrantyUpsellModal = memo(
  function ExtendedWarrantyUpsellModal({
    customCss,
    selectedOffering,
    cheapestOffering,
    onAddClick,
    text,
    foundSelector,
  }: {
    customCss?: string;
    selectedOffering?: Offering;
    cheapestOffering?: Offering;
    onAddClick: () => void;
    text: EWWidgetTextProps;
    foundSelector?: string;
  }) {
    const {
      pdpTitle,
      pdpDescription,
      pdpAddToCartDescription,
      pdpAddedToCartTitle,
      pdpAddedToCartDescription,
    } = text;

    const itemSelected =
      selectedOffering && selectedOffering.id !== NO_COVERAGE_TIME_ID;

    const cheapestOfferingPrice =
      cheapestOffering?.price?.amount &&
      CURRENCY_FORMAT(cheapestOffering?.price?.currency).format(
        cheapestOffering?.price?.amount,
      );

    return (
      <>
        {customCss && <style>{customCss}</style>}
        {foundSelector && <meta found-selector={foundSelector} />}
        <Flex
          align="center"
          data-target={ExtendedWarrantyDataTarget.PDP_CONTAINER}
          gap="xs"
          justify="space-between"
        >
          <Flex
            align="flex-start"
            data-target={ExtendedWarrantyDataTarget.PDP_CONTENT}
            gap="xs"
          >
            {itemSelected ? (
              <>
                <ShieldTickIcon
                  className={warrantyStyles.shieldIcon}
                  data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
                />
                <div>
                  <div
                    className={warrantyStyles.title}
                    data-target={ExtendedWarrantyDataTarget.PDP_TITLE}
                  >
                    {htmlReactParser(pdpAddedToCartTitle)}
                  </div>
                  <div
                    className={warrantyStyles.subtitle}
                    data-target={ExtendedWarrantyDataTarget.PDP_SUBTITLE}
                  >
                    {htmlReactParser(
                      pdpAddedToCartDescription.replace(
                        "%plan_title%",
                        selectedOffering.title,
                      ),
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <ShieldTickIcon
                  className={warrantyStyles.shieldIcon}
                  data-target={ExtendedWarrantyDataTarget.PDP_SHIELD}
                />
                <div>
                  <div
                    className={warrantyStyles.title}
                    data-target={ExtendedWarrantyDataTarget.PDP_TITLE}
                  >
                    {htmlReactParser(pdpTitle)}
                  </div>
                  <div
                    className={warrantyStyles.subtitle}
                    data-target={ExtendedWarrantyDataTarget.PDP_SUBTITLE}
                  >
                    {cheapestOfferingPrice
                      ? htmlReactParser(
                          pdpAddToCartDescription.replace(
                            "%price%",
                            cheapestOfferingPrice,
                          ),
                        )
                      : htmlReactParser(pdpDescription)}
                  </div>
                </div>
              </>
            )}
          </Flex>
          <ToggleAddButton
            dataTarget={ExtendedWarrantyDataTarget.PDP_ADD_BUTTON}
            onAddClick={onAddClick}
            selectedOffering={selectedOffering}
          />
        </Flex>
      </>
    );
  },
);
