import { useState } from "react";
import { useHandler } from "./hook";

export type UseQueueResult<T> = {
  front: T | undefined;
  push: (item: T) => void;
  pop: () => void;
};

export function useQueue<T>(): UseQueueResult<T> {
  const [queue, setQueue] = useState<T[]>([]);

  const push = useHandler((item: T) => {
    setQueue((prevQueue) => [...prevQueue, item]);
  });

  const pop = useHandler(() => {
    setQueue((prevQueue) => {
      if (prevQueue.length === 0) {
        return prevQueue;
      }
      return prevQueue.slice(1);
    });
  });

  const front = queue[0] && queue.length > 0 ? queue[0] : undefined;

  return { front, push, pop };
}
