import { defineSchema, SchemaInstance } from "../../type-system/schema";

export const baseReviewsSchema = defineSchema({
  fields: {
    discountCode: {
      dataType: "Text",
      documentation: "The discount code to shared with the customer.",
    },
    emailAddress: {
      dataType: "Email",
      documentation: "The email address of the customer.",
    },
    customerName: {
      dataType: "Name",
      documentation: "The name of the customer.",
    },
  },
});

export const exampleBaseReviewsSchemaInstance: SchemaInstance<
  typeof baseReviewsSchema
> = {
  discountCode: "DISCOUNTCODE",
  emailAddress: "test@test.com",
  customerName: "John Doe",
};

export function createBaseReviewSchemaInstance(
  discountCode: string,
  emailAddress: string,
  customerName: string,
): SchemaInstance<typeof baseReviewsSchema> {
  return { discountCode, emailAddress, customerName };
}
