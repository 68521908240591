import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { DisplayedShippingRateTableSchema } from "../integration/redo-in-shipping/shipping-rate-table";

export enum CheckoutAbTestMetricToOptimize {
  CHECKOUT_CONVERSION_RATE = "CHECKOUT_CONVERSION_RATE",
}

export const CheckoutAbTestMetricToOptimizeLabelMap: Record<
  CheckoutAbTestMetricToOptimize,
  string
> = {
  [CheckoutAbTestMetricToOptimize.CHECKOUT_CONVERSION_RATE]:
    "Checkout conversion",
};

/**
 * All information required to view a rate table and be able to save it as a
 * new rate table (if desired).
 */
export const SavableRateTableSchema = z.object({
  rateTable: DisplayedShippingRateTableSchema,
});

export const CheckoutAbTestTreatmentSchema = z.object({
  id: z.string(),
  /**
   * The name of the treatment.
   */
  name: z.string(),
  /**
   * The percentage of traffic that will be sent to this treatment.
   */
  trafficAllocation: z.string(),
  /**
   * The checkout experience to use as the base for this treatment. Edits can
   * be made, and the user will choose at the end whether to save as a new
   * checkout experience or overwrite the existing one.
   */
  baseCheckoutExperience: z
    .union([
      z.object({
        isNewCheckoutExperience: z.literal(false),
        id: z.string(),
        isEdited: z.boolean(),
      }),
      z.object({ isNewCheckoutExperience: z.literal(true) }),
    ])
    .optional(),
  /**
   * The state of the checkout experience being modified locally (not persisted
   * until saved).
   */
  checkoutExperienceState: z.object({
    name: z.string(),
    rateTables: z.array(SavableRateTableSchema),
  }),
});
export type CheckoutAbTestTreatment = z.infer<
  typeof CheckoutAbTestTreatmentSchema
>;

/**
 * The global state store for the checkout ab test builder.
 */
const checkoutAbTestBuilderStateSchema = z.object({
  splitSettings: z.object({
    name: z.string(),
    hypothesis: z.string(),
    metricToOptimize: z.nativeEnum(CheckoutAbTestMetricToOptimize),
  }),
  allAvailableBaseCheckoutExperiences: z.record(
    z.string(),
    z.object({
      id: z.string(),
      name: z.string(),
      rateTables: z.array(SavableRateTableSchema),
    }),
  ),
  /**
   * As of 02/10/2025, only one checkout experience or one A/B test can be live
   * at a time. This assumption will likely change with the addition of
   * segments.
   */
  allTreatments: z.record(z.string(), CheckoutAbTestTreatmentSchema),
  /**
   * The controls for the preview of the shipping method items for the checkout.
   */
  previewControls: z.object({
    orderValue: z.number(),
    orderWeightInPounds: z.number(),
  }),
});
export type CheckoutAbTestBuilderState = z.infer<
  typeof checkoutAbTestBuilderStateSchema
>;

export function checkoutAbTestMetricToOptimizeLabel(
  metric: CheckoutAbTestMetricToOptimize,
) {
  switch (metric) {
    case CheckoutAbTestMetricToOptimize.CHECKOUT_CONVERSION_RATE:
      return "Checkout conversion";
    default:
      assertNever(metric);
  }
}

export const controlTreatmentId = `control-checkout-treatment`;
export const variantTreatmentId = `variant-checkout-treatment`;
