import type { Coverage } from "./coverage";

export const fullCoverage = "Return or Exchange";
export const texts = {
  "000": undefined, // FIXME
  "001": "Store Credit",
  "010": "Refunds",
  "011": "Refunds or Store Credit",
  "100": "Exchanges",
  "101": "Store Credit or Exchanges",
  "110": "Refunds or Exchanges",
  "111": fullCoverage,
} as const;

export const checkboxOptions = [
  "Free Return",
  "Free &amp; Easy Return",
  "Free &amp; Easy Return Coverage",
  "Try Risk Free with Free Returns",
  "Free Return Label",
];

export const fullCoverageOptions = [
  "Free Return or Exchange",
  "Free &amp; Easy Return or Exchange",
  "Free &amp; Easy Return or Exchange Coverage",
  "Try Risk Free with Free Returns or Exchanges",
  "Free Return or Exchange Label",
];

export function getReturnType(coverage: Coverage): string | undefined {
  return texts[
    `${+coverage.exchange}${+coverage.refund}${+coverage.storeCredit}` as keyof typeof texts
  ];
}

export function getHtml(
  value: string,
  returnType: string | undefined,
): string | undefined {
  if (returnType === "Return or Exchange" && checkboxOptions.includes(value)) {
    return fullCoverageOptions[checkboxOptions.indexOf(value)];
  } else if (
    returnType !== "Return or Exchange" &&
    fullCoverageOptions.includes(value)
  ) {
    return checkboxOptions[fullCoverageOptions.indexOf(value)];
  }

  return value;
}
