import { z } from "zod";
import { MarketingEntryPoint } from "../../advanced-flow/schemas/marketing/marketing";

export const signupFormDataSchema = z.object({
  email: z.string().optional(),
  phone: z.string().optional(),
  birthday: z.date().optional(),
});
export type SignupFormData = z.infer<typeof signupFormDataSchema>;

// email or phone number
export const submitSignupFormStepSchema = {
  input: z.object({
    formId: z.string(),
    stepIndex: z.number(),
    formData: signupFormDataSchema,
    customerId: z.string().nullable(),
    entryPoint: z.nativeEnum(MarketingEntryPoint),
    anonymousId: z.string().optional(),
  }),
  output: z.object({
    success: z.boolean(),
    error: z.string().optional(),
    customerId: z.string().nullable(),
  }),
};
