import * as classNames from "classnames";
import { memo, useEffect, useRef } from "react";
import CheckIcon from "../../arbiter-icon/check.svg";
import MinusIcon from "../../arbiter-icon/minus.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import * as redoCheckboxCss from "./redo-checkbox.module.css";

export type CheckboxValue = boolean | "indeterminate";

export const redoCheckboxSize = ["xs", "sm", "md"] as const;
export type RedoCheckboxSize = (typeof redoCheckboxSize)[number];

export interface RedoCheckboxProps {
  dataTestId?: string;
  value: CheckboxValue;
  setValue(value: CheckboxValue): void;
  size?: RedoCheckboxSize;
  disabled?: boolean;
  label?: string;
  description?: React.ReactNode;
  showLabelSpacer?: boolean;
  showDescriptionSpacer?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const RedoCheckbox = memo(function RedoCheckbox({
  dataTestId,
  value,
  setValue,
  disabled,
  label,
  description,
  size = "md",
  showLabelSpacer,
  showDescriptionSpacer,
  onClick,
}: RedoCheckboxProps) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    if (event.target.indeterminate) {
      setValue("indeterminate");
    } else if (event.target.checked) {
      setValue(true);
    } else {
      setValue(false);
    }
  }

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.indeterminate = value === "indeterminate";
  }, [value, inputRef]);

  const checked = !!value;

  const Icon = value === "indeterminate" ? MinusIcon : CheckIcon;

  const labelColor = disabled || !checked ? "secondary" : "primary";
  const labelWeight = checked ? "medium" : "regular";

  return (
    <Flex as="label">
      <Flex align="flex-start" pt="xxs">
        <input
          checked={checked}
          className={redoCheckboxCss.input}
          disabled={disabled}
          onChange={handleChange}
          onClick={onClick}
          ref={inputRef}
          type="checkbox"
        />
        <div
          className={classNames(
            redoCheckboxCss.box,
            checkboxSizeToCssSize[size],
            disabled && redoCheckboxCss.disabled,
            checked && redoCheckboxCss.checked,
          )}
          data-testid={dataTestId}
        >
          <Icon
            className={classNames(
              redoCheckboxCss.icon,
              checkboxSizeToCssSize[size],
              disabled && redoCheckboxCss.disabled,
              checked && redoCheckboxCss.checked,
            )}
          />
        </div>
      </Flex>
      {(label || description) && (
        <Flex dir="column" gap="none">
          {label ? (
            <Text
              className={redoCheckboxCss.text}
              fontSize={checkboxSizeToLabelSize[size]}
              fontWeight={labelWeight}
              textColor={labelColor}
            >
              {label}
            </Text>
          ) : (
            showLabelSpacer && (
              <span
                className={classNames(
                  redoCheckboxCss.labelSpacer,
                  checkboxSizeToCssSize[size],
                )}
              />
            )
          )}
          {description ? (
            typeof description === "string" ? (
              <Text
                className={redoCheckboxCss.text}
                fontSize={checkboxSizeToDescriptionSize[size]}
                textColor="tertiary"
              >
                {description}
              </Text>
            ) : (
              description
            )
          ) : (
            showDescriptionSpacer && (
              <span className={redoCheckboxCss.descriptionSpacer} />
            )
          )}
        </Flex>
      )}
    </Flex>
  );
});

const checkboxSizeToCssSize: Record<RedoCheckboxSize, string> = {
  ["xs"]: redoCheckboxCss.small,
  ["sm"]: redoCheckboxCss.small,
  ["md"]: redoCheckboxCss.medium,
};

const checkboxSizeToLabelSize: Record<RedoCheckboxSize, TextSizeValue> = {
  ["xs"]: "xs",
  ["sm"]: "sm",
  ["md"]: "md",
};

const checkboxSizeToDescriptionSize: Record<RedoCheckboxSize, TextSizeValue> = {
  ["xs"]: "xs",
  ["sm"]: "sm",
  ["md"]: "sm",
};
