import type { Jsonified } from "@redotech/json/json";
import { isEnumValue } from "@redotech/util/enum";
import type { ObjectId } from "bson";
import { z } from "zod";
import { CheckoutAbTestMetricToOptimize } from "./checkouts/checkout-ab-test-builder";
import { CheckoutTreeZodSchema } from "./checkouts/checkout-tree";
import { zExt } from "./common/zod-util";
import {
  ExtendedWarrantyBlurbType,
  ExtendedWarrantyCartToggleExperience,
  ExtendedWarrantyDefaultSelection,
  ExtendedWarrantyPdpExperience,
  ExtendedWarrantyPdpNoCoverageType,
  ExtendedWarrantyPdpRenderMethod,
  ExtendedWarrantyRadioOptionType,
  ExtendedWarrantyTilesSize,
} from "./extended-warranty";
import { CheckoutExperienceZodArraySchema } from "./integration/redo-in-shipping/ris";
import type {
  AttachmentStrategy,
  DynamicReturnPricing,
  Icons,
  InternationalReturnPricing,
  PricingRuleSet,
  ToggleFields,
} from "./team";
import {
  ClickStandardCheckoutButton,
  InsertionPosition,
  TermsAndConditions,
  WidgetConfig,
} from "./widget";

/**
 * TODO - This should probably be called `SplitType`
 */
export enum TreatmentType {
  COVERAGE = "Treatment",
  CONCIERGE = "ConciergeTreatment",
  CHECKOUT_OPTIMIZATION = "CheckoutOptimization",
  EXPANDED_WARRANTY = "ExpandedWarranty",
}

/**
 * These are the treatment types for split tests run in Redo admin app.
 * E.g. CHECKOUT_OPTIMIZATION split tests are run by merchants directly from
 * merchant app.
 */
// TODO: Refactor to use a Record<TreatmentType, string>
export const AdminTreatmentTypes = [
  TreatmentType.COVERAGE,
  TreatmentType.CONCIERGE,
  TreatmentType.EXPANDED_WARRANTY,
] as const;
export type AdminTreatmentType = (typeof AdminTreatmentTypes)[number];
export function isAdminTreatmentType(
  treatmentType: string,
): treatmentType is AdminTreatmentType {
  return AdminTreatmentTypes.includes(treatmentType as AdminTreatmentType);
}

export enum MetricName {
  PAGE_VIEWED = "page_viewed",
  PRODUCT_ADDED_TO_CART = "product_added_to_cart",
  CART_VIEWED = "cart_viewed",
  CHECKOUT_STARTED = "checkout_started",
  CHECKOUT_COMPLETED = "checkout_completed",
  TOTAL_SPENT = "total_spent",
  TOTAL_MERCHANT_ITEMS_PURCHASED = "total_merchant_items_purchased",
  RETURN_PURCHASED = "return_purchased",
  PACKAGE_PROTECTION_PURCHASED = "package_protection_purchased",
  RETURN_PACKAGE_PROTECTION_PURCHASED = "return,package_protection_purchased",
  EW_ELIGIBLE_PRODUCT_PURCHASED = "ew_eligible_product_purchased",
  EW_PURCHASED = "ew_purchased",
  SHIPPING_REVENUE = "shipping_revenue",
}

export function isMetricName(name: string): name is MetricName {
  return isEnumValue(name, MetricName);
}

export type Treatment = Jsonified<TreatmentDoc>;

export type GenericTreatment = {
  _id: ObjectId;
  split_id: ObjectId;
  name: string;
  active: boolean;
  settings: object;
  weight: number;
};

export interface TreatmentDoc {
  _id: ObjectId;
  split_id: ObjectId;
  name: string;
  active: boolean;
  settings: {
    redoEnabled: boolean;
    returnEnabled: boolean;
    packageProtectionEnabled: boolean;
    attachmentStrategy: AttachmentStrategy;
    toggleTextOptions?: {
      returnToggle: ToggleFields;
      packageProtectionToggle: ToggleFields;
      bothProductToggle: ToggleFields;
    };
    hideOnPDP: boolean;
    redoAutoEnable: boolean;
    packageProtectionAutoEnable: boolean;
    hideBranding: boolean;
    hideCartProduct?: boolean;
    hideCartProductSelector?: string;
    hideCheckoutButtonSelector?: string;
    offerDiscountOnNextOrder?: boolean;
    offerDiscountOnNextOrderPercentage?: string;
    showIcons?: boolean;
    cartElementPlacementSelector?: string;
    cartElementShadowRootPlacementSelector?: string;
    cartListenerSelector?: string;
    cartElementPlacement?: "before" | "after" | "prepend" | "append";
    cartElementImage?: URL;
    usingCartAndCheckoutToggle?: boolean;
    forceDoubleCheckoutToggle?: boolean;
    customToggleCss?: string;
    displayIcon?: boolean;
    icon?: Icons;
    iconColor?: string;
    iconBackgroundColor?: string;
    returnOptions?: {
      pricePerOrder: string;
      serviceCountries: string[];
      dynamicPricing?: DynamicReturnPricing;
      internationalPricing?: InternationalReturnPricing;
      excludeCoverageIfAnyInvalidProduct: boolean;
    };
    widgetConfigs?: WidgetConfig[];
    groupNearbyToggleElementsTogether?: boolean;
    packageProtectionPricingRulesets?: PricingRuleSet[];
    packageProtectionMinPrice?: string;
    packageProtectionMaxPrice?: string;
    splitProducts?: boolean;
    termsAndConditions?: TermsAndConditions;
    clickStandardCheckoutButton?: ClickStandardCheckoutButton;
  };
  weight: number;
}

export type TestMetricsTimeseries = Partial<{
  [metricWithSource in string]: (number | null)[];
}> & { date: string[] };

export interface TreatmentPerformance {
  treatmentId: string;
  treatmentName: string;
  views: number;
  checkouts: number;
  conversionRate: number;
  returnsAdoption: number;
  averageOrderPrice: number;
  spendPerVisitor: number;
  rawData: TestMetricsTimeseries;
  expandedWarrantyAdoption: number;
}

export interface PopulatedSplit {
  _id: string;
  name?: string;
  team: string;
  active: boolean;
  treatments: Treatment[];
  startDate: string;
  endDate?: string | null;
  deleted?: boolean;
  treatmentType: AdminTreatmentType;
}

export type Split = Jsonified<SplitDoc>;

export const baseSplitZodSchema = z.object({
  _id: zExt.objectId(),
  name: z.string().optional(),
  team: zExt.objectId(),
  active: z.boolean().optional(),
  treatments: z.array(zExt.objectId()),
  startDate: z.date(),
  endDate: z.date().nullable().optional(),
  deleted: z.boolean().optional(),
  treatmentType: z.nativeEnum(TreatmentType),
});

export type BaseSplitZodType = z.infer<typeof baseSplitZodSchema>;

export const checkoutOptimizationSplitZodSchema = baseSplitZodSchema.extend({
  name: z.string(),
  description: z.string(),
  metricToOptimize: z.nativeEnum(CheckoutAbTestMetricToOptimize),
  controlTreatment: zExt.objectId(),
  createdAt: z.date(),
  concluded: z
    .object({
      selectedTreatmentId: zExt.objectId(),
      checkoutTreeSnapshot: CheckoutTreeZodSchema.optional(),
      checkoutExperiences: CheckoutExperienceZodArraySchema.optional(),
    })
    .optional(),
});

export interface SplitDoc {
  _id: ObjectId;
  name?: string;
  team: ObjectId;
  active: boolean;
  treatments: ObjectId[];
  startDate: Date;
  endDate?: Date | null;
  deleted?: boolean;
}

export enum DataSource {
  PIXEL = "pixel",
  ORDER = "order",
  CARRIER_SERVICE = "carrier_service",
}

export interface TestMetricDoc {
  treatment_id: ObjectId;
  metric: MetricName;
  date: string;
  dataSource: DataSource;
  country: string;
  priceBracket: number;
  numMerchantItemsPurchased: number;
  value: number;
  order_id?: ObjectId;
}

export const checkoutOptimizationTreatmentZodSchema = z.object({
  _id: zExt.objectId(),
  splitId: zExt.objectId(),
  weight: z.number(),
  name: z.string(),
});
export type CheckoutOptimizationTreatment = z.infer<
  typeof checkoutOptimizationTreatmentZodSchema
>;

export const expandedWarrantyTreatmentZodSchema = z.object({
  _id: zExt.objectId(),
  type: z.literal(TreatmentType.EXPANDED_WARRANTY),
  split_id: zExt.objectId(),
  name: z.string(),
  weight: z.number(),
  active: z.boolean(),
  settings: z.object({ expandedWarrantiesEnabled: z.boolean().optional() }),
  ewWidgetConfig: z.object({
    variantSelector: z.string().optional(),
    customToggleCss: z.string().optional(),
    cartToggleExperience: z
      .nativeEnum(ExtendedWarrantyCartToggleExperience)
      .optional(),
    cartToggleModalNotAddedDescription: z.string().optional(),
    cartToggleModalAddedDescription: z.string().optional(),
    cartToggleAddedDescription: z.string().optional(),
    cartToggleNotAddedDescription: z.string().optional(),
    clickListenerSelector: z.string().optional(),
    customPdpCss: z.string().optional(),
    pdpExperience: z.nativeEnum(ExtendedWarrantyPdpExperience).optional(),
    pdpDefaultSelection: z
      .nativeEnum(ExtendedWarrantyDefaultSelection)
      .optional(),
    pdpRenderMethod: z.nativeEnum(ExtendedWarrantyPdpRenderMethod).optional(),
    pdpNoCoverageType: z
      .nativeEnum(ExtendedWarrantyPdpNoCoverageType)
      .optional(),
    pdpNoCoveragePrice: z.string().optional(),
    pdpSelector: z.string().optional(),
    pdpTitle: z.string().optional(),
    pdpLabel: z.string().optional(),
    pdpCompactBlurbTitle: z.string().optional(),
    pdpTilesSize: z.nativeEnum(ExtendedWarrantyTilesSize).optional(),
    pdpBlurbType: z.nativeEnum(ExtendedWarrantyBlurbType).optional(),
    pdpRadioOptionType: z
      .nativeEnum(ExtendedWarrantyRadioOptionType)
      .optional(),
    pdpRadioOptionTitle: z.string().optional(),
    pdpCoverageDisabledLabel: z.string().optional(),
    pdpDescription: z.string().optional(),
    pdpAddToCartDescription: z.string().optional(),
    pdpAddedToCartTitle: z.string().optional(),
    pdpAddedToCartDescription: z.string().optional(),
    pdpTilesBackdropEnabled: z.boolean().optional(),
    pdpInsertPosition: z.nativeEnum(InsertionPosition).optional(),
    customModalCss: z.string().optional(),
    logo: z.string().nullable().optional(),
    sideImage: z.string().nullable().optional(),
    modalTitle: z.string().optional(),
    modalCoverageHeader: z.string().optional(),
    modalDescription: z.string().optional(),
    modalPlanHeader: z.string().optional(),
    modalBulletPoints: z.array(z.string()).optional(),
    modalSeeDetailsUrl: z.string().optional(),
  }),
});

export type ExpandedWarrantyTreatment = z.infer<
  typeof expandedWarrantyTreatmentZodSchema
>;

export const treatmentZodSchema = z.discriminatedUnion("type", [
  expandedWarrantyTreatmentZodSchema,
]);

export type TreatmentUnion = z.infer<typeof treatmentZodSchema>;

/**
 * Copied from frontend, from what I can tell these are the meanings of the parameters:
 * @param n1 - number of views for "treatment"
 * @param c1 - conversion rate for "treatment"
 * @param n2 - number of views for "control"
 * @param c2 - conversion rate for "control"
 */
export const calculateStatisticalConfidence = (
  n1: number,
  c1: number,
  n2: number,
  c2: number,
) => {
  const normalDistribution = (x: number) => {
    // Calculate the probability of a value being less than x given a normal distribution with mean 0 and standard deviation 1
    return 0.5 * (1 + erf(x / Math.sqrt(2)));
  };

  const erf = (x: number) => {
    // Error function approximation for a normal distribution
    // https://en.wikipedia.org/wiki/Error_function
    const a1 = 0.254829592;
    const a2 = -0.284496736;
    const a3 = 1.421413741;
    const a4 = -1.453152027;
    const a5 = 1.061405429;
    const p = 0.3275911;

    const sign = x >= 0 ? 1 : -1;
    const t = 1 / (1 + p * Math.abs(x));
    const y =
      1 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

    return sign * y;
  };

  const stdError1 = Math.sqrt((c1 * (1 - c1)) / n1);
  const stdError2 = Math.sqrt((c2 * (1 - c2)) / n2);
  const zScore = (c1 - c2) / Math.sqrt(stdError1 ** 2 + stdError2 ** 2);
  const p = normalDistribution(zScore);

  return p;
};
