import { GetDeliveryProfileResult } from "@redotech/admin-sdk/rpc/schema/shopify/get-delivery-profile";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import {
  CoverageProductEnum,
  TEST_CHECKOUT_OUTBOUND_RATES_NAME,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoToggle } from "@redotech/redo-web/arbiter-components/toggle/redo-toggle";
import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import { Card } from "@redotech/redo-web/card";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { isDef } from "@redotech/util/checks";
import { memo, useCallback, useEffect, useMemo } from "react";
import { RedoAdminRpcClientContext } from "../../../app/redo-admin-rpc-client-provider";

const demoCheckoutOutboundRatesDescription = `Use "${TEST_CHECKOUT_OUTBOUND_RATES_NAME}" as the first name field in the Shopify checkout to demo the Redo checkout optimization rates. All other customers' checkouts will be unaffected. This toggle should be used when the main \`enabled\` toggle is off and the merchant wants a preview of what rates will be shown. Note: this will not prevent the Shopify rates that would otherwise be shown so you should expect to see duplicate rates.`;

export const checkoutOutboundRatesForm = groupInput({
  checkoutOutboundRatesEnabled: input<boolean>(),
  demoCheckoutOutboundRatesEnabled: input<boolean>(),
  hideNonRedoRatesWhenRedoPresent: input<boolean>(),
});

type CheckoutOutboundRatesForm = InputProvider.Form<
  typeof checkoutOutboundRatesForm
>;

type CheckoutOutboundRatesValue = InputProvider.Value<
  typeof checkoutOutboundRatesForm
>;

export const checkoutOutboundRatesDefault: CheckoutOutboundRatesValue = {
  checkoutOutboundRatesEnabled: false,
  demoCheckoutOutboundRatesEnabled: false,
  hideNonRedoRatesWhenRedoPresent: false,
};

enum CheckoutOutboundRatesErrorState {
  INSUFFICIENT_PERMISSIONS = "INSUFFICIENT_PERMISSIONS",
  CARRIER_SERVICES_NOT_AVAILABLE = "CARRIER_SERVICES_NOT_AVAILABLE",
  DELIVERY_PROFILE_ERROR = "DELIVERY_PROFILE_ERROR",
}

export const CheckoutOutboundRatesCard = memo(
  function CheckoutOutboundRatesCard({
    input,
    teamId,
    hasCarrierServices,
  }: {
    input: CheckoutOutboundRatesForm;
    teamId: string;
    hasCarrierServices: boolean;
  }) {
    const rpcClient = useRequiredContext(RedoAdminRpcClientContext);

    const deliveryProfileLoad = useLoad(async () => {
      return await rpcClient.getDeliveryProfile({ teamId });
    }, [teamId, rpcClient]);

    const isLoading = useMemo(() => {
      return !isDef(deliveryProfileLoad.value);
    }, [deliveryProfileLoad.value]);

    const checkoutOutboundRatesError:
      | { errorState: CheckoutOutboundRatesErrorState; message: string }
      | undefined = useMemo(() => {
      // wait for delivery profile to load before error state is known
      if (isLoading) {
        return undefined;
      }

      if (deliveryProfileLoad.error) {
        return {
          errorState: CheckoutOutboundRatesErrorState.DELIVERY_PROFILE_ERROR,
          message: deliveryProfileLoad.error.message,
        };
      }
      if (
        deliveryProfileLoad.value?.result ===
        GetDeliveryProfileResult.ERROR__GRAPHQL_ACCESS_DENIED
      ) {
        return {
          errorState: CheckoutOutboundRatesErrorState.INSUFFICIENT_PERMISSIONS,
          message:
            "In order to check if it's possible to enable Redo rates in checkout, this merchant must update Redo's collaborator access to include Shipping and Delivery permissions. Please request permissions with the button below and tell the merchant to go to their Redo dashboard and click 'Update Permission' in the banner that shows. After the merchant has granted the permissions, we will then be able to check ~if it's possible~ to enable Redo rates in checkout.",
        };
      }
      if (!hasCarrierServices) {
        return {
          errorState:
            CheckoutOutboundRatesErrorState.CARRIER_SERVICES_NOT_AVAILABLE,
          message:
            "This merchant does not have access to carrier services. Checkout outbound rates are not available. This is likely due to their Shopify plan being too low. See `https://shopify.dev/docs/api/admin-rest/2024-07/resources/carrierservice` for more information.",
        };
      }
      return undefined;
    }, [
      isLoading,
      deliveryProfileLoad.value,
      deliveryProfileLoad.error,
      hasCarrierServices,
    ]);

    const [shippingCoverageSettingsLoad, triggerShippingCoverageSettingsLoad] =
      useTriggerLoad(async () => {
        const result = await rpcClient.getRisIntegration({
          teamId,
          integrationType: "Shopify",
        });
        return result.risIntegration;
      });

    const isRisEnabled: boolean = useMemo(() => {
      return (
        !!shippingCoverageSettingsLoad.value?.enabled &&
        shippingCoverageSettingsLoad.value?.coverage ===
          CoverageProductEnum.SHIPPING
      );
    }, [shippingCoverageSettingsLoad.value]);

    const [
      calculatedFulfillmentDelayDaysLoad,
      doCalculatedFulfillmentDelayDaysLoad,
    ] = useTriggerLoad(async () => {
      return await rpcClient.getCalculatedFulfillmentDelay({ teamId });
    });

    useEffect(() => {
      if (
        (input.value.checkoutOutboundRatesEnabled ||
          input.value.demoCheckoutOutboundRatesEnabled) &&
        !calculatedFulfillmentDelayDaysLoad.value
      ) {
        doCalculatedFulfillmentDelayDaysLoad();
      }
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      input.value.checkoutOutboundRatesEnabled,
      input.value.demoCheckoutOutboundRatesEnabled,
    ]);

    const handleRequestPermissions = useCallback(async () => {
      await rpcClient.requestShippingDeliveryPermissions({ teamId });
      triggerShippingCoverageSettingsLoad();
    }, [teamId, rpcClient, triggerShippingCoverageSettingsLoad]);

    useEffect(() => {
      triggerShippingCoverageSettingsLoad();
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
      checkoutOutboundRatesEnabled,
      demoCheckoutOutboundRatesEnabled,
      hideNonRedoRatesWhenRedoPresent,
    } = input.inputs;

    return (
      <Card title="Checkout outbound rates">
        {isLoading ? (
          <LoadingRedoAnimation />
        ) : (
          <Flex dir="column" gap="xl">
            {hasCarrierServices ? (
              <Tooltip
                placement="top"
                title="Carrier services are available to use for this merchant."
              >
                <Flex w="fit-content">
                  <RedoBadge
                    color="success"
                    iconTrailing={{ type: "icon", Icon: CheckIcon }}
                    text="Carrier services"
                  />
                </Flex>
              </Tooltip>
            ) : undefined}
            {checkoutOutboundRatesError && (
              <>
                <Text fontSize="md" fontWeight="regular" textColor="primary">
                  {checkoutOutboundRatesError.message}
                </Text>
                {checkoutOutboundRatesError.errorState ===
                  CheckoutOutboundRatesErrorState.INSUFFICIENT_PERMISSIONS && (
                  <RedoButton
                    disabled={
                      shippingCoverageSettingsLoad.value
                        ?.requestShippingDeliveryPermissions
                    }
                    hierarchy="secondary"
                    onClick={handleRequestPermissions}
                    text={
                      shippingCoverageSettingsLoad.value
                        ?.requestShippingDeliveryPermissions
                        ? "Already requested"
                        : "Request permmissions"
                    }
                  />
                )}
              </>
            )}
            <RedoToggle
              disabled={
                isRisEnabled
                  ? "Enabling outbound rates in checkout is not yet supported for merchants with Redo in Shipping (RIS) enabled. Bother @Logan Nichol if you want it built."
                  : !!checkoutOutboundRatesError
              }
              label="Enabled"
              setValue={checkoutOutboundRatesEnabled.setValue}
              size="sm"
              value={checkoutOutboundRatesEnabled.value}
            />
            <RedoToggle
              description="When enabled, the delivery customization function will hide any non-Redo rates (when Redo rates are available). This is useful during onboarding to avoid having to manually remove a merchant's existing shipping rates."
              label="Hide non-Redo rates when Redo rates are present"
              setValue={hideNonRedoRatesWhenRedoPresent.setValue}
              size="sm"
              value={hideNonRedoRatesWhenRedoPresent.value}
            />
            <RedoToggle
              description={demoCheckoutOutboundRatesDescription}
              label="Demo enabled"
              setValue={demoCheckoutOutboundRatesEnabled.setValue}
              size="sm"
              value={demoCheckoutOutboundRatesEnabled.value}
            />
            {(checkoutOutboundRatesEnabled.value ||
              demoCheckoutOutboundRatesEnabled.value) && (
              <Text fontSize="sm" textColor="primary">
                Calculated fulfillment delay days:{" "}
                {calculatedFulfillmentDelayDaysLoad.value
                  ? (calculatedFulfillmentDelayDaysLoad.value
                      .calculatedFulfillmentDelayDays ?? "Unable to calculate")
                  : "Loading..."}
              </Text>
            )}
          </Flex>
        )}
      </Card>
    );
  },
);
