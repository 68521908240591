import { z } from "zod";
import {
  ImageElementType,
  InsertionPosition,
  PriceLocation,
  WidgetElementType,
} from "./widget";

export const termsAndConditionsSchema = z.object({
  blockCheckoutIfNotAccepted: z.boolean(),
  termsCheckboxSelector: z.string().optional(),
  blockedMessageText: z.string().optional(),
});
export type TermsAndConditions = z.infer<typeof termsAndConditionsSchema>;

export const titleSubtitleSchema = z.object({
  titleText: z.string().optional(),
  subtitleText: z.string().optional(),
});
export type TitleSubtitle = z.infer<typeof titleSubtitleSchema>;

export const coverageTextSchema = z.object({
  returns: titleSubtitleSchema.optional(),
  packageProtection: titleSubtitleSchema.optional(),
  both: titleSubtitleSchema.optional(),
});
export type CoverageText = z.infer<typeof coverageTextSchema>;

export const nonCoverageButtonTextSchema = z.object({
  returns: z.string().optional(),
  packageProtection: z.string().optional(),
  both: z.string().optional(),
});
export type NonCoverageButtonText = z.infer<typeof nonCoverageButtonTextSchema>;

export const infoCardStylesSchema = z.object({
  color: z.string().optional(),
  titleFontSize: z.number().optional(),
  titleFontColor: z.string().optional(),
  subtitleFontSize: z.number().optional(),
  subtitleFontColor: z.string().optional(),
});
export type InfoCardStyles = z.infer<typeof infoCardStylesSchema>;

export const baseWidgetConfigSchema = z.object({
  insertionPosition: z.nativeEnum(InsertionPosition).optional(),
  placementSelector: z.string(),
  shadowRootSelector: z.string().optional(),
  dataTarget: z.string().optional(),
  placeInsideAndOutsideShadowRoot: z.boolean().optional(),
});
export type BaseWidgetConfig = z.infer<typeof baseWidgetConfigSchema>;

export const InfoCardWidgetConfigSchema = baseWidgetConfigSchema.merge(
  z.object({
    elementType: z.literal(WidgetElementType.INFO_CARD),
    cardText: coverageTextSchema.optional(),
    placementSelector: z.string(),
    showInfoIcon: z.boolean(),
    badgeWord: z.string().optional(),
    infoCardStyles: infoCardStylesSchema.optional(),
    imageElement: z.nativeEnum(ImageElementType).optional(),
    priceLocation: z.nativeEnum(PriceLocation).optional(),
    showBackground: z.boolean().optional(),
  }),
);
export type InfoCardWidgetConfig = z.infer<typeof InfoCardWidgetConfigSchema>;

export const coverageButtonStylesSchema = z.object({
  color: z.string().optional(),
  fontColor: z.string().optional(),
  subtitleFontColor: z.string().optional(),
  fontSize: z.number().optional(),
  subtitleFontSize: z.number().optional(),
  nonCoverageFontColor: z.string().optional(),
  nonCoverageFontSize: z.number().optional(),
  includeNonCoverageUnderline: z.boolean().optional(),
});
export type CoverageButtonStyles = z.infer<typeof coverageButtonStylesSchema>;

export const BothCheckoutButtonsWidgetConfigSchema =
  baseWidgetConfigSchema.merge(
    z.object({
      elementType: z.literal(WidgetElementType.BOTH_CHECKOUT_BUTTONS),
      coverageButtonText: coverageTextSchema.optional(),
      nonCoverageButtonText: nonCoverageButtonTextSchema.optional(),
      coverageButtonStyles: coverageButtonStylesSchema.optional(),
    }),
  );
export type BothCheckoutButtonsWidgetConfig = z.infer<
  typeof BothCheckoutButtonsWidgetConfigSchema
>;

export const IndividualCheckoutButtonsCoverageEnabledWidgetConfigSchema =
  baseWidgetConfigSchema.merge(
    z.object({
      elementType: z.literal(WidgetElementType.COVERAGE_CHECKOUT_BUTTON),
      content: z.string(),
    }),
  );
export type IndividualCheckoutButtonsCoverageEnabledWidgetConfig = z.infer<
  typeof IndividualCheckoutButtonsCoverageEnabledWidgetConfigSchema
>;

export const IndividualCheckoutButtonsCoverageDisabledWidgetConfigSchema =
  baseWidgetConfigSchema.merge(
    z.object({
      elementType: z.literal(WidgetElementType.NON_COVERAGE_CHECKOUT_BUTTON),
      content: z.string(),
    }),
  );
export type IndividualCheckoutButtonsCoverageDisabledWidgetConfig = z.infer<
  typeof IndividualCheckoutButtonsCoverageDisabledWidgetConfigSchema
>;

export const widgetConfigSchema = z.discriminatedUnion("elementType", [
  InfoCardWidgetConfigSchema,
  BothCheckoutButtonsWidgetConfigSchema,
  IndividualCheckoutButtonsCoverageEnabledWidgetConfigSchema,
  IndividualCheckoutButtonsCoverageDisabledWidgetConfigSchema,
]);
export type WidgetConfig = z.infer<typeof widgetConfigSchema>;
