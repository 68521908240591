import { z } from "zod";

export namespace GmailPromotionConstants {
  export const EMAIL_SUBJECT_PREFIX =
    "Gmail Promotion Tab Whitelist Request for ";

  export const TO_EMAIL = "p-Promo-Outreach@google.com";

  export const FROM_EMAIL = "gmail-promotion-tab@support.getredo.com";
}

export enum GmailPromotionTabWhitelistStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  INDETERMINATE = "indeterminate",
}

export enum MessageDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export const inferredIntentsSchema = z.array(
  z.object({
    inferredDomain: z
      .string()
      .describe("The domain inferred from the email received from Gmail"),
    inferredStatus: z
      .nativeEnum(GmailPromotionTabWhitelistStatus)
      .describe(
        "The status inferred from the email received from Gmail, if any",
      ),
    explanation: z.string().nullable(),
  }),
);

export const gmailPromotionTabWhitelistMessageSchema = z.discriminatedUnion(
  "direction",
  [
    z.object({
      direction: z.literal(MessageDirection.INBOUND),
      body: z.string().describe("The body of the email received from Gmail"),
      inferredIntents: inferredIntentsSchema,
    }),
    z.object({
      direction: z.literal(MessageDirection.OUTBOUND),
      body: z
        .string()
        .describe(
          `The body of the email sent to ${GmailPromotionConstants.TO_EMAIL}`,
        ),
    }),
  ],
);
export type GmailPromotionTabWhitelistMessage = z.infer<
  typeof gmailPromotionTabWhitelistMessageSchema
>;

export const gmailPromotionTabWhitelistEventSchema = z.object({
  status: z.nativeEnum(GmailPromotionTabWhitelistStatus),
  message: gmailPromotionTabWhitelistMessageSchema,
  date: z.date(),
});
export type GmailPromotionTabWhitelistEvent = z.infer<
  typeof gmailPromotionTabWhitelistEventSchema
>;

export const gmailPromotionTabWhitelistSchema = z.object({
  domain: z.string(),
  status: z.nativeEnum(GmailPromotionTabWhitelistStatus),
  statusSetAt: z.date(),
  events: z.array(gmailPromotionTabWhitelistEventSchema),
});
export type GmailPromotionTabWhitelist = z.infer<
  typeof gmailPromotionTabWhitelistSchema
>;
