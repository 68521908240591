import { LengthUnit } from "@redotech/redo-model/outbound-labels";
import {
  AxisKind,
  OutboundCubicSizeByZoneRateTable,
  OutboundWeightByZoneRateTable,
  RateTableType,
  RateTableValueMode,
  WeightUnit,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  RateTableCarrier,
  RateTableServiceLevel,
} from "@redotech/redo-model/rate-table/rate-table-mapping";

export const defaultNewOutboundRateTable = (
  carrier: RateTableCarrier,
  serviceLevel: RateTableServiceLevel,
  valueMode: RateTableValueMode,
  rateTableType:
    | RateTableType.OUTBOUND_SIZE_BY_ZONE
    | RateTableType.OUTBOUND_WEIGHT_BY_ZONE,
): OutboundWeightByZoneRateTable | OutboundCubicSizeByZoneRateTable => {
  if (rateTableType === RateTableType.OUTBOUND_WEIGHT_BY_ZONE) {
    const value = valueMode === RateTableValueMode.Rate ? "5" : "0.00";

    return {
      rateTableType,
      valueMode,
      carrier,
      serviceLevel,
      data: [
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
        [value, value, value, value, value, value, value, value],
      ],
      xAxis: {
        values: ["1", "2", "3", "4", "5", "6", "7", "8"],
        kind: AxisKind.ZONE,
      },
      yAxis: {
        values: [
          { minWeight: 0, maxWeight: 0.25 },
          { minWeight: 0.25, maxWeight: 0.5 },
          { minWeight: 0.5, maxWeight: 0.75 },
          { minWeight: 0.75, maxWeight: 0.99 },
          { minWeight: 0.99, maxWeight: 1 },
          { minWeight: 1, maxWeight: 2 },
          { minWeight: 2, maxWeight: 3 },
          { minWeight: 3, maxWeight: 4 },
          { minWeight: 4, maxWeight: 5 },
          { minWeight: 5, maxWeight: 6 },
          { minWeight: 6, maxWeight: 7 },
          { minWeight: 7, maxWeight: 8 },
          { minWeight: 8, maxWeight: 9 },
        ],
        kind: AxisKind.WEIGHT,
        units: WeightUnit.LB,
      },
      defaultValue: "0.5",
    };
  } else {
    return {
      rateTableType,
      valueMode,
      carrier,
      serviceLevel,
      data: [
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0"],
      ],
      xAxis: {
        values: ["1", "2", "3", "4", "5", "6", "7", "8"],
        kind: AxisKind.ZONE,
      },
      yAxis: {
        values: [
          { minCubicSize: 0, maxCubicSize: 0.1 },
          { minCubicSize: 0.1, maxCubicSize: 0.2 },
          { minCubicSize: 0.2, maxCubicSize: 0.3 },
          { minCubicSize: 0.3, maxCubicSize: 0.4 },
          { minCubicSize: 0.4, maxCubicSize: 0.5 },
          { minCubicSize: 0.5, maxCubicSize: 0.6 },
          { minCubicSize: 0.6, maxCubicSize: 0.7 },
          { minCubicSize: 0.7, maxCubicSize: 0.8 },
          { minCubicSize: 0.8, maxCubicSize: 0.9 },
          { minCubicSize: 0.9, maxCubicSize: 1 },
          { minCubicSize: 1, maxCubicSize: 2 },
          { minCubicSize: 2, maxCubicSize: 3 },
          { minCubicSize: 3, maxCubicSize: 4 },
          { minCubicSize: 4, maxCubicSize: 5 },
          { minCubicSize: 5, maxCubicSize: 6 },
          { minCubicSize: 6, maxCubicSize: 7 },
          { minCubicSize: 7, maxCubicSize: 8 },
          { minCubicSize: 8, maxCubicSize: 9 },
        ],
        kind: AxisKind.CUBIC_SIZE,
        units: LengthUnit.INCH,
      },
      defaultValue: "0",
    };
  }
};
