import { memo, useState } from "react";
import ThreeDotsHorizontalIcon from "../../arbiter-icon/dots-horizontal.svg";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../command-menu/redo-command-menu";
import * as redoListItemCss from "./redo-list-item.module.css";

export const RedoListItemCommandMenu = memo(function RedoListItemCommandMenu({
  disabled,
  menu,
  open,
  setOpen,
}: {
  disabled: boolean;
  menu: RedoCommandMenuItem[] | { onClick: () => void };
  open: boolean;
  setOpen(value: boolean): void;
}) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const isCommandMenu = Array.isArray(menu);

  return (
    <>
      <button
        className={redoListItemCss.menu}
        disabled={disabled}
        onClick={(event) => {
          if (!disabled) {
            event.stopPropagation();
            if (isCommandMenu) {
              setOpen(!open);
            } else {
              menu.onClick();
            }
          }
        }}
        ref={setAnchor}
      >
        <ThreeDotsHorizontalIcon />
      </button>

      {isCommandMenu && (
        <RedoCommandMenu
          anchor={anchor}
          items={menu}
          open={open}
          setOpen={setOpen}
          size="xs"
        />
      )}
    </>
  );
});
