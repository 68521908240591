import { Modal as MuiModal } from "@mui/base/Modal";
import Fade from "@mui/material/Fade";
import { useHandler } from "@redotech/react-util/hook";
import { Coverage } from "@redotech/redo-model/coverage";
import { InfoModalVersion } from "@redotech/redo-model/team";
import {
  bulletThreeSubtitleText,
  bulletThreeTitleText,
  bulletTwoSubtitleText,
  bulletTwoTitleText,
  defaultHeaderText,
  defaultSubheaderText,
  returnMessages,
} from "@redotech/redo-model/text-defaults/info-modal-default-text";
import { InfoModal } from "@redotech/redo-model/widget";
import FeaturedLaptopIcon from "@redotech/redo-web/arbiter-icon/featured-laptop-2.svg";
import FeaturedPackageCheckIcon from "@redotech/redo-web/arbiter-icon/featured-package-check.svg";
import FeaturedRefreshIcon from "@redotech/redo-web/arbiter-icon/featured-refresh-cw-3.svg";
import CheckCircle from "@redotech/redo-web/icon-old/check-circle.svg";
import Exchange from "@redotech/redo-web/icon-old/exchange.svg";
import Flag from "@redotech/redo-web/icon-old/flag.svg";
import ModalCloseButton from "@redotech/redo-web/icon-old/modal-close-button.svg";
import Logo from "@redotech/redo-web/logo.svg";
import { ModalRootContext } from "@redotech/redo-web/modal-root";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import HTMLReactParser from "html-react-parser";
import * as React from "react";
import { ReactElement, ReactNode, memo, useContext } from "react";
import * as modalV2 from "./modal-v2.module.css";
import * as modal from "./modal.module.css";
// import { Divider } from "@redotech/redo-web/divider";

const Background = React.forwardRef<HTMLDivElement, any>(function Background(
  { className, ownerState, onClick, ...props },
  ref,
) {
  const blockPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  const handleClickEventAndBlockPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClick?.(e as React.MouseEvent);
  };

  // MUI Modal only listens to and blocks the click event, so we need to use custom behaviour
  return (
    <div
      className={modal.background}
      ref={ref}
      {...props}
      onClick={blockPropagation}
      onMouseDown={blockPropagation}
      onPointerDown={blockPropagation}
      onPointerUp={handleClickEventAndBlockPropagation}
      onTouchEnd={blockPropagation}
      onTouchMove={blockPropagation}
      onTouchStart={blockPropagation}
    />
  );
});

export const Modal = memo(function Modal({
  children,
  open,
  onClose,
}: {
  children: ReactElement;
  open: boolean;
  onClose?(): void;
}) {
  const root = useContext(ModalRootContext);

  // Because shopify's cart drawer listens to these events and will move behind us
  const blockPropagation = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <MuiModal
      className={modal.container}
      closeAfterTransition
      container={root}
      onClose={onClose}
      open={open}
      slots={{ backdrop: Background }}
    >
      <Fade in={open}>
        <div
          className={modalV2.modal}
          onClick={blockPropagation}
          onMouseDown={blockPropagation}
          onMouseUp={blockPropagation}
          onPointerDown={blockPropagation}
          onPointerUp={blockPropagation}
          onTouchEnd={blockPropagation}
          onTouchStart={blockPropagation}
        >
          {children}
        </div>
      </Fade>
    </MuiModal>
  );
});

const Button = memo(function Button({
  children,
  onClick,
}: {
  children: ReactNode | ReactNode[];
  onClick?(): void | Promise<void>;
}) {
  return (
    <button className={modal.buttonPrimary} onClick={onClick} type="button">
      {children}
    </button>
  );
});

export const RedoModal = memo(function RedoModal({
  open,
  onAdd,
  onClose,
  modalTextAdjustments,
  modalLogo,
  returnsCoverage,
  packageProtectionPlusEnabled,
  packageProtectionEnabled,
  modalCustomizationsV2,
  version,
  isPreview,
}: {
  open: boolean;
  onAdd?(): void | Promise<void>;
  onClose?: () => void;
  returnsCoverage?: Coverage;
  packageProtectionEnabled?: boolean;
  packageProtectionPlusEnabled?: boolean;
  modalTextAdjustments?: {
    text1: string;
    title1: string;
    text2: string;
    title2: string;
    text3: string;
    title3: string;
  };
  modalLogo?: string;
  modalCustomizationsV2?: InfoModal;
  version?: InfoModalVersion;
  isPreview?: boolean;
}) {
  if (version === InfoModalVersion.V2) {
    return (
      <ModalContentV2ImageWrapper
        customCss={modalCustomizationsV2?.customCss}
        isPreview={isPreview}
        onClose={onClose}
        open={open}
        sideImage={modalCustomizationsV2?.sideImage}
      >
        <ModalContentV2
          modalCustomizationsV2={modalCustomizationsV2}
          packageProtectionEnabled={packageProtectionEnabled}
          packageProtectionPlusEnabled={packageProtectionPlusEnabled}
          returnsCoverage={returnsCoverage}
        />
      </ModalContentV2ImageWrapper>
    );
  }

  return (
    <Modal onClose={onClose} open={open}>
      <div className={modal.redoModal}>
        <ModalContent
          modalLogo={modalLogo}
          modalTextAdjustments={modalTextAdjustments}
          onAdd={onAdd}
          onClose={onClose}
          packageProtectionPlusEnabled={packageProtectionPlusEnabled}
        />
      </div>
    </Modal>
  );
});

export const ModalContent = memo(function ModalContent({
  packageProtectionPlusEnabled,
  modalTextAdjustments,
  modalLogo,
  onAdd,
  onClose,
}: {
  packageProtectionPlusEnabled?: boolean;
  modalTextAdjustments?: {
    text1: string;
    title1: string;
    text2: string;
    title2: string;
    text3: string;
    title3: string;
  };
  modalLogo?: string;
  onClose?(): void;
  onAdd?(): void | Promise<void>;
}) {
  const onButtonClick = useHandler(async () => {
    onAdd && (await onAdd());
    onClose && onClose();
  });

  const onCloseClick = useHandler(() => {
    onClose && onClose();
  });

  return (
    <>
      <div className={modal.modalLogo}>
        {(modalLogo && (
          <img alt="Logo" className={modal.modalImageLogo} src={modalLogo} />
        )) || <Logo />}
      </div>
      {onClose && (
        <div className={modal.modalCloseButton} onPointerUp={onCloseClick}>
          <ModalCloseButton />
        </div>
      )}

      <div className={modal.modalFeatures}>
        <Feature
          icon={<CheckCircle />}
          title={modalTextAdjustments?.title1 || "Add Redo"}
        >
          {modalTextAdjustments?.text1 ||
            "Qualifies you for FREE Returns if needed"}
        </Feature>
        <Feature
          icon={<Flag />}
          title={modalTextAdjustments?.title2 || "Receive your item"}
        >
          {modalTextAdjustments?.text2 || "Try it out"}
        </Feature>
        <Feature
          icon={<Exchange />}
          title={
            modalTextAdjustments?.title3 ||
            "Send it back for FREE if it doesn't work"
          }
        >
          {modalTextAdjustments?.text3 || "Quick and simple, at no extra cost"}
        </Feature>
      </div>

      {onAdd && (
        <div className={modal.modalAction}>
          <Button onClick={onButtonClick}>Add It</Button>
        </div>
      )}

      <div className={modal.modalFooter}>
        By purchasing Redo you agree and have read the{" "}
        <a
          className={modalV2.link}
          href="https://www.getredo.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className={modalV2.link}
          href="https://www.getredo.com/terms-conditions"
          rel="noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </a>
        . Redo is subject to Merchant's Return Policy.
        {packageProtectionPlusEnabled && (
          <>
            {" "}
            Shipping protection provided by{" "}
            <a
              className={modalV2.link}
              href="https://shipsurance.com/redo/terms"
              rel="noreferrer"
              target="_blank"
            >
              Shipsurance
            </a>
            .
          </>
        )}
      </div>
    </>
  );
});

const Feature = memo(function Feature({
  icon,
  title,
  children,
}: {
  icon: ReactNode;
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <section className={modal.feature}>
      <div className={modal.featureBody}>
        <div className={modal.featureIcon}>{icon}</div>
        <h1 className={modal.featureTitle}>{title}</h1>
        <div className={modal.featureSummary}>{children}</div>
      </div>
    </section>
  );
});

export const ModalContentV2ImageWrapper = memo(
  function ModalContentImageWrapper({
    open,
    children,
    onClose,
    sideImage,
    isExtendedWarranty,
    customCss,
    isPreview,
    foundSelector,
  }: {
    open: boolean;
    children: ReactNode;
    onClose?(): void;
    sideImage?: string;
    isExtendedWarranty?: boolean;
    customCss?: string;
    isPreview?: boolean;
    foundSelector?: string;
  }) {
    const content = (
      <>
        {customCss && <style>{customCss}</style>}
        {foundSelector && <meta found-selector={foundSelector} />}
        <div className={modalV2.redoModal} data-target="redo-modal-container">
          <div
            className={modalV2.modalContent}
            data-target="redo-modal-content"
          >
            {onClose && !isPreview && (
              <div
                className={modalV2.modalCloseButton}
                data-target="redo-modal-close-button"
                onPointerUp={onClose}
              >
                <ModalCloseButton />
              </div>
            )}
            {sideImage && (
              <div
                className={classNames(
                  modalV2.modalSideImage,
                  isExtendedWarranty && modalV2.extendedWarranty,
                )}
                data-target="redo-modal-side-image"
                style={{
                  backgroundImage: `url(${sideImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            )}
            <div
              className={classNames(
                modalV2.modalContentMain,
                isExtendedWarranty && modalV2.extendedWarranty,
              )}
              data-target="redo-modal-content-main"
            >
              {children}
            </div>
          </div>
        </div>
      </>
    );

    if (isPreview) {
      return (
        <div className={classNames(modalV2.modal, modalV2.preview)}>
          {content}
        </div>
      );
    }
    return (
      <Modal onClose={onClose} open={open}>
        {content}
      </Modal>
    );
  },
);

export const ModalLogo = memo(function ModalLogo({ logo }: { logo?: string }) {
  return (
    <div className={modalV2.modalLogo} data-target="redo-modal-logo">
      {(logo && (
        <img alt="Logo" className={modal.modalImageLogo} src={logo} />
      )) || <Logo />}
    </div>
  );
});

export const ModalContentV2 = memo(function ModalContentV2({
  returnsCoverage,
  packageProtectionPlusEnabled,
  packageProtectionEnabled,
  modalCustomizationsV2,
}: {
  returnsCoverage?: Coverage;
  packageProtectionPlusEnabled?: boolean;
  packageProtectionEnabled?: boolean;
  modalCustomizationsV2?: InfoModal;
}) {
  const key =
    `${returnsCoverage?.exchange ? 1 : 0}${returnsCoverage?.refund ? 1 : 0}${returnsCoverage?.storeCredit ? 1 : 0}` as `${0 | 1}${0 | 1}${0 | 1}`;
  const { primaryMessage, secondaryMessage } = returnMessages[key] || {
    primaryMessage: "Free returns & exchanges",
    secondaryMessage: "Return or exchange",
  };

  return (
    <>
      <ModalLogo logo={modalCustomizationsV2?.logo} />
      <Text as="div" fontSize="xl" fontWeight="semibold">
        {HTMLReactParser(
          modalCustomizationsV2?.headerText || defaultHeaderText,
        )}
      </Text>
      <Text as="div" fontSize="sm" fontWeight="thin">
        {HTMLReactParser(
          modalCustomizationsV2?.subheaderText || defaultSubheaderText,
        )}
      </Text>
      <br />
      <div className={modalV2.contentItem} data-target="Bullet-1">
        <div>
          <FeaturedRefreshIcon height={32} width={32} />
        </div>
        <div>
          <Text as="div" fontSize="md" fontWeight="semibold">
            {HTMLReactParser(
              modalCustomizationsV2?.line1?.titleText || primaryMessage,
            )}
          </Text>
          <Text
            as="div"
            color="var(--redo-colors-gray-true-500)"
            fontSize="xs"
            fontWeight="thin"
          >
            {HTMLReactParser(
              modalCustomizationsV2?.line1?.subtitleText || secondaryMessage,
            )}
          </Text>
        </div>
      </div>
      <br />
      {(packageProtectionEnabled ||
        modalCustomizationsV2?.line2?.titleText) && (
        <>
          <div className={modalV2.contentItem} data-target="Bullet-2">
            <div>
              <FeaturedPackageCheckIcon height={32} width={32} />
            </div>
            <div>
              <Text as="div" fontSize="md" fontWeight="semibold">
                {HTMLReactParser(
                  modalCustomizationsV2?.line2?.titleText || bulletTwoTitleText,
                )}
              </Text>
              <Text
                as="div"
                color="var(--redo-colors-gray-true-500)"
                fontSize="xs"
                fontWeight="thin"
              >
                {modalCustomizationsV2?.line2?.subtitleText ||
                  bulletTwoSubtitleText}
              </Text>
            </div>
          </div>
          <br />
        </>
      )}
      <div className={modalV2.contentItem} data-target="Bullet-3">
        <div>
          <FeaturedLaptopIcon height={32} width={32} />
        </div>
        <div>
          <Text as="div" fontSize="md" fontWeight="semibold">
            {HTMLReactParser(
              modalCustomizationsV2?.line3?.titleText || bulletThreeTitleText,
            )}
          </Text>
          <Text
            as="div"
            color="var(--redo-colors-gray-true-500)"
            fontSize="xs"
            fontWeight="thin"
          >
            {HTMLReactParser(
              modalCustomizationsV2?.line3?.subtitleText ||
                bulletThreeSubtitleText,
            )}
          </Text>
        </div>
      </div>
      <br />
      <br />

      <Text
        color="var(--redo-colors-gray-true-500)"
        fontSize="xs"
        fontWeight="thin"
      >
        By purchasing Redo, you agree and have read the{" "}
        <a
          className={modalV2.link}
          href="https://www.getredo.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className={modalV2.link}
          href="https://www.getredo.com/terms-conditions"
          rel="noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </a>
        . Redo is subject to Merchant's Return Policy.
        {packageProtectionPlusEnabled && (
          <>
            {" "}
            Shipping protection provided by{" "}
            <a
              className={modalV2.link}
              href="https://shipsurance.com/redo/terms"
              rel="noreferrer"
              target="_blank"
            >
              Shipsurance
            </a>
            .
          </>
        )}
      </Text>
    </>
  );
});
