import { ShippingCoverageZodSchema } from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export interface UpdateRisIntegrationDef {
  input: z.ZodObject<{
    teamId: z.ZodType<string>;
    integrationType: z.ZodString;
    integration: z.ZodNullable<typeof ShippingCoverageZodSchema>;
  }>;
  output: z.ZodNullable<typeof ShippingCoverageZodSchema>;
}

export const updateRisIntegration: UpdateRisIntegrationDef = {
  input: z.object({
    teamId: zExt.objectId(),
    integrationType: z.string(),
    integration: ShippingCoverageZodSchema.nullable(),
  }),
  output: ShippingCoverageZodSchema.nullable(),
};
