/**
 * Enum defining the possible social authentication statuses
 */
export enum SocialAuthStatus {
  Success = "success",
  Error = "error",
  NoMatchingEmail = "no-matching-email",
  NoAuthorizationCode = "no-authorization-code",
  NoIdToken = "no-id-token",
}

/**
 * Enum defining the available social login options (may be limited by team)
 */
export enum SocialLoginOption {
  GOOGLE = "google",
}

/**
 * Interface defining the structure of authentication messages from social login providers
 */
export interface SocialAuthMessage {
  type: "redo-auth-result";
  provider: SocialLoginOption;
  status: SocialAuthStatus;
  email?: string;
  error?: string;
  code?: string;
}

export const isValidSocialAuthMessage = (
  message: MessageEvent["data"],
): message is SocialAuthMessage => {
  return (
    message.type === "redo-auth-result" &&
    "provider" in message &&
    typeof message.provider === "string" &&
    Object.values(SocialLoginOption).includes(
      message.provider as SocialLoginOption,
    )
  );
};

/**
 * Interface for the response from the convertSocialAuthToCustomerAuth RPC
 */
export interface SocialAuthConversionResponse {
  customerAuthToken?: string;
  customer?: { name: string; email: string; customerId: string };
  error?: SocialAuthConversionErrorMessage & string;
  orderId?: string;
}

export enum SocialAuthConversionErrorMessage {
  NO_MATCHING_CUSTOMER = "Failed to get matching customer",
}
