import { z } from "zod";
///
/// AMP is a system that allows for more interactive behavior in emails.
/// Email clients that support AMP require you to get approval from them
/// before they will render your amp emails.
///

export enum AmpClient {
  GMAIL = "gmail",
  YAHOO = "yahoo",
  MAIL_RU = "mail.ru",
}

export enum AmpRegistrationStatus {
  /** A demo email has been sent, but the form hasn't been submitted yet. */
  EMAIL_SENT = "email_sent",
  /** The form has been submitted, but we haven't heard back yet. */
  PENDING = "pending",
  /** The form has been approved. */
  APPROVED = "approved",
  /** The form has been rejected. */
  REJECTED = "rejected",
}

export const ampRegistrationSchema = z.object({
  emailAddress: z.string(),
  client: z.nativeEnum(AmpClient),
  status: z.nativeEnum(AmpRegistrationStatus),
  statusSetAt: z.date(),
});
