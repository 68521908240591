import { z } from "zod";
import {
  addWeight,
  convertWeight,
  Weight,
  WeightSchema,
  WeightUnit,
} from "../outbound-labels/util";
import { RedoShopifyCountryCode } from "./fulfillment-country-code";

export enum HSCodeSource {
  CLASSIFICATION = "CLASSIFICATION",
  MARKETPLACE = "MARKETPLACE",
  MANUAL = "MANUAL",
}

export const CustomsDeclarationSchema = z.object({
  description: z.string(),
  quantity: z.number(),
  itemValue: z.number(),
  itemWeight: WeightSchema,
  harmonizationCode: z.string().nullish(),
  hsCodeSource: z.nativeEnum(HSCodeSource).nullish(),
  countryOfOrigin: z.nativeEnum(RedoShopifyCountryCode),
});
export type CustomsDeclaration = z.infer<typeof CustomsDeclarationSchema>;
export interface ICustomsDeclaration
  extends z.infer<typeof CustomsDeclarationSchema> {}

export function customsDeclarationsAreEqual({
  a,
  b,
}: {
  a?: CustomsDeclaration | null;
  b?: CustomsDeclaration | null;
}) {
  if (!a || !b) {
    return false;
  }

  return (
    a.description === b.description &&
    a.quantity === b.quantity &&
    a.itemValue === b.itemValue &&
    convertWeight(a.itemWeight, WeightUnit.OUNCE).value ===
      convertWeight(b.itemWeight, WeightUnit.OUNCE).value &&
    a.harmonizationCode === b.harmonizationCode &&
    a.countryOfOrigin === b.countryOfOrigin
  );
}

export function getCustomsDeclarationsTotalWeight(
  customsDeclarations: CustomsDeclaration[],
): Weight {
  return customsDeclarations.reduce(
    (total: Weight, declaration) => {
      if (declaration.quantity > 0) {
        return addWeight(total, {
          unit: WeightUnit.OUNCE,
          value: declaration.itemWeight.value * declaration.quantity,
        });
      }
      return total;
    },
    { unit: WeightUnit.OUNCE, value: 0 },
  );
}
