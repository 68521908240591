import { OverlapTransitionStateContext } from "@redotech/react-animation/outlet-transition";
import { OverlapTransitionState } from "@redotech/react-animation/transition";
import { Tabs } from "@redotech/redo-web/tab";
import { ReactElement, useContext } from "react";
import { createPortal } from "react-dom";
import { RedoHorizontalTabs } from "./arbiter-components/tabs/redo-horizontal-tabs";
import { TabsPortalContext } from "./page";

/**
 * @param tabCount - If specified, the number of tabs in the portal. Will be used to dynamically determine if the portal has tab content.
 * @returns
 */
export function TabsPortal({ children }: { children: ReactElement }) {
  const { portalNode } = useContext(TabsPortalContext);

  const transitionState = useContext(OverlapTransitionStateContext);

  if (!portalNode) {
    return null;
  }

  if (!(children.type === Tabs || children.type === RedoHorizontalTabs)) {
    throw new Error(
      "TabsPortal can only render a <Tabs> or <RedoHorizontalTabs> component.",
    );
  }

  if (transitionState === OverlapTransitionState.EXIT) {
    return null;
  }

  return createPortal(children, portalNode);
}
