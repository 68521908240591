import { z } from "zod";

export const getCheckoutOptimizationCustomer = {
  input: z.object({
    emailAddress: z.string().optional(),
    phoneNumber: z.string().optional(),
  }),
  output: z.object({ numberOfOrders: z.number(), tags: z.array(z.string()) }),
};

export type GetCheckoutOptimizationCustomerOutput = z.infer<
  typeof getCheckoutOptimizationCustomer.output
>;
