import { Serializable } from "@redotech/wire-protocol/wire-protocol";
import { z } from "zod";

export const unaryRpc = <
  Input extends Serializable,
  Output extends Serializable,
>(schemas: {
  input: z.ZodType<Input>;
  output: z.ZodType<Output>;
}) => ({ ...schemas, method: "unary" as const });

export const unaryStreamRpc = <
  Input extends Serializable,
  Output extends Serializable,
>(schemas: {
  input: z.ZodType<Input>;
  output: z.ZodType<Output>;
}) => ({ ...schemas, method: "unary-stream" as const });

export type UnaryRpcSchema<Input, Output> = {
  input: z.ZodType<Input>;
  output: z.ZodType<Output>;
  method?: "unary" | undefined;
};

export type UnaryStreamRpcSchema<Input, Output> = {
  input: z.ZodType<Input>;
  output: z.ZodType<Output>;
  method: "unary-stream";
};

export type RpcSchema<Input, Output> =
  | UnaryRpcSchema<Input, Output>
  | UnaryStreamRpcSchema<Input, Output>;

export type RpcClientSchema<Input, Output> = {
  input: Input;
  output: Output;
  method: "unary-stream" | "unary";
};

export type RpcDefinition = { [key: string]: RpcSchema<any, any> };

export type RpcClientDefinition = { [key: string]: RpcClientSchema<any, any> };

export type InferRpcDefinition<T> = T extends RpcDefinition
  ? { [key in keyof T]: InferRpcSchema<T[key]> }
  : never;

type InferRpcSchema<T> =
  T extends UnaryStreamRpcSchema<infer I, infer O>
    ? {
        input: I extends Serializable ? I : "Error: unserializable data";
        output: O extends Serializable ? O : "Error: unserializable data";
        method: "unary-stream";
      }
    : T extends UnaryRpcSchema<infer I, infer O>
      ? {
          input: I extends Serializable ? I : "Error: unserializable data";
          output: O extends Serializable ? O : "Error: unserializable data";
          method: "unary";
        }
      : never;
