import { zExt } from "@redotech/rpc/ext";
import { isDef } from "@redotech/util/checks";
import { z } from "zod";
import { SPECIAL_EXISTING_SHIPPING_METHODS_CHECKOUT_EXPERIENCE } from "../../checkouts/checkout-ab-test-utils";
import { WidgetConfigForCheckoutExperienceSchema } from "../../checkouts/widget";
import { ShippingRateTableArraySchema } from "./shipping-rate-table";

export const DELIVERY_DATE_VARIABLE = "{delivery_date}";
export const TEST_CHECKOUT_OUTBOUND_RATES_NAME = "REDO_CHECKOUT_TEST";
export const TEST_CHECKOUT_OUTBOUND_RATES_TREATMENT_ID_REGEX = new RegExp(
  `${TEST_CHECKOUT_OUTBOUND_RATES_NAME}-(\\S+)`,
);

export enum DeliveryFilterType {
  // Filter out all redo options except the cheapest
  ONLY_CHEAPEST_REDO = "only-cheapest-redo",
  // Filter out all non-redo options
  ONLY_REDO = "only-redo",
  // Filter out all redo options (only useful for tests)
  NO_REDO = "no-redo",
  // Filter out all non-redo options except the cheapest one
  ONLY_CHEAPEST_NON_REDO = "only-cheapest-non-redo",
}

export enum DeliverySortType {
  // Cheapest Redo option, then sort all others by price
  REDO_CHEAPEST_FIRST = "redo-cheapest-first",
  // Show all Redo options before any other shipping options
  REDO_FIRST = "redo-first",
  // Sort all shipping options by price, cheapest first
  // Is essentially "toggle off"
  CHEAPEST_FIRST = "cheapest-first",
}

export enum CoverageProductEnum {
  SHIPPING = "Shipping",
  CHECKOUT = "Checkout",
}

export const maxValue = 1000000000;

const DeliveryFilterTypeEnum = z.nativeEnum(DeliveryFilterType);

const DeliverySortTypeEnum = z.nativeEnum(DeliverySortType);

const FilterSort = z.object({
  filterType: DeliveryFilterTypeEnum.optional(),
  sortType: DeliverySortTypeEnum.optional(),
});

export const CheckoutExperienceZodSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  rateTables: ShippingRateTableArraySchema,
  deleted: z.date().nullish(),
  checkoutWidgetsConfig: WidgetConfigForCheckoutExperienceSchema.nullish(),
});
export type CheckoutExperience = z.infer<typeof CheckoutExperienceZodSchema>;

export const CheckoutExperienceZodArraySchema = z.array(
  CheckoutExperienceZodSchema,
);

export const ShippingCoverageZodSchema = z.object({
  coverage: z.nativeEnum(CoverageProductEnum).nullish(), //I don't think coverage product should live here, but I need this out quickly
  enabled: z.boolean(),
  checkoutOutboundRatesEnabled: z.boolean().optional(),
  demoCheckoutOutboundRatesEnabled: z.boolean().optional(),
  requestShippingDeliveryPermissions: z.boolean().optional(),
  checkoutExperiences: CheckoutExperienceZodArraySchema.optional(),
  rateTables: ShippingRateTableArraySchema,
  deliverySortType: DeliverySortTypeEnum.optional(),
  deliveryFilterType: DeliveryFilterTypeEnum.optional(),
  optIn: FilterSort.optional(),
  optOut: FilterSort.optional(),
});
export type ShippingCoverage = z.infer<typeof ShippingCoverageZodSchema>;

/**
 * WARNING: If passing includeSpecialExistingShippingMethodsCheckoutExperience as
 * true, ensure that it's properly filtered out if it's not a checkout experience
 * that's present and active in the checkout tree.
 */
export function safeParseCheckoutExperienceArray({
  checkoutExperiences,
  includeSpecialExistingShippingMethodsCheckoutExperience = false,
}: {
  checkoutExperiences: unknown[];
  includeSpecialExistingShippingMethodsCheckoutExperience?: boolean | undefined;
}): CheckoutExperience[] {
  const parsedCheckoutExperiences = checkoutExperiences
    .map((checkoutExperience) => {
      const result = CheckoutExperienceZodSchema.safeParse(checkoutExperience);
      if (!result.success) {
        return undefined;
      }
      return result.data;
    })
    .filter(isDef);

  if (includeSpecialExistingShippingMethodsCheckoutExperience) {
    parsedCheckoutExperiences.push(
      SPECIAL_EXISTING_SHIPPING_METHODS_CHECKOUT_EXPERIENCE,
    );
  }

  return parsedCheckoutExperiences;
}
