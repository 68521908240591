import {
  isOutboundCubicSizeByZoneRateTable,
  isOutboundWeightByZoneRateTable,
  isReturnsWeightByZoneRateTable,
  RateTable,
  RateTableType,
  RateTableValueMode,
} from "@redotech/redo-model/rate-table/rate-table";
import {
  RateTableCarrier,
  RateTableServiceLevel,
} from "@redotech/redo-model/rate-table/rate-table-mapping";
import {
  groupInput,
  input,
  InputProvider,
  listInput,
  nonEmptyValidator,
} from "@redotech/ui/form";

/*
 *  Our rate table input is a grid that looks like this (z = zone, m = max weight, x = rate)
 *     z z z z z z
 *   m x x x x x x
 *   m x x x x x x
 *   m x x x x x x
 *
 *  These functions handle the logic for transforming data to and from this grid from the RateTable model
 */

// The rates are the x's in the grid above - get them from the grid
export function tableDataToRateData(tableData: string[][]): string[][] {
  const data = [...tableData];
  const withoutFirstRow = data.slice(1);
  const withoutFirstColumn = withoutFirstRow.map((row) => row.slice(1));
  return withoutFirstColumn;
}

// The zones are the z's in the grid above - get them from the grid
export function tableDataToZones(tableData: string[][]): string[] {
  const firstRow = tableData.at(0);
  const zones = firstRow?.slice(1);
  return zones ?? [];
}

// The min weights are the m's in the grid above - get them from the grid
export function tableDataToMinMaxWeights(
  tableData: string[][],
): { minWeight: number; maxWeight: number }[] {
  // Get rid of the zones row
  const withoutFirstRow = tableData.slice(1);
  const minMaxValues = withoutFirstRow.map((row, index) => {
    const prevRow = withoutFirstRow[index - 1];
    const prevMaxWeight = Number(prevRow?.at(0) ?? 0);
    const maxWeight = Number(row.at(0) ?? 0);
    return { minWeight: prevMaxWeight, maxWeight };
  });

  return minMaxValues;
}

// The max weights are the m's in the grid above - get them from the grid
export function tableDataToMaxValues(tableData: string[][]): string[] {
  return tableData
    .map((row) => row.at(0))
    .filter((value) => value !== undefined);
}

// We only support weight on the y-axis and zone on the x-axis for now
export function rateTableToTableData(rateTable: RateTable): string[][] {
  let maxValues: string[] = [];
  if (
    isOutboundWeightByZoneRateTable(rateTable) ||
    isReturnsWeightByZoneRateTable(rateTable)
  ) {
    maxValues = rateTable.yAxis.values.map((value) =>
      value.maxWeight.toString(),
    );
  } else if (isOutboundCubicSizeByZoneRateTable(rateTable)) {
    maxValues = rateTable.yAxis.values.map((value) =>
      value.maxCubicSize.toString(),
    );
  }

  const withMaxValues = rateTable.data.map((row, rowIndex) => {
    return [
      maxValues.at(rowIndex) ?? "",
      ...row.map((cell) => cell.toString()),
    ];
  });

  const zones = rateTable.xAxis.values;
  // The leftmost cells in the "zone" row are empty
  const zonesWithEmptyCells = ["0", ...zones];
  const zonesAndRates = [zonesWithEmptyCells, ...withMaxValues];

  return zonesAndRates;
}

// The min cubic sizes are the m's in the grid above - get them from the grid
export function tableDataToMinMaxCubicSizes(
  tableData: string[][],
): { minCubicSize: number; maxCubicSize: number }[] {
  // Get rid of the zones row
  const withoutFirstRow = tableData.slice(1);
  const minMaxValues = withoutFirstRow.map((row, index) => {
    const prevRow = withoutFirstRow[index - 1];
    const prevMaxCubicSize = Number(prevRow?.at(0) ?? 0);
    const maxCubicSize = Number(row.at(0) ?? 0);
    return { minCubicSize: prevMaxCubicSize, maxCubicSize };
  });

  return minMaxValues;
}

// Input forms for rate tables

export const rateCardKeyForm = groupInput({
  selectedCarrier: input<RateTableCarrier>(),
  selectedServiceLevel: input<RateTableServiceLevel>(),
  valueMode: input<RateTableValueMode>(),
  rateTableType: input<
    RateTableType.OUTBOUND_SIZE_BY_ZONE | RateTableType.OUTBOUND_WEIGHT_BY_ZONE
  >(),
});

export type RateCardKeyForm = InputProvider.Form<typeof rateCardKeyForm>;
export type RateCardKeyFormValue = InputProvider.Value<typeof rateCardKeyForm>;
export const rateCardKeyFormDefault: RateCardKeyFormValue = {
  selectedCarrier: RateTableCarrier.USPS,
  selectedServiceLevel: "Default",
  valueMode: RateTableValueMode.Spread,
  rateTableType: RateTableType.OUTBOUND_WEIGHT_BY_ZONE,
};

export const rateCardForm = groupInput({
  defaultValue: input<string>(),
  data: listInput(
    () => inputRow,
    () => [],
    (_, index) => index,
  ),
});

export const inputRow = listInput(
  () => input<string>({ validator: nonEmptyValidator }),
  () => "",
  (_, index) => index,
);

export type RateCardForm = InputProvider.Form<typeof rateCardForm>;
export type RateCardValue = InputProvider.Value<typeof rateCardForm>;
