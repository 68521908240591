// extracted by mini-css-extract-plugin
export var addButton = "_23-d";
export var bullets = "_23-e";
export var compact = "_23-2";
export var container = "_23-j";
export var extendedWarrantyModal = "_23-f";
export var learn = "_23-l";
export var learnMore = "_23-g";
export var offeringOption = "_23-3";
export var offeringPrice = "_23-8";
export var offeringSubtitle = "_23-7";
export var offeringTitle = "_23-6";
export var offeringsContainer = "_23-0";
export var pdpTilesBackground = "_23-n";
export var plan = "_23-m";
export var regular = "_23-1";
export var remove = "_23-k";
export var seeDetailsIcon = "_23-i";
export var seeDetailsLink = "_23-h";
export var selectedOption = "_23-4";
export var shieldIcon = "_23-b";
export var shieldIconCompact = "_23-c";
export var subtitle = "_23-a";
export var title = "_23-9";
export var unselectedOption = "_23-5";