export const DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

export const DATE_TIME_FORMAT = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
});

const SHORT_DAY_DATE_FORMAT = new Intl.DateTimeFormat(undefined, {
  weekday: "short",
  month: "short",
  day: "numeric",
});

export function formatDate(
  temporal: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.Instant,
) {
  return DATE_FORMAT.format(new Date(String(temporal)));
}

export function formatDateTime(
  temporal: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.Instant,
) {
  return DATE_TIME_FORMAT.format(new Date(String(temporal)));
}

export function formatShortDayDate(temporal: Temporal.PlainDate) {
  return SHORT_DAY_DATE_FORMAT.format(
    new Date(temporal.year, temporal.month - 1, temporal.day),
  );
}
