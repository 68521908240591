import { z } from "zod";
import {
  AmazonServices,
  UpsServices,
} from "../fulfillments/fulfillment-carriers-and-services";
import { LengthUnit } from "../outbound-labels";
import {
  FedexRateTableServiceLevel,
  RateTableCarrier,
  USPSRateTableServiceLevel,
} from "./rate-table-mapping";

export enum AxisKind {
  ZONE = "zone",
  WEIGHT = "weight",
  CUBIC_SIZE = "cubic_size",
}

export enum WeightUnit {
  OZ = "OZ",
  LB = "LB",
}

export enum RateTableType {
  OUTBOUND_WEIGHT_BY_ZONE = "outbound_weight_by_zone",
  OUTBOUND_SIZE_BY_ZONE = "outbound_size_by_zone",
  RETURNS_WEIGHT_BY_ZONE = "returns_weight_by_zone",
}

export type OutboundRateTableType =
  | RateTableType.OUTBOUND_WEIGHT_BY_ZONE
  | RateTableType.OUTBOUND_SIZE_BY_ZONE;

// Are the values meant to be used as the values we charge the customer (rate) or are they the margins that we add to the base rate (spread)
export enum RateTableValueMode {
  Rate = "Rate",
  Spread = "Spread",
}

const zoneAxisSchema = z.object({
  kind: z.literal(AxisKind.ZONE),
  values: z.array(z.string()),
});

const weightAxisSchema = z.object({
  kind: z.literal(AxisKind.WEIGHT),
  units: z.nativeEnum(WeightUnit),
  values: z.array(z.object({ minWeight: z.number(), maxWeight: z.number() })),
});

const cubicSizeAxisSchema = z.object({
  kind: z.literal(AxisKind.CUBIC_SIZE),
  units: z.nativeEnum(LengthUnit),
  values: z.array(
    z.object({ minCubicSize: z.number(), maxCubicSize: z.number() }),
  ),
});

const serviceLevelsSchema = z.union([
  z.union([z.nativeEnum(USPSRateTableServiceLevel), z.literal("Default")]),
  z.union([z.nativeEnum(FedexRateTableServiceLevel), z.literal("Default")]),
  z.union([z.nativeEnum(UpsServices), z.literal("Default")]),
  z.union([z.nativeEnum(AmazonServices), z.literal("Default")]),
]);

const _baseSchema = z.object({
  version: z.number().nullish(),
  rateTableType: z.nativeEnum(RateTableType),
  valueMode: z.nativeEnum(RateTableValueMode),
});

const outboundWeightByZoneSchema = _baseSchema.extend({
  rateTableType: z.literal(RateTableType.OUTBOUND_WEIGHT_BY_ZONE),
  carrier: z.nativeEnum(RateTableCarrier),
  serviceLevel: serviceLevelsSchema,
  xAxis: zoneAxisSchema,
  yAxis: weightAxisSchema,
  data: z.array(z.array(z.string())),
  defaultValue: z.string(),
});

const outboundCubicSizeByZoneSchema = _baseSchema.extend({
  rateTableType: z.literal(RateTableType.OUTBOUND_SIZE_BY_ZONE),
  carrier: z.nativeEnum(RateTableCarrier),
  serviceLevel: serviceLevelsSchema,
  xAxis: zoneAxisSchema,
  yAxis: cubicSizeAxisSchema,
  data: z.array(z.array(z.string())),
  defaultValue: z.string(),
});

export const returnsWeightByZoneSchema = _baseSchema.extend({
  rateTableType: z.literal(RateTableType.RETURNS_WEIGHT_BY_ZONE),
  xAxis: zoneAxisSchema,
  yAxis: weightAxisSchema,
  data: z.array(z.array(z.string())),
  defaultValue: z.string(),
});

export const rateTableSchema = z
  .discriminatedUnion("rateTableType", [
    outboundWeightByZoneSchema,
    outboundCubicSizeByZoneSchema,
    returnsWeightByZoneSchema,
  ])
  .refine(
    ({ data, yAxis }) => data.length === yAxis.values.length,
    "Invalid number of rows",
  )
  .refine(
    ({ data, xAxis }) =>
      data.every((row) => row.length === xAxis.values.length),
    "Invalid number of columns",
  );

export type OutboundCubicSizeByZoneRateTable = z.infer<
  typeof outboundCubicSizeByZoneSchema
>;

export type OutboundWeightByZoneRateTable = z.infer<
  typeof outboundWeightByZoneSchema
>;

export type OutboundRateTable =
  | OutboundCubicSizeByZoneRateTable
  | OutboundWeightByZoneRateTable;

export type ReturnsWeightByZoneRateTable = z.infer<
  typeof returnsWeightByZoneSchema
>;

export type WeightByZoneRateTable =
  | OutboundWeightByZoneRateTable
  | ReturnsWeightByZoneRateTable;

export function isOutboundWeightByZoneRateTable(
  table: RateTable,
): table is OutboundWeightByZoneRateTable {
  return table.rateTableType === RateTableType.OUTBOUND_WEIGHT_BY_ZONE;
}

export function isOutboundCubicSizeByZoneRateTable(
  table: RateTable,
): table is OutboundCubicSizeByZoneRateTable {
  return table.rateTableType === RateTableType.OUTBOUND_SIZE_BY_ZONE;
}

export function isOutboundRateTable(
  table: RateTable,
): table is OutboundRateTable {
  return (
    isOutboundWeightByZoneRateTable(table) ||
    isOutboundCubicSizeByZoneRateTable(table)
  );
}

export function isReturnsWeightByZoneRateTable(
  table: RateTable,
): table is ReturnsWeightByZoneRateTable {
  return table.rateTableType === RateTableType.RETURNS_WEIGHT_BY_ZONE;
}

const axisSchema = z.union([
  zoneAxisSchema,
  weightAxisSchema,
  cubicSizeAxisSchema,
]);

export type AxisDef = z.infer<typeof axisSchema>;
export type RateTable = z.infer<typeof rateTableSchema>;

export type ShipmentData = {
  [AxisKind.ZONE]: string;
  [AxisKind.WEIGHT]: { unit: WeightUnit; value: number };
  [AxisKind.CUBIC_SIZE]?: { cubic_feet: number };
};

// A rate table is defined by the carrier, service level, rate table type (weight by zone or size by zone) and value mode (rate vs spread)
export function rateTableDefinitionEqual(
  a: OutboundRateTable,
  b: OutboundRateTable,
) {
  return (
    a.carrier === b.carrier &&
    a.serviceLevel === b.serviceLevel &&
    a.rateTableType === b.rateTableType &&
    a.valueMode === b.valueMode
  );
}

export const defaultNewReturnsRateTable: ReturnsWeightByZoneRateTable = {
  rateTableType: RateTableType.RETURNS_WEIGHT_BY_ZONE,
  valueMode: RateTableValueMode.Spread,
  data: [
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485", "1.485"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98", "1.98"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475", "2.475"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
    ["2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97", "2.97"],
  ],
  xAxis: {
    values: ["1", "2", "3", "4", "5", "6", "7", "8"],
    kind: AxisKind.ZONE,
  },
  yAxis: {
    values: [
      { minWeight: 0, maxWeight: 0.0625 },
      { minWeight: 0.0625, maxWeight: 0.125 },
      { minWeight: 0.125, maxWeight: 0.1875 },
      { minWeight: 0.1875, maxWeight: 0.25 },
      { minWeight: 0.25, maxWeight: 0.3125 },
      { minWeight: 0.3125, maxWeight: 0.375 },
      { minWeight: 0.375, maxWeight: 0.4375 },
      { minWeight: 0.4375, maxWeight: 0.5 },
      { minWeight: 0.5, maxWeight: 0.5625 },
      { minWeight: 0.5625, maxWeight: 0.625 },
      { minWeight: 0.625, maxWeight: 0.6875 },
      { minWeight: 0.6875, maxWeight: 0.75 },
      { minWeight: 0.75, maxWeight: 0.8125 },
      { minWeight: 0.8125, maxWeight: 0.875 },
      { minWeight: 0.875, maxWeight: 0.9375 },
      { minWeight: 0.9375, maxWeight: 1 },
      { minWeight: 1, maxWeight: 2 },
      { minWeight: 2, maxWeight: 3 },
      { minWeight: 3, maxWeight: 4 },
      { minWeight: 4, maxWeight: 5 },
      { minWeight: 5, maxWeight: 6 },
      { minWeight: 6, maxWeight: 7 },
      { minWeight: 7, maxWeight: 8 },
      { minWeight: 8, maxWeight: 9 },
      { minWeight: 9, maxWeight: 10 },
      { minWeight: 10, maxWeight: 11 },
      { minWeight: 11, maxWeight: 12 },
      { minWeight: 12, maxWeight: 13 },
      { minWeight: 13, maxWeight: 14 },
      { minWeight: 14, maxWeight: 15 },
      { minWeight: 15, maxWeight: 16 },
      { minWeight: 16, maxWeight: 17 },
      { minWeight: 17, maxWeight: 18 },
      { minWeight: 18, maxWeight: 19 },
      { minWeight: 19, maxWeight: 20 },
      { minWeight: 20, maxWeight: 21 },
      { minWeight: 21, maxWeight: 22 },
      { minWeight: 22, maxWeight: 23 },
      { minWeight: 23, maxWeight: 24 },
      { minWeight: 24, maxWeight: 25 },
      { minWeight: 25, maxWeight: 26 },
      { minWeight: 26, maxWeight: 27 },
      { minWeight: 27, maxWeight: 28 },
      { minWeight: 28, maxWeight: 29 },
      { minWeight: 29, maxWeight: 30 },
      { minWeight: 30, maxWeight: 31 },
      { minWeight: 31, maxWeight: 32 },
      { minWeight: 32, maxWeight: 33 },
      { minWeight: 33, maxWeight: 34 },
      { minWeight: 34, maxWeight: 35 },
      { minWeight: 35, maxWeight: 36 },
      { minWeight: 36, maxWeight: 37 },
      { minWeight: 37, maxWeight: 38 },
      { minWeight: 38, maxWeight: 39 },
      { minWeight: 39, maxWeight: 40 },
      { minWeight: 40, maxWeight: 41 },
      { minWeight: 41, maxWeight: 42 },
      { minWeight: 42, maxWeight: 43 },
      { minWeight: 43, maxWeight: 44 },
      { minWeight: 44, maxWeight: 45 },
      { minWeight: 45, maxWeight: 46 },
      { minWeight: 46, maxWeight: 47 },
      { minWeight: 47, maxWeight: 48 },
      { minWeight: 48, maxWeight: 49 },
      { minWeight: 49, maxWeight: 50 },
    ],
    kind: AxisKind.WEIGHT,
    units: WeightUnit.LB,
  },
  defaultValue: "5",
};
