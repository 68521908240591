import { z } from "zod";

export const verifyOneTimePasscode = {
  input: z.object({
    fourDigitCodeId: z.string(),
    passcode: z.string(),
    customerEmail: z.string(),
    teamId: z.string(),
    currentLocation: z.string().optional(),
  }),
  output: z.object({
    success: z.boolean(),
    customerAuthToken: z.string().optional(),
    multipassUrl: z.string().optional(),
  }),
};
