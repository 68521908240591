// extracted by mini-css-extract-plugin
export var background = "_2h-0";
export var buttonPrimary = "_2h-1";
export var container = "_2h-2";
export var feature = "_2h-5";
export var featureBody = "_2h-6";
export var featureIcon = "_2h-7";
export var featureSummary = "_2h-9";
export var featureTitle = "_2h-8";
export var modal = "_2h-f";
export var modalAction = "_2h-e";
export var modalCloseButton = "_2h-d";
export var modalFeatures = "_2h-4";
export var modalFooter = "_2h-a";
export var modalImageLogo = "_2h-b";
export var modalLogo = "_2h-c";
export var redoModal = "_2h-3";