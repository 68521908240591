import MuiToolTip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import * as classNames from "classnames";
import { ReactNode } from "react";
import * as tooltipStyles from "./tooltip.module.css";
export interface RedoTooltipProps extends TooltipProps {
  leftAlign?: boolean;
  subtitle?: ReactNode;
  darkMode?: boolean;
  paddingOverride?: string | undefined;
}

export const Tooltip: React.FunctionComponent<RedoTooltipProps> = styled(
  function Tooltip({
    className,
    darkMode,
    leftAlign,
    subtitle,
    paddingOverride,
    ...props
  }: RedoTooltipProps) {
    if (!props.title && !subtitle) {
      return props.children;
    }
    return (
      <MuiToolTip
        {...props}
        classes={{ popper: classNames(tooltipStyles.popper, className) }}
        title={
          <div>
            <div className={leftAlign ? tooltipStyles.leftAlign : undefined}>
              {props.title}
            </div>
            {subtitle && (
              <div className={tooltipStyles.subtitle}>{subtitle}</div>
            )}
          </div>
        }
      />
    );
  },
)(({ darkMode, theme, paddingOverride }) => {
  if (darkMode) {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFFFFF",
        color: "#141414",
        fontSize: "var(--redo-body-xsmall-text-size) !important",
        font: "var(--redo-body-font-family)",
        padding: paddingOverride ?? "8px 12px",
        borderRadius: "var(--redo-corner-small-radius)",
        lineHeight: "18px",
        textAlign: "center",
      },
      [`& .${tooltipClasses.arrow}`]: {
        "&::before": { backgroundColor: "#FFFFFF" },
      },
    };
  } else {
    return {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#0F0F0F",
        color: "#FFFFFF",
        fontSize: "var(--redo-body-xsmall-text-size) !important",
        font: "var(--redo-body-font-family)",
        padding: paddingOverride ?? "8px 12px",
        borderRadius: "var(--redo-corner-small-radius)",
        lineHeight: "18px",
        textAlign: "center",
      },
      [`& .${tooltipClasses.arrow}`]: {
        "&::before": { backgroundColor: "#0F0F0F" },
      },
    };
  }
});
