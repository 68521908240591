import { reviewBodySchemaWithAdditionalInferredInfo } from "@redotech/redo-model/review/review-body-schema";
import { array, number, object, string } from "zod";

export const getSortedProductReviews = {
  input: object({ productId: string(), count: number() }),
  output: object({
    reviews: array(reviewBodySchemaWithAdditionalInferredInfo),
    totalCount: number(),
  }),
};
